import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import * as Parse from 'parse';
import _ from 'lodash';

import { useWindowFocus } from './useWindowFocus';

export const useCurrentUser = ({ initialized }) => {
  const userQueryRef = useRef();
  const userSubscriptionRef = useRef();
  // const windowFocussed = useWindowFocus();
  const [parseUser, setParseUser] = useState(initialized ? Parse.User.current() : null);

  const authenticated = initialized ? _.isObject(parseUser) : false;
  const userId = authenticated ? parseUser.id : '';

  useEffect(() => {
    if (initialized) {
      setParseUser(Parse.User.current());
    }
  }, [initialized, userId]);

  const user = useMemo(() => {
    if (!parseUser) {
      return null;
    }
    const user = authenticated ? parseUser.toJSON() : null;
    const roles = _.get(user, 'roles', []);
    const isAdmin = roles.includes('administrator') || roles.includes('system');
    const isFulfillmentUser = roles.includes('fulfillment');
    return {
      ...(user || {}),
      isAdmin,
      isFulfillmentUser,
    };
  }, [parseUser]);

  const subscribe = async () => {
    if (!Parse.User.current()) {
      return;
    }
    try {
      userQueryRef.current = new Parse.Query(Parse.User);
      userQueryRef.current.equalTo('objectId', Parse.User.current().id);
      const queriedUser = await userQueryRef.current.first();
      setParseUser(queriedUser);
      userSubscriptionRef.current = await userQueryRef.current.subscribe();
      userSubscriptionRef.current.on('update', (object) => {
        setParseUser(object);
      });
    } catch (error) {
      console.warn('Subscribe to user failed', error);
    }
  };

  const unsubscribe = () => {
    if (!userSubscriptionRef.current) {
      return;
    }
    userSubscriptionRef.current.unsubscribe();
    userSubscriptionRef.current = null;
  };

  useEffect(() => {
    unsubscribe();
    if (!authenticated) {
      return () => {};
    }
    subscribe();
    return () => unsubscribe();
  }, [authenticated, userId]);

  // Reload user when window gets into focus again?
  // useEffect(() => {
  //   if (windowFocussed) {
  //     fetchData();
  //   }
  // }, [windowFocussed]);

  const logout = useCallback(async () => {
    await Parse.User.logOut();
    setParseUser(null);
  }, [parseUser]);

  const login = useCallback(
    async (username, password) => {
      try {
        await Parse.User.logIn(username, password);
        const user = Parse.User.current();
        // Only Allow admins for now, server will not return any data
        const roles = _.get(user.toJSON(), 'roles', []);
        const isAdmin = roles.includes('administrator') || roles.includes('system');
        const isFulfillmentUser = roles.includes('fulfillment');
        if (!isAdmin && !isFulfillmentUser) {
          await Parse.User.logOut();
          throw new Error('Login failed');
          return;
        }
        setParseUser(user);
      } catch (error) {
        if (error && error.message) {
          throw new Error(error.message);
        }
        console.warn('login failed', error);
        await Parse.User.logOut();
        setParseUser(null);
      }
    },
    [parseUser],
  );

  return {
    user,
    parseUser,
    authenticated,
    logout,
    login,
  };
};
