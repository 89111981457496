import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { routes } from '../../lib/routes';
import { Button, notification } from 'antd';

import { Container, ContentWrapper } from '../../components/Layout/Layout';
import { AdminClassEntryEdit } from '../../components/AdminClassEntryEdit/AdminClassEntryEdit';
import * as Parse from 'parse';

const properties = [
  {
    key: 'processing',
    label: 'Processing',
    type: 'boolean',
    disabled: true,
    extra: '',
  },
  {
    key: 'title',
    label: 'Title',
    type: 'string',
    required: true,
    extra: '',
  },
  {
    key: 'link',
    label: 'Link',
    type: 'string',
    required: true,
    extra: '',
  },
  {
    key: 'description',
    label: 'Description',
    type: 'string',
    required: false,
    extra: '',
  },
  {
    key: 'url',
    label: 'URL',
    type: 'string',
    required: false,
    disabled: true,
    extra: '',
  },
  {
    key: 'productCount',
    label: 'Product Count',
    type: 'number',
    required: false,
    disabled: true,
    extra: '',
  },
  {
    key: 'fieldMapping',
    label: 'Field Mapping (JSON Object)',
    type: 'jsonObject',
    required: true,
    extra: `
      Use these field names for the feed: id, title, brand, price, googleProductCategory, link, imageLink, description, groupId, condition, availability, identifierExists.
      Use these field values from the product: id, sku, title, brand, price, googleProductCategory, link, imageLink, description, shortDescription, groupId, availability, stock, identifierExists,
    `,
  },
  {
    key: 'filters',
    label: 'Filters (JSON Array)',
    type: 'jsonArray',
    required: true,
    extra:
      'Use these props per filter: "field":"field name from fieldMapping", "type": "includes | notIncludes", "value": "some string to compare", "result": "include | exclude"',
  },
  {
    key: 'urlParams',
    label: 'Product Link params',
    type: 'string',
    required: false,
    extra:
      'Append utm parameters as string. The ? or & connector is inserted automatically. Use $id for product id, e.g "utm_term=$id"',
  },
  {
    key: 'priceFormat',
    label: 'Price Format',
    type: 'string',
    required: true,
    extra: 'Enter price format with currency, use $p for price. E.g. "$p EUR"',
  },
  {
    key: 'priceDecimalSeparator',
    label: 'Price Decimal Separator',
    type: 'radio',
    required: true,
    extra: '',
    options: [
      { value: '.', label: '. (Dot)' },
      { value: ',', label: ', (Comma)' },
    ],
  },
];

const ProductFeedsProcessButton = ({ productFeedId }) => {
  const handleProcessFeed = async () => {
    try {
      const result = await Parse.Cloud.run('adminCreateProductFeed', {
        productFeedId,
      });
      notification.success({
        message: 'Feed processing started',
        description: 'The feed will be generated now, this might take a few minutes.',
      });
    } catch (error) {
      console.warn('handleProcessFeed: failed', error);
      notification.error({
        message: 'Cannot process feed',
        description: 'Unfortunately, we cannot process this feed. Please try again.',
      });
    }
  };
  return <Button onClick={handleProcessFeed}>Generate Feed now</Button>;
};

export const ProductFeedsEdit = (props) => {
  const { user, authenticated, match = {}, history } = props;
  const {
    location: { pathname = '' },
  } = history;
  const productFeedId = _.last(pathname.split('/'));

  return (
    <Container>
      <ContentWrapper>
        <AdminClassEntryEdit
          user={user}
          history={history}
          title={'Product Feed'}
          className={'ProductFeed'}
          editRoute={routes.ADMIN_PRODUCT_FEEDS_EDIT}
          listRoute={routes.ADMIN_PRODUCT_FEEDS}
          properties={properties}
          FooterComponent={<ProductFeedsProcessButton productFeedId={productFeedId} />}
        />
      </ContentWrapper>
    </Container>
  );
};
