import React, { useState, useRef, useEffect, useCallback } from 'react';
import * as Parse from 'parse';

import {Button, Col, Row, notification, message, Select, Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';

import { Loader } from '../../components/Loader/Loader';
import {CardContent, CardHeader, CardWrapper, Container, ContentWrapper, Spacer} from "../../components/Layout/Layout";

const { Dragger } = Upload;

export const OrderTransactionImport = (props) => {
  const { user, authenticated, match = {}, history } = props;
  const {
    location: { pathname = '' },
  } = history;

  const [loading, setLoading] = useState(false);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [selectedTransactionType, setSelectedTransactionType] = useState();

  const handleSubmit = async () => {
    // setLoading(true);
    // try {
    //   const result = await Parse.Cloud.run('adminGenerateProductImages', values);
    //   setData(result.data);
    //
    //   notification.success({
    //     message: 'All saved',
    //     description: 'The new entry was saved successfully.',
    //   });
    // } catch (error) {
    //   notification.error({
    //     message: 'Cannot save entry',
    //     description: 'Unfortunately, we cannot save the new entry. Please try again.',
    //   });
    // }
    // setLoading(false);
  };

  const fetchTransactionTypes = useCallback(async () => {
    setLoading(true);
    try {
      const result = await Parse.Cloud.run('adminFetchOrderTransactionImportTypes', {});
      setTransactionTypes(result || []);
      setTimeout(() => setLoading(false), 100);
    } catch (error) {
      notification.error({
        message: 'Cannot fetch transaction types',
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTransactionTypes();
  }, []);

  const { serverURL } = Parse;

  const uploadProps = {
    name: 'orderTransactionImport',
    action: `${serverURL}/admin/order-transactions/import`,
    headers: {
      'X-User-Id': user.objectId,
      Authorization: user.sessionToken,
    },
    data: {
      type: selectedTransactionType,
    },
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        const {
          file: { response },
        } = info;
        console.log('INFO', info);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        console.warn('Upload failed', status, info);
      }
    },
  };

  const handleTypeSelect = (value) => {
    setSelectedTransactionType(value);
  }

  return (
    <Container>
      <ContentWrapper>
        <CardWrapper>
          <CardHeader>
          <h1>Order Transaction Import</h1>
          </CardHeader>
          <CardContent>
            {loading ? <Loader /> : null}


            <Row>
              <Col>
                <Select onChange={handleTypeSelect} style={{ width: '200px'}}>
                  {transactionTypes.map(t => {
                    return (
                        <Select.Option key={t}>{t}</Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            <Spacer/>

            <Row>
              <Col>
                <Dragger {...uploadProps}>
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>Click or drag files to upload your pictures</p>
                  <p className='ant-upload-hint'>Upload one or multiples pictures.</p>
                </Dragger>
              </Col>
            </Row>

            <Spacer/>
            <Spacer/>
          </CardContent>
        </CardWrapper>
      </ContentWrapper>
    </Container>
  );
};
