import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { routes } from '../../lib/routes';

import { Container, ContentWrapper, Spacer, CardWrapper } from '../../components/Layout/Layout';
import { AdminClassEntryEdit } from '../../components/AdminClassEntryEdit/AdminClassEntryEdit';

const properties = [
  {
    key: 'key',
    label: 'Key',
    type: 'string',
    required: true,
    extra: '',
  },
  {
    key: 'description',
    label: 'Description',
    type: 'string',
    required: false,
    extra: '',
  },
  {
    key: 'type',
    label: 'Type',
    type: 'radio',
    required: true,
    extra: '',
    options: [
      { value: 'string', label: 'String' },
      { value: 'number', label: 'Number' },
      { value: 'boolean', label: 'Boolean' },
      { value: 'json', label: 'Json' },
    ],
  },
  {
    key: 'value',
    label: 'Value',
    type: 'string',
    extra: 'Use this for string | number | boolean',
  },
  {
    key: 'valueJson',
    label: 'Value JSON',
    type: 'textJson',
    extra: 'Use this for JSON',
  },
  {
    key: 'public',
    label: 'Public',
    type: 'checkbox',
    extra: '',
  },
];

export const ConfigParamsEdit = (props) => {
  const { user, authenticated, match = {}, history } = props;

  return (
    <Container>
      <ContentWrapper>
        <AdminClassEntryEdit
          user={user}
          history={history}
          title={'Config Param'}
          className={'ConfigParam'}
          editRoute={routes.ADMIN_CONFIG_PARAMS_EDIT}
          listRoute={routes.ADMIN_CONFIG_PARAMS}
          properties={properties}
        />
      </ContentWrapper>
    </Container>
  );
};
