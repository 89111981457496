import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as Parse from 'parse';

import { useWindowFocus } from './useWindowFocus';

export const useActiveJobLogs = ({ user, fetchActive = true, fetchPrevious = false, type }) => {
  const jobLogsRef = useRef();
  const [jobLogs, setJobLogs] = useState([]);
  const [previousJobLogs, setPreviousJobLogs] = useState([]);
  const queryRef = useRef();
  const querySubscriptionRef = useRef();
  const windowFocussed = useWindowFocus();

  const updateItem = async (nextItem) => {
    jobLogsRef.current = jobLogsRef.current.map((item) => {
      if (`${nextItem.objectId}` === nextItem.objectId) {
        return nextItem;
      }
      return item;
    });
    setJobLogs(jobLogsRef.current);
  };

  const cancelById = async (jobLogId) => {
    const JobLog = Parse.Object.extend('JobLog');
    const query = new Parse.Query(JobLog);
    query.equalTo('objectId', jobLogId);
    const jobLog = await query.first({});
    if (jobLog) {
      jobLog.set('completed', true);
      await jobLog.save(null, {});
    }
  };

  const addItem = async (nextItem) => {
    // Check if job already in list
    const findJobLog = jobLogs.find((item) => item.objectId === nextItem.objectId);
    if (findJobLog) {
      return;
    }
    // Add to ref and state
    jobLogsRef.current = [...jobLogsRef.current, nextItem];
    setJobLogs(jobLogsRef.current);
  };

  const removeItem = async (removedItem) => {
    // Add to ref and state
    jobLogsRef.current = jobLogsRef.current.filter((item) => item.objectId !== removedItem.objectId);
    setJobLogs(jobLogsRef.current);
  };

  const subscribe = async () => {
    if (!queryRef.current) {
      return;
    }
    querySubscriptionRef.current = await queryRef.current.subscribe();
    querySubscriptionRef.current.on('create', (object) => {
      // console.log('JobLog created', object);
      addItem(object.toJSON());
    });
    querySubscriptionRef.current.on('update', (object) => {
      // console.log('JobLog updated', object);
      addItem(object.toJSON());
      updateItem(object.toJSON());
    });
    querySubscriptionRef.current.on('enter', (object) => {
      // console.log('JobLog entered', object);
    });
    querySubscriptionRef.current.on('leave', (object) => {
      // console.log('JobLog left', object);
      removeItem(object.toJSON());
    });
    querySubscriptionRef.current.on('delete', (object) => {
      // console.log('JobLog deleted', object);
    });
    querySubscriptionRef.current.on('close', () => {
      // console.log('JobLog subscription closed');
    });
  };

  const unsubscribe = async () => {
    if (!querySubscriptionRef.current) {
      return;
    }
    querySubscriptionRef.current.unsubscribe();
    querySubscriptionRef.current = null;
  };

  useEffect(() => {
    return () => unsubscribe();
  }, []);

  const fetchData = useCallback(async () => {
    if (!user) {
      return;
    }
    await unsubscribe();

    const JobLog = Parse.Object.extend('JobLog');
    queryRef.current = new Parse.Query(JobLog);
    // Create parse user from current user
    const ParseUser = Parse.Object.extend('_User');
    const parseUser = new ParseUser();
    parseUser.id = user.objectId;
    queryRef.current.equalTo('user', parseUser);
    if (type) {
      queryRef.current.equalTo('type', type);
    }
    queryRef.current.notEqualTo('completed', true);
    queryRef.current.notEqualTo('error', true);
    queryRef.current.notEqualTo('success', true);
    queryRef.current.descending('createdAt');
    const queryResult = await queryRef.current.find({});
    // Keep ref for updates via subscription
    jobLogsRef.current = queryResult.map((jobLog) => jobLog.toJSON());
    setJobLogs(jobLogsRef.current);

    // Subscribe to query
    await subscribe();
  }, [jobLogs, setJobLogs, subscribe, user, type]);

  useEffect(() => {
    if (fetchActive) {
      fetchData();
    }
  }, [user, fetchActive]);

  useEffect(() => {
    if (windowFocussed) {
      fetchData();
    }
  }, [windowFocussed]);

  const fetchPreviousData = useCallback(async () => {
    const JobLog = Parse.Object.extend('JobLog');
    const query = new Parse.Query(JobLog);
    if (user) {
      // Create parse user from current user
      const ParseUser = Parse.Object.extend('_User');
      const parseUser = new ParseUser();
      parseUser.id = user.objectId;
      query.equalTo('user', parseUser);
    }
    if (type) {
      query.equalTo('type', type);
    }
    query.limit(50);
    query.descending('createdAt');
    const queryResult = await query.find({});
    setPreviousJobLogs(queryResult.map((jobLog) => jobLog.toJSON()));
  }, [setPreviousJobLogs, user, type]);

  useEffect(() => {
    if (fetchPrevious) {
      fetchPreviousData();
    }
  }, [user, fetchPrevious]);

  return { jobLogs, previousJobLogs, cancelById };
};
