import React from 'react';
import { routes } from '../../lib/routes';
import { AdminClassEntryList } from '../../components/AdminClassEntryList/AdminClassEntryList';

const tableConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
  },
  {
    title: 'Reply to',
    dataIndex: 'replyTo',
    key: 'replyTo',
  },
];

export const EmailTemplates = ({ user, authenticated, history }) => {
  return (
    <AdminClassEntryList
      history={history}
      title={'Email Template'}
      className={'EmailTemplate'}
      tableConfig={tableConfig}
      editRoute={routes.ADMIN_EMAIL_TEMPLATES_EDIT}
    />
  );
};
