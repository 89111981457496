import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {UI, colors} from '../../lib/theme';
import _ from 'lodash';
import moment from 'moment';
import * as Parse from 'parse';

import {Button, notification, Input, Row, Col, Checkbox, Avatar, Tag, Table} from 'antd';

import {
    CardContent,
    CardFooter,
    CardHeader,
    CardWrapper,
    Container,
    ContentWrapper,
    Spacer
} from "../../components/Layout/Layout";
import {formatPrice, getSwLink} from "../../lib/utils";
import {routes} from "../../lib/routes";
import {CustomerAffiliate} from "../../components/CustomerAffiliate/CustomerAffiliate";
import {CustomerOrders} from "../../components/CustomerOrders/CustomerOrders";

const RowUnderline = styled(Row)`
    border-bottom: 1px solid ${colors.VERY_LIGHT_GREY};
`;

const devicesTableColumns = [
    {
        title: 'Id',
        dataIndex: 'objectId',
        key: 'objectId',
    },
    {
        title: 'Installed At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt, record) => moment(createdAt).format(),
    },
    {
        title: 'Last Active',
        dataIndex: 'lastActiveAt',
        key: 'lastActiveAt',
        render: (lastActiveAt, record) => moment(lastActiveAt).format(),
    },
    {
        title: 'Language',
        dataIndex: 'language',
        key: 'language',
    },
    {
        title: 'Push Enabled',
        dataIndex: 'pushAllowed',
        key: 'pushAllowed',
        render: (pushAllowed, record) => pushAllowed ? 'YES' : 'NO',
    },
    {
        title: 'Token',
        dataIndex: 'pushToken',
        key: 'pushToken',
        render: (pushToken, record) => <small>{pushToken}</small>,
    },
    {
        title: 'App Version',
        dataIndex: 'appVersion',
        key: 'appVersion',
    },
    {
        title: 'OS Version',
        dataIndex: 'osVersion',
        key: 'osVersion',
        render: (osVersion, record) => `${record.platform} - ${osVersion}`,
    },
]

export const CustomerSearch = ({user, authenticated, history}) => {
    const [loading, setLoading] = useState(false);
    const [searchState, setSearchState] = useState({});
    const searchStateRef = useRef({});

    const [customers, setCustomers] = useState([]);
    const [devices, setDevices] = useState([]);
    const [activeCustomer, setActiveCustomer] = useState(null);
    const [activeReferralData, setActiveReferralData] = useState(null);

    const handleInputChange = useCallback((inputName) => {
        return ({target: {value}}) => {
            searchStateRef.current = {
                ...searchStateRef.current,
                [inputName]: value,
            };
            setSearchState(searchStateRef.current);
        };
    }, []);

    const handleSearch = useCallback(async (searchParams) => {
        setLoading(true);
        try {
            const result = await Parse.Cloud.run('adminFindShopwareCustomers', searchParams, {});
            setCustomers(result.customers || []);
            if (_.size(result.customers) === 1) {
                setActiveCustomer(result.customers[0]);
                const { devices = [] } = await Parse.Cloud.run('adminFetchCustomerDevices', {
                    customerId: result.customers[0].id,
                }, {});
                setDevices(devices);
            }
        } catch (error) {
            console.log('adminSearchCustomer failed', error);
            notification.error({
                message: 'Cannot find any customers',
            });
        }
        setLoading(false);
    }, []);

    const { location: { pathname = '' } } = history;
    const paramCustomerId = _.last(pathname.split('/'));
    const customerId = _.get(activeCustomer, 'id', '');
    useEffect(() => {
        if (!activeCustomer && _.size(paramCustomerId) > 24) {
            handleSearch({ 'id': paramCustomerId });
        }
    }, [activeCustomer, paramCustomerId]);

    const handleSearchClear = useCallback(() => {
        searchStateRef.current = {};
        setSearchState(searchStateRef.current);
        setActiveCustomer(undefined);
    }, []);

    const fetchReferralData = async () => {
        try {
            const customerDate = _.get(activeCustomer, 'customFields.mip_customer_woo_registered', activeCustomer.createdAt);
            const result = await Parse.Cloud.run('fetchCustomerReferral', {
                registeredAt: moment(customerDate).unix(),
                customerId: activeCustomer.id,
            }, {});
            if (result && _.size(result.link)) {
                setActiveReferralData(result);
            }
        } catch (error) {
            console.log('adminSearchCustomer failed', error);
            notification.error({
                message: 'Cannot find customer referrals',
            });
        }
    }

    useEffect(() => {
        if (!activeCustomer || !_.size(activeCustomer.id)) {
            return;
        }
        fetchReferralData();
    }, [activeCustomer]);

    const handleReferralVoucherCreate = async () => {
        if (!activeCustomer || !_.size(activeCustomer.id)) {
            return;
        }
        try {
            const customerDate = _.get(activeCustomer, 'customFields.mip_customer_woo_registered', activeCustomer.createdAt);
            const result = await Parse.Cloud.run('createReferralVoucher', {
                registeredAt: moment(customerDate).unix(),
                customerId: activeCustomer.id,
            }, {});
            fetchReferralData();
        } catch (error) {
            console.log('adminSearchCustomer failed', error);
            notification.error({
                message: 'Cannot find customer referrals',
            });
        }
    }

    useEffect(() => {
        if (!activeCustomer || !_.size(activeCustomer.id)) {
            return;
        }
        window.history.pushState('', '', routes.ADMIN_CUSTOMER_SEARCH_DETAIL.replace(':id', activeCustomer.id));
    }, [activeCustomer]);

    return (
        <Container>
            <ContentWrapper>
                <h1>Customer Search</h1>

                <CardWrapper>
                    <CardContent>
                        <Row gutter={[16, 16]}>
                            <Col md={5} sm={24}>
                                <Input
                                    placeholder='Fristname'
                                    onChange={handleInputChange('firstname')}
                                    style={{width: '100%'}}
                                    value={searchState.firstname || ''}
                                />
                            </Col>
                            <Col md={5} sm={24}>
                                <Input
                                    placeholder='Lastname'
                                    onChange={handleInputChange('lastname')}
                                    style={{width: '100%'}}
                                    value={searchState.lastname || ''}
                                />
                            </Col>
                            <Col md={5} sm={24}>
                                <Input
                                    placeholder='E-Mail'
                                    onChange={handleInputChange('email')}
                                    style={{width: '100%'}}
                                    value={searchState.email || ''}
                                />
                            </Col>
                            <Col md={5} sm={24}>
                                <Input
                                    placeholder='Referral Code REFXXX'
                                    onChange={handleInputChange('referralCode')}
                                    style={{width: '100%'}}
                                    value={searchState.referralCode || ''}
                                />
                            </Col>
                            <Col md={4} sm={24}>
                                <Button onClick={handleSearchClear}>Clear</Button>
                                <Button type='primary' onClick={() => handleSearch(searchState)} loading={loading}>Search</Button>
                            </Col>
                        </Row>
                    </CardContent>
                    <CardContent>
                        {customers.map((customer) => {
                            return (
                                <RowUnderline gutter={[16, 16]} key={customer.id}>
                                    <Col md={4} sm={12}>
                                        {customer.firstName}
                                    </Col>
                                    <Col md={4} sm={12}>
                                        {customer.lastName}
                                    </Col>
                                    <Col md={3} sm={12}>
                                        {customer.customerNumber}
                                    </Col>
                                    <Col md={5} sm={12}>
                                        {customer.email}
                                    </Col>
                                    <Col md={5} sm={12}>
                                        {(customer.customFields || {}).customer_referral_code}
                                    </Col>
                                    <Col md={3} sm={24}>
                                        <Button onClick={() => setActiveCustomer(customer)} type='primary'>Open</Button>
                                    </Col>
                                </RowUnderline>
                            );
                        })}
                    </CardContent>
                </CardWrapper>

                <Spacer/>

                {activeCustomer ? (
                    <CardWrapper>
                        <CardHeader><h2>{activeCustomer.firstName} {activeCustomer.lastName}</h2></CardHeader>
                        <CardContent>
                            <RowUnderline gutter={[16, 16]}>
                                <Col md={6} sm={24}>
                                    Customer ID:
                                </Col>
                                <Col md={6} sm={24}>
                                    {_.get(activeCustomer, 'id', '')} (<a href={getSwLink('/sw/customer/detail/', _.get(activeCustomer, 'id', ''))} target='_blank'>Shopware</a>)
                                </Col>
                            </RowUnderline>
                            <RowUnderline gutter={[16, 16]}>
                                <Col md={6} sm={24}>
                                    Referrals activated:
                                </Col>
                                <Col md={6} sm={24}>
                                    {_.get(activeCustomer, 'customFields.feature_referrals_enabled', false) ? 'YES' : 'NO'}
                                </Col>
                            </RowUnderline>
                            <RowUnderline gutter={[16, 16]}>
                                <Col md={6} sm={24}>
                                    Own referral code:
                                </Col>
                                <Col md={6} sm={24}>
                                    {_.get(activeCustomer, 'customFields.referral_code', '-')}
                                </Col>
                            </RowUnderline>
                            <RowUnderline gutter={[16, 16]}>
                                <Col md={6} sm={24}>
                                    Referred by other code:
                                </Col>
                                <Col md={6} sm={24}>
                                    {_.get(activeCustomer, 'customFields.customer_referral_code', '-')}
                                </Col>
                            </RowUnderline>
                            <RowUnderline gutter={[16, 16]}>
                                <Col md={6} sm={24}>
                                    Referral welcome voucher used:
                                </Col>
                                <Col md={6} sm={24}>
                                    {_.get(activeCustomer, 'customFields.customer_referral_used', false) ? 'YES' : 'NO'}
                                </Col>
                            </RowUnderline>
                            <RowUnderline gutter={[16, 16]}>
                                <Col md={6} sm={24}>
                                    Affiliate activated:
                                </Col>
                                <Col md={6} sm={24}>
                                    {_.get(activeCustomer, 'customFields.feature_affiliate_enabled', false) ? 'YES' : 'NO'}
                                </Col>
                            </RowUnderline>
                        </CardContent>
                        {/*<CardFooter>*/}
                        {/*    <div>*/}
                        {/*        <Button onClick={() => {}} type='primary'>Shopware</Button>*/}
                        {/*        {_.size(activeCustomer.customFields.mip_customer_woo_registered) ? (*/}
                        {/*            <Button onClick={() => {}} type='primary'>WooCommerce</Button>*/}
                        {/*        ) : null}*/}
                        {/*    </div>*/}
                        {/*</CardFooter>*/}
                    </CardWrapper>
                ) : null}
                <Spacer/>

                <CustomerOrders user={user} history={history} activeCustomer={activeCustomer} />

                <Spacer/>
                {activeCustomer ? (
                    <CardWrapper>
                        <CardHeader><h2>App Installations <small>({_.size(devices)})</small></h2></CardHeader>
                        {devices.length ? (
                            <Table
                                columns={devicesTableColumns}
                                dataSource={devices}
                                pagination={{
                                    pageSize: 100,
                                }}
                                rowKey='objectId'
                            />
                        ) : (
                            <CardContent>No app installations found</CardContent>
                        )}
                    </CardWrapper>
                ) : null}

                <Spacer/>

                {activeReferralData ? (
                    <CardWrapper>
                        <CardHeader><h2>Referrals</h2></CardHeader>
                        <CardContent>
                            <Row gutter={[16, 16]}>
                                <Col md={8} sm={24}>
                                    <h1>{formatPrice(activeReferralData.totalValue)}</h1>
                                    <p>Totally earned</p>
                                </Col>
                                <Col md={8} sm={24}>
                                    <h1>{formatPrice(activeReferralData.voucherValue)}</h1>
                                    <p>Available by vouchers</p>
                                </Col>
                                <Col md={8} sm={24}>
                                    <h1>{formatPrice(activeReferralData.newValue)}</h1>
                                    <p>For new vouchers</p>
                                </Col>
                            </Row>
                        </CardContent>
                        <CardContent>
                            <Input value={activeReferralData.link} disabled={true}/>
                        </CardContent>
                        <CardFooter>
                            <p>{activeReferralData.visitCount || 0} Visits</p>
                        </CardFooter>
                        <CardHeader><h3>Personal Vouchers</h3></CardHeader>
                        {!_.size(activeReferralData.vouchers) ? (
                            <CardFooter>No vouchers created yet by this customer</CardFooter>
                        ) : null}
                        <CardContent>
                            {activeReferralData.vouchers.map((voucher) => {
                                return (
                                    <RowUnderline gutter={[16, 16]} key={voucher.code}>
                                        <Col md={8} sm={12}>
                                            {voucher.code} (<a href={getSwLink('/sw/promotion/v2/detail/', voucher.id)}  target="_blank">Shopware</a>)
                                        </Col>
                                        <Col md={8} sm={12}>
                                            {formatPrice(voucher.value)}
                                        </Col>
                                        <Col md={8} sm={12}>
                                            <Tag>{voucher.used ? 'USED' : 'NEW'}</Tag>
                                        </Col>
                                    </RowUnderline>
                                );
                            })}
                        </CardContent>
                        {activeReferralData.newValue > 0 ? (
                            <CardFooter>
                                <Button type='primary' onClick={handleReferralVoucherCreate}>Create Voucher with {formatPrice(activeReferralData.newValue)}</Button>
                            </CardFooter>
                        ) : null}
                        <CardHeader><h3>Referred Orders</h3></CardHeader>
                        {!_.size(activeReferralData.orders) ? (
                            <CardFooter>No orders created yet with this referral link</CardFooter>
                        ) : null}
                        <CardContent>
                            {activeReferralData.orders.map((order) => {
                                return (
                                    <RowUnderline gutter={[16, 16]} key={order.orderId}>
                                        <Col md={6} sm={12}>
                                            {order.orderId} (<a href={routes.ORDER_SEARCH_DETAILS.replace(':orderId', order.orderId)} target="_blank">Fulfillment</a>)
                                        </Col>
                                        <Col md={6} sm={12}>
                                            {order.orderedAt}
                                        </Col>
                                        <Col md={6} sm={12}>
                                            {order.value}
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <Tag>{_.get(order, 'state', '').toUpperCase()}</Tag>
                                        </Col>
                                    </RowUnderline>
                                );
                            })}
                        </CardContent>
                    </CardWrapper>
                ) : null}
                <Spacer/>

                <CustomerAffiliate user={user} history={history} activeCustomer={activeCustomer}/>

                <Spacer/>
            </ContentWrapper>
        </Container>
    );
};
