
import React, { Component } from 'react';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';

import { OrdersFulfillmentTableByFilters } from '../../components/OrdersFulfillmentTableByFilters/OrdersFulfillmentTableByFilters';
import {CardWrapper, Container, ContentWrapper} from "../../components/Layout/Layout";

const filterTags = {
  shippingCountries: ['CH'],
};
const fulfillmentFilterTags = {};
const warehouses = [];

export class OrderCH extends Component {
  componentDidMount() {}

  render() {
    const { user } = this.props;
    return (
      <Container>
        <ContentWrapper>
          <CardWrapper>
            <OrdersFulfillmentTableByFilters
              title={`Orders Switzerland`}
              user={user}
              filterTags={filterTags}
              fulfillmentFilterTags={fulfillmentFilterTags}
              warehouses={warehouses}
              showOrderStatistics={false}
            />
          </CardWrapper>
        </ContentWrapper>
      </Container>
    );
  }
}
