import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as Parse from 'parse';
import _ from 'lodash';

export const useWarehouseStock = ({ user, productIds, live = true, skipQuery = false, warehouseId }) => {
  const warehouseStockRef = useRef([]);
  const [warehouseStocks, setWarehouseStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const warehouseStockQueryRef = useRef();
  const warehouseStockQuerySubscriptionRef = useRef();

  const updateWarehouseStock = async (updatedWarehouseStock) => {
    // Update ref and state
    warehouseStockRef.current = warehouseStockRef.current.map((warehouseStock) => {
      if (`${warehouseStock.objectId}` === updatedWarehouseStock.objectId) {
        return {
          ...warehouseStock,
          ...updatedWarehouseStock,
        };
      }
      return warehouseStock;
    });
    setWarehouseStocks(warehouseStockRef.current);
  };

  const subscribeWarehouseStock = async () => {
    if (!warehouseStockQueryRef.current) {
      return;
    }
    // First unsubscribe before create a new subscription
    unsubscribeWarehouseStock();
    console.log('Subscribe warehouseStock');
    warehouseStockQuerySubscriptionRef.current = await warehouseStockQueryRef.current.subscribe();
    // lineItemFulfillmentQuerySubscriptionRef.current.on('create', (object) => {
    //   console.log('lineItemFulfillment created', object);
    // });
    warehouseStockQuerySubscriptionRef.current.on('update', (object) => {
      updateWarehouseStock(object.toJSON());
    });
    // lineItemFulfillmentQuerySubscriptionRef.current.on('enter', (object) => {
    //   console.log('lineItemFulfillment entered', object);
    // });
    // // Delete and Leave should never happen
    // lineItemFulfillmentQuerySubscriptionRef.current.on('leave', (object) => {
    //   console.log('lineItemFulfillment left', object);
    // });
    // lineItemFulfillmentQuerySubscriptionRef.current.on('delete', (object) => {
    //   console.log('lineItemFulfillment deleted', object);
    // });
  };

  const unsubscribeWarehouseStock = async () => {
    if (!warehouseStockQuerySubscriptionRef.current) {
      return;
    }
    console.log('Unsubscribe warehouseStock');
    warehouseStockQuerySubscriptionRef.current.unsubscribe();
    warehouseStockQuerySubscriptionRef.current = null;
  };

  useEffect(() => {
    return () => unsubscribeWarehouseStock();
  }, []);

  const fetchData = useCallback(async () => {
    if (!_.size(productIds) || !_.size(warehouseId) || skipQuery) {
      return;
    }
    setLoading(true);

    const Warehouse = Parse.Object.extend('Warehouse');
    const warehouse = new Warehouse();
    warehouse.id = warehouseId;

    const WarehouseStock = Parse.Object.extend('PlentyWarehouseStock');
    const warehouseStockQuery = new Parse.Query(WarehouseStock);
    warehouseStockQuery.equalTo('warehouse', warehouse);
    warehouseStockQuery.containedIn('externalId', productIds);
    warehouseStockQuery.include('plentyProduct');
    const warehouseStockResult = await warehouseStockQuery.find({});

    if (!warehouseStockResult) {
      console.warn('UH OH, no warehouse stock query');
      setLoading(false);
      return;
    }
    warehouseStockRef.current = warehouseStockResult.map((w) => w.toJSON());
    setWarehouseStocks(warehouseStockRef.current || []);

    warehouseStockQueryRef.current = warehouseStockQuery;

    // Subscribe to query
    if (live) {
      subscribeWarehouseStock();
    }
  }, [setWarehouseStocks, warehouseId, productIds, live, setLoading, skipQuery]);

  useEffect(() => {
    if (skipQuery) {
      return;
    }
    fetchData();
  }, [skipQuery, warehouseId]);

  return {
    warehouseStocks,
    loading,
  };
};
