import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import * as Parse from 'parse';
import _ from 'lodash';
import moment from 'moment';
import { UI, colors } from '../../lib/theme';
import { Link } from 'react-router-dom';

import { useLineItemFulfillment } from '../../hooks/useLineItemFulfillment';
import { useOrderFulfillment } from '../../hooks/useOrderFulfillment';
import { useOrderNotes } from '../../hooks/useOrderNotes';
import { useOrderFulfillmentHistory } from '../../hooks/useOrderFulfillmentHistory';
import { DATE_TIME_FORMAT } from '../../lib/constants';

import { Col, Row, Table, Tag, Button, notification, Input, Alert, Select } from 'antd';
import {ArrowLeftOutlined, CheckCircleOutlined, ReloadOutlined, StopOutlined} from '@ant-design/icons';
import {
  orderStatuses,
  getOrderFulfillmentStatus,
  getDuration,
  getWcOrderEditLink, getPlentyOrderLink,
} from '../../lib/utils';

import {
  Container,
  ContentWrapper,
  CardWrapper,
  CardContent,
  CardHeader,
  Spacer,
  PullRight,
} from '../../components/Layout/Layout';
import {OrderPackageTable} from "../../components/OrderPackageTable/OrderPackageTable";
import {OrderExternalTable, OrderFulfillmentTable, OrderProductionTable} from "../../components/OrderFulfillmentTable/OrderFulfillmentTable";
import {OrderFulfillmentAddress} from "../../components/OrderFulfillmentAddress/OrderFulfillmentAddress";
import {useWarehouseStock} from "../../hooks/useWarehouseStock";
import {OrderFulfillmentDetailsEmailForm} from "../../components/OrderFulfillmentDetailsEmailForm/OrderFulfillmentDetailsEmailForm";

const CommentSidebar = styled.div`
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  border-left: 1px solid ${colors.TRUE_BLACK20};
  background: ${colors.TRUE_BLACK10};
  padding: 15px;
  position: fixed;
  width: 250px;
  top: ${UI.NAVBAR_HEIGHT}px;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    top: auto;
    left: auto;
    right: auto;
  }
`;
const ContentWrapperWithPadding = styled(ContentWrapper)`
  padding-right: 265px;
  @media only screen and (max-width: 768px) {
    padding-right: 15px;
  }
`;
const ContentHeader = styled.div`
  position: fixed;
  top: ${UI.NAVBAR_HEIGHT};
  left: 0;
  right: 0;
  height: 55px;
  padding: 10px 15px;
  padding-right: 265px;
  background-color: ${colors.WHITE};
  border-bottom: 1px solid ${colors.TRUE_BLACK20};
  z-index: 100;
  display: block;

  @media only screen and (max-width: 991px) {
    height: 100px;
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    padding-right: 15px;
    height: auto;
  }
`;
const TitleLabel = styled.h1`
  font-size: 16px;
  line-height: 16px;
`;
const TitleLastUpdate = styled.span`
  font-size: 10px;
  margin-top: -2px;
  display: block;
`;
const TopSpacer = styled.div`
  height: 0;
  width: 100%;
  @media only screen and (max-width: 991px) {
    height: 100px;
  }
  @media only screen and (max-width: 768px) {
    height: 0;
  }
`;

export const OrderFulfillmentDetails = (props) => {
  const {
    user,
    match: { params },
    location: { pathname = '' },
  } = props;
  const orderId = _.last(pathname.split('/'));
  const [orderNote, setOrderNote] = useState('');
  const memoizedOrderId = useMemo(() => [orderId], [orderId]);

  const { history } = useOrderFulfillmentHistory({ user, orderId });
  const { addOrderNote } = useOrderNotes({ user, orderId });

  const { loading: orderLoading, orders, claimOrder, unclaimOrder, bounceOrder, setOrderWarehouse, refetchOrders } = useOrderFulfillment({
    user,
    orderIds: memoizedOrderId,
  });
  const order = _.first(orders);

  const fulfillmentUserId = _.get(order, 'fulfillment.fulfillmentUser.objectId');
  const fulfillmentUsername = _.get(order, 'fulfillment.fulfillmentUser.username');
  const currentUserFulfills = _.get(user, 'objectId', '') === fulfillmentUserId;

  const orderStatus = _.get(order, 'status');
  const plentyStatusId = _.get(order, 'plentyStatusId', 0);
  const postStatus = _.get(order, 'postStats');
  const orderStatusConfig = _.get(orderStatuses, postStatus);

  const comments = _.get(order, 'comments', []);

  const orderFulfillment = _.get(order, 'fulfillment');
  const plentyId = _.get(orderFulfillment, 'plentyId', '');
  const orderFulfillmentWarehouse = _.get(orderFulfillment, 'warehouse');
  const orderFulfillmentWarehouseId = _.get(orderFulfillmentWarehouse, 'objectId');
  const orderFulfillmentStatus = getOrderFulfillmentStatus(orderFulfillment);
  const orderDeliveries = _.get(order, 'deliveries', []);

  const orderTags = _.get(order, 'tags', {});
  const { needsSinzingTransfer, isExternalWarehouse } = orderTags;
  const { itemCountProduced, itemCountProducedFulfilled, itemCountFulfilled, itemCountExternalFulfilled } =
    orderFulfillment || {};
  const allItemsProduced = itemCountProduced > 0 && itemCountProduced === itemCountProducedFulfilled;
  const warehouseChangeDisabled = true; // itemCountProduced > 0 || itemCountFulfilled > 0 || itemCountExternalFulfilled > 0 || _.size(orderDeliveries) > 0;

  const [showEmailForm, setShowEmailForm] = useState(false);

  const {
    lineItems,
    addItem,
    removeItem,
    addExternalItem,
    removeExternalItem,
    addProducedItem,
    removeProducedItem,
  } = useLineItemFulfillment({ user, orderIds: memoizedOrderId, warehouseId: orderFulfillmentWarehouseId });

  const [warehouses, setWarehouses] = useState([]);
  const [activeWarehouseId, setActiveWarehouseId] = useState(null);
  useEffect(() => {
    (async () => {
      const Warehouse = Parse.Object.extend('Warehouse');
      const warehouseQuery = new Parse.Query(Warehouse);
      const warehouseResults = await warehouseQuery.find();
      const warehouseTags = warehouseResults.map((w) => {
        const { name, objectId, color } = w.toJSON();
        return {
          label: name,
          value: objectId,
          color,
        };
      });
      setWarehouses(warehouseTags);
    })();
  }, []);
  useEffect(() => {
    if (_.size(warehouses) && orderFulfillmentWarehouse) {
      setActiveWarehouseId(orderFulfillmentWarehouse.objectId);
    }
  }, [warehouses, orderFulfillmentWarehouse]);
  const handleWarehouseChange = (warehouseId) => {
    setActiveWarehouseId(warehouseId);
  };

  const orderLineItems = useMemo(() => {
    return _.get(order, 'lineItems', []);
  }, [order]);

  const productionBaseProductIds = useMemo(() => {
    return _.flatten(orderLineItems.map(item => item.bundleItems)).filter(item => _.isObject(item)).map(item => item.variationId);
  }, [orderLineItems]);

  const { warehouseStocks: productionBaseWarehouseStock } = useWarehouseStock({
    live: false,
    productIds: productionBaseProductIds,
    warehouseId: orderFulfillmentWarehouseId,
    skipQuery: !_.size(orderFulfillmentWarehouseId) || !_.size(productionBaseProductIds),
  });

  const joinedLineItems = useMemo(
    () => {
      const lineItemsWithBase = lineItems
          .map((item) => {
            const orderLineItem = orderLineItems.find((i) => `${i.itemId}` === item.itemId);
            if (!orderLineItem) {
              return undefined;
            }
            const { itemName, bundleItems, qty } = orderLineItem;
            // Add the base gtin to the product if it is a production item
            if (_.size(bundleItems)) {
              const bundleItemWarehouseStock = productionBaseWarehouseStock.find(stock => stock.plentyVariationId === _.first(bundleItems).plentyVariationId);
              // Set product Gtin to Base Gtin
              const baseGtin = _.get(bundleItemWarehouseStock, 'plentyProduct.gtin');
              if (_.isObject(_.get(item, 'warehouseStock.plentyProduct'))) {
                item.warehouseStock.plentyProduct.baseGtin = baseGtin;
              }
            }

            return {
              ...item,
              ...orderLineItem,
              qty: item.qty,
              onStockClick: () => {},
            };
          })
          .filter((item) => _.isObject(item))

      return lineItemsWithBase;
    },
    [lineItems, orderLineItems, productionBaseWarehouseStock],
  );


  const handleReImportOrder = useCallback(async () => {
    try {
      await Parse.Cloud.run('adminPlentyImportFulfillmentOrders', {
        orderIds: [orderId],
      });
      window.location.reload();
      notification.success({
        message: 'Re-Imported order',
      });
    } catch (error) {
      console.warn('handleReImportOrder failed', error);
      notification.error({
        message: 'Cannot import order',
      });
    }
  }, [orderId]);

  const handleReImportOrderWithDelete = useCallback(async () => {
    try {
      await Parse.Cloud.run('adminDeleteImportedFulfillmentOrder', {
        orderId,
      });
      setTimeout(async () => {
        await handleReImportOrder();
      }, 300);
    } catch (error) {
      console.warn('handleReImportOrder failed', error);
      notification.error({
        message: 'Cannot import order',
      });
    }
  }, [orderId]);

  const handleTransfer = useCallback(async (order) => {
    try {
      const { lineItems = [] } = order;
      const bundleItems = lineItems.map(item => {
        const { plentyVariationId, bundleItems } = item;
        return {
          plentyVariationId,
          bundleItems,
        };
      });
      await Parse.Cloud.run('adminTransferOrderFulfillment', {
        orderId: order.id,
        bundleItems,
      });
      notification.success({
        message: 'Order transferred',
      });
    } catch (error) {
      console.warn('handleTransfer failed', error);
      notification.error({
        message: 'Order transfer failed',
        description: error.toString(),
      });
    }
  }, []);

  const handleAddOrderNote = useCallback(
    async (note) => {
      await addOrderNote(note);
      setOrderNote('');
      await refetchOrders();
    },
    [addOrderNote],
  );

  const handleRefreshOrder = async () => {
    await refetchOrders();
  };

  const reviewSkus = useMemo(() => {
    // TODO These links should be generated on the server side?
    return (_.size(joinedLineItems) ? joinedLineItems.filter((lineItem) => {
      const { itemName = '', sku } = lineItem;
      return _.size(sku) > 0 && !itemName.includes('Kaffee') && !itemName.includes('Schneiden') && !itemName.includes('Schablone') && !itemName.includes('Gutschein');
    }).map((item) => {
      return item.sku;
    }) : []).join(';');
  }, [joinedLineItems]);

  const pdfVoucherItems = useMemo(() => {
    return joinedLineItems.filter(l => l.isPdfVoucher === true);
  }, [joinedLineItems]);

  const [voucherPdfs, setVoucherPdfs] = useState([]);

  useEffect(() => {
    if (pdfVoucherItems.length) {
      (async () => {
        try {
          const voucherResponse = await Parse.Cloud.run('adminFetchShopwarePdfVouchers', {
            orderId,
          });
          if (!voucherPdfs.length) {
            setVoucherPdfs(voucherResponse.vouchers || []);
          }
        } catch (error) {
          console.warn('adminFetchShopwarePdfVouchers failed', error);
        }
      })();
    }
  }, [pdfVoucherItems]);

  return (
    <Container>
      <ContentHeader>
        <Row gutter={[16, 16]}>
          <Col lg={5} md={8}>
            <Link to={pathname.replace(`/${orderId}`, '')}>
              <Button type='primary' icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            <Spacer/>
            {currentUserFulfills ? (
                <Button type='danger' onClick={() => unclaimOrder(order)} icon={<StopOutlined/>} title={'Unclaim Order'}/>
            ) : (
                <Button type='primary' onClick={() => claimOrder(order)} icon={<CheckCircleOutlined/>} title={'Claim Order'}/>
            )}
            <Spacer/>
            <Button icon={<ReloadOutlined />} onClick={handleRefreshOrder} loading={orderLoading}/>
          </Col>
          <Col lg={7} md={16}>
            <TitleLabel>
              Order{' '}
              <a href={getWcOrderEditLink(orderId)} target='_blank'>
                {orderId}
              </a>
              {' '}(PM:
              <a href={getPlentyOrderLink(plentyId)} target={'_blank'}>
                {' '}{plentyId}
              </a>)
              <br/>
              {orderFulfillment ? (
                <TitleLastUpdate> (Last updated {getDuration(orderFulfillment.updatedAt)})</TitleLastUpdate>
              ) : null}
            </TitleLabel>
          </Col>
          <Col lg={12} md={24}>
            <PullRight>
              {orderStatus ? <Tag color={orderStatus.color}>{orderStatus.name}</Tag> : ''}
              {orderStatusConfig && !orderStatus ? <Tag color={orderStatusConfig.color}>{orderStatusConfig.title}</Tag> : ''}
              {orderFulfillmentStatus ? (
                <Tag color={orderFulfillmentStatus.color}>{orderFulfillmentStatus.title}</Tag>
              ) : (
                ''
              )}
            </PullRight>
          </Col>
        </Row>
      </ContentHeader>

      <ContentWrapperWithPadding>

        <TopSpacer/>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <CardWrapper>
              <CardContent>
                <Row gutter={[16, 16]}>
                  <Col sm={24} md={8}>
                    <p>
                      <strong>
                        {_.size(fulfillmentUsername) ? `Claimed by ${fulfillmentUsername}` : 'Not claimed'}
                      </strong>
                      <br />
                      {orderFulfillment && _.size(fulfillmentUsername) ? (
                        <small>Claimed {getDuration(orderFulfillment.claimedAt.iso)}</small>
                      ) : null}
                    </p>
                  </Col>
                  <Col sm={24} md={16}>
                    <PullRight>
                      {currentUserFulfills ? (
                        <Button type='danger' onClick={() => unclaimOrder(order)}>
                          Unclaim
                        </Button>
                      ) : (
                        <Button type='primary' onClick={() => claimOrder(order)}>
                          Claim Order
                        </Button>
                      )}
                      <Spacer />
                    </PullRight>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={18}>
                    <Select
                      showArrow
                      value={activeWarehouseId}
                      style={{ width: '100%' }}
                      options={warehouses}
                      onChange={handleWarehouseChange}
                      disabled={warehouseChangeDisabled}
                    />
                  </Col>
                  <Col xs={6}>
                    <Button
                      type='primary'
                      block
                      onClick={() => setOrderWarehouse(order, activeWarehouseId)}
                      disabled={warehouseChangeDisabled}
                    >
                      Save Warehouse
                    </Button>
                  </Col>
                </Row>
                {_.size(orderDeliveries) > 0 ? (
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <Alert
                          message={
                            <>
                              <strong>Warehouse change:</strong> The warehouse can only be changed if the order does not have any sub-deliveries. Otherwise the warehouse of each sub-delivery is determined by its shipping profile.
                            </>
                          }
                          type='info'
                          showIcon
                        />
                      </Col>
                    </Row>
                ) : null}
                {isExternalWarehouse ? (
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <Alert
                            message={
                              <>
                                <strong>External Warehouse:</strong> This order is fulfilled with an external warehouse.
                              </>
                            }
                            type='warning'
                            showIcon
                        />
                      </Col>
                    </Row>
                ) : null}
                {needsSinzingTransfer ? (
                  <Row gutter={[16, 16]}>
                    <Col xs={18}>
                      <Alert
                        message={
                          <>
                            <strong>Sinzing Transfer Order:</strong> Producted items need to be transferred to Sinzing for
                            final packaging and dispatching, after all production items are produced.
                          </>
                        }
                        type='warning'
                        showIcon
                      />
                    </Col>
                    <Col xs={6}>
                      <Button type='primary' block onClick={() => handleTransfer(order)} disabled={!allItemsProduced}>
                        Transfer Order
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
              </CardContent>
            </CardWrapper>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>

          <OrderFulfillmentAddress order={order} />

          <OrderFulfillmentTable
              order={order}
              orderFulfillment={orderFulfillment}
              joinedLineItems={joinedLineItems}
              addItem={addItem}
              removeItem={removeItem}
              bounceOrder={bounceOrder}
              claimOrder={claimOrder}
              unclaimOrder={unclaimOrder}
              currentUserFulfills={currentUserFulfills}
              fulfillmentUsername={fulfillmentUsername}
          />

          <OrderPackageTable
              user={user}
              order={order}
              refreshOrder={handleRefreshOrder}
              joinedLineItems={joinedLineItems}
          />

          <OrderExternalTable
              orderFulfillment={orderFulfillment}
              joinedLineItems={joinedLineItems}
              addExternalItem={addExternalItem}
              removeExternalItem={removeExternalItem}
              currentUserFulfills={currentUserFulfills}
              fulfillmentUsername={fulfillmentUsername}
          />

          <OrderProductionTable
              plentyStatusId={plentyStatusId}
              orderFulfillment={orderFulfillment}
              joinedLineItems={joinedLineItems}
              addProducedItem={addProducedItem}
              removeProducedItem={removeProducedItem}
              currentUserFulfills={currentUserFulfills}
              fulfillmentUsername={fulfillmentUsername}
          />

          <Col span={24}>
            <CardWrapper>
              <CardHeader>
                <h1>Fulfillment History</h1>
              </CardHeader>
              <CardContent>
                {history.map((h) => {
                  return (
                      <p key={h.objectId}>
                        <small>{moment(h.updatedAt).format(DATE_TIME_FORMAT)}</small> -{' '}
                        <strong>{_.get(h, 'user.username', '?')}</strong>: {h.description}
                      </p>
                  );
                })}
              </CardContent>
            </CardWrapper>
          </Col>

          {voucherPdfs.length ? (
              <Col span={24}>
                <CardWrapper>
                  <CardHeader>
                    <h1>PDF Gutscheine</h1>
                  </CardHeader>
                  <CardContent>
                    {voucherPdfs.map((voucher) => {
                      return (
                          <div key={voucher.objectId}><a href={voucher.url} target='_blank'>{voucher.name} [{voucher.code}]</a></div>
                      )
                    })}
                  </CardContent>
                </CardWrapper>
              </Col>
          ) : null}

          <Col span={24}>
            <CardWrapper>
              <CardHeader>
                <h1>Customer Emails & Reviews</h1>
              </CardHeader>
              {order && order.id ? (
                  <CardContent>
                    <strong>Product Reviews</strong>
                    <p>{window.encodeURI(`http://www.reviews.io/store/landing_new_review?store=www.misspompadour.de&user=${order.billingFirstName} ${order.billingLastName}&order_id=${order.id}&email=${order.billingEmail}&products=${reviewSkus}&l=&type=product`)}</p>
                    <strong>Company Review</strong>
                    <p>{window.encodeURI(`http://www.reviews.io/store/landing_new_review?store=www.misspompadour.de&user=${order.billingFirstName} ${order.billingLastName}&order_id=${order.id}&email=${order.billingEmail}&type=company`)}</p>
                  </CardContent>
              ) : null}
              <CardContent>
                <Button onClick={() => setShowEmailForm(true)}>Send Email to customer</Button>
                <Spacer />
                {showEmailForm && order ? (
                    <OrderFulfillmentDetailsEmailForm
                        orderId={orderId}
                        email={order.billingEmail}
                        onSuccess={handleRefreshOrder}
                    />
                ) : null}
              </CardContent>
            </CardWrapper>
          </Col>

          <Col span={24}>
            <CardWrapper>
              <CardHeader>
                <h1>Administration</h1>
              </CardHeader>
              <CardContent>
                <Button onClick={handleReImportOrder}>Import Order again</Button>
                <Spacer />
                <Button onClick={handleReImportOrderWithDelete}>Delete & Import Order again</Button>
              </CardContent>
            </CardWrapper>
          </Col>
        </Row>
      </ContentWrapperWithPadding>

      <CommentSidebar>
        <h3>Order Notes:</h3>
        <div>
          <Input.TextArea
            onChange={({ target: { value } }) => setOrderNote(value)}
            value={orderNote}
            rows={3}
            placeholder='Write your note here'
          />
          <Button type='primary' onClick={() => handleAddOrderNote(orderNote)}>
            Add Order note
          </Button>
        </div>
        <Spacer />
        {comments.map((comment) => {
          return (
            <div key={comment.id}>
              <p>
                <small>
                  <strong>
                    {moment(comment.createdAt).format(DATE_TIME_FORMAT)} ({getDuration(comment.createdAt)})
                    <br />
                  </strong>
                </small>
                <strong>UserID {comment.userId}</strong>:<br />
                <small>
                  <span dangerouslySetInnerHTML={{ __html: comment.text }} />
                </small>
              </p>
            </div>
          );
        })}
      </CommentSidebar>

    </Container>
  );
};
