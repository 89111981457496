import React, {useCallback, useEffect, useState, useRef, useMemo} from 'react';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';
import _ from 'lodash';

import {Alert, Button, InputNumber, notification, Tag, List, Modal, Row, Col, Select, Table} from 'antd';
import * as Parse from 'parse';
import { Loader } from '../Loader/Loader';
import {CardContent, CardFooter, CardHeader, CardHeaderIcon, CardWrapper, Spacer} from "../Layout/Layout";
import {formatTitle, getPlentyShippingProfilesForCountry, getWcProductEditLink} from "../../lib/utils";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";

import {
  plentyShippingProfiles,
} from '../../lib/utils';

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
`;
const ModalContentContainer = styled.div`
  background-color: ${colors.WHITE};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  display: block;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const ModalContentScrollContainer = styled.div`
  padding: 20px 0;
  overflow-y: auto;
  max-height: 80vh;
`;
const ModalFooterContainer = styled.div`
  border-top: 1px solid ${colors.TRUE_BLACK10};
  padding: 20px;
`;
const ContentPaddingContainer = styled.div`
  padding: 20px;
`;
const LoadingOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
`;

const TableWrapper = styled.div`
  background-color: ${colors.WHITE};
  margin: -15px -15px;
  position: relative;
`;
const TableOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  z-index: 100;
`;

const LineItemQtyColumn = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
const LineItemQtyLabel = styled.p`
  padding-right: 8px;
  margin: 0;
  display: inline-block;
  font-weight: 600;
`;

const lineItemNameColumn = {
  title: 'Name',
  dataIndex: 'itemName',
  key: 'itemName',
  render: (itemName, row) => {
    const { deliveryQty = 0 } = row;
    return (
        <>
          <strong>{deliveryQty} x </strong> {formatTitle(itemName)}{' '}
        </>
    );
  },
};
const lineItemSkuColumn = {
  title: 'SKU',
  dataIndex: 'sku',
  key: 'sku',
  render: (sku, row) => (
      <a href={getWcProductEditLink(row.productId)} target='_blank'>
        {sku}
      </a>
  ),
  className: 'package-item-sku-cell',
};
const lineItemQuantityColumn = {
  title: 'Quantity',
  dataIndex: 'qty',
  key: 'qty',
  render: (qty, row) => {
    return (
        <LineItemQtyColumn>
          <LineItemQtyLabel>
            {row.deliveryQty || 0} / {row.unallocatedQty}
          </LineItemQtyLabel>
        </LineItemQtyColumn>
    );
  },
  className: 'package-item-qty-cell',
};
const lineItemActionsColumn = {
  // This gets extended in render function
  title: 'Actions',
  dataIndex: 'totalTaxes',
  key: 'actions',
  width: 110,
  render: (objectId, row) => '',
  className: 'package-item-actions-cell',
};

const packageTableColumnsDef = [
  lineItemNameColumn,
  lineItemSkuColumn,
  lineItemQuantityColumn,
  lineItemActionsColumn,
];

export const OrderDeliveryModal = (props) => {
  const { order, onClose, onSave, visible, unallocatedItems = [] } = props;

  const modifiedRef = useRef(false);
  const [packageTableColumns, setPackageTableColumns] = useState(packageTableColumnsDef);

  const [loading, setLoading] = useState(false);
  const [activeShippingProfileId, setActiveShippingProfileId] = useState(false);

  const [deliveryItems, setDeliveryItems] = useState([]);
  const deliveryItemsRef = useRef([]);

  const { shippingCountry } = order;
  const shippingProfiles = useMemo(() => {
    return getPlentyShippingProfilesForCountry(shippingCountry);
  }, [shippingCountry]);

  useEffect(() => {
    // Cleanup when closed
    if (!visible) {
      setDeliveryItems([])
    } else {
      setDeliveryItems(unallocatedItems);
    }
  }, [visible]);

  useEffect(() => {
    deliveryItemsRef.current = deliveryItems;
  }, [deliveryItems]);

  const handleRemove = ({ plentyVariationId }) => {
    const nextDeliveryItems = deliveryItemsRef.current.map((item) => {
      const { deliveryQty = 0 } = item;
      return item.plentyVariationId === plentyVariationId && deliveryQty > 0 ? {
        ...item,
        deliveryQty: deliveryQty - 1,
      } : item;
    });
    setDeliveryItems(nextDeliveryItems);
  };

  const handleAdd = (row) => {
    const { plentyVariationId } = row;
    const nextDeliveryItems = deliveryItemsRef.current.map((item) => {
      const { deliveryQty = 0, unallocatedQty } = item;
      return item.plentyVariationId === plentyVariationId && deliveryQty < unallocatedQty ? {
        ...item,
        deliveryQty: deliveryQty + 1,
      } : item;
    });
    setDeliveryItems(nextDeliveryItems);
  };

  useEffect(() => {
    const nextColumns = packageTableColumnsDef.map((column) => {
      if (column.key === 'actions') {
        return {
          ...column,
          render: (objectId, row) => {
            return (
                <>
                  <Button type='danger' onClick={() => handleRemove(row)} icon={<MinusOutlined />} />
                  <Spacer />
                  <Button type='primary' onClick={() => handleAdd(row)} icon={<PlusOutlined />} />
                </>
            )
          },
        };
      }
      return column;
    });
    setPackageTableColumns(nextColumns);
  }, [setPackageTableColumns]);

  const formatItemForDelivery = (item, parentQty) => {
    return {
      plentyReferenceItemId: item.plentyId,
      plentyVariationId: item.plentyVariationId,
      qty: item.deliveryQty || parentQty,
    };
  };

  const handleCreateOrderDelivery = async () => {
    const items = deliveryItems.map(item => {
        return {
          ...(formatItemForDelivery(item)),
          bundleItems: (item.bundleItems || []).map(bundleItem => {
            // It should be always 1 bundle item per item - but you never know how bundles might be used
            const bundleItemPerItem = (item.qty / bundleItem.qty);
            console.log('bundleItemPerItem', bundleItemPerItem);
            return formatItemForDelivery(bundleItem, item.deliveryQty * bundleItemPerItem);
          }),
        }
      }).filter(item => item.qty > 0);

    if (!items.length) {
      notification.error({
        message: `Select some items first.`,
      });
      return;
    }
    setLoading(true);
    try {
      const shippingProfile = plentyShippingProfiles.find(p => p.value === activeShippingProfileId);
      const warehouseId = _.get(shippingProfile, 'warehouseId', order.warehouseId);
      const data = await Parse.Cloud.run('adminCreatePlentyOrderDelivery', {
        plentyOrderId: order.plentyId,
        items,
        shippingProfileId: activeShippingProfileId,
        warehouseId,
      });
      setTimeout(() => {
        setLoading(false);
        notification.success({
          message: `Order delivery created.`,
        });
        onClose();
      }, 1000);
    } catch (error) {
      console.warn('handleCreateOrderDelivery failed', error);
      setLoading(false);
      notification.error({
        message: `Failed to create new order delivery.`,
      });
    }
  };

  const handleShippingProfileChange = (shippingProfileId) => {
    setActiveShippingProfileId(shippingProfileId);
  };

  if (!visible) {
    return null;
  }

  return (
    <ModalContainer>
      <ModalContentContainer>
        {loading ? (
          <LoadingOverlay>
            <Loader />
          </LoadingOverlay>
        ) : (
          <ModalContentScrollContainer>
            <ContentPaddingContainer>
              <h2>Create Order Delivery</h2>
              <p>Choose from the list of items that are not part of any delivery yet:</p>
              <ul>
                {unallocatedItems.map((item) => {
                  return <li key={item.itemId}>{item.unallocatedQty} x {item.itemName} ({item.plentyId} {item.itemId})</li>
                })}
              </ul>

              <CardWrapper>
                  <CardContent>
                    <Select
                        showArrow
                        value={activeShippingProfileId}
                        style={{ width: '100%' }}
                        options={shippingProfiles}
                        onChange={handleShippingProfileChange}
                    />
                  </CardContent>
                  <CardContent>
                    <TableWrapper>
                      <Table
                          columns={packageTableColumns}
                          dataSource={deliveryItems}
                          rowKey={'itemId'}
                          pagination={{
                            pageSize: 1000,
                            hideOnSinglePage: true,
                          }}
                      />
                    </TableWrapper>
                  </CardContent>
                <CardFooter>
                  <p></p>
                  <Button type='primary' disabled={!activeShippingProfileId} onClick={handleCreateOrderDelivery}>Create Delivery Order</Button>
                </CardFooter>
              </CardWrapper>

            </ContentPaddingContainer>
          </ModalContentScrollContainer>
        )}
        <ModalFooterContainer>
          <Button onClick={modifiedRef.current ? onSave : onClose} size='large' block>
            {modifiedRef.current ? 'Save' : 'Close'}
          </Button>
        </ModalFooterContainer>
      </ModalContentContainer>
    </ModalContainer>
  );
};
