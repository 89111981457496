import React, {useCallback, useEffect, useState, useMemo, useRef} from 'react';
import * as Parse from 'parse';
import _ from 'lodash';
import {notification, Modal } from 'antd';
import {plentyShippingProfiles} from "../lib/utils";

const { confirm } = Modal;

export const useOrderPackages = ({user, plentyOrderIds: plentyOrderIdsParam = [], orderId = '' }) => {

    const [loading, setLoading] = useState(false);
    const [packages, setPackages] = useState([]);
    const packagesRef = useRef([]);

    const plentyOrderIdsString = plentyOrderIdsParam.join(',');
    const plentyOrderIds = useMemo(() => {
        return _.size(plentyOrderIdsString) ? plentyOrderIdsString.split(',') : [];
    }, [plentyOrderIdsString]);

    const setPackagesAndItems = (nextPackages = [], nextItems = {}, plentyOrderId) => {
        // Keep all packages of other order deliveries
        const keepPackages = plentyOrderId ? packagesRef.current.filter(p => `${p.orderId}` !== `${plentyOrderId}`) : [];
        const packagesWithItems = nextPackages.map(p => {
            const packageItems = _.get(nextItems, `${p.id}`, []);
            return {
                ...p,
                items: packageItems,
            };
        }).filter(p => plentyOrderId ? `${p.orderId}` === `${plentyOrderId}` : true);
        packagesRef.current = [...keepPackages, ...(packagesWithItems || [])];
        setPackages(packagesRef.current);
    };

    const fetchData = useCallback(async () => {
        if (!_.size(plentyOrderIds)) {
            return;
        }
        setLoading(true);
        try {
            const {packages = [], items = {}} = await Parse.Cloud.run('adminFetchPlentyOrderPackages', {
                plentyOrderIds,
                orderId,
            });
            setPackagesAndItems(packages, items);
        } catch (error) {
            notification.error({
                message: `Failed to fetch data from Plentymarkets for order ${plentyOrderIds.join(',')}.`,
            });
        }
        setLoading(false);
    }, [plentyOrderIds, orderId]);

    useEffect(() => {
        if (_.size(plentyOrderIds)) {
            fetchData();
        }
    }, [plentyOrderIds]);

    const createPackage = useCallback(
        async (plentyOrderId, shippingProfileId) => {
            if (!_.size(plentyOrderId)) {
                return;
            }
            setLoading(true);
            try {
                const {packages = [], items = {}} = await Parse.Cloud.run('adminCreatePlentyOrderPackage', {
                    plentyOrderId,
                    shippingProfileId,
                });
                setPackagesAndItems(packages, items, plentyOrderId);
                notification.success({
                    message: `Package added to order ${plentyOrderId}.`,
                  });
            } catch (error) {
                notification.error({
                    message: `Failed to add new package for order ${plentyOrderId}.`,
                  });
            }
            setLoading(false);
        },
        [],
    );
    const deletePackage = useCallback(
        async (plentyOrderId, packageId) => {
            if (!_.size(plentyOrderId)) {
                return;
            }
            setLoading(true);
            try {
                const {packages = [], items = {}} = await Parse.Cloud.run('adminDeletePlentyOrderPackage', {
                    plentyOrderId,
                    packageId,
                });
                setPackagesAndItems(packages, items, plentyOrderId);
                notification.success({
                    message: `Package deleted for order ${plentyOrderId}.`,
                });
            } catch (error) {
                notification.error({
                    message: `Failed to delete package for order ${plentyOrderId}.`,
                });
            }
            setLoading(false);
        },
        [],
    );
    const saveShippingProfile = useCallback(
        async (plentyOrderId, shippingProfileId, localPackages) => {
            if (!_.size(plentyOrderId)) {
                return;
            }
            setLoading(true);
            try {
                const shippingProfile = plentyShippingProfiles.find(p => p.value === shippingProfileId);
                const warehouseId = _.get(shippingProfile, 'warehouseId');
                const { packages = [], items = {}} = await Parse.Cloud.run('adminSavePlentyOrderShippingProfile', {
                    plentyOrderId,
                    shippingProfileId,
                    packages: localPackages,
                    warehouseId,
                });
                notification.success({
                    message: `Shipping Profile changed for order ${plentyOrderId}.`,
                });
                setTimeout(() => {
                    fetchData();
                }, 2000);
            } catch (error) {
                notification.error({
                    message: `Failed to change shipping profile ${plentyOrderId}.`,
                  });
            }
            setLoading(false);
        },
        [packages, fetchData],
    );
    const saveAllPackages = useCallback(
        async (plentyOrderId, localPackages) => {
            if (!_.size(plentyOrderId)) {
                return;
            }
            setLoading(true);
            try {
                const {items = {}} = await Parse.Cloud.run('adminSavePlentyOrderPackages', {
                    plentyOrderId,
                    packages: localPackages,
                });
                setPackagesAndItems(packages, items, plentyOrderId);
                notification.success({
                    message: `All packages saved for order ${plentyOrderId}.`,
                });
            } catch (error) {
                notification.error({
                    message: `Failed to save packages for order ${plentyOrderId}.`,
                });
            }
            setLoading(false);
        },
        [packages],
    );
    const registerLabelsForDHL = useCallback(
        async (plentyOrderId, shippingCountry) => {
            if (!_.size(plentyOrderId)) {
                return;
            }

            const handleOk = async () => {
                setLoading(true);
                try {
                    const { packages = [], items = {}} = await Parse.Cloud.run('registerPlentyOrderPackagesForDHL', {
                        plentyOrderId,
                        shippingCountry,
                    });
                    setPackagesAndItems(packages, items, plentyOrderId);
                    notification.success({
                        message: `DHL Labels generated for order ${plentyOrderId}.`,
                    });
                } catch (error) {
                    notification.error({
                        message: `Failed to create DHL Labels for order ${plentyOrderId}.`,
                    });
                }
                setLoading(false);
            };

            confirm({
                title: 'Print all DHL labels?',
                content: 'This will complete the delivery and book out the stock.',
                onOk() {
                    handleOk()
                },
                onCancel() {
                },
            });
        },
        [],
    );
    const resetLabelsForDHL = useCallback(
        async (plentyOrderId, shippingCountry) => {
            if (!_.size(plentyOrderId)) {
                return;
            }

            const handleOk = async () => {
                setLoading(true);
                try {
                    const {packages = [], items = {}} = await Parse.Cloud.run('resetPlentyOrderPackagesForDHL', {
                        plentyOrderId,
                        shippingCountry,
                    });
                    setPackagesAndItems(packages, items, plentyOrderId);
                    notification.success({
                        message: `DHL Labels deleted ${plentyOrderId}.`,
                    });
                } catch (error) {
                    notification.error({
                        message: `Failed to delete DHL Labels for order ${plentyOrderId}.`,
                    });
                }
                setLoading(false);
            };

            confirm({
                title: 'Reset all DHL labels?',
                content: 'This will cancel the labels, but will not reset the stock.',
                onOk() {
                    handleOk()
                },
                onCancel() {
                },
            });
        },
        [],
    );

    return { loading, packages, createPackage, deletePackage, saveAllPackages, saveShippingProfile, registerLabelsForDHL, resetLabelsForDHL, refetchPackages: fetchData};
};
