import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import * as Parse from 'parse';
import _ from 'lodash';

import { Form, Input, Button, Alert } from 'antd';
import Icon from '@ant-design/icons';

import { UI, colors } from '../../lib/theme';

const Container = styled.div`
  height: calc(100vh - ${UI.NAVBAR_HEIGHT}px - ${UI.FOOTER_HEIGHT}px);
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;
const FormContainer = styled(Form)`
  width: 300px;
  background-color: ${colors.WHITE};
  padding: 30px;
`;
const InputIcon = styled(Icon)`
  color: ${colors.PRIMARY};
`;
const SubmitButton = styled(Button)`
  background-color: ${colors.primary};
  display: block;
`;
const LoginAlert = styled(Alert)`
  margin-bottom: 10px;
`;

const LoginForm = ({ history, onLogin }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  // Use form inside UseEffect
  useEffect(() => {
    form.setFieldsValue({});
  }, [form]);

  const handleLogin = useCallback(async () => {
    // event.preventDefault();
    setErrorMessage(null);
    setLoading(true);
    try {
      const values = await form.validateFields();
      const { username, password } = values;
      const { success, user } = await Parse.Cloud.run('authenticatePlentyAdminUser', {
        username,
        password,
      });
      console.log('loginResponse', success, user);
      if (!success) {
        setErrorMessage('Please check your data');
        return;
      }
      const { error } = await onLogin(user.username, password);
      if (error && error.message) {
        setErrorMessage(error.message);
      }
    } catch (error) {
      if (error && error.message) {
        setErrorMessage(error.message);
      }
      console.warn('Login failed: Cannot validate fields', error);
    }
    setLoading(false);
  }, [form]);

  return (
    <Container>
      <FormContainer form={form} onFinish={handleLogin}>
        <h1>Login</h1>
        {errorMessage ? <LoginAlert description={errorMessage} type='error' /> : ''}
        <Form.Item name='username' rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input prefix={<InputIcon type='user' />} placeholder='Username' size='large' />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: 'Please input your Password!' }]}>
          <Input prefix={<InputIcon type='lock' />} type='password' placeholder='Password' size='large' />
        </Form.Item>
        <Form.Item>
          <SubmitButton type='primary' size='large' htmlType='submit' block loading={loading}>
            Login
          </SubmitButton>
        </Form.Item>
      </FormContainer>
    </Container>
  );
};

export const Login = LoginForm;
