import React, { useState } from 'react';
import _ from 'lodash';
import { routes } from '../../lib/routes';
import { Button, Col, Input, notification, Row } from 'antd';

import { Container, ContentWrapper } from '../../components/Layout/Layout';
import { AdminClassEntryEdit } from '../../components/AdminClassEntryEdit/AdminClassEntryEdit';
import * as Parse from 'parse';

const properties = [
  {
    key: 'name',
    label: 'Name',
    type: 'string',
    required: true,
    extra: '',
  },
  {
    key: 'subject',
    label: 'Subject',
    type: 'string',
    required: true,
    extra: <small>Available placeholders: %orderId% | %orderDate%</small>,
  },
  {
    key: 'body',
    label: 'Body',
    type: 'text',
    required: true,
    extra: (
      <small>
        Available placeholders: %title% | %firstname% | %lastname% | %orderId% | %orderDate% | %orderTotal% | %logo%
      </small>
    ),
  },
  {
    key: 'replyTo',
    label: 'Reply to',
    type: 'string',
    required: false,
    disabled: true,
    extra: <small>This field is not in use yet</small>,
  },
  {
    key: 'useHtmlTemplate',
    label: 'Use HTML Template',
    type: 'checkbox',
    disabled: false,
    extra: '',
  },
];

const ProductFeedsProcessButton = ({ templateId, email }) => {
  const [testEmail, setTestEmail] = useState(email || '');
  const handleSendTestEmail = async () => {
    try {
      await Parse.Cloud.run('adminSendTestEmail', {
        templateId: templateId,
        emailAddress: testEmail,
      });
      notification.success({
        message: 'Test E-Mail sent',
        description: `E-Mail was sent to ${testEmail}`,
      });
    } catch (error) {
      console.warn('sendTestEmail failed', error);
      notification.error({
        message: 'Cannot send test email',
        description: error.toString(),
      });
    }
  };
  return (
    <>
      <h1>Send test email</h1>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Input
            placeholder='email address for test'
            value={testEmail}
            onChange={({ target: { value } }) => setTestEmail(value)}
          />
        </Col>
        <Col span={8}>
          <Button type='primary' onClick={handleSendTestEmail}>
            Send test email
          </Button>
        </Col>
      </Row>
    </>
  );
};

export const EmailTemplatesEdit = (props) => {
  const { user, authenticated, match = {}, history } = props;
  const {
    location: { pathname = '' },
  } = history;
  const templateId = _.last(pathname.split('/'));

  return (
    <Container>
      <ContentWrapper>
        <AdminClassEntryEdit
          user={user}
          history={history}
          title={'Email Templates'}
          className={'EmailTemplate'}
          editRoute={routes.ADMIN_EMAIL_TEMPLATES_EDIT}
          listRoute={routes.ADMIN_EMAIL_TEMPLATES}
          properties={properties}
          FooterComponent={<ProductFeedsProcessButton templateId={templateId} email={user.email} />}
        />
      </ContentWrapper>
    </Container>
  );
};
