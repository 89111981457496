import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { UI, colors } from '../../lib/theme';

const WarehouseStockChipContainer = styled.a`
  position: relative;
  border-radius: 3px;
  border: 1px solid;
  border-color: ${colors.TRUE_BLACK20};
  display: inline-block;
  color: ${colors.BLACK};
  padding: 3px 8px 3px 5px;
  vertical-align: middle;
  line-height: 12px;

  & > span {
    display: inline-block;
    background-color: ${({ color }) => color};
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 3px;
    vertical-align: middle;
  }

  & > small {
    font-size: 10px;
    vertical-align: middle;
    line-height: 12px;
  }
`;

export const WarehouseStockChip = ({ warehouseStock, onClick }) => {
  const { stock, isManaged = true, externalId } = warehouseStock || {};
  const warehouseStockNotAvailable = _.size(externalId) <= 1;

  const disabled = !warehouseStock || !isManaged;
  let color = colors.GREY;
  if (stock <= 0 && isManaged) {
    color = colors.RED;
  } else if (stock > 0 && stock <= 10 && isManaged) {
    color = colors.ORANGE;
  } else if (stock > 10 || !isManaged) {
    color = colors.GREEN;
  }

  let label = stock <= 0 && !disabled ? 'out of stock' : 'in stock';
  const limitedStockNumber = stock <= 0 ? 0 : stock;
  return (
    <>
      <WarehouseStockChipContainer color={color}>
        <span color={color}></span>
        <small>
          {disabled ? '∞' : limitedStockNumber} {label} {warehouseStockNotAvailable ? '(N/A)' : ''}
        </small>
      </WarehouseStockChipContainer>
    </>
  );
};
