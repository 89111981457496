import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { UI, colors } from '../../lib/theme';
import { routes } from '../../lib/routes';
import { Link } from 'react-router-dom';
import * as Parse from 'parse';

import {
  Container,
  ContentWrapper,
  CardHeader,
  CardWrapper,
  CardContent,
  PullRight
} from '../../components/Layout/Layout';
import {Modal, Table, Button, Divider, Tag, notification, Select} from 'antd';
const { confirm } = Modal;

const DeleteLink = styled.a`
  color: ${colors.RED};
`;

const pageSize = 100;

export const AdminClassEntryList = ({ title, className, tableConfig, editRoute, history, filters = [], filterOptions = [], sortOptions = [], editable = true, onData }) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const currentFilterOptionRef = useRef(filters);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (typeof onData === 'function') {
      onData(data);
    }
  }, [data]);

  const fetchData = useCallback(async (page, sortBy = 'createdAt', sortDirection = 'DESC') => {
    try {
      const data = await Parse.Cloud.run('adminFetchDataForClassWithFilters', {
        className,
        filters: currentFilterOptionRef.current,
        sortBy,
        sortDirection,
        page,
        pageSize,
      });
      setData(data.results);
      setTotalCount(data.count);
    } catch (error) {
      notification.error({
        message: 'Cannot get data',
        description: 'Unfortunately, we cannot fetch any data for you.',
      });
    }
  }, [setData, filters]);

  const handleFilterOptionsChange = useCallback((field, fieldType, value) => {
    // Remove current selected option
    currentFilterOptionRef.current = currentFilterOptionRef.current.filter(o => o.field !== field);
    if (value !== undefined) {
      // Add it for field again, if not undefined
      currentFilterOptionRef.current.push({
        field,
        fieldType,
        value
      });
    }
    fetchData(1);
  }, [filters]);

  const handleSortOptionsChange = useCallback((value) => {
    console.log('VALUE', value);
    const [field, direction] = value.split('|');
    fetchData(1, field, direction);
  }, [filters]);

  const handlePageChange = useCallback((nextPage) => {
    setCurrentPage(nextPage);
  }, []);

  const handleDelete = useCallback(
    ({ objectId }) => {
      confirm({
        content: 'Do you want to delete this entry?',
        onOk() {
          (async () => {
            const ClassName = Parse.Object.extend(className);
            const record = new ClassName();
            record.id = objectId;
            record.set('deleted', true);
            await record.save();
            notification.success({
              message: 'Entry deleted',
              description: 'The entry was deleted successfully.',
            });
            fetchData(currentPage);
          })();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    [fetchData, currentPage],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Id',
        dataIndex: 'objectId',
        key: 'objectId',
        render: (text, record) => editable ? (
            <Link to={editRoute.replace(':id', record.objectId)}>{text}</Link>
        ) : record.objectId,
      },
      ...tableConfig,
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => editable ? (
          <span>
            <Link to={editRoute.replace(':id', record.objectId)}>Edit</Link>
            <Divider type='vertical' />
            <DeleteLink onClick={() => handleDelete(record)}>Delete</DeleteLink>
          </span>
        ) : '',
      },
    ];
  }, [routes, handleDelete, editable]);

  const handleCreateEntry = useCallback(() => {
    history.push(editRoute.replace(':id', 'new'));
  }, [routes]);

  return title ? (
    <Container>
      <ContentWrapper>
        <h1>{title} <small>({totalCount} Items)</small></h1>
        <CardWrapper>
          <CardHeader>
          {editable ? (
                <Button type='primary' onClick={handleCreateEntry}>
                  Create new {title}
                </Button>
          ) : null}
          {sortOptions.length ? (
              <PullRight>
                <p>Sort List by:{' '}
                <Select
                    defaultValue={sortOptions[0].value}
                    options={sortOptions}
                    onChange={(event) => handleSortOptionsChange(event)}
                    style={{ width: '150px' }}
                    placeholder={sortOptions.map(o => o.label).join()}
                /></p>
              </PullRight>
          ) : null}
          </CardHeader>
          {filterOptions.length ? (
              <CardContent>
                {filterOptions.map((option) => {
                  const { options, field, fieldType } = option;
                  const defaultValue =_.get(currentFilterOptionRef.current.find(o => o.field === field), 'value');
                  return (
                    <Select
                        defaultValue={defaultValue}
                        options={options}
                        onChange={(event) => handleFilterOptionsChange(field, fieldType, event)}
                        style={{ width: '150px' }}
                        placeholder={options.map(o => o.label).join()}
                        allowClear={true}
                    />
                  );
                })}
              </CardContent>
          ) : null}
          <Table
              columns={columns}
              dataSource={data}
              rowKey='objectId'
              pagination={{
                showSizeChanger: false,
                pageSize,
                total: totalCount,
                current: currentPage,
                onChange: handlePageChange
              }}
          />
        </CardWrapper>
      </ContentWrapper>
    </Container>
  ) : (
      <Table
          columns={columns}
          dataSource={data}
          rowKey='objectId'
          pagination={{
            pageSize,
          }}
      />
  );
};
