import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Modal } from 'antd';
import _ from 'lodash';
import { colors } from '../../lib/theme';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Spacer } from '../Layout/Layout';
import * as Parse from 'parse';
import { Loader } from '../Loader/Loader';

const Note = styled.span`
  color: ${colors.MID_GREY};
  font-size: 0.75rem;
  text-align: center;
  margin-top: 1em;
`;
const SubTitle = styled.span`
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1em;
`;
const MyStats = styled.h1`
  color: ${colors.MAGENTA_NEW};
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  text-align: center;
`;
const InnerContainer = styled.div`
  background-color: ${colors.VERY_LIGHT_GREY};
  padding: 20px;
`;
const ContentContainer = styled.div`
  background-color: ${colors.WHITE};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin: 12px;
`;

export const PackerStatistics = ({ parseUser }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const packerId = parseUser ? parseUser.get('packerId') : '';

  useEffect(() => {
    if (isModalVisible) {
      (async () => {
        setLoading(true);
        try {
          const data = await Parse.Cloud.run('adminFetchPackerStatisticsByPackerId', {
            packerId,
          });
          setStats(data);
        } catch (error) {
          console.warn('fetch adminFetchPackerStatisticsByPackerId failed', error);
        }
        setLoading(false);
      })();
    }
  }, [isModalVisible]);

  const { addedItems = 0, addedItemsManual = 0, completedFulfillments = 0, packedItems = 0, packedItemsManual = 0 } = stats;

  if (!_.size(packerId)) {
    return null;
  }

  return (
    <>
      <Spacer />
      <Button type='primary' icon={<InfoCircleOutlined />} onClick={showModal}></Button>
      <Modal
        visible={isModalVisible}
        title='My Packing Statistics'
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={2000}
        bodyStyle={{ padding: 0 }}
      >
        <InnerContainer>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Row gutter={20}>
                <Col lg={8} md={12} xs={24}>
                  <ContentContainer>
                    <Note>PICKING</Note>
                    <MyStats>{addedItems}</MyStats>
                    <SubTitle>{addedItemsManual} manual</SubTitle>
                    <SubTitle>Packages picked today</SubTitle>
                  </ContentContainer>
                </Col>
                <Col lg={8} md={12} xs={24}>
                  <ContentContainer>
                    <Note>PACKING</Note>
                    <MyStats>{packedItems}</MyStats>
                    <SubTitle>{packedItemsManual} manual</SubTitle>
                    <SubTitle>Items packed today</SubTitle>
                  </ContentContainer>
                </Col>
                <Col lg={8} md={12} xs={24}>
                  <ContentContainer>
                    <Note>COMPLETED</Note>
                    <MyStats>{completedFulfillments}</MyStats>
                    <SubTitle>Orders completed</SubTitle>
                  </ContentContainer>
                </Col>
              </Row>
            </>
          )}
        </InnerContainer>
      </Modal>
    </>
  );
};
