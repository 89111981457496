import React from 'react';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';

import {Tag} from 'antd';

const Container = styled.div`
    display: inline-block;
    margin-left: 8px;
`;

export const OrderOriginTags = ({ order }) => {

    if (!order) {
        return null;
    }
    const { isB2BOrder, originId, originName = '', paymentMethod, shippingCountry } = order;
    const isAvocado = paymentMethod === 'avocadostore';
    const isAmazon = originName.toLowerCase().includes('amazon');
    const isOtto = originName.toLowerCase().includes('otto');
    const isShopOrder = [9, 10].includes(originId);
    const localShipping = ['DE', 'AT'].includes(shippingCountry);
    return (
        <Container>
            {isB2BOrder ? <Tag color={colors.RED}>B2B</Tag> : null}
            {isAvocado ? <Tag color={colors.GREEN_NEW}>AVOCADO</Tag> : null}
            {isOtto ? <Tag color={colors.RED}>OTTO</Tag> : null}
            {isAmazon ? <Tag color={colors.AMAZON_ORANGE}>AMAZON</Tag> : null}
            {!isB2BOrder && !isAvocado && isShopOrder ? <Tag color={colors.GREY}>Normal</Tag> : null}
            {!isB2BOrder && !isAvocado && !isOtto && !isAmazon && !isShopOrder ? (
                <Tag color={colors.BLUE}>{originName}</Tag>
            ) : null}
            <Tag color={localShipping ? colors.GREY : colors.RED}>{shippingCountry}</Tag>
        </Container>
    )
};
