import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as Parse from 'parse';
import _ from 'lodash';
import moment from 'moment';

import { DATE_FORMAT } from '../lib/constants';
import { useWindowFocus } from './useWindowFocus';

import { Modal, notification } from 'antd';

const { confirm } = Modal;

export const useOrderFulfillment = ({
  user,
  orderIds,
  orderStatuses,
  usePlentyId = false,
  orderType,
  statusFrom,
  statusTo,
  startDate,
  endDate,
  skip = 0,
  limit = 100,
  sorting,
  live = true,
  filter = (order) => true,
  skipMeta = false,
  usePreloadedOrders = false,
  skipQuery,
}) => {
  const ordersRef = useRef();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);
  const orderFulfillmentQueryRef = useRef();
  const orderFulfillmentQuerySubscriptionRef = useRef();
  const windowFocussed = useWindowFocus();
  // Store users by id as user objects dont change in short time frame
  const userStoreRef = useRef({});

  // Params
  // includeWcOrders
  // StartDate, EndDate, orderIds, orderStatuses, limit, skip
  // subscribe

  const updateFulfillment = async (orderFulfillment) => {
    const fulfillmentUserPointer = _.get(orderFulfillment, 'fulfillmentUser', undefined);
    let fulfillmentUser = fulfillmentUserPointer;
    // Manually fetch this users data - not included in subscription updates
    if (fulfillmentUserPointer) {
      const fulfillmentUserId = fulfillmentUserPointer.objectId;
      if (userStoreRef.current[fulfillmentUserId]) {
        fulfillmentUser = userStoreRef.current[fulfillmentUserId];
      } else {
        const ParseUser = Parse.Object.extend('_User');
        const userQuery = new Parse.Query(ParseUser);
        userQuery.equalTo('objectId', fulfillmentUserPointer.objectId);
        const userResult = await userQuery.first({});
        if (userResult) {
          fulfillmentUser = userResult.toJSON();
          userStoreRef.current[fulfillmentUserId] = fulfillmentUser;
        }
      }
    }
    // Update ref and state
    ordersRef.current = ordersRef.current.map((order) => {
      if (`${order.id}` === orderFulfillment.orderId) {
        return {
          ...order,
          fulfillment: {
            ...orderFulfillment,
            fulfillmentUser,
          },
        };
      }
      return order;
    });
    setOrders(ordersRef.current);
  };

  const subscribeOrderFulfillment = async () => {
    if (!live) {
      return;
    }
    if (!orderFulfillmentQueryRef.current) {
      return;
    }
    console.log('Subscribe OrderFulfillment');
    orderFulfillmentQuerySubscriptionRef.current = await orderFulfillmentQueryRef.current.subscribe();
    // orderFulfillmentQuerySubscriptionRef.current.on('create', (object) => {
    //   console.log('ORDER FULFILLMENT created', object);
    // });
    orderFulfillmentQuerySubscriptionRef.current.on('update', (object) => {
      updateFulfillment(object.toJSON());
    });
    // orderFulfillmentQuerySubscriptionRef.current.on('enter', (object) => {
    //   console.log('ORDER FULFILLMENT entered', object);
    // });
    // // Delete and Leave should never happen
    // orderFulfillmentQuerySubscriptionRef.current.on('leave', (object) => {
    //   console.log('ORDER FULFILLMENT left', object);
    // });
    // orderFulfillmentQuerySubscriptionRef.current.on('delete', (object) => {
    //   console.log('ORDER FULFILLMENT deleted', object);
    // });
    // orderFulfillmentQuerySubscriptionRef.current.on('close', () => {
    //   console.log('ORDER FULFILLMENT subscription closed');
    // });
  };

  const unsubscribeOrderFulfillment = async () => {
    if (!orderFulfillmentQuerySubscriptionRef.current) {
      return;
    }
    console.log('Unsubscribe OrderFulfillment');
    orderFulfillmentQuerySubscriptionRef.current.unsubscribe();
    orderFulfillmentQuerySubscriptionRef.current = null;
  };

  useEffect(() => {
    return () => unsubscribeOrderFulfillment();
  }, []);

  const fetchData = useCallback(async () => {
    if (loadingRef.current || skipQuery) {
      return;
    }
    loadingRef.current = true;
    try {
      setLoading(true);
      unsubscribeOrderFulfillment();
      const fetchParams = {
        // TODO: itemsPerPage, page
        limit,
        skip,
      };
      if (startDate) {
        // TODO: createdAtFrom
        fetchParams.startDate = moment(startDate).format(DATE_FORMAT);
      }
      if (endDate) {
        // TODO: createdAtFrom
        fetchParams.endDate = moment(endDate).format(DATE_FORMAT);
      }
      if (_.size(orderIds)) {
        if (usePlentyId) {
          fetchParams.orderIds = (orderIds || []).join(',');
        } else {
          fetchParams.externalOrderId = (orderIds || []).join(',');
        }
      }
      if (_.size(orderType)) {
        fetchParams.orderType = orderType;
      }
      if (statusFrom) {
        // fetchParams.statuses = orderStatuses;
        fetchParams.statusFrom = statusFrom;
        fetchParams.statusTo = statusTo;
      }
      if (_.size(sorting)) {
        fetchParams.sorting = sorting;
      }
      if (usePreloadedOrders) {
        fetchParams.usePreloadedOrders = usePreloadedOrders;
      }
      // Fetch orders from WooCommerce
      const wcOrders = await Parse.Cloud.run('adminFetchPlentyOrders', fetchParams);
      const filteredWcOrders = wcOrders.filter(filter);
      const wcOrderIds = filteredWcOrders.map((order) => `${order.id}`);

      const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
      orderFulfillmentQueryRef.current = new Parse.Query(OrderFulfillment);
      orderFulfillmentQueryRef.current.containedIn('orderId', wcOrderIds);
      orderFulfillmentQueryRef.current.include('fulfillmentUser');
      orderFulfillmentQueryRef.current.include('warehouse');
      orderFulfillmentQueryRef.current.limit(limit);
      const orderFulfillmentQueryResult = await orderFulfillmentQueryRef.current.find({});
      const orderFulfillmentStore = _.keyBy(
        orderFulfillmentQueryResult.map((order) => order.toJSON()),
        'orderId',
      );
      const nextOrders = _.orderBy(
        wcOrders.map((order) => {
          const { id } = order;
          const orderFulfillment = orderFulfillmentStore[`${id}`];
          return {
            ...order,
            fulfillment: orderFulfillment,
          };
        }),
        ['postDate'],
        [false],
      );
      // Keep ref for updates via subscription
      ordersRef.current = nextOrders;
      setOrders(nextOrders);
      loadingRef.current = false;
      setLoading(false);

      // Subscribe to query
      subscribeOrderFulfillment();
    } catch (error) {
      console.warn('useOrderFulfillment failed', error);
      notification.error({
        message: 'Cannot get order data',
        description: 'Please try again later',
      });
      loadingRef.current = false;
      setLoading(false);
    }
  }, [setOrders, orderIds, skipQuery, usePlentyId]);

  const orderIdsString = (orderIds || []).join(',');

  useEffect(() => {
    fetchData();
  }, [statusFrom, statusTo, startDate, endDate, limit, skip, orderIdsString, skipQuery, orderType]);

  useEffect(() => {
    if (windowFocussed) {
      fetchData();
    }
  }, [windowFocussed]);

  const claimOrder = useCallback(
    async (order) => {
      const save = async (fulfillment, nextFulfillmentUserId) => {
        try {
          // Set new fulfillment User
          const nextFulfillmentUser = new Parse.User();
          nextFulfillmentUser.id = nextFulfillmentUserId;
          fulfillment.set('fulfillmentUser', nextFulfillmentUser);
          await fulfillment.save(null);
          notification.success({
            message: 'Order claimed successfully',
          });
        } catch (error) {
          console.warn('ClaimOrder save failed', error);
          notification.error({
            message: 'Cannot claim order',
            description: 'Claim order failed. Please try again later',
          });
        }
      };

      const { id } = order;
      const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
      const query = new Parse.Query(OrderFulfillment);
      query.equalTo('orderId', `${id}`);
      const orderFulfillment = await query.first({});
      if (orderFulfillment) {
        // Check if fulfillment user is set already - needs confirmation
        const fulfillmentUser = orderFulfillment.get('fulfillmentUser');
        if (fulfillmentUser) {
          const username = fulfillmentUser.get('username');
          confirm({
            title: 'Order claimed by other user. Are you sure you want to claim this order?',
            icon: 'exclamation-circle',
            content: `The order is currently claimed by ${username}`,
            okText: 'Yes, claim',
            cancelText: 'No, cancel',
            onOk: async () => save(orderFulfillment, user.objectId),
            onCancel() {},
          });
        } else {
          // Set fulfillment user
          await save(orderFulfillment, user.objectId);
        }
      } else {
        notification.error({
          message: 'Cannot claim order',
          description: 'Order is not imported yet. Please try again later.',
        });
      }
    },
    [user],
  );

  const unclaimOrder = useCallback(
    async (order) => {
      const { id } = order;
      const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
      const query = new Parse.Query(OrderFulfillment);
      query.equalTo('orderId', `${id}`);
      const orderFulfillment = await query.first({});
      if (orderFulfillment) {
        // Reset fulfillment user
        orderFulfillment.unset('fulfillmentUser');
        await orderFulfillment.save(null);
        notification.success({
          message: 'Order unclaimed successfully',
        });
      } else {
        notification.error({
          message: 'Cannot unclaim order',
          description: 'Unclaim order failed. Please try again later',
        });
      }
    },
    [user],
  );

  const setOrderTags = useCallback(
    async (order, tags) => {
      const { id } = order;
      const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
      const query = new Parse.Query(OrderFulfillment);
      query.equalTo('orderId', `${id}`);
      const orderFulfillment = await query.first({});
      if (orderFulfillment) {
        // Reset fulfillment user
        orderFulfillment.set('tags', tags);
        await orderFulfillment.save(null);
        notification.success({
          message: 'Order tags updated successfully',
        });
      } else {
        notification.error({
          message: 'Cannot update order tags',
          description: 'Updating tags failed. Please try again later',
        });
      }
    },
    [user],
  );

  const setOrderWarehouse = useCallback(
    async (order, warehouseId) => {
      const { id } = order;
      const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
      const query = new Parse.Query(OrderFulfillment);
      query.equalTo('orderId', `${id}`);
      const orderFulfillment = await query.first({});
      if (orderFulfillment) {
        const Warehouse = Parse.Object.extend('Warehouse');
        const warehouse = new Warehouse();
        warehouse.id = warehouseId;
        orderFulfillment.set('warehouse', warehouse);
        await orderFulfillment.save(null);
        notification.success({
          message: 'Order warehouse updated successfully',
        });
      } else {
        notification.error({
          message: 'Cannot update order warehouse',
          description: 'Updating warehouse failed. Please try again later',
        });
      }
    },
    [user],
  );

  const bounceOrder = useCallback(
    async (order) => {
      const bounce = async () => {
        const { id } = order;
        const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
        const query = new Parse.Query(OrderFulfillment);
        query.equalTo('orderId', `${id}`);
        const orderFulfillment = await query.first({});
        if (orderFulfillment) {
          const currentBouncedCount = orderFulfillment.get('bouncedCount') || 0;
          orderFulfillment.set('bouncedCount', currentBouncedCount + 1);
          await orderFulfillment.save(null);
          notification.success({
            message: 'Order bounced successfully',
          });
          // await unclaimOrder(order);
        } else {
          notification.error({
            message: 'Cannot bounce order',
            description: 'Bounce order failed. Please try again later',
          });
        }
      };
      confirm({
        title: 'Are you sure you want to bounce this order?',
        icon: 'exclamation-circle',
        content: 'The order will bounce until tomorrow.',
        okText: 'Yes, bounce',
        okType: 'danger',
        cancelText: 'No, cancel',
        onOk: bounce,
        onCancel() {},
      });
    },
    [user],
  );

  return { orders, claimOrder, unclaimOrder, bounceOrder, loading, setOrderTags, setOrderWarehouse, refetchOrders: fetchData };
};
