import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as Parse from 'parse';
import _ from 'lodash';
import { notification } from 'antd';

export const useOrderNotes = ({ user, orderId }) => {

  const addOrderNote = useCallback(
    async (comment) => {
      if (!_.size(orderId) || !_.size(comment)) {
        return;
      }
      try {
          await Parse.Cloud.run('adminPlentyCreateOrderComment', {
            comment,
            orderId,
          });
          notification.success({
              message: `Note added successfully to order ${orderId}.`,
          });
      } catch (error) {
          console.warn('addOrderNote failed', error);
          notification.error({
              message: `Failed to add note to order ${orderId}.`,
          });
      }
    },
    [orderId],
  );

  return { addOrderNote };
};
