import React from 'react';
import _ from 'lodash';
import { routes } from '../../lib/routes';
import { AdminClassEntryList } from '../../components/AdminClassEntryList/AdminClassEntryList';

const tableConfig = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (value = '') => _.size(value) > 30 ? `${value.slice(0, 30)}...` : `${value}`,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Value JSON',
    dataIndex: 'valueJson',
    key: 'valueJson',
    render: (value = '') => _.size(value) > 50 ? `${value.slice(0, 50)}...` : `${value}`,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
];

export const ConfigParams = ({ user, authenticated, history }) => {
  return (
    <AdminClassEntryList
      history={history}
      title={'Config Param'}
      className={'ConfigParam'}
      tableConfig={tableConfig}
      editRoute={routes.ADMIN_CONFIG_PARAMS_EDIT}
    />
  );
};
