import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from "lodash";

import { UI, colors } from '../../lib/theme';

import {Card, Col, Row, Button, Form, Input} from 'antd';
import {
  Container,
  ContentWrapper,
  CardWrapper,
  CardContent
} from '../../components/Layout/Layout';
import {FacebookAuth} from "../../components/FacebookAuth/FacebokAuth";

const Spacer = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  background-color: ${colors.TRUE_BLACK20};
`;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

export class UserProfile extends Component {
  componentDidMount() {}

  render() {
    const { user, parseUser } = this.props;
    const { isAdmin } = user;

    const handleFinish = async (values) => {
        try {
          if (_.size(values.packerId)) {
            parseUser.set('packerId', values.packerId);
          }
          if (_.size(values.plentyAccessKey)) {
            parseUser.set('plentyAccessKey', values.plentyAccessKey);
          }
          if (_.size(values.plentyId)) {
            parseUser.set('plentyId', parseInt(values.plentyId));
          }
          if (_.size(values.plentyUsername)) {
            parseUser.set('plentyUsername', values.plentyUsername);
          }
          if (_.size(values.plentyWarehouseId)) {
            parseUser.set('plentyWarehouseId', parseInt(values.plentyWarehouseId));
          }
          await parseUser.save();
          window.location.reload();
        } catch (error) {
          console.warn('Packer selection form onFinish failed!', error);
        }
    };

    return (
      <Container>
        <ContentWrapper>

          {isAdmin ? (
              <CardWrapper>
                <CardContent>
                  <FacebookAuth/>
                </CardContent>
              </CardWrapper>
          ) : null}

          <Spacer/>
          <CardWrapper>

            <Card>
              <h1>User Profile {user.username}</h1>

              <Spacer />

              <Form name='control-ref' onFinish={handleFinish} initialValues={{...user, plentyAccessKey: ''}}>
                <Form.Item  {...layout} name='packerId' label='Packer ID'>
                  <Input />
                </Form.Item>
                <Form.Item  {...layout} name='plentyId' label='Plentymarkets User ID'>
                  <Input />
                </Form.Item>
                <Form.Item  {...layout} name='plentyUsername' label='Plentymarkets Username' help={'Without @ or anything'}>
                  <Input />
                </Form.Item>
                <Form.Item  {...layout} name='plentyAccessKey' label='Plentymarkets Password' help={'Leave empty if you dont want to change it'}>
                  <Input />
                </Form.Item>
                <Form.Item  {...layout} name='plentyWarehouseId' label='Plentymarkets Warehouse ID' help={'1 for Sinzing, 102 for Laaber'}>
                  <Input />
                </Form.Item>

                <Form.Item {...layout}>
                  <Button type='primary' htmlType='submit'>
                    Save
                  </Button>
                </Form.Item>
              </Form>

            </Card>
          </CardWrapper>
        </ContentWrapper>
      </Container>
    );
  }
}
