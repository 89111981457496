import React, { useState } from 'react';
import _ from 'lodash';
import { routes } from '../../../lib/routes';

import { Container, ContentWrapper } from '../../../components/Layout/Layout';
import { AdminClassEntryEdit } from '../../../components/AdminClassEntryEdit/AdminClassEntryEdit';

const properties = [
  {
    key: 'name',
    label: 'Name',
    type: 'string',
    required: true,
    extra: '',
  },
  {
    key: 'deeplId',
    label: 'Deepl Glossary Id',
    type: 'string',
    required: false,
    disabled: true,
    extra: <span>This updated automatically after changing the glossary terms</span>,
  },
  {
    key: 'error',
    label: 'Deepl Error',
    type: 'string',
    required: false,
    disabled: true,
    extra: <span>If there is an error, the glossary is not gonna work!</span>,
  },
  {
    key: 'sourceLang',
    label: 'Source Lang',
    type: 'string',
    required: true,
    extra: <span>Available: de - Cannot be changed afterwards!</span>,
  },
  {
    key: 'targetLang',
    label: 'Target Lang',
    type: 'string',
    required: true,
    extra: <span>Available: de | en | fr | nl - Cannot be changed afterwards!</span>,
  },
  {
    key: 'terms',
    label: 'Terms',
    type: 'text',
    required: true,
    extra: <span>Every term a new row | Separated by comma | Put quotes around the terms!</span>,
  },
];

export const AdminTranslationGlossariesEdit = (props) => {
  const { user, authenticated, match = {}, history } = props;
  const {
    location: { pathname = '' },
  } = history;

  return (
    <Container>
      <ContentWrapper>
        <AdminClassEntryEdit
          user={user}
          history={history}
          title={'Translation Glossaries'}
          className={'TranslationGlossary'}
          editRoute={routes.ADMIN_TRANSLATION_GLOSSARIES_EDIT}
          listRoute={routes.ADMIN_TRANSLATION_GLOSSARIES}
          properties={properties}
        />
      </ContentWrapper>
    </Container>
  );
};
