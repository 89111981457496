import React, { useState } from 'react';
import _ from 'lodash';
import {
  formatPrice,
  getDuration,
  getFulfillmentBouncedState,
  getFulfillmentExternalStatus,
  getFulfillmentProducedStatus,
  getFulfillmentUsername,
  getOrderFulfillmentStatus,
  orderFulfillmentTagMap,
  orderStatuses,
  sortByBounceDatesAndPriority,
} from './utils';
import { Tag, Popover } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DATE_TIME_FORMAT } from './constants';
import { Link } from 'react-router-dom';
import { colors } from './theme';
import styled from 'styled-components';
import { useLineItemFulfillment } from '../hooks/useLineItemFulfillment';
import { Loader } from '../components/Loader/Loader';
import { WarehouseStockChip } from '../components/WarehouseStockChip/WarehouseStockChip';
import {OrderOriginTags} from "../components/OrderOriginTags/OrderOriginTags";

const BounceColumn = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 15px;
  color: ${colors.WHITE};
`;
const BounceColumnBackground = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ opacity }) => opacity};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;

const BounceNumberLabel = styled.h1`
  text-align: center;
  position: relative;
  color: ${({ color }) => color};
  z-index: 10;
  font-weight: 700;
  display: block;
`;
const BounceLabel = styled.small`
  text-align: center;
  position: relative;
  color: ${({ color }) => color};
  z-index: 10;
  font-weight: 700;
  display: block;
`;
export const getOrderBounceSummaryCell = (order) => {
  const { bouncedCount, bouncedUntil, lastBouncedAt, currentlyBounced } = getFulfillmentBouncedState(order);
  let backgroundColor = colors.GREEN;
  let color = colors.WHITE;
  if (currentlyBounced) {
    backgroundColor = colors.TRUE_BLACK;
  } else if (!currentlyBounced && bouncedCount >= 4) {
    backgroundColor = colors.RED;
  } else if (!currentlyBounced && bouncedCount > 0) {
    backgroundColor = colors.ORANGE;
  }
  return (
    <BounceColumn>
      <BounceColumnBackground backgroundColor={backgroundColor} opacity={(5 + bouncedCount) / 10} />
      <BounceNumberLabel color={color}>{bouncedCount}x</BounceNumberLabel>
      <BounceLabel color={color}>
        {currentlyBounced ? `Bounced until ${getDuration(bouncedUntil)}` : 'Not bounced currently'}
      </BounceLabel>
    </BounceColumn>
  );
};

export const orderBounceDateTableColumn = {
  title: 'Bounced',
  dataIndex: 'postDate',
  key: 'postDate',
  render: (id, row) => getOrderBounceSummaryCell(row),
  className: 'bounce-column',
  defaultSortOrder: 'ascend',
  sorter: (a, b) => sortByBounceDatesAndPriority(a, b),
};

const OrderDurationLabel = styled.span`
  text-align: center;
  position: relative;
  color: ${({ color }) => color};
  z-index: 10;
  font-weight: 700;
`;
export const orderIdTableColumn = {
  title: 'OrderId',
  dataIndex: 'id',
  key: 'id',
  render: (id, row) => {
    const orderDate = moment(row.postDate).format(DATE_TIME_FORMAT);
    const { plentyId } = row;
    const { pathname } = window.location;
    return (
      <>
        <Link to={`${pathname}/${id}`}>
          <strong>{id} ({plentyId})</strong>
          <br />
        </Link>
        <small>
          {orderDate}
          <br />
          <OrderDurationLabel>{getDuration(row.postDate)}</OrderDurationLabel>
        </small>
      </>
    );
  },
  sorter: (a, b) => new Date(a.postDate) - new Date(b.postDate),
};

const LineItemList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const LineItemIconFulfilled = styled(CheckCircleOutlined)`
  color: ${colors.SUCCESS};
  margin-right: 5px;
  margin-bottom: -3px;
`;
const LineItemIconExternal = styled(InfoCircleOutlined)`
  color: ${colors.BLUE};
  margin-right: 5px;
  margin-bottom: -3px;
`;
const LineItemIconStarted = styled(ClockCircleOutlined)`
  color: ${colors.GOLD};
  margin-right: 5px;
  margin-bottom: -3px;
`;
const LineItemIconPending = styled(CloseCircleOutlined)`
  color: ${colors.DANGER};
  margin-right: 5px;
  margin-bottom: -3px;
`;
const LoadingWrapper = styled.div`
  width: 200px;
  height: 60px;
  display: block;
`;

const LineItemsPopoverContent = ({ orderId, lineItems, warehouseId }) => {
  const { lineItems: fulfillmentLineItems, loading } = useLineItemFulfillment({
    orderIds: [`${orderId}`],
    warehouseId,
    live: false,
  });
  if (loading) {
    return (
      <LoadingWrapper>
        <Loader />
      </LoadingWrapper>
    );
  }
  return (
    <LineItemList>
      {lineItems.map((lineItem) => {
        const { itemId, qty: qtyString, mylandsBase, sku } = lineItem;
        const qty = parseInt(qtyString);
        const fulfillmentItem = fulfillmentLineItems.find((item) => item.itemId === `${itemId}`);
        const qtyFulfilled = _.get(fulfillmentItem, 'qtyFulfilled', 0);
        const fulfilled = qtyFulfilled === qty;
        const started = !fulfilled && qtyFulfilled > 0;
        const externalItem = lineItem.isExternalItem;
        const pending = qtyFulfilled === 0 && !externalItem;
        const warehouseStock = _.get(fulfillmentItem, 'warehouseStock');
        return (
          <li key={itemId}>
            {externalItem ? <LineItemIconExternal /> : ''}
            {fulfilled ? <LineItemIconFulfilled /> : ''}
            {pending ? <LineItemIconPending /> : ''}
            {started ? <LineItemIconStarted /> : ''}
            <WarehouseStockChip warehouseStock={warehouseStock} /> {started ? <small>{qtyFulfilled} / </small> : ''}
            <small>
              {lineItem.qty} x {lineItem.itemName} {mylandsBase ? `(B: ${mylandsBase})` : ''}{' '}
              {sku ? `[SKU: ${sku}]` : ''}
            </small>
          </li>
        );
      })}
    </LineItemList>
  );
};

const LineItemsPopover = ({ children, row }) => {
  const [showPopoverContent, setShowPopoverContent] = useState(false);
  const { fulfillment } = row;
  const warehouseId = _.get(fulfillment, 'warehouse.objectId');

  const handlePopoverVisibleChange = (visible) => {
    setShowPopoverContent(visible);
  };
  // Render content only when visible to make sure the line item hook does not trigger
  const content = showPopoverContent ? (
    <LineItemsPopoverContent lineItems={row.lineItems || []} orderId={row.id} warehouseId={warehouseId} />
  ) : null;
  return (
    <Popover placement='bottomLeft' content={content} onVisibleChange={handlePopoverVisibleChange}>
      {children}
    </Popover>
  );
};

export const orderStatusTableColumn = {
  title: 'Status',
  dataIndex: 'postStatus',
  key: 'postStatus',
  render: (postStatus, row) => {
    const orderStatus = _.get(row, 'status');
    const orderStatusConfig = _.get(orderStatuses, postStatus);
    const orderFulfillment = _.get(row, 'fulfillment', {});
    const orderFulfillmentStatus = getOrderFulfillmentStatus(orderFulfillment);
    const { itemCount = 0, itemCountFulfilled = 0 } = orderFulfillment;
    const fulfillmentUserName = getFulfillmentUsername(row);
    const claimedAt = _.get(row, 'fulfillment.claimedAt.iso');
    return (
      <LineItemsPopover row={row}>
        <div>
          <span>
            {orderStatusConfig && !orderStatus ? <Tag color={orderStatusConfig.color}>{orderStatusConfig.title}</Tag> : ''}
            {orderStatus ? <Tag color={orderStatus.color}>{orderStatus.name}</Tag> : ''}
            {orderFulfillmentStatus ? (
              <Tag color={orderFulfillmentStatus.color}>{orderFulfillmentStatus.title}</Tag>
            ) : (
              ''
            )}
          </span>
          <br />
          <small>
            {itemCountFulfilled} of {itemCount} items fulfilled
          </small>
          <br />
          {_.size(fulfillmentUserName) > 1 ? (
            <small>
              Claimed by <strong>{fulfillmentUserName}</strong> ({getDuration(claimedAt)})
            </small>
          ) : (
            ''
          )}
        </div>
      </LineItemsPopover>
    );
  },
};

export const orderCustomerAndAddressTableColumn = {
  title: 'Customer',
  dataIndex: 'shippingLastName',
  key: 'shippingLastName',
  render: (shippingLastName, row) => {
    return (
      <>
        <OrderOriginTags order={row}/>
        <br />
        <small>
          {_.size(row.shippingCompany) ? (
            <strong>
              {row.shippingCompany}
              <br />
            </strong>
          ) : (
            ''
          )}
          <strong>
            {row.shippingFirstName} {row.shippingLastName}
          </strong>
          <br />
          {row.shippingAddress1}
          <br />
          {row.shippingAddress2 ? (
            <>
              {row.shippingAddress2}
              <br />
            </>
          ) : null}
          {row.shippingCountry} - {row.shippingPostcode} {row.shippingCity}
        </small>
      </>
    );
  },
};

export const orderFulfillmentTagsTableColumn = {
  title: 'Fulfillment tags',
  dataIndex: 'fulfillment',
  key: 'fulfillmentTags',
  render: (fulfillment = {}, row) => {
    const { tags = [], warehouse = {} } = fulfillment;
    // Also show warehouse tag here
    const { name, color } = warehouse;
    return (
      <>
        {name && color ? <Tag color={color}>{name}</Tag> : null}
        {tags.map((tag, index) => {
          const { label, color } = orderFulfillmentTagMap[tag] || {};
          return (
            <Tag color={color} key={`tag-${index}`}>
              {label || tag}
            </Tag>
          );
        })}
      </>
    );
  },
};

export const orderItemTagsTableColumn = {
  title: 'Item tags',
  dataIndex: 'tags',
  key: 'tags',
  render: (tags = {}, row) => {
    const externalItemStatus = getFulfillmentExternalStatus(row.fulfillment);
    const productionItemStatus = getFulfillmentProducedStatus(row.fulfillment);
    const notEU = _.get(row, 'notEU');
    const transferredAt = _.get(row, 'fulfillment.transferredAt.iso');
    // const { lineItems = [] } = row;
    // <small>{lineItems.map((item) => `${item.qty} x ${item.itemName}`).join(', ')}</small>
    return (
      <>
        {tags.needsSinzingTransfer && transferredAt ? <Tag color={colors.GREEN}>Transfer completed</Tag> : ''}
        {tags.needsSinzingTransfer && !transferredAt ? <Tag color={colors.VOLCANO}>Transfer pending</Tag> : ''}
        {tags.hasMylands ? <Tag>Mylands</Tag> : ''}
        {tags.hasMissPompadourExpress ? <Tag>MP Express</Tag> : ''}
        {tags.hasMissPompadourManufacture ? <Tag>MP Manufacture</Tag> : ''}
        {tags.hasAvm ? <Tag>AvM</Tag> : ''}
        {tags.hasPtp ? <Tag>PtP</Tag> : ''}
        {tags.hasVia ? <Tag>Via</Tag> : ''}
        {tags.hasCoucou ? <Tag>Coucou</Tag> : ''}
        {tags.hasColorCards ? <Tag>Farbkarten</Tag> : ''}
        {tags.hasClothing ? <Tag>MP Clothing</Tag> : ''}
        {tags.hasExternalItems ? <Tag>External</Tag> : ''}
        {externalItemStatus ? (
          <Tag color={externalItemStatus.color}>
            {externalItemStatus.title} ({externalItemStatus.itemCount})
          </Tag>
        ) : (
          ''
        )}
        {productionItemStatus ? (
          <Tag color={productionItemStatus.color}>
            {productionItemStatus.title} ({productionItemStatus.itemCount})
          </Tag>
        ) : (
          ''
        )}
        {notEU ? (
            <Tag color={colors.BLACK80}>
              Not EU
            </Tag>
        ) : null}
      </>
    );
  },
};

export const orderItemTagsWithItemsTableColumn = {
  title: 'Item tags',
  dataIndex: 'tags',
  key: 'tags',
  render: (tags = {}, row) => {
    const externalItemStatus = getFulfillmentExternalStatus(row.fulfillment);
    const productionItemStatus = getFulfillmentProducedStatus(row.fulfillment);
    return (
      <>
        {tags.hasMylands ? <Tag>Mylands</Tag> : ''}
        {tags.hasAvm ? <Tag>AvM</Tag> : ''}
        {tags.hasPtp ? <Tag>PtP</Tag> : ''}
        {tags.hasVia ? <Tag>Via</Tag> : ''}
        {tags.hasCoucou ? <Tag>Coucou</Tag> : ''}
        {tags.hasMissPompadour ? <Tag>MissPompadour</Tag> : ''}
        {tags.hasColorCards ? <Tag>Farbkarten</Tag> : ''}
        {externalItemStatus ? (
          <Tag color={externalItemStatus.color}>
            {externalItemStatus.title} ({externalItemStatus.itemCount})
          </Tag>
        ) : (
          ''
        )}
        {productionItemStatus ? (
          <Tag color={productionItemStatus.color}>
            {productionItemStatus.title} ({productionItemStatus.itemCount})
          </Tag>
        ) : (
          ''
        )}
        <ul>
          {(row.lineItems || []).map((item) => {
            return (
              <li key={item.itemId}>
                <small>
                  {item.qty} x {item.itemName}
                </small>
              </li>
            );
          })}
        </ul>
      </>
    );
  },
};

export const orderTotalTableColumn = {
  title: 'Total',
  dataIndex: 'orderTotal',
  key: 'orderTotal',
  render: (orderTotal, row) => formatPrice(row.orderTotal),
  sorter: (a, b) => a.orderTotal - b.orderTotal,
};
