import _ from 'lodash';
import React from 'react';
import moment from "moment";
import {Tag} from "antd";

import {colors} from "../../../lib/theme";
import {TranslationContentTable} from "../../../components/TranslationContentTable/TranslationContentTable";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Group',
        dataIndex: 'group',
        key: 'group',
        render: (group) => {
            return _.get(group, 'name');
        }
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        render: (value) => {
            return value ? (
                <Tag color={colors.PRIMARY}><small>TRUE</small></Tag>
            ) : (
                <Tag color={colors.GREY}><small>FALSE</small></Tag>
            )
        }
    },
]

export const AdminTranslationProductPropertyGroupOptions = ({user, authenticated, history}) => {

    return (
        <TranslationContentTable
            type={'property-group-option'}
            capitalName={'Product Property Group Options'}
            functionName={'ProductPropertyGroupOptions'}
            columns={columns}
            user={user}
            history={history}
        />
    )

};
