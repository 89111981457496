import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import { UI, colors } from '../../lib/theme';

import { DATE_TIME_FORMAT } from '../../lib/constants';
import { getOrderBounceSummaryCell } from '../../lib/tableUtils';

import {Col, Table, Tag, Button, Alert} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  formatPrice,
  getLineItemFulfillmentStatus,
  getDuration,
  getFulfillmentBouncedState,
  getFulfillmentExternalStatus,
  getFulfillmentProducedStatus,
  getLineItemExternalFulfillmentStatus,
  getLineItemProducedFulfillmentStatus,
  formatTitle,
  getWcProductEditLink,
} from '../../lib/utils';

import {
  CardWrapper,
  CardContent,
  CardHeader,
  CardFooter,
  Spacer, CardHeaderIcon,
} from '../../components/Layout/Layout';
import { WarehouseStockChip } from '../../components/WarehouseStockChip/WarehouseStockChip';
import { GtinScannerInput } from '../../components/GtinScannerInput/GtinScannerInput';

const Parse = require('parse');

const BounceSummaryWrapper = styled.div`
  position: relative;
  min-height: 100px;
  border-bottom: 1px solid ${colors.TRUE_BLACK20};
`;

const TableWrapper = styled.div`
  background-color: ${colors.WHITE};
  margin: -15px -15px;
  position: relative;
`;
const TableOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  z-index: 100;
`;
const ReturnStockTag = styled.h3`
  color: ${colors.GREEN_NEW};
`;

const LineItemQtyColumn = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
const LineItemQtyLabel = styled.p`
  padding-right: 8px;
  margin: 0;
  display: inline-block;
  font-weight: 600;
`;
const BundleItemNames = styled.div`
  margin-top: 10px;
`;
const lineItemImageColumn = {
  title: 'Image',
  dataIndex: 'image',
  key: 'image',
  render: (itemName, row) => {
    const imageUrl = _.get(row, 'warehouseStock.plentyProduct.imageUrl', '');
    return <img src={imageUrl} style={{ width: 60, height: 60, borderRadius: 4, backgroundColor: '#f2f2f2' }}/>;
  }
};

const lineItemNameColumn = {
  title: 'Name',
  dataIndex: 'itemName',
  key: 'itemName',
  render: (itemName, row) => {
    const { paFarbvarianten, paProdukt, paFarbmenge, qty, mylandsBase, warehouseStock, onStockClick, bundleItems = [] } = row;
    const plentyProduct = _.get(warehouseStock, 'plentyProduct', {});
    const attributes = [paFarbvarianten, paProdukt, paFarbmenge].filter((a) => _.size(a) > 0);
    return (
        <>
          <strong>{qty} x </strong> {formatTitle(itemName)}{' '}
          <small>
            {_.size(attributes) > 0 ? `(${attributes.join(',')})` : ''} {mylandsBase ? `(Base: ${mylandsBase})` : ''}
          </small>
          {_.size(plentyProduct.gtin) > 0 || _.size(plentyProduct.baseGtin) > 0 ? (
              <small><br />GTIN: {plentyProduct.gtin || ''} {_.size(plentyProduct.baseGtin) ? `(Base: ${plentyProduct.baseGtin})` : ''}<br /></small>
          ) : null}
          <WarehouseStockChip warehouseStock={warehouseStock} onClick={onStockClick} />
          {_.size(bundleItems) ? (
            <BundleItemNames>
              Includes {bundleItems.map(item => <Tag key={item.itemId}>{item.itemName}</Tag>)}
            </BundleItemNames>
          ) : ''}
        </>
    );
  },
  defaultSortOrder: 'ascend',
  sorter: (a, b) => a.itemName - b.itemName,
};
const lineItemSkuColumn = {
  title: 'SKU',
  dataIndex: 'sku',
  key: 'sku',
  sorter: (a, b) => a.sku - b.sku,
  render: (sku, row) => (
    <a href={getWcProductEditLink(row.productId)} target='_blank'>
      {sku}
    </a>
  ),
  className: 'line-item-sku-cell',
};
const lineItemQuantityColumn = {
  title: 'Quantity',
  dataIndex: 'qty',
  key: 'qty',
  render: (qty, row) => {
    const { color, title } = getLineItemFulfillmentStatus(row);
    return (
      <LineItemQtyColumn>
        <LineItemQtyLabel>
          {row.qtyFulfilled} / {qty}
        </LineItemQtyLabel>
        <Tag color={color}>{title}</Tag>
      </LineItemQtyColumn>
    );
  },
  sorter: (a, b) => a.qty - b.qty,
  className: 'line-item-qty-cell',
};
const lineItemPriceColumn = {
  title: 'Price',
  dataIndex: 'lineTotal',
  key: 'lineTotal',
  render: (lineTotal, row) => formatPrice(parseFloat(lineTotal) + parseFloat(row.lineTax)),
  sorter: (a, b) => a.lineTotal - b.lineTotal,
  className: 'line-item-price-cell',
};
const lineItemActionsColumn = {
  // This gets extended in render function
  title: 'Actions',
  dataIndex: 'totalTaxes',
  key: 'actions',
  width: 110,
  render: (objectId, row) => '',
  className: 'line-item-actions-cell',
};
const lineItemTableColumnsDef = [
  lineItemImageColumn,
  lineItemNameColumn,
  lineItemSkuColumn,
  // lineItemPriceColumn,
  {
    title: 'External',
    dataIndex: 'external',
    key: 'external',
    render: (external, row) => {
      if (!external) {
        return '';
      }
      const { color, title } = getLineItemExternalFulfillmentStatus(row);
      return <Tag color={color}>{title}</Tag>;
    },
  },
  {
    title: 'Production',
    dataIndex: 'produced',
    key: 'produced',
    render: (external, row) => {
      if (!external) {
        return '';
      }
      const { color, title } = getLineItemProducedFulfillmentStatus(row);
      return <Tag color={color}>{title}</Tag>;
    },
  },
  lineItemQuantityColumn,
  lineItemActionsColumn,
];
const lineItemExternalTableColumnsDef = [
  lineItemNameColumn,
  lineItemSkuColumn,
  // lineItemPriceColumn,
  {
    ...lineItemQuantityColumn,
    render: (qty, row) => {
      const { color, title } = getLineItemExternalFulfillmentStatus(row);
      return (
        <LineItemQtyColumn>
          <LineItemQtyLabel>
            {row.qtyExternalFulfilled || 0} / {qty}
          </LineItemQtyLabel>
          <Tag color={color}>{title}</Tag>
        </LineItemQtyColumn>
      );
    },
  },
  lineItemActionsColumn,
];
const lineItemProducedTableColumnsDef = [
  lineItemImageColumn,
  lineItemNameColumn,
  {
    title: 'Return Stock',
    dataIndex: 'availableReturnStock',
    key: 'availableReturnStock',
    render: (value, row) => {
      return (
          <ReturnStockTag>{value}</ReturnStockTag>
      )
    },
  },
  lineItemSkuColumn,
  lineItemPriceColumn,
  {
    ...lineItemQuantityColumn,
    render: (qty, row) => {
      const { color, title } = getLineItemProducedFulfillmentStatus(row);
      return (
        <LineItemQtyColumn>
          <LineItemQtyLabel>
            {row.qtyProducedFulfilled || 0} / {qty}
          </LineItemQtyLabel>
          <Tag color={color}>{title}</Tag>
        </LineItemQtyColumn>
      );
    },
  },
  lineItemActionsColumn,
];

const createActionButtons = (row, onAdd, onRemove, addDisabled, removeDisabled) => {
  return (
    <>
      <Button type='danger' onClick={() => onRemove(row)} disabled={removeDisabled} icon={<MinusOutlined />} />
      <Spacer />
      <Button type='primary' onClick={() => onAdd(row)} disabled={addDisabled} icon={<PlusOutlined />} />
    </>
  );
};

export const OrderProductionTable = (props) => {
  const { orderFulfillment, fulfillmentUsername, joinedLineItems = [], currentUserFulfills, addProducedItem, removeProducedItem, plentyStatusId } = props;

  const [open, setOpen] = useState(false);
  const [lineItemProducedTableColumns, setLineItemProducedTableColumns] = useState(lineItemProducedTableColumnsDef);

  const producedLineItems = useMemo(() => joinedLineItems.filter((lineItem) => lineItem.produced === true), [
    joinedLineItems,
  ]);
  const productionItemStatus = getFulfillmentProducedStatus(orderFulfillment || {});
  const orderCancelled = plentyStatusId >= 8 && plentyStatusId < 9;
  const [returnStockCounts, setReturnStockCounts] = useState(producedLineItems.map(p => 0));

  const fetchedReturnStockRef = useRef(false);
  useEffect(() => {
    if (!_.size(producedLineItems) || !open || fetchedReturnStockRef.current) {
      return;
    }
    fetchedReturnStockRef.current = true;
    (async () => {
      // Fetching via Redash from Google Sheet
      const availableReturnStock = await Parse.Cloud.run('adminFetchReturnStockForProductionItems', {
        "parameters": {}
      });
      const nextReturnStockCounts = producedLineItems.map((lineItem) => {
        const lineItemName = _.get(lineItem, 'itemName', '');
        const colorName = lineItemName.split(' - ')[0].replace('[BUNDLE] ', '').replace('MissPompadour', '')
            .replace('Mylands', '')
            .replace('LittlePomp', '')
            .replace('CosyColours', '').trim();
        const paintTypeName =  lineItemName.split(' - ')[1].split(' & ').join(' ').split(' &amp; ').join(' ').trim();
        console.log('Check Return Stock', colorName, paintTypeName);
        return _.get(availableReturnStock.find(s => s.FARBEN === colorName), `${paintTypeName}`, 0);
      });
      setReturnStockCounts(nextReturnStockCounts);
    })();
  }, [producedLineItems, open]);

  useEffect(() => {
    const nextLineItemProducedTableColumns = lineItemProducedTableColumns.map((column) => {
      if (column.key === 'actions') {
        return {
          ...column,
          render: (objectId, row) => {
            const { qty, qtyProducedFulfilled } = row;
            const addDisabled = orderCancelled || qty === qtyProducedFulfilled || !currentUserFulfills;
            const removeDisabled = qtyProducedFulfilled === 0 || !currentUserFulfills;
            return createActionButtons(row, addProducedItem, removeProducedItem, addDisabled, removeDisabled);
          },
        };
      }
      return column;
    });
    setLineItemProducedTableColumns(nextLineItemProducedTableColumns);
  }, [setLineItemProducedTableColumns, currentUserFulfills, orderCancelled]);

  const tableData = useMemo(() => {
    return producedLineItems.map((p, index) => {
      return {
        ...p,
        availableReturnStock: returnStockCounts[index],
      };
    })
  }, [producedLineItems, returnStockCounts]);

  return _.size(producedLineItems) > 0 ? (
        <Col span={24}>
          <CardWrapper>
            <CardHeader onClick={() => setOpen(!open)}>
              <h1><CardHeaderIcon open={open}/> Production Items (Mylands & MissPompadour)</h1>
              {productionItemStatus ? (
                  <Tag color={productionItemStatus.color}>{productionItemStatus.title}</Tag>
              ) : null}
            </CardHeader>
            {open ? (
                <>
                  <GtinScannerInput
                      onAdd={addProducedItem}
                      lineItems={joinedLineItems}
                      gtinKey='warehouseStock.plentyProduct.gtin'
                      baseGtinKey='warehouseStock.plentyProduct.baseGtin'
                      qtyCheckEnabled={true}
                      qtyCheckItemKey='qtyProducedFulfilled'
                      barcodePrinterEnabled={true}
                  />
                  {orderCancelled ? (
                      <CardContent>
                        <Alert
                            message={
                              <strong>This order has been cancelled!</strong>
                            }
                            type='error'
                            showIcon
                        />
                      </CardContent>
                  ) : null}
                  <CardContent>
                    <TableWrapper>
                      {!currentUserFulfills && _.size(fulfillmentUsername) ? <TableOverlay /> : ''}
                      <Table
                          columns={lineItemProducedTableColumns}
                          dataSource={tableData}
                          rowKey={'objectId'}
                          pagination={{
                            pageSize: 100,
                            hideOnSinglePage: true,
                          }}
                      />
                    </TableWrapper>
                  </CardContent>
                </>
            ) : null}
          </CardWrapper>
        </Col>
    ) : null
};

export const OrderExternalTable = (props) => {
  const { orderFulfillment, fulfillmentUsername, joinedLineItems = [], currentUserFulfills, addExternalItem, removeExternalItem } = props;

  const [open, setOpen] = useState(false);
  const [lineItemExternalTableColumns, setLineItemExternalTableColumns] = useState(lineItemExternalTableColumnsDef);

  const externalLineItems = useMemo(() => joinedLineItems.filter((lineItem) => lineItem.external === true), [
    joinedLineItems,
  ]);
  const externalItemStatus = getFulfillmentExternalStatus(orderFulfillment || {});

  useEffect(() => {
    const nextLineItemExternalTableColumns = lineItemExternalTableColumns.map((column) => {
      if (column.key === 'actions') {
        return {
          ...column,
          render: (objectId, row) => {
            const { qty, qtyExternalFulfilled } = row;
            const addDisabled = qty === qtyExternalFulfilled || !currentUserFulfills;
            const removeDisabled = qtyExternalFulfilled === 0 || !currentUserFulfills;
            return createActionButtons(row, addExternalItem, removeExternalItem, addDisabled, removeDisabled);
          },
        };
      }
      return column;
    });
    setLineItemExternalTableColumns(nextLineItemExternalTableColumns);
  }, [setLineItemExternalTableColumns, currentUserFulfills]);

  return _.size(externalLineItems) > 0 ? ((
          <Col span={24}>
            <CardWrapper>
              <CardHeader onClick={() => setOpen(!open)}>
                <h1><CardHeaderIcon open={open}/> External Items (AS Creation etc.)</h1>
                {externalItemStatus ? <Tag color={externalItemStatus.color}>{externalItemStatus.title}</Tag> : null}
              </CardHeader>
              {open ? (
                  <CardContent>
                    <TableWrapper>
                      {!currentUserFulfills && _.size(fulfillmentUsername) ? <TableOverlay /> : ''}
                      <Table
                          columns={lineItemExternalTableColumns}
                          dataSource={externalLineItems}
                          rowKey={'objectId'}
                          pagination={{
                            pageSize: 100,
                            hideOnSinglePage: true,
                          }}
                      />
                    </TableWrapper>
                  </CardContent>
              ) : null}
            </CardWrapper>
          </Col>
      )
  ) : null
};

export const OrderFulfillmentTable = (props) => {
  const { order, orderFulfillment, fulfillmentUsername, joinedLineItems = [], currentUserFulfills, addItem, removeItem, orderFulfillmentStatus, claimOrder, unclaimOrder, bounceOrder } = props;

  const [open, setOpen] = useState(false);
  const [lineItemTableColumns, setLineItemTableColumns] = useState(lineItemTableColumnsDef);
  const { bouncedCount, bouncedUntil, lastBouncedAt } = getFulfillmentBouncedState(order);

  useEffect(() => {
    const nextLineItemTableColumns = lineItemTableColumns.map((column) => {
      if (column.key === 'actions') {
        return {
          ...column,
          render: (objectId, row) => {
            const { qty, qtyFulfilled } = row;
            const addDisabled = qty === qtyFulfilled || !currentUserFulfills;
            const removeDisabled = qtyFulfilled === 0 || !currentUserFulfills;
            return createActionButtons(row, addItem, removeItem, addDisabled, removeDisabled);
          },
        };
      }
      return column;
    });
    setLineItemTableColumns(nextLineItemTableColumns);
  }, [setLineItemTableColumns, currentUserFulfills]);

  return _.size(joinedLineItems) > 0 ? ((
        <Col span={24}>
          <CardWrapper>
            <CardHeader onClick={() => setOpen(!open)}>
              <h1><CardHeaderIcon open={open}/> Fulfillment</h1>
              <p>
                <strong>
                  {orderFulfillment ? (
                      <>
                        {orderFulfillment.itemCountFulfilled} / {orderFulfillment.itemCount} items
                      </>
                  ) : (
                      ''
                  )}
                </strong>
                <Spacer />
                {orderFulfillmentStatus ? (
                    <Tag color={orderFulfillmentStatus.color}>{orderFulfillmentStatus.title}</Tag>
                ) : (
                    ''
                )}
              </p>
            </CardHeader>
            {open ? (
                <>
                  <GtinScannerInput
                      onAdd={addItem}
                      lineItems={joinedLineItems}
                      gtinKey='warehouseStock.plentyProduct.gtin'
                      baseGtinKey='warehouseStock.plentyProduct.baseGtin'
                      qtyCheckEnabled={true}
                      qtyCheckItemKey='qtyFulfilled'
                  />
                  <CardContent>
                    <TableWrapper>
                      {!currentUserFulfills && _.size(fulfillmentUsername) ? <TableOverlay /> : ''}
                      <Table
                          columns={lineItemTableColumns}
                          dataSource={joinedLineItems}
                          rowKey={'objectId'}
                          pagination={{
                            pageSize: 100,
                            hideOnSinglePage: true,
                          }}
                      />
                    </TableWrapper>
                  </CardContent>
                </>
            ) : null}
            <BounceSummaryWrapper>{getOrderBounceSummaryCell(order)}</BounceSummaryWrapper>
            <CardFooter>
              <p>
                {orderFulfillment ? <strong>{bouncedCount} times bounced</strong> : ''}
                <Spacer />
                {orderFulfillment && lastBouncedAt ? (
                    <>
                      Last bounced at {moment(lastBouncedAt).format(DATE_TIME_FORMAT)} until{' '}
                      <strong>{getDuration(bouncedUntil)}</strong>{' '}
                    </>
                ) : null}
              </p>
              <Button
                  type='danger'
                  onClick={() => bounceOrder(order)}
                  disabled={orderFulfillment && orderFulfillment.fulfilled}
              >
                Bounce Order
              </Button>
            </CardFooter>
          </CardWrapper>
        </Col>
    )
  ) : null
};
