import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import * as Parse from 'parse';
import _ from 'lodash';
import { notification, Modal } from 'antd';
import { useWarehouseStock } from './useWarehouseStock';
const { confirm } = Modal;

export const useLineItemFulfillment = ({ user, orderIds, live = true, skipQuery = false, warehouseId }) => {
  const lineItemsRef = useRef();
  const [lineItems, setLineItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const lineItemFulfillmentQueryRef = useRef();
  const lineItemFulfillmentQuerySubscriptionRef = useRef();
  const productIds = useMemo(() => {
    return lineItems.map((lineItem) => lineItem.productId);
  }, [lineItems]);
  // Store users by id as user objects dont change in short time frame
  const userStoreRef = useRef({});

  const { warehouseStocks } = useWarehouseStock({
    live,
    productIds,
    warehouseId,
    skipQuery: !_.size(warehouseId) || !_.size(productIds),
  });

  useEffect(() => {
    if (_.size(warehouseStocks)) {
      // Add warehouse stock to line items
      const formattedWarehouseStocks = warehouseStocks.map(w => {
        return {
          ...w,
          plentyVariationId: `${w.plentyVariationId}`,
        };
      });
      const warehouseStockByProductId = _.keyBy(formattedWarehouseStocks, 'plentyVariationId');
      lineItemsRef.current = lineItemsRef.current.map((lineItem) => {
        const warehouseStock = warehouseStockByProductId[lineItem.plentyVariationId] || {};
        return {
          ...lineItem,
          warehouseStock,
        };
      });
      setLineItems(lineItemsRef.current);
    }
  }, [warehouseStocks]);

  const updateLineItem = async (updatedLineItem) => {
    const fulfillmentUserPointer = _.get(updatedLineItem, 'fulfillmentUser', undefined);
    let fulfillmentUser = fulfillmentUserPointer;
    // Manually fetch this users data - not included in subscription updates
    if (fulfillmentUserPointer) {
      const fulfillmentUserId = fulfillmentUserPointer.objectId;
      // This local storing minimises the amount of requests
      if (userStoreRef.current[fulfillmentUserId]) {
        fulfillmentUser = userStoreRef.current[fulfillmentUserId];
      } else {
        const ParseUser = Parse.Object.extend('_User');
        const userQuery = new Parse.Query(ParseUser);
        userQuery.equalTo('objectId', fulfillmentUserId);
        const userResult = await userQuery.first({});
        if (userResult) {
          fulfillmentUser = userResult.toJSON();
          userStoreRef.current[fulfillmentUserId] = fulfillmentUser;
        }
      }
    }
    // Update ref and state
    lineItemsRef.current = lineItemsRef.current.map((lineItem) => {
      if (`${lineItem.objectId}` === updatedLineItem.objectId) {
        return {
          ...lineItem,
          ...updatedLineItem,
          fulfillmentUser,
        };
      }
      return lineItem;
    });
    setLineItems(lineItemsRef.current);
  };

  const subscribeLineItemFulfillment = async () => {
    if (!lineItemFulfillmentQueryRef.current) {
      return;
    }
    console.log('Subscribe lineItemFulfillment', new Date().toLocaleDateString());
    lineItemFulfillmentQuerySubscriptionRef.current = await lineItemFulfillmentQueryRef.current.subscribe();
    // lineItemFulfillmentQuerySubscriptionRef.current.on('create', (object) => {
    //   console.log('lineItemFulfillment created', object);
    // });
    lineItemFulfillmentQuerySubscriptionRef.current.on('update', (object) => {
      console.log('lineItemFulfillment updated', object, new Date().toLocaleDateString());
      updateLineItem(object.toJSON());
    });
    // lineItemFulfillmentQuerySubscriptionRef.current.on('enter', (object) => {
    //   console.log('lineItemFulfillment entered', object);
    // });
    // // Delete and Leave should never happen
    // lineItemFulfillmentQuerySubscriptionRef.current.on('leave', (object) => {
    //   console.log('lineItemFulfillment left', object);
    // });
    // lineItemFulfillmentQuerySubscriptionRef.current.on('delete', (object) => {
    //   console.log('lineItemFulfillment deleted', object);
    // });
  };

  const unsubscribeLineItemFulfillment = async () => {
    if (!lineItemFulfillmentQuerySubscriptionRef.current) {
      return;
    }
    console.log('Unsubscribe lineItemFulfillment');
    lineItemFulfillmentQuerySubscriptionRef.current.unsubscribe();
    lineItemFulfillmentQuerySubscriptionRef.current = null;
  };

  useEffect(() => {
    return () => unsubscribeLineItemFulfillment();
  }, []);

  const fetchData = useCallback(async () => {
    if (!_.size(orderIds) || skipQuery) {
      return;
    }
    setLoading(true);
    const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
    const orderFulfillmentQuery = new Parse.Query(OrderFulfillment);

    orderFulfillmentQuery.containedIn('orderId', orderIds);
    const orderFulfillmentQueryResult = await orderFulfillmentQuery.find({});

    if (!orderFulfillmentQueryResult) {
      console.warn('UH OH, no fulfillment query');
      setLoading(false);
      return;
    }

    // const orderRelationLineItemsQuery = orderFulfillmentQueryResult.relation('lineItems').query();
    // const orderRelationLineItems = await orderRelationLineItemsQuery.find({});

    const LineItemFulfillment = Parse.Object.extend('LineItemFulfillment');
    lineItemFulfillmentQueryRef.current = new Parse.Query(LineItemFulfillment);
    lineItemFulfillmentQueryRef.current.containedIn('orderId', orderIds);
    const result = await lineItemFulfillmentQueryRef.current.find({});
    lineItemsRef.current = result.map((item) => item.toJSON());
    setLineItems(lineItemsRef.current);
    setLoading(false);

    // Subscribe to query
    if (live) {
      subscribeLineItemFulfillment();
    }
  }, [lineItems, setLineItems, orderIds, live, setLoading, skipQuery]);

  useEffect(() => {
    fetchData();
  }, [skipQuery]);

  const fulfillLineItem = useCallback(
    async (lineItem, qtyChange, type, packingType = 'manual') => {
      const { objectId } = lineItem;
      const LineItemFulfillment = Parse.Object.extend('LineItemFulfillment');
      const query = new Parse.Query(LineItemFulfillment);
      query.equalTo('objectId', objectId);
      const parseLineItem = await query.first({});
      if (parseLineItem && user) {
        const fulfillAndSave = async (parseLineItemRef, userId, typeRef) => {
          const currentFulfilledQty = parseLineItemRef.get(`qty${typeRef || ''}Fulfilled`) || 0;
          const qty = parseLineItemRef.get('qty');
          const nextFulfilledQty = currentFulfilledQty + qtyChange;
          if (nextFulfilledQty >= 0 && nextFulfilledQty <= qty) {
            // Set new fulfillment User
            const parseUser = new Parse.User();
            parseUser.id = userId;
            parseLineItemRef.set('fulfillmentUser', parseUser);
            parseLineItemRef.set('packingType', packingType);
            // Update Quantity
            parseLineItemRef.set(`qty${typeRef || ''}Fulfilled`, nextFulfilledQty);
            await parseLineItemRef.save(null);
            notification.success({
              message: `${typeRef || 'Fulfillment'} - Line item quantity adjusted`,
            });
          } else {
            notification.error({
              message: `${typeRef || 'Fulfillment'} - Line item quantity not allowed`,
            });
          }
        };

        // Check if item gets fulfilled before it was externally ordered
        if (!type && parseLineItem.get('external') && !parseLineItem.get('qtyExternalFulfilled')) {
          confirm({
            title: 'Are you sure you want to fulfill this item already?',
            icon: 'exclamation-circle',
            content: `Item is not externally ordered yet!`,
            okText: 'Yes, fulfill already',
            cancelText: 'No, cancel',
            onOk: async () => fulfillAndSave(parseLineItem, user.objectId, type),
            onCancel() {},
          });
          return;
        }
        // Check if item gets fulfilled before it was externally ordered
        if (!type && parseLineItem.get('produced') && !parseLineItem.get('qtyProducedFulfilled')) {
          confirm({
            title: 'Are you sure you want to fulfill this item already?',
            icon: 'exclamation-circle',
            content: `Item is not produced yet!`,
            okText: 'Yes, fulfill already',
            cancelText: 'No, cancel',
            onOk: async () => fulfillAndSave(parseLineItem, user.objectId, type),
            onCancel() {},
          });
          return;
        }
        await fulfillAndSave(parseLineItem, user.objectId, type);
      } else {
        notification.error({
          message: 'Cannot adjust line item quantity',
          description: 'No fulfillment data imported yet. Please try again later.',
        });
      }
    },
    [user],
  );

  const addItem = (lineItem, packingType) => fulfillLineItem(lineItem, 1, '', packingType);
  const removeItem = (lineItem, packingType) => fulfillLineItem(lineItem, -1, '', packingType);

  const addProducedItem = (lineItem, packingType) => fulfillLineItem(lineItem, 1, 'Produced', packingType);
  const removeProducedItem = (lineItem, packingType) => fulfillLineItem(lineItem, -1, 'Produced', packingType);

  const addExternalItem = (lineItem, packingType) => fulfillLineItem(lineItem, 1, 'External', packingType);
  const removeExternalItem = (lineItem, packingType) => fulfillLineItem(lineItem, -1, 'External', packingType);

  return {
    lineItems,
    addItem,
    removeItem,
    addProducedItem,
    removeProducedItem,
    addExternalItem,
    removeExternalItem,
    loading,
  };
};
