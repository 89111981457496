import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { routes } from '../../lib/routes';

const { location } = window;

export const ProtectedRoute = ({ route, path, exact, component, user, authenticated, adminOnly = false }) => {
  const { isAdmin, isFulfillmentUser } = user || {};

  if (!authenticated && location.pathname !== routes.LOGIN) {
    // return <Redirect to={routes.LOGIN} />;
  }

  if (adminOnly && user && !isAdmin && location.pathname !== routes.DASHBOARD) {
    return <Redirect to={routes.DASHBOARD} />;
  }

  return <Route exact={exact} path={route} component={component} />;
};
