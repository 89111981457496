import React, {useCallback, useEffect, useState} from 'react';
import { routes } from '../../../lib/routes';
import { AdminClassEntryList } from '../../../components/AdminClassEntryList/AdminClassEntryList';
import {
  CardContent,
  CardHeader,
  CardWrapper,
  Container,
  ContentWrapper, Spacer
} from "../../../components/Layout/Layout";
import {Button, Card, Col, notification, Row, Select, Input} from "antd";
import * as Parse from "parse";
import _ from "lodash";
import {sourceLangs, targetLangs} from "../../../components/TranslationContentTable/TranslationContentTable";

const { Option } = Select;

const tableConfig = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Deepl ID',
    dataIndex: 'deeplId',
    key: 'deeplId',
  },
  {
    title: 'Source Lang',
    dataIndex: 'sourceLang',
    key: 'sourceLang',
  },
  {
    title: 'Target Lang',
    dataIndex: 'targetLang',
    key: 'targetLang',
  },
  {
    title: 'Error',
    dataIndex: 'error',
    key: 'error',
  },
];

export const AdminTranslationGlossaries = ({ user, authenticated, history }) => {

  const [loading, setLoading] = useState(false);
  const [translationLoading, setTranslationLoading] = useState(false);
  const [deeplStats, setDeeplStats] = useState({});
  const [sourceLang, setSourceLang] = useState('de');
  const [sourceText, setSourceText] = useState('');
  const [translationText, setTranslationText] = useState('');
  const [targetLang, setTargetLang] = useState('en');

  useEffect(() => {
    (async () => {
      try {
        const { character } = await Parse.Cloud.run(`translationFetchDeeplStatus`);
        setDeeplStats(character);
      } catch (error) {
      }
    })();
  }, []);

  const handleMatchRepositories = useCallback(async () => {
    setLoading(true);
    try {
      const nextDataResponse = await Parse.Cloud.run(`translationMatchLocalGlossaries`);
      console.log(nextDataResponse);
      notification.success({
        message: 'All synced',
      });
    } catch (error) {
      notification.error({
        message: 'Cannot get data',
        description: 'Unfortunately, we cannot fetch any data for you.',
      });
    }
    setLoading(false);
  }, []);


  const handleSourceLangChange = (langKey) => {
    setSourceLang(langKey);
  };
  const handleTargetLangChange = (langKey) => {
    setTargetLang(langKey);
  };
  const handleSourceTextChange = ({ target }) => {
    setSourceText(target.value);
  };
  const handleTranslateSource = async (text) => {
    setTranslationLoading(true);
    try {
      const { translation = '' } = await Parse.Cloud.run(`translationTranslateCustomText`, {
        text,
        sourceLang,
        targetLang,
      });
      setTranslationText(translation)
    } catch (error) {
      notification.error({
        message: 'Cannot translate',
        description: 'Sorry! Please try again.',
      });
    }
    setTranslationLoading(false);
  };

  const { count = 0, limit = 1 } = deeplStats;
  const deeplUsage = Math.floor(100 * count / limit) / 100;

  return (
      <Container>
        <ContentWrapper>
          <CardWrapper>
            <CardHeader>
              <h2>Manual Translation</h2>
              <h2>Usage: <span>{deeplUsage}%</span> <small>({count} of {limit} characters)</small></h2>
            </CardHeader>
            <CardHeader>
              <div>
                <Select
                    placeholder='Source language'
                    defaultValue={sourceLang}
                    onChange={handleSourceLangChange}
                >
                  {sourceLangs.map((lang) => (
                      <Option key={lang.key}>
                        {lang.name}
                      </Option>
                  ))}
                </Select>
                <Spacer/>
                <Select
                    placeholder='Target language'
                    defaultValue={targetLang}
                    onChange={handleTargetLangChange}
                >
                  {targetLangs.map((lang) => (
                      <Option key={lang.key}>
                        {lang.name}
                      </Option>
                  ))}
                </Select>
                <Spacer/>
                <Button
                    type='primary'
                    onClick={() => handleTranslateSource(sourceText)}
                    disabled={!_.size(sourceText)}
                    loading={translationLoading}
                >
                  Translate
                </Button>
              </div>
            </CardHeader>
            <CardContent>
              <Row>
                <Col sm={24} md={12}>
                  <p>SOURCE</p>
                  <Input.TextArea rows={10} onChange={handleSourceTextChange} value={sourceText} placeholder='Your text to translate' />
                </Col>
                <Col sm={24} md={12}>
                  <p>TRANSLATION</p>
                  <Input.TextArea rows={10} disabled={true} value={translationText} placeholder='Translation will be here' />
                </Col>
              </Row>
            </CardContent>
          </CardWrapper>
        </ContentWrapper>

        <ContentWrapper>
          <CardWrapper>
            <CardHeader>
              <Button type="primary" onClick={handleMatchRepositories} loading={loading}>Sync Glossaries</Button>
            </CardHeader>
          </CardWrapper>
        </ContentWrapper>

        <AdminClassEntryList
            history={history}
            title={'Translation Glossaries'}
            className={'TranslationGlossary'}
            tableConfig={tableConfig}
            editRoute={routes.ADMIN_TRANSLATION_GLOSSARIES_EDIT}
        />

      </Container>
  );
};
