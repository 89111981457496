import React, { useState, useRef, useMemo, useCallback } from 'react';
import { Container, CardWrapper, ContentWrapper, LinkCard, LinkCardTitle } from '../../components/Layout/Layout';
import { routes } from '../../lib/routes';
import { Link } from 'react-router-dom';
import {Row, Col, Input, Table, Button} from 'antd';
import {useOrderFulfillment} from "../../hooks/useOrderFulfillment";
import _ from "lodash";
import {
  orderBounceDateTableColumn, orderCustomerAndAddressTableColumn,
  orderFulfillmentTagsTableColumn,
  orderIdTableColumn, orderItemTagsTableColumn,
  orderStatusTableColumn, orderTotalTableColumn
} from "../../lib/tableUtils";

const orderTableColumns = [
  orderIdTableColumn,
  orderBounceDateTableColumn,
  orderStatusTableColumn,
  orderFulfillmentTagsTableColumn,
  orderCustomerAndAddressTableColumn,
  orderItemTagsTableColumn,
  orderTotalTableColumn,
  {
    // This gets extended in render function
    title: 'Actions',
    dataIndex: 'postDate',
    key: 'actions',
    width: 260,
    render: (postDate, row) => '',
  },
];

export const OrderSearch = ({ user }) => {

  const [orderIdValue, setOrderIdValue] = useState('');
  const [orderIdValueSearch, setOrderIdValueSearch] = useState('');
  const [plentyOrderIdValue, setPlentyOrderIdValue] = useState('');
  const [plentyOrderIdValueSearch, setPlentyOrderIdValueSearch] = useState('');

  const { orders, loading } = useOrderFulfillment({
    user,
    orderIds: [plentyOrderIdValueSearch, orderIdValueSearch].filter(v => _.size(v) > 0),
    skipQuery: !_.size(orderIdValueSearch) && !_.size(plentyOrderIdValueSearch),
    usePlentyId: _.size(plentyOrderIdValueSearch) > 0,
    orderType: '1,5',
  });

  const handleOrderIdValue = ({ target: { value }}) => {
    setOrderIdValue(value);
  };
  const handleOrderIdSearchClick = () => {
    setPlentyOrderIdValueSearch('');
    setOrderIdValueSearch(orderIdValue);
  };
  const handlePlentyOrderId = ({ target: { value }}) => {
    setPlentyOrderIdValue(value);
  };
  const handlePlentyOrderIdSearchClick = () => {
    setOrderIdValueSearch('');
    setPlentyOrderIdValueSearch(plentyOrderIdValue);
  };

  return (
    <Container>
      <ContentWrapper>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Input onChange={handleOrderIdValue} placeholder={'Search WC Order ID'}/>
          </Col>
          <Col span={4}>
            <Button type='primary' onClick={handleOrderIdSearchClick}>Search</Button>
          </Col>
          <Col span={8}>
            <Input onChange={handlePlentyOrderId} placeholder={'Search PM Order ID'}/>
          </Col>
          <Col span={4}>
            <Button type='primary' onClick={handlePlentyOrderIdSearchClick}>Search</Button>
          </Col>
        </Row>

        <CardWrapper>

          <Table
              columns={orderTableColumns}
              dataSource={orders}
              pagination={false}
              loading={loading}
              rowKey='id'
          />

        </CardWrapper>
      </ContentWrapper>
    </Container>
  );
}
