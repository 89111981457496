import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import _ from 'lodash';
import * as Parse from 'parse';
import moment from "moment";

import {Tag, Table} from 'antd';

import {
    CardContent,
    CardHeader,
    CardWrapper,
} from "../../components/Layout/Layout";
import {formatPrice, getSwLink, getWcOrderEditLink, getPlentyOrderLink, getFulfillmentLink} from "../../lib/utils";

const columnTableConfig = [
    {
        title: 'ID',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
    },
    {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date) => moment(date).format('llll'),
    },
    {
        title: 'Total',
        dataIndex: 'amountTotal',
        key: 'amountTotal',
        render: (amount) => formatPrice(amount),
    },
    {
        title: 'Plenty Status',
        dataIndex: 'fulfillment',
        key: 'fulfillment',
        render: (fulfillment = {}) => (
            <Tag>{_.get(fulfillment, 'plentyStatusId', '?')}</Tag>
        ),
    },
    {
        title: 'Affiliate',
        dataIndex: 'affiliateCode',
        key: 'affiliateCode',
    },
    {
        title: 'Links',
        dataIndex: 'id',
        key: 'id',
        render: (orderId, row) => {
            const { orderNumber = '', fulfillment } = row;
            const isWc = orderNumber.includes('WC-');
            const plentyId = _.get(fulfillment, 'plentyId');
            return (
                <>
                {isWc ? (
                    <a href={getWcOrderEditLink(orderNumber)} target='_blank'>WooCommerce</a>
                ) : (
                    <a href={getSwLink('/sw/order/detail/', orderId)} target='_blank'>Shopware</a>
                )}{' '}|{' '}<a href={getFulfillmentLink(orderNumber)} target='_blank'>Fulfillment</a>{' '}|
                    {' '}{plentyId ? <a href={getPlentyOrderLink(plentyId)} target='_blank'>Plenty</a> : null}
                </>
            );
        }
    },
];

export const CustomerOrders = ({user, authenticated, history, activeCustomer }) => {
    const [loading, setLoading] = useState(false);
    const [customerOrders, setCustomerOrders] = useState([]);

    const customerId = _.get(activeCustomer, 'id', '');
    const customerNumber = _.get(activeCustomer, 'customerNumber', '');

    useEffect(() => {
        if (!_.size(customerId)) {
            return;
        }
        (async () => {
            setLoading(true);
            try {
                const { orders: shopwareOrders = [] } = await Parse.Cloud.run('adminFetchShopwareOrdersByCustomer', {
                    customerId,
                    customerNumber,
                });
                setCustomerOrders(shopwareOrders);
            } catch (error) {
                console.warn('adminFetchShopwareOrdersByCustomer', error);
            }
            setLoading(false);
        })();
    }, [customerId]);

    const orderIds = useMemo(() => {
        return customerOrders.map(order => {
            return `${order.orderNumber}`.replace('WC-', '');
        });
    }, [customerOrders]);

    const fetchOrderFulfillments = useCallback(async (orderIds = []) => {
        // Fetch fulfillments by order Ids
        const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
        const orderFulfillmentQuery = new Parse.Query(OrderFulfillment);
        orderFulfillmentQuery.containedIn('orderId', orderIds);
        orderFulfillmentQuery.include('warehouse');
        orderFulfillmentQuery.limit(_.size(orderIds));
        const orderFulfillmentQueryResult = await orderFulfillmentQuery.find({});
        const orderFulfillmentStore = _.keyBy(
            orderFulfillmentQueryResult.map((order) => order.toJSON()),
            'orderId',
        );

        // Extend orders with fulfillment Data
        const nextCustomerOrders = customerOrders.map((order) => {
            const cleanOrderNumber = `${order.orderNumber}`.replace('WC-', '');
            return {
                ...order,
                fulfillment: _.get(orderFulfillmentStore, `${cleanOrderNumber}`),
            };
        })
        setCustomerOrders(nextCustomerOrders)
    }, [customerOrders]);

    useEffect(() => {
        if (_.size(orderIds)) {
            fetchOrderFulfillments(orderIds);
        }
        // Compare strings, so that it does not start looping as the memo array changes
    }, [orderIds.join(',')]);

    if (!activeCustomer) {
        return null;
    }
    return (
      <CardWrapper>
          <CardHeader><h2>Orders <small>({_.size(customerOrders)})</small></h2></CardHeader>
          <CardContent>
              <Table dataSource={customerOrders} loading={loading} pagination={false} columns={columnTableConfig} />
          </CardContent>
      </CardWrapper>
    );
};
