import React, { useState, useRef, useEffect, useCallback } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';
import { routes } from '../../lib/routes';
import * as Parse from 'parse';

import { Form, Input, Button, Col, Row, notification, List, Radio, Table } from 'antd';
import { DownCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';

import { Loader } from '../../components/Loader/Loader';

const { Search } = Input;

const Container = styled.div`
  height: calc(100vh - ${UI.NAVBAR_HEIGHT}px - ${UI.FOOTER_HEIGHT}px);
  display: block;
  width: 100vw;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
`;
const ContentWrapper = styled.div`
    max-width: ${UI.CONTENT_MAXWIDTH}
    padding: 15px;
`;
const CardWrapper = styled.div`
  padding: 30px;
  background-color: ${colors.WHITE};
`;
const Spacer = styled.div`
  height: 30px;
`;

const tableColumns = [
  {
    title: 'ProductId',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    width: 150,
  },
  {
    title: 'Name',
    dataIndex: 'postTitle',
    key: 'postTitle',
    width: 250,
  },
  {
    title: 'ColorCode',
    dataIndex: 'colorCode',
    key: 'colorCode',
    width: 100,
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 20 },
  },
  colon: false,
};

const coverImages = [
  { label: 'MissPompadour', value: 'mp' },
  { label: 'MP Bucket STS', value: 'mp_2' },
  { label: 'MP Bucket', value: 'mp_3' },
  { label: 'MP Bucket Die Wertvolle 2.5l', value: 'mp_wertvoll_25' },
  { label: 'MP Bucket Die Wertvolle 2.5l - AMAZON', value: 'mp_wertvoll_25_amazon' },
  { label: 'MP Bucket Die Wertvolle 1l', value: 'mp_wertvoll_1' },
  { label: 'MP Bucket Die Wertvolle 1l - AMAZON', value: 'mp_wertvoll_1_amazon' },
  { label: 'MP Bucket Die Nützliche 1l', value: 'mp_nuetzlich_1' },
  { label: 'MP Bucket Die Nützliche 1l - AMAZON', value: 'mp_nuetzlich_1_amazon' },
  { label: 'MP Bucket Die Nützliche 2.5l', value: 'mp_nuetzlich_25' },
  { label: 'MP Bucket Die Nützliche 2.5l - AMAZON', value: 'mp_nuetzlich_25_amazon' },
  { label: 'MP Bucket STS 1l - AMAZON', value: 'mp_sts_1_amazon' },
  { label: 'MP Bucket STS 2.5l - AMAZON', value: 'mp_sts_25_amazon' },
  { label: 'MP LittlePomp 2.5l Wandfarbe', value: 'mp_lp_25_wandfarbe' },
  { label: 'Wall', value: 'wall' },
  { label: 'Wall Bricks', value: 'wall_bricks' },
  { label: 'Wall New', value: 'wall_plain' },
  { label: 'Wall New Shadow', value: 'wall_shadow' },
  { label: 'Wall Bricks New', value: 'wall_bricks_2' },
  { label: 'Mylands', value: 'mylands' },
  { label: 'PtP', value: 'ptp' },
  { label: 'Via', value: 'via' },
];

const DataForm = ({ form, data, onSubmit, loading, products, onLoadProducts }) => {
  const [generationType, setGenerationType] = useState('By Colors');
  const [searchString, setSearchString] = useState('');
  const selectedProductIdsRef = useRef([]);

  const handleFormValuesChange = (values) => {
    if (values.generationType) {
      setGenerationType(values.generationType);
    }
  };

  useEffect(() => {
    if (generationType === 'By Products') {
      onLoadProducts(searchString);
    }
  }, [generationType, searchString]);

  const handleSubmit = async (event) => {
    try {
      const values = await form.validateFields();
      if (generationType === 'By Colors') {
        const colors = JSON.parse(values.colorsJson);
        if (!_.size(colors) || !_.isArray(colors)) {
          throw new Error('Wrong JSON input');
        }
        onSubmit({ ...values, colors });
      } else {
        onSubmit({ ...values, productIds: selectedProductIdsRef.current });
      }
    } catch (error) {
      console.warn('ProductImages: Form failed', error);
      notification.error({
        message: 'Input not valid',
        description: 'Please review your inputs.',
      });
    }
  };

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys) => {
      selectedProductIdsRef.current = selectedRowKeys;
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const initialData = {
    coverImage: 'mp',
    colorsJson: '[\n' +
        '{\n' +
        '"color": "#D8E1E4",\n' +
        '"name": "misspompadour-blau-mit-schnee-farbtopf",\n' +
        '"line1": "Blau mit",\n' +
        '"line2": "Schnee",\n' +
        '"line3":""\n' +
        '}\n' +
        ']',
    generationType,
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={handleSubmit}
      initialValues={initialData}
      onValuesChange={handleFormValuesChange}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name='coverImage'
            label='Cover Image'
            rules={[{ required: true, message: 'Please choose a cover image!' }]}
          >
            <Radio.Group options={coverImages} optionType='button' buttonStyle='solid' />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='generationType' label='Generation Type'>
            <Radio.Group options={['By Products', 'By Colors']} optionType='button' buttonStyle='solid' />
          </Form.Item>
        </Col>
        {generationType === 'By Colors' ? (
          <Col span={24}>
            <Form.Item
              name='colorsJson'
              label='Colors (JSON)'
              rules={[{ required: true, message: 'Please define the colors!' }]}
              extra={<small>This field accepts JSON array with color codes ["#fffff", "rgba(0,0,0,1)"];</small>}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>
        ) : (
          <Row gutter={[16, 16]}>
            <Col md={12} sm={24}>
              <Search
                placeholder='Search for product'
                enterButton='Search'
                prefix={<SearchOutlined />}
                onSearch={(value) => setSearchString(value)}
              />
            </Col>
            <Col md={6} sm={24}>
              {_.size(products)} Products
            </Col>
            <Col md={6} sm={24}>
              <Button type='primary' icon={<ReloadOutlined />} onClick={() => onLoadProducts(searchString)} block>
                Refresh Data
              </Button>
            </Col>
            <Col span={24}>
              <Table
                rowSelection={rowSelection}
                columns={tableColumns}
                dataSource={products}
                scroll={{ x: 1000, y: 500 }}
                pagination={false}
                rowKey='id'
                loading={loading || _.size(products) === 0}
              />
            </Col>
          </Row>
        )}
      </Row>
      <Row>
        <Col>
          <Button type='primary' htmlType='submit' loading={loading}>
            Generate Images
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const ProductImages = (props) => {
  const { user, authenticated, match = {}, history } = props;
  const {
    location: { pathname = '' },
  } = history;

  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const result = await Parse.Cloud.run('adminGenerateProductImages', values);
      setData(result.data);

      notification.success({
        message: 'All saved',
        description: 'The new entry was saved successfully.',
      });
    } catch (error) {
      notification.error({
        message: 'Cannot save entry',
        description: 'Unfortunately, we cannot save the new entry. Please try again.',
      });
    }
    setLoading(false);
  };

  const fetchProductsByFilter = useCallback(async (searchString) => {
    setLoading(true);
    try {
      const { count, results } = await Parse.Cloud.run('adminWpFetchProducts', {
        page: 1,
        limit: 200,
        searchString,
        includeVariations: false,
      });

      // Only show products with color code
      const filteredProducts = results.filter((p) => _.size(p.colorCode) > 0);
      setProducts(filteredProducts);
      setTimeout(() => setLoading(false), 100);
    } catch (error) {
      notification.error({
        message: 'Cannot fetch products',
      });
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      <ContentWrapper>
        <CardWrapper>
          <h1>Generate Product Images</h1>
          {loading ? <Loader /> : null}
          <DataForm
            onSubmit={handleSubmit}
            form={form}
            loading={loading}
            products={products}
            onLoadProducts={fetchProductsByFilter}
          />
        </CardWrapper>
        <Spacer />
        {_.size(data) ? (
          <CardWrapper>
            <h1>Result</h1>
            <p>These images become available after the job has finished.</p>
            <List
              bordered
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <a href={item.url} download={item.name} target='_blank'>
                    <DownCircleOutlined style={{ fontSize: 15 }} /> Download
                  </a>
                  {' | '}
                  Open in Browser:{' '}
                  <a href={item.url} target='_blank'>
                    {item.url}
                  </a>
                </List.Item>
              )}
            />
          </CardWrapper>
        ) : null}
      </ContentWrapper>
    </Container>
  );
};
