import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';
import { routes } from '../../lib/routes';
import { Link } from 'react-router-dom';
import * as Parse from 'parse';

import { Modal, Table, Button, Divider, Tag, notification } from 'antd';
const { confirm } = Modal;

const Container = styled.div`
  height: calc(100vh - ${UI.NAVBAR_HEIGHT}px - ${UI.FOOTER_HEIGHT}px);
  display: block;
  width: 100vw;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
`;
const ContentWrapper = styled.div`
    max-width: ${UI.CONTENT_MAXWIDTH}
    padding: 15px;
`;
const CardWrapper = styled.div`
  padding: 0 0 30px;
  background-color: ${colors.WHITE};
`;
const CardHeader = styled.div`
  padding: 15px;
`;
const DeleteLink = styled.a`
  color: ${colors.RED};
`;

export const AdminUsers = ({ user, authenticated, history }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = useCallback(async () => {
    try {
      const Users = Parse.Object.extend('_User');
      const query = new Parse.Query(Users);
      query.limit(1000);
      query.contains('roles', 'fulfillment');
      query.notEqualTo('deleted', true);
      const results = await query.find();
      setUsers(results.map((result) => result.toJSON()));
    } catch (error) {
      notification.error({
        message: 'Cannot get users',
        description: 'Unfortunately, we cannot fetch any users for you.',
      });
    }
  }, []);

  const handleDelete = useCallback(({ objectId }) => {
    confirm({
      content: 'Do you want to delete this user?',
      onOk() {
        (async () => {
          const User = Parse.Object.extend('_User');
          const deleteUser = new User();
          deleteUser.id = objectId;
          deleteUser.set('deleted', true);
          await deleteUser.save();
          notification.success({
            message: 'User deleted',
            description: 'The user was deleted successfully.',
          });
          fetchUsers();
        })();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: 'Id',
        dataIndex: 'objectId',
        key: 'objectId',
        // render: (text, record) =>
        //     <Link to={routes.ADMIN_USERS_EDIT.replace(':userId', record.objectId)}>{text}</Link>,
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Roles',
        key: 'roles',
        dataIndex: 'roles',
        render: (roles) =>
          (roles || []).map((role) => (
            <Tag color='blue' key={role}>
              {role.toUpperCase()}
            </Tag>
          )),
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <span>
            {/*<Link to={routes.ADMIN_USERS_EDIT.replace(':userId', record.objectId)}>Edit</Link>*/}
            {/*<Divider type="vertical" />*/}
            {/*<DeleteLink onClick={() => handleDelete(record)}>Delete</DeleteLink>*/}
          </span>
        ),
      },
    ];
  }, [routes, handleDelete]);

  const handleCreateUser = useCallback(() => {
    history.push(routes.ADMIN_USERS_EDIT.replace(':userId', 'new'));
  }, [routes]);

  return (
    <Container>
      <ContentWrapper>
        <h1>Users</h1>
        <CardWrapper>
          <CardHeader>
            <Button type='primary' onClick={handleCreateUser}>
              Create new User
            </Button>
          </CardHeader>
          <Table columns={columns} dataSource={users} />
        </CardWrapper>
      </ContentWrapper>
    </Container>
  );
};
