import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as Parse from 'parse';

import { useWindowFocus } from './useWindowFocus';

export const useActiveOrderFulfillment = ({ user }) => {
  const ordersRef = useRef();
  const [orders, setOrders] = useState([]);
  const [activeOrder, setActiveOrder] = useState();
  const orderFulfillmentQueryRef = useRef();
  const orderFulfillmentQuerySubscriptionRef = useRef();
  const windowFocussed = useWindowFocus();

  // Params
  // includeWcOrders
  // StartDate, EndDate, orderIds, orderStatuses, limit, skip
  // subscribe

  const updateFulfillment = async (nextOrderFulfillment) => {
    // Update ref and state
    ordersRef.current = ordersRef.current.map((orderFulfillment) => {
      if (`${orderFulfillment.orderId}` === nextOrderFulfillment.orderId) {
        return nextOrderFulfillment;
      }
      return orderFulfillment;
    });
    setOrders(ordersRef.current);
  };

  const addFulfillment = async (nextOrderFulfillment) => {
    // Check if fulfillment already in list
    const findOrder = orders.find((order) => order.orderId === nextOrderFulfillment.orderId);
    if (findOrder) {
      return;
    }
    // Add to ref and state
    ordersRef.current = [...ordersRef.current, nextOrderFulfillment];
    setOrders(ordersRef.current);
    setActiveOrder(nextOrderFulfillment);
  };

  const removeFulfillment = async (removedOrderFulfillment) => {
    // Add to ref and state
    ordersRef.current = ordersRef.current.filter(
      (orderFulfillment) => orderFulfillment.objectId !== removedOrderFulfillment.objectId,
    );
    setOrders(ordersRef.current);
    if (activeOrder && activeOrder.objectId === removedOrderFulfillment.objectId) {
      setActiveOrder(undefined);
    }
  };

  const subscribeOrderFulfillment = async () => {
    if (!orderFulfillmentQueryRef.current) {
      return;
    }
    orderFulfillmentQuerySubscriptionRef.current = await orderFulfillmentQueryRef.current.subscribe();
    // orderFulfillmentQuerySubscriptionRef.current.on('create', (object) => {
    //   console.log('MY FULFILLMENT created', object);
    // });
    orderFulfillmentQuerySubscriptionRef.current.on('update', (object) => {
      // console.log('Updated Active OrderFulfillment', object);
      updateFulfillment(object.toJSON());
    });
    orderFulfillmentQuerySubscriptionRef.current.on('enter', (object) => {
      // console.log('Entered Active OrderFulfillment', object);
      addFulfillment(object.toJSON());
    });
    orderFulfillmentQuerySubscriptionRef.current.on('leave', (object) => {
      // console.log('Left Active OrderFulfillment', object);
      removeFulfillment(object.toJSON());
    });
    // orderFulfillmentQuerySubscriptionRef.current.on('delete', (object) => {
    //   console.log('MY FULFILLMENT deleted', object);
    // });
    // orderFulfillmentQuerySubscriptionRef.current.on('close', () => {
    //   console.log('MY FULFILLMETN subscription closed');
    // });
  };

  const unsubscribeOrderFulfillment = async () => {
    if (!orderFulfillmentQuerySubscriptionRef.current) {
      return;
    }
    orderFulfillmentQuerySubscriptionRef.current.unsubscribe();
    orderFulfillmentQuerySubscriptionRef.current = null;
  };

  useEffect(() => {
    return () => unsubscribeOrderFulfillment();
  }, []);

  const fetchData = useCallback(async () => {
    if (!user) {
      return;
    }
    await unsubscribeOrderFulfillment();
    const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
    orderFulfillmentQueryRef.current = new Parse.Query(OrderFulfillment);
    // Create parse user from current user
    const ParseUser = Parse.Object.extend('_User');
    const parseUser = new ParseUser();
    parseUser.id = user.objectId;
    orderFulfillmentQueryRef.current.equalTo('fulfillmentUser', parseUser);
    orderFulfillmentQueryRef.current.descending('claimedAt');
    const orderFulfillmentQueryResult = await orderFulfillmentQueryRef.current.find({});
    // Keep ref for updates via subscription
    ordersRef.current = orderFulfillmentQueryResult.map((order) => order.toJSON());
    setOrders(ordersRef.current);

    // Subscribe to query
    await subscribeOrderFulfillment();
  }, [orders, setOrders, subscribeOrderFulfillment, user]);

  useEffect(() => {
    fetchData();
  }, [user]);

  useEffect(() => {
    if (windowFocussed) {
      fetchData();
    }
  }, [windowFocussed]);

  return { orders, activeOrder };
};
