import _ from 'lodash';
import React, {useState, useCallback, useMemo, useEffect} from 'react';
import styled from 'styled-components';
import * as Parse from 'parse';
import moment from "moment";

import {Table, Button, notification, Select, Input, Tag, Checkbox} from 'antd';
import {UI, colors} from '../../lib/theme';
import {Spacer} from "../../components/Layout/Layout";

const {Search} = Input;
const {Option} = Select;

// TODO: Get all configs from API?
export const sourceLangs = [
    {
        key: 'de',
        name: 'FROM: de-DE'
    }
]
export const targetLangs = [
    {
        key: 'en',
        name: 'TO: en-GB'
    }, {
        key: 'fr',
        name: 'TO: fr-FR'
    }, {
        key: 'nl',
        name: 'TO: nl-NL'
    }
];

const crowdinFileIdMap = {
    'product': 4,
    'category': 6,
    'cms-page': 8,
    'property-group': 12,
    'property-group-option': 10,
}

const Container = styled.div`
  height: calc(100vh - ${UI.NAVBAR_HEIGHT}px - ${UI.FOOTER_HEIGHT}px);
  display: block;
  width: 100vw;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
`;
const ContentWrapper = styled.div`
    max-width: ${UI.CONTENT_MAXWIDTH}
    padding: 15px;
`;
const CardWrapper = styled.div`
  padding: 0 0 30px;
  background-color: ${colors.WHITE};
`;
const CardHeader = styled.div`
  padding: 15px;
`;

const pageSize = 100;

export const TranslationContentTable = ({type, capitalName, functionName, columns}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [sourceLang, setSourceLang] = useState('de');
    const [targetLang, setTargetLang] = useState('en');
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [searchQuery, setSearchQuery] = useState({search: '', page: 1, sourceLang, targetLang, limit: pageSize});
    const [force, setForce] = useState(false);

    const crowdinFileId = crowdinFileIdMap[type];

    const fetchData = useCallback(async (query) => {
        setLoading(true);
        try {
            const nextDataResponse = await Parse.Cloud.run(`translationFetch${functionName}`, {
                ...query
            });
            // Add a key to make the table row selection work
            const withKey = nextDataResponse.data.map(p => {
                return {
                    ...p,
                    key: p.id,
                }
            });
            console.log(withKey.map((p => `https://crowdin.com/translate/misspompadour/${crowdinFileId}/${sourceLang}-${targetLang}?filter=basic&value=0#q=${p.id}`)).join('\n'))
            setData(withKey);
            setSelectedRowIds([]);
        } catch (error) {
            notification.error({
                message: 'Cannot get data',
                description: 'Unfortunately, we cannot fetch any data for you.',
            });
        }
        setLoading(false);
    }, [capitalName]);

    useEffect(() => {
        fetchData(searchQuery);
    }, [searchQuery.search, searchQuery.page, searchQuery.sourceLang, searchQuery.targetLang]);

    const handleTranslateSelection = useCallback(async (ids) => {
        try {
            await Parse.Cloud.run(`translationTranslate${functionName}`, {
                ids,
                sourceLang,
                targetLang,
                force,
            });
            setForce(false);
        } catch (error) {
            notification.error({
                message: 'Cannot translate data',
                description: error,
            });
        }
    }, [sourceLang, targetLang, force]);

    const handleTranslate = useCallback(async (record) => {
        handleTranslateSelection([record.id]);
    }, [handleTranslateSelection]);

    const handleOpenCrowdin = useCallback(async (record) => {
        console.log('RECORD', record);
        window.open(`https://crowdin.com/translate/misspompadour/${crowdinFileId}/${sourceLang}-${targetLang}?filter=basic&value=0#q=${record.id}`, '_blank');
    }, [sourceLang, targetLang, crowdinFileId]);

    const handleSearch = useCallback(
        async (searchValue) => {
            setSearchQuery({
                page: 1,
                search: searchValue,
                sourceLang,
                targetLang,
            });
        },
        [searchQuery, sourceLang, targetLang],
    );

    const handlePageChange = useCallback(
        async (page) => {
            setSearchQuery({
                ...searchQuery,
                page,
            });
        },
        [searchQuery],
    );

    const columnsWithActions = useMemo(() => {
        return [
            ...columns,
            {
                title: 'Length',
                dataIndex: 'length',
                key: 'length',
                render: (value, record) => {
                    const sourceCharCount = _.get(record, 'translationReference.sourceCharCount', 0);
                    const targetCharCount = _.get(record, 'translationReference.targetCharCount', 0);
                    return `${sourceCharCount} => ${targetCharCount} Chars`;
                }
            },
            {
                title: 'Last Translated',
                dataIndex: 'translated',
                key: 'translated',
                render: (value, record) => {
                    const translationDate = _.get(record, 'translationReference.updatedAt');
                    return _.size(translationDate) ? (
                        <Tag color={colors.GREEN}>{moment(translationDate).format('YYYY-MM-DD HH:mm')}</Tag>
                    ) : (
                        <Tag color={colors.ORANGE}><small>MISSING</small></Tag>
                    )
                }
            },
            {
                title: 'Actions',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button type='default' onClick={() => handleOpenCrowdin(record)}>Crowdin</Button>
                        <Button type='primary' onClick={() => handleTranslate(record)}>Translate</Button>
                    </span>
                ),
            },
        ];
    }, [columns, handleTranslate, handleOpenCrowdin]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowIds(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            key: record.id,
            name: record.name,
        }),
    };

    const handleSourceLangChange = (langKey) => {
        setSourceLang(langKey);
        setSearchQuery({...searchQuery, sourceLang: langKey});
    };

    const handleTargetLangChange = (langKey) => {
        setTargetLang(langKey);
        setSearchQuery({...searchQuery, targetLang: langKey});
    };

    const handleForceCheckboxChange = ({ target: { checked }}) => {
        setForce(checked);
    };

    const dataCount = _.size(data);

    return (
        <Container>
            <ContentWrapper>
                <h1>{capitalName} Translations</h1>
                <CardWrapper>
                    <CardHeader>
                        <Search placeholder={`Search ${capitalName} Name`} enterButton='Search' onSearch={handleSearch}
                                style={{maxWidth: 450}}/>
                    </CardHeader>
                    <CardHeader>
                        <Select
                            placeholder='Source language'
                            defaultValue={sourceLang}
                            onChange={handleSourceLangChange}
                        >
                            {sourceLangs.map((lang) => (
                                <Option key={lang.key}>
                                    {lang.name}
                                </Option>
                            ))}
                        </Select>
                        <Spacer/>
                        <Select
                            placeholder='Target language'
                            defaultValue={targetLang}
                            onChange={handleTargetLangChange}
                        >
                            {targetLangs.map((lang) => (
                                <Option key={lang.key}>
                                    {lang.name}
                                </Option>
                            ))}
                        </Select>
                        <Spacer/>
                        <Button type='primary' onClick={() => handleTranslateSelection(selectedRowIds)}
                                disabled={!_.size(selectedRowIds)}>
                            Translate ({_.size(selectedRowIds)} {capitalName})
                        </Button>
                        <Spacer/>

                        <Checkbox checked={force} onChange={handleForceCheckboxChange}>
                            Force translation (use with care!)
                        </Checkbox>

                        <Spacer/>

                        <a href={`https://crowdin.com/translate/misspompadour/${crowdinFileId}/${sourceLang}-${targetLang}?filter=basic&value=0`} target='_blank'>
                            Go To CrowdIn
                        </a>
                    </CardHeader>

                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        columns={columnsWithActions}
                        dataSource={data}
                        pagination={{
                            showSizeChanger: false,
                            pageSize,
                            total: dataCount === pageSize ? (searchQuery.page + 1) * pageSize : (searchQuery.page * pageSize) + dataCount,
                            current: searchQuery.page,
                            onChange: handlePageChange
                        }}
                        loading={loading}
                    />

                </CardWrapper>
            </ContentWrapper>
        </Container>
    );
};
