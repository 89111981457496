import React, { Component } from 'react';
import { Container, CardWrapper, ContentWrapper, LinkCard, LinkCardTitle } from '../../components/Layout/Layout';
import { routes } from '../../lib/routes';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { OrdersFulfillmentTableByFilters } from '../../components/OrdersFulfillmentTableByFilters/OrdersFulfillmentTableByFilters';

const filterTags = {
  hasColorCardsOnly: false,
  hasWallpaperOnly: false,
};
const fulfillmentFilterTags = {
  // hasExternalFulfilled: true,
  hasProductionFulfilled: true,
};
const warehouses = [];

export class OrderFulfillment extends Component {
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    const { user } = this.props;
    return (
      <Container>
        <ContentWrapper>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Link to={routes.ORDER_FULFILLMENT_BY_WAREHOUSE.replace(':warehouseId', 'sinzing')}>
                <LinkCard>
                  <LinkCardTitle>Sinzing Fulfillment</LinkCardTitle>
                </LinkCard>
              </Link>
            </Col>
            <Col span={12}>
              <Link to={routes.ORDER_FULFILLMENT_BY_WAREHOUSE.replace(':warehouseId', 'laaber')}>
                <LinkCard>
                  <LinkCardTitle>Laaber Fulfillment</LinkCardTitle>
                </LinkCard>
              </Link>
            </Col>
          </Row>

          <CardWrapper>
            <OrdersFulfillmentTableByFilters
              title={'Order Fulfillment all Warehouses'}
              user={user}
              filterTags={filterTags}
              fulfillmentFilterTags={fulfillmentFilterTags}
              warehouses={warehouses}
              showOrderStatistics={true}
            />
          </CardWrapper>
        </ContentWrapper>
      </Container>
    );
  }
}
