import _ from 'lodash';
import React, { useEffect, useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';
import { routes } from '../../lib/routes';
import { isPlentyPanel } from '../../lib/utils';
import { Route, Link, withRouter, Redirect, Switch } from 'react-router-dom';
import * as Parse from 'parse';
import { Layout, Menu, Avatar, Button } from 'antd';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';

import { ProtectedRoute } from '../../components/ProtectedRoute/ProtectedRoute';
import { Login } from '../Login/Login';
import { Dashboard } from '../Dashboard/Dashboard';
import { OrderFulfillment } from '../Orders/OrderFulfillment';
import { OrderProduction } from '../Orders/OrderProduction';
import { OrderFulfillmentDetails } from '../Orders/OrderFulfillmentDetails';
import { OrderFulfillmentBatchDetails } from '../Orders/OrderFulfillmentBatchDetails';
import { ActiveOrderFulfillments } from '../../components/ActiveOrderFulfillments/ActiveOrderFulfillments';
import { ActiveJobLogs } from '../../components/ActiveJobLogs/ActiveJobLogs';

import { OrderBounced } from '../Orders/OrderBounced';
import { AdminUsers } from '../Admin/AdminUsers';
import { AdminUsersEdit } from '../Admin/AdminUsersEdit';
import { EmailTemplates } from '../Admin/EmailTemplates';
import { EmailTemplatesEdit } from '../Admin/EmailTemplatesEdit';
import { ShopwareSync } from '../Admin/ShopwareSync';
import { ProductImages } from '../Admin/ProductImages';
import { ConfigParamsEdit } from '../Admin/ConfigParamsEdit';
import { ConfigParams } from '../Admin/ConfigParams';
import { OrderColorCards } from '../Orders/OrderColorCards';
import { PushNotifications } from '../Admin/PushNotifications';
import { ImageLibrary } from '../Admin/ImageLibrary';
import { ImageLibraryEdit } from '../Admin/ImageLibraryEdit';
import { OrderFulfillmentByWarehouse } from '../Orders/OrderFulfillmentByWarehouse';
import { OrderProductionByBrand } from '../Orders/OrderProductionByBrand';
import { PackerSelectionForm } from '../../components/PackerSelection/PackerSelection';
import { ProductFeedsEdit } from '../Admin/ProductFeedsEdit';
import { ProductFeeds } from '../Admin/ProductFeeds';
import { PromotionCarouselItemEdit } from '../Admin/PromotionCarouselItemEdit';
import { PromotionCarouselItem } from '../Admin/PromotionCarouselItem';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { PackerStatistics } from '../../components/PackerStatistics/PackerStatistics';
import { Loader } from '../../components/Loader/Loader';
import {UserMenu} from "../../components/UserMenu/UserMenu";
import {UserProfile} from "../UserProfile/UserProfile";
import {OrderFulfillmentByPlentyId} from "../Orders/OrderFulfillmentByPlentyId";
import {CustomerSupportCartLink} from "../Admin/CustomerSupportCartLink";
import {OrderSearch} from "../Orders/OrderSearch";
import {CustomerSearch} from "../Admin/CustomerSearch";
import {AdminTranslationProducts} from "../Admin/Translation/ProductTranslations";
import {AdminTranslationCategories} from "../Admin/Translation/CategoryTranslations";
import {AdminTranslationGlossaries} from "../Admin/Translation/TranslationGlossaries";
import {AdminTranslationGlossariesEdit} from "../Admin/Translation/TranslationGlossariesEdit";
import {AdminTranslationProductPropertyGroupOptions} from "../Admin/Translation/ProductPropertyGroupOptionTranslations";
import {AdminTranslationProductPropertyGroups} from "../Admin/Translation/ProductPropertyGroupsTranslations";
import {AdminTranslationPages} from "../Admin/Translation/PageTranslations";
import {OrderMarketplace} from "../Orders/OrderMarketplace";
import {OrderCH} from "../Orders/OrderCH";
import {YoutubeVideosEdit} from "../Admin/YoutubeVideosEdit";
import {YoutubeVideos} from "../Admin/YoutubeVideos";
import {OrderTransactionImport} from "../Admin/OrderTransactionImport";
import {OrderExternal} from "../Orders/OrderExternal";

const { Header, Footer } = Layout;

const StyledHeader = styled(Header)`
  height: ${UI.NAVBAR_HEIGHT}px;
  padding: 0 15px;
  border-bottom: 1px solid ${colors.BLACK60};
`;
const ContentContainer = styled.div`
  height: calc(100vh - ${UI.NAVBAR_HEIGHT}px - ${UI.FOOTER_HEIGHT}px);
  display: block;
  width: 100vw;
`;
const StyledFooter = styled(Footer)`
  height: ${UI.FOOTER_HEIGHT}px;
  padding: 2px 15px;
  background-color: ${colors.TRUE_BLACK};
  color: ${colors.SECONDARY};
  font-size: 10px;
`;
const StyledLayout = styled(Layout)``;
const Logo = styled.h2`
  line-height: 20px;
  font-size: 16px;
  padding: 5px;
  letter-spacing: 2px;
  border: 2px solid ${isPlentyPanel ? colors.RED : colors.BLUE};
  background-color: ${colors.BLACK60};
  color: ${colors.WHITE};
  font-weight: 500;
  margin: 0;
`;
const LogoLink = styled(Link)`
  margin: 15px 30px 15px 0;
  float: left;
  display: inline-block;
`;

const StyledMenu = styled(Menu).attrs({
  theme: 'dark',
  mode: 'horizontal',
  defaultSelectedKeys: ['1'],
})`
  line-height: ${UI.NAVBAR_HEIGHT}px;
  float: left;
  font-weight: 500;
  height: ${UI.FOOTER_HEIGHT - 1}px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const StyledMenuMobile = styled(Menu).attrs({
  theme: 'dark',
  mode: 'inline',
  defaultSelectedKeys: ['1'],
})`
  line-height: ${UI.NAVBAR_HEIGHT}px;
  font-weight: 500;
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;
const MobileMenuOverlay = styled.div`
  top: ${UI.NAVBAR_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background-color: ${colors.BLACK90};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;
const MobileMenuButton = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    float: right;
  }
`;

const menu = [
  {
    key: 'Dashboard',
    path: routes.DASHBOARD,
    title: 'Dashboard',
    private: true,
  },
  {
    key: 'Orders',
    path: '',
    title: 'Orders',
    private: true,
    submenu: [
      {
        key: 'Fulfillment',
        path: routes.ORDER_FULFILLMENT,
        title: 'Fulfillment',
      },
      {
        key: 'Production',
        path: routes.ORDER_PRODUCTION,
        title: 'Production',
      },
      {
        key: 'Marketplaces',
        path: routes.ORDER_MARKETPLACE,
        title: 'Marketplaces',
      },
      {
        key: 'CH-Orders',
        path: routes.ORDER_CH,
        title: 'CH Orders',
      },
      {
        key: 'Bounced',
        path: routes.ORDER_BOUNCED,
        title: 'Bounced',
      },
      {
        key: 'ColorCards',
        path: routes.ORDER_COLOR_CARDS,
        title: 'Color Cards only',
      },
      {
        key: 'External',
        path: routes.ORDER_EXTERNAL,
        title: 'External / Wallpaper',
      },
      {
        key: 'Search',
        path: routes.ORDER_SEARCH,
        title: 'Search',
      },
    ],
  },
  {
    key: 'support',
    path: '',
    title: 'Support',
    private: true,
    submenu: [
      {
        key: 'customer-search',
        path: routes.ADMIN_CUSTOMER_SEARCH,
        title: 'Customer Search',
      },
      {
        key: 'create-cart-link',
        path: routes.ADMIN_CUSTOMER_SUPPORT_CART_LINK.replace(':id', 'new'),
        title: 'Create Cart Link',
      },
      {
        key: 'email-templates',
        path: routes.ADMIN_EMAIL_TEMPLATES,
        title: 'Email Templates',
      },
    ],
  },
  {
    key: 'translation',
    path: '',
    title: 'Translations',
    private: true,
    submenu: [
      {
        key: 'glossary-translations',
        path: routes.ADMIN_TRANSLATION_GLOSSARIES,
        title: 'Glossary',
      },
      {
        key: 'product-translations',
        path: routes.ADMIN_TRANSLATION_PRODUCTS,
        title: 'Products',
      },
      {
        key: 'product-property-groups',
        path: routes.ADMIN_TRANSLATION_PRODUCT_PROPERTY_GROUPS,
        title: 'Product Property Groups',
      },
      {
        key: 'product-property-group-options',
        path: routes.ADMIN_TRANSLATION_PRODUCT_PROPERTY_GROUP_OPTIONS,
        title: 'Product Property Group Options',
      },
      {
        key: 'category-translations',
        path: routes.ADMIN_TRANSLATION_CATEGORIES,
        title: 'Categories',
      },
      {
        key: 'page-translations',
        path: routes.ADMIN_TRANSLATION_PAGES,
        title: 'Pages',
      },
    ],
  },
  {
  	key: 'admin',
    path: routes.ADMIN_USERS,
    title: 'Admin',
    private: true,
    admin: true,
    submenu: [
      {
        key: 'admin-users',
        path: routes.ADMIN_USERS,
        title: 'Users',
      },
      {
        key: 'admin-shopware-sync',
        path: routes.ADMIN_SHOPWARE_SYNC,
        title: 'Shopware / Plenty Sync',
      },
      {
        key: 'youtube-videos',
        path: routes.ADMIN_YOUTUBE_VIDEOS,
        title: 'Youtube Videos',
      },
      {
        key: 'product-feeds',
        path: routes.ADMIN_PRODUCT_FEEDS,
        title: 'Product Feeds',
      },
      {
        key: 'admin-product-images',
        path: routes.ADMIN_PRODUCT_IMAGES,
        title: 'Product Images',
      },
      {
        key: 'config-params',
        path: routes.ADMIN_CONFIG_PARAMS,
        title: 'Configuration',
      },
      {
        key: 'push-notifications',
        path: routes.ADMIN_PUSH_NOTIFICATIONS,
        title: 'Push Notifications',
      },
      {
        key: 'image-library',
        path: routes.ADMIN_IMAGE_LIBRARY,
        title: 'Image Library',
      },
      {
        key: 'promotion-carousel-items',
        path: routes.ADMIN_PROMOTION_CAROUSEL_ITEMS,
        title: 'Promotion Carousel Item',
      },
      {
        key: 'order-transaction-import',
        path: routes.ADMIN_IMPORT_ORDER_TRANSACTIONS,
        title: 'Order Transaction Import',
      },
    ],
  },
];

export const Root = withRouter(({ history, location }) => {
  const [initialized, setInitialized] = useState(false);
  const [menuItems, setMenuItems] = useState(menu);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user, parseUser, authenticated, logout, login } = useCurrentUser({ initialized });
  const { isAdmin, isFulfillmentUser } = user || {};

  const logoutTimeoutRef = useRef();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  useEffect(() => {
    Parse.initialize('JAGEw7icYVszr6fmhgUMkkk9EeTyTEvD4ExczqQyEKGD', '');
    if (process.env.NODE_ENV === 'development') {
      Parse.serverURL = 'http://localhost:8080/v1';
      // Parse.serverURL = 'https://api.misspompadour.de/v1';
    } else {
      Parse.serverURL = isPlentyPanel ? 'https://api-plenty.misspompadour.de/v1' : 'https://api.misspompadour.de/v1';
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    const hasUser = _.isObject(user);
    const filteredMenuItems = menu.filter((item) => {
      const privateItem = item.private === true;
      return (privateItem && hasUser && (isAdmin || isFulfillmentUser)) || !privateItem;
    });
    setMenuItems(filteredMenuItems);
  }, [initialized, authenticated, isAdmin, isFulfillmentUser]);

  const renderMenuItems = () => {
    return menuItems.map((item) => {
      // Only show admin menus to admin users
      if (item.admin && !isAdmin) {
        return;
      }
      return _.size(item.submenu) > 0 ? (
        <Menu.SubMenu key={item.key} title={item.title}>
          {item.submenu.map((subitem) => (
            <Menu.Item key={subitem.key}>
              <Link to={subitem.path}>{subitem.title}</Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item key={item.key} path={item.path}>
          <Link to={item.path}>{item.title}</Link>
        </Menu.Item>
      );
    });
  };


  const handleLogout = useCallback(async () => {
    logout();
    history.push(routes.LOGIN);
  }, [logout, authenticated]);

  useEffect(() => {
    // Wait for parse to authenticate -> if not authenticated, then just logout
    const clearLogoutTimeout = () => {
      clearTimeout(logoutTimeoutRef.current);
      logoutTimeoutRef.current = null;
    };
    if (authenticated) {
      clearLogoutTimeout();
    } else {
      logoutTimeoutRef.current = setTimeout(() => {
        handleLogout();
      }, 2000);
    }
    return () => clearLogoutTimeout();
  }, [authenticated]);

  const handleLogin = useCallback(async (username, password) => {
    try {
      await login(username, password);
      history.push(routes.DASHBOARD);
      return { success: true };
    } catch (error) {
      return { error };
    }
  }, []);

  useEffect(() => {
    // Any other user rule than Admin or Fulfillment User -> straight logout
    // TODO: Prevent this on Server side from being possible
    if (authenticated && !isAdmin && !isFulfillmentUser) {
      handleLogout();
    }
  }, [authenticated, isAdmin, isFulfillmentUser]);

  if (!initialized) {
    return <Loader />;
  }

  // TODO: Handle this in ProtectedRoute
  if (!authenticated && location.pathname !== routes.LOGIN) {
    // const redirect = `/?r=${location.pathname}`;
    // return <Redirect to={redirect} />;
    return <Loader />;
  }

  if (authenticated && _.size(location.search)) {
    // const redirect = location.search.replace('?r=', '');
    // return <Redirect to={redirect} />;
    return <Loader />;
  }
  return (
    <StyledLayout>
      {mobileMenuOpen ? (
        <MobileMenuOverlay>
          {authenticated ? (
            <>
              <StyledMenuMobile>{renderMenuItems()}</StyledMenuMobile>
              <UserMenu onLogout={handleLogout} user={user} showDesktop={false}/>
            </>
          ) : (
            ''
          )}
        </MobileMenuOverlay>
      ) : (
        ''
      )}

      <StyledHeader>
        <LogoLink to={authenticated ? routes.DASHBOARD : routes.LOGIN}>
          <Logo>MP Panel</Logo>
        </LogoLink>
        {authenticated ? (
          <>
            <StyledMenu>{renderMenuItems()}</StyledMenu>
            <PackerSelectionForm parseUser={parseUser} />
            <PackerStatistics parseUser={parseUser} />
            <UserMenu onLogout={handleLogout} user={user} showMobile={false}/>
            <MobileMenuButton>
              <Button icon={<MenuOutlined />} type='primary' onClick={() => setMobileMenuOpen(!mobileMenuOpen)} />
            </MobileMenuButton>
          </>
        ) : (
          ''
        )}
      </StyledHeader>

      <ContentContainer>
        <Switch>
          {/* Public routes */}
          <Route
            path={routes.LOGIN}
            exact
            component={(props) =>
              authenticated ? (
                <Redirect to={routes.DASHBOARD} />
              ) : (
                <Login {...props} user={user} authenticated={authenticated} onLogin={handleLogin} />
              )
            }
          />

          {/* Private routes */}
          <ProtectedRoute
              path={routes.DASHBOARD}
              component={(props) => <Dashboard {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
              path={routes.USER_PROFILE}
              component={(props) => <UserProfile {...props} user={user} parseUser={parseUser} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_FULFILLMENT_BY_PLENTY_ID}
            component={(props) => <OrderFulfillmentByPlentyId {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_FULFILLMENT_BY_WAREHOUSE_DETAILS}
            component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_FULFILLMENT_BY_WAREHOUSE}
            component={(props) => <OrderFulfillmentByWarehouse {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_FULFILLMENT_DETAILS}
            component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_FULFILLMENT_BATCH_DETAILS}
            component={(props) => <OrderFulfillmentBatchDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_FULFILLMENT}
            component={(props) => <OrderFulfillment {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
              path={routes.ORDER_SEARCH_DETAILS}
              component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
              path={routes.ORDER_SEARCH}
              component={(props) => <OrderSearch {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_PRODUCTION_BY_BRAND_DETAILS}
            component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_PRODUCTION_BY_BRAND}
            component={(props) => <OrderProductionByBrand {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_PRODUCTION_DETAILS}
            component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_PRODUCTION}
            component={(props) => <OrderProduction {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_EXTERNAL}
            component={(props) => <OrderExternal {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_MARKETPLACE_DETAILS}
            component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_MARKETPLACE}
            component={(props) => <OrderMarketplace {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
              path={routes.ORDER_CH_DETAILS}
              component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
              path={routes.ORDER_CH}
              component={(props) => <OrderCH {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_BOUNCED_DETAILS}
            component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_BOUNCED}
            component={(props) => <OrderBounced {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_COLOR_CARDS_DETAILS}
            component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_COLOR_CARDS}
            component={(props) => <OrderColorCards {...props} user={user} authenticated={authenticated} />}
          />
          <ProtectedRoute
            path={routes.ORDER_OVERVIEW_DETAILS}
            component={(props) => <OrderFulfillmentDetails {...props} user={user} authenticated={authenticated} />}
          />

          {/* Admin routes */}
          <ProtectedRoute
            path={routes.ADMIN_USERS_EDIT}
            component={(props) => <AdminUsersEdit {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_USERS}
            component={(props) => <AdminUsers {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_EMAIL_TEMPLATES_EDIT}
            component={(props) => <EmailTemplatesEdit {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_EMAIL_TEMPLATES}
            component={(props) => <EmailTemplates {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_CONFIG_PARAMS_EDIT}
            component={(props) => <ConfigParamsEdit {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_CONFIG_PARAMS}
            component={(props) => <ConfigParams {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_PRODUCT_FEEDS_EDIT}
            component={(props) => <ProductFeedsEdit {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_PRODUCT_FEEDS}
            component={(props) => <ProductFeeds {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_PRODUCT_LIST}
            component={(props) => <ShopwareSync {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_SHOPWARE_SYNC}
            component={(props) => <ShopwareSync {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_PRODUCT_IMAGES}
            component={(props) => <ProductImages {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_PUSH_NOTIFICATIONS}
            component={(props) => <PushNotifications {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_IMAGE_LIBRARY_EDIT}
            component={(props) => <ImageLibraryEdit {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_IMAGE_LIBRARY}
            component={(props) => <ImageLibrary {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_PROMOTION_CAROUSEL_ITEMS_EDIT}
            component={(props) => <PromotionCarouselItemEdit {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_PROMOTION_CAROUSEL_ITEMS}
            component={(props) => <PromotionCarouselItem {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_CUSTOMER_SUPPORT_CART_LINK}
            component={(props) => <CustomerSupportCartLink {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />
          <ProtectedRoute
            path={routes.ADMIN_CUSTOMER_SEARCH}
            component={(props) => <CustomerSearch {...props} user={user} authenticated={authenticated} />}
            adminOnly={true}
          />

          <ProtectedRoute
              path={routes.ADMIN_TRANSLATION_PRODUCTS}
              component={(props) => <AdminTranslationProducts {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
          <ProtectedRoute
              path={routes.ADMIN_TRANSLATION_PRODUCT_PROPERTY_GROUPS}
              component={(props) => <AdminTranslationProductPropertyGroups {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
          <ProtectedRoute
              path={routes.ADMIN_TRANSLATION_PRODUCT_PROPERTY_GROUP_OPTIONS}
              component={(props) => <AdminTranslationProductPropertyGroupOptions {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
          <ProtectedRoute
              path={routes.ADMIN_TRANSLATION_CATEGORIES}
              component={(props) => <AdminTranslationCategories {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
          <ProtectedRoute
              path={routes.ADMIN_TRANSLATION_PAGES}
              component={(props) => <AdminTranslationPages {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
          <ProtectedRoute
              path={routes.ADMIN_TRANSLATION_GLOSSARIES_EDIT}
              component={(props) => <AdminTranslationGlossariesEdit {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
          <ProtectedRoute
              path={routes.ADMIN_TRANSLATION_GLOSSARIES}
              component={(props) => <AdminTranslationGlossaries {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
          <ProtectedRoute
              path={routes.ADMIN_YOUTUBE_VIDEOS_EDIT}
              component={(props) => <YoutubeVideosEdit {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
          <ProtectedRoute
              path={routes.ADMIN_YOUTUBE_VIDEOS}
              component={(props) => <YoutubeVideos {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
          <ProtectedRoute
              path={routes.ADMIN_IMPORT_ORDER_TRANSACTIONS}
              component={(props) => <OrderTransactionImport {...props} user={user} authenticated={authenticated} />}
              adminOnly={true}
          />
        </Switch>
      </ContentContainer>
      <StyledFooter></StyledFooter>
      {isAdmin || isFulfillmentUser ? <ActiveOrderFulfillments user={user} history={history} /> : ''}
      {isAdmin ? <ActiveJobLogs user={user} history={history} /> : ''}
    </StyledLayout>
  );
});
