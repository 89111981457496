import React from 'react';
import { routes } from '../../lib/routes';
import { AdminClassEntryList } from '../../components/AdminClassEntryList/AdminClassEntryList';

const tableConfig = [
  {
    title: 'Background',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: (imageUrl) => (imageUrl ? <img src={imageUrl} style={{ width: 100 }} /> : ''),
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
    key: 'slug',
  },
  {
    title: 'SortIndex',
    dataIndex: 'sortIndex',
    key: 'sortIndex',
  },
  {
    title: 'Visible',
    dataIndex: 'visible',
    key: 'visible',
    render: (visible) => (visible ? 'True' : 'False'),
  },
];

export const PromotionCarouselItem = ({ user, authenticated, history }) => {
  return (
    <AdminClassEntryList
      history={history}
      title={'Promotion Carousel Items'}
      className={'PromotionCarouselItem'}
      tableConfig={tableConfig}
      editRoute={routes.ADMIN_PROMOTION_CAROUSEL_ITEMS_EDIT}
    />
  );
};
