import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';
import * as Parse from 'parse';

import { Form, Input, Button, Col, Row, Select, notification } from 'antd';
import { Loader } from '../../components/Loader/Loader';

const { Option } = Select;

const Container = styled.div`
  display: block;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const ContentWrapper = styled.div`
    max-width: ${UI.CONTENT_MAXWIDTH}
    padding: 15px;
`;
const CardWrapper = styled.div`
  padding: 30px;
  background-color: ${colors.WHITE};
`;
const FormWrapper = styled.div`
  max-width: 800px;
`;
const Spacer = styled.div`
  height: 30px;
`;
const TemplateSelect = styled(Select)`
  width: 100%;
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 20 },
  },
  colon: false,
};

const DataForm = ({ form, data, onSubmit, loading }) => {
  const handleSubmit = async (event) => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
    } catch (error) {
      console.warn('OrderFulfillmentDetailsForm: Form failed', error);
      notification.error({
        message: 'Cannot save entry',
        description: 'Please review your inputs.',
      });
    }
  };

  return (
    <Form {...formItemLayout} initialValues={data} onFinish={handleSubmit} form={form}>
      <Row gutter={[16, 0]}>
        <Col>
          <Form.Item name='subject' rules={[{ required: true }]} label='Subject'>
            <Input disabled />
          </Form.Item>
          <Spacer />
          <Form.Item
            name='body'
            rules={[{ required: true, message: 'Please define a text!' }]}
            label='Text'
            extra={
              <small>
                Available placeholders: %title% | %firstname% | %lastname% | %orderId% | %orderDate% | %orderTotal% |
                %logo%
              </small>
            }
          >
            <Input.TextArea rows={10} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type='primary' htmlType='submit' loading={loading} disabled={loading}>
            Send email
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const OrderFulfillmentDetailsEmailForm = (props) => {
  const { orderId, email, onSuccess } = props;

  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(false);

  const templateId = template ? template.objectId : undefined;

  useEffect(() => {
    if (!_.size(orderId)) {
      return;
    }
    (async () => {
      try {
        const EmailTemplate = Parse.Object.extend('EmailTemplate');
        const query = new Parse.Query(EmailTemplate);
        query.notEqualTo('deleted', true);
        const results = await query.find({});
        setData(results.map((result) => result.toJSON()));
      } catch (error) {
        notification.error({
          message: 'Cannot get data',
          description: 'Unfortunately, we cannot fetch this data for you.',
        });
      }
    })();
  }, [templateId]);

  const sendEmail = async (values, previewOnly) => {
    setLoading(true);
    const {
      emailAddress,
      // subject, TODO: Support subject overwrite
      body,
    } = values;

    try {
      const { sendTo } = await Parse.Cloud.run('adminSendOrderEmail', {
        templateId,
        // emailAddress,
        orderId,
        body,
        // subject,
        previewOnly,
      });
      notification.success({
        message: 'E-Mail sent',
        description: `E-Mail was sent to ${sendTo} for order ${orderId}`,
      });
      setLoading(false);
      setTemplate(undefined);
      onSuccess();
    } catch (error) {
      console.warn('sendTestEmail failed', error);
      notification.error({
        message: 'Send email failed',
        description: error.toString(),
      });
      setLoading(false);
    }
  };

  const handleTemplateChange = (selectedTemplateId) => {
    const findTemplate = data.find((template) => template.objectId === selectedTemplateId);
    if (findTemplate) {
      setTemplate(findTemplate);
    }
  };

  return (
    <Container>
      <ContentWrapper>
        <CardWrapper>
          <h3>Send email to customer (to {email})</h3>
          {_.size(data) ? (
            <FormWrapper>
              <Form {...formItemLayout}>
                <Row gutter={[16, 0]}>
                  <Col>
                    <Form.Item label='Email template'>
                      <TemplateSelect
                        placeholder='Please select a template for the email'
                        defaultValue={templateId}
                        onChange={handleTemplateChange}
                      >
                        {data.map((template) => (
                          <Option key={template.objectId}>
                            {template.name} ({template.subject})
                          </Option>
                        ))}
                      </TemplateSelect>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              {_.size(templateId) ? (
                <DataForm data={template} onSubmit={sendEmail} loading={loading} form={form} />
              ) : null}
            </FormWrapper>
          ) : (
            <Loader />
          )}
        </CardWrapper>
      </ContentWrapper>
    </Container>
  );
};
