import {colors} from './theme';
import * as moment from 'moment';
import _ from 'lodash';
import React from 'react';
import {routes} from "./routes";

const url = _.get(window, 'location.href', '');
export const isProduction = url.includes('https://app.misspompadour.de') || url.includes('https://app-plenty.misspompadour.de');
export const isPlentyPanel = url.includes('https://app-plenty.misspompadour.de');

export const orderStatuses = {
    'wc-pending': {
        title: 'Pending',
        color: colors.VOLCANO,
    },
    'wc-on-hold': {
        title: 'On-Hold',
        color: colors.GEEKBLUE,
    },
    'wc-processing': {
        title: 'Processing',
        color: colors.LIME,
    },
    'wc-color-production': {
        title: 'Color Production',
        color: colors.PURPLE,
    },
    'wc-completed': {
        title: 'Completed',
        color: colors.GREEN,
    },
    'wc-refunded': {
        title: 'Refunded',
        color: colors.GREEN,
    },
    'wc-cancelled': {
        title: 'Cancelled',
        color: colors.TRUE_BLACK,
    },
    'wc-failed': {
        title: 'Failed',
        color: colors.RED,
    },
};

export const orderFulfillmentTags = [
    {value: 'complaint', label: 'Complaint', color: colors.MAGENTA},
    {value: 'fulfillment-mistake', label: 'Fulfillment Mistake', color: colors.BLACK},
];

export const orderFulfillmentTagMap = _.keyBy(orderFulfillmentTags, 'value');

export const priceFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
});
export const formatPrice = (price = '') => {
    return priceFormatter.format(price);
};

export const round = (number) => {
    return Math.floor(number);
};

// NOTE: Legacy - will be removed soon after move to plenty is complete
export const getOrderFulfillmentStatus = (orderFulfillment) => {
    if (!orderFulfillment || !orderFulfillment.objectId) {
        return {
            title: 'Loading...',
            color: null,
        };
    }
    const {itemCount, itemCountFulfilled, fulfilled} = orderFulfillment;
    if (itemCount === itemCountFulfilled || fulfilled) {
        return {
            title: 'Packing completed',
            color: colors.GREEN,
        };
    }
    if (itemCountFulfilled > 0 && itemCount > itemCountFulfilled) {
        return {
            title: 'Packing started',
            color: colors.GOLD,
        };
    }
    return {
        title: 'Packing pending',
        color: colors.VOLCANO,
    };
};

export const getLineItemFulfillmentStatus = (lineItem) => {
    if (!lineItem) {
        return {
            title: 'Loading...',
            color: null,
        };
    }
    const {qty, qtyFulfilled, fulfilled, isExternalItem} = lineItem;
    if (isExternalItem) {
        return {
            title: 'External',
            color: colors.BLUE,
        };
    }
    if (qty === qtyFulfilled || fulfilled) {
        return {
            title: 'Fulfilled',
            color: colors.GREEN,
        };
    }
    if (qtyFulfilled > 0 && qty > qtyFulfilled) {
        return {
            title: 'Started',
            color: colors.GOLD,
        };
    }
    return {
        title: 'Pending',
        color: colors.VOLCANO,
    };
};

export const getDuration = (start = new Date(), end = new Date()) => {
    const diff = moment(end) - moment(start);
    const duration = moment.duration(diff);
    const seconds = duration.get('seconds');
    const minutes = duration.get('minutes');
    const hours = duration.get('hours');
    const days = duration.get('days');
    const months = duration.get('months');
    // Positive
    if (months >= 1) {
        return `${months} month${months > 1 ? 's' : ''} ago`;
    }
    if (days >= 1) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    }
    if (hours >= 1) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    if (minutes >= 1) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    if (seconds >= 0) {
        return `seconds ago`;
    }
    // Negative
    if (months <= -1) {
        return `in ${Math.abs(months)} month${months < 1 ? 's' : ''}`;
    }
    if (days <= -1) {
        return `in ${Math.abs(days)} day${days < 1 ? 's' : ''}`;
    }
    if (hours <= -1) {
        return `in ${Math.abs(hours)} hour${hours < 1 ? 's' : ''}`;
    }
    if (minutes <= -1) {
        return `in ${Math.abs(minutes)} minute${minutes < 1 ? 's' : ''}`;
    }
    return `in ${Math.abs(seconds)} seconds`;
};

export const getFulfillmentUsername = (order) => {
    const fulfillmentUser = _.get(order, 'fulfillment.fulfillmentUser', '-');
    const username = _.get(fulfillmentUser, 'username');
    const userId = _.get(fulfillmentUser, 'objectId');
    return username || userId || fulfillmentUser;
};

export const getFulfillmentBouncedState = (order) => {
    const bouncedCount = _.get(order, 'fulfillment.bouncedCount', 0);
    const postDate = _.get(order, 'postDate');
    const lastBouncedAt = _.get(order, 'fulfillment.lastBouncedAt.iso');
    if (!bouncedCount || !lastBouncedAt) {
        return {
            bouncedCount,
            bouncedUntil: moment(postDate).format(),
            lastBouncedAt: undefined,
            currentlyBounced: false,
        };
    }
    // const todayStart = moment().hours(0).minutes(0).seconds(0);
    const todayEnd = moment().hours(23).minutes(59).seconds(59);
    const bouncedUntil = moment(lastBouncedAt).add(1, 'day');
    // const bouncedUntilToday = todayStart.format(DATE_FORMAT) === bouncedUntil.format(DATE_FORMAT);
    // const bouncedUntilBefore = bouncedUntil < todayStart;
    const bouncedUntilLater = bouncedUntil > todayEnd;
    return {
        bouncedCount,
        bouncedUntil: bouncedUntil.format(),
        lastBouncedAt,
        currentlyBounced: bouncedUntilLater,
    };
};

export const sortByBounceDates = (a, b) => {
    const {bouncedUntil: bouncedUntilA, currentlyBounced: currentlyBouncedA} = getFulfillmentBouncedState(a);
    const {bouncedUntil: bouncedUntilB, currentlyBounced: currentlyBouncedB} = getFulfillmentBouncedState(b);
    const dateA = currentlyBouncedA ? new Date(bouncedUntilA) : new Date(a.postDate);
    const dateB = currentlyBouncedB ? new Date(bouncedUntilB) : new Date(b.postDate);
    return dateA - dateB;
};

export const sortByBounceDatesAndPriority = (a, b) => {
    // Add priority to sort orders
    const { priority: priorityA = 1 } = a;
    const { priority: priorityB = 1 } = b;
    const { bouncedUntil: bouncedUntilA, currentlyBounced: currentlyBouncedA } = getFulfillmentBouncedState(a);
    const { bouncedUntil: bouncedUntilB, currentlyBounced: currentlyBouncedB } = getFulfillmentBouncedState(b);
    const dateA = moment(currentlyBouncedA ? new Date(bouncedUntilA) : new Date(a.postDate)).add(priorityA * 10, 'day').unix();
    const dateB = moment(currentlyBouncedB ? new Date(bouncedUntilB) : new Date(b.postDate)).add(priorityB * 10, 'day').unix();
    return dateA > dateB ? 1 : -1;
};

export const getFulfillmentProducedStatus = (orderFulfillment) => {
    const {itemCountProduced, itemCountProducedFulfilled} = orderFulfillment || {};
    if (itemCountProduced > 0) {
        if (itemCountProduced === itemCountProducedFulfilled) {
            return {
                title: 'Produced',
                color: colors.GREEN,
                itemCount: itemCountProduced,
            };
        }
        return {
            title: 'Production Pending',
            color: colors.VOLCANO,
            itemCount: itemCountProduced,
        };
    }
    return null;
};

export const getLineItemProducedFulfillmentStatus = (lineItem) => {
    if (!lineItem) {
        return {
            title: 'Loading...',
            color: null,
        };
    }
    const {qty, qtyProducedFulfilled = 0} = lineItem;
    if (qty === qtyProducedFulfilled) {
        return {
            title: 'Produced',
            color: colors.GREEN,
        };
    }
    return {
        title: 'Production Pending',
        color: colors.VOLCANO,
    };
};

export const getFulfillmentExternalStatus = (orderFulfillment) => {
    const {itemCountExternal, itemCountExternalFulfilled} = orderFulfillment || {};
    if (itemCountExternal > 0) {
        if (itemCountExternal === itemCountExternalFulfilled) {
            return {
                title: 'External Ordered',
                color: colors.GREEN,
                itemCount: itemCountExternal,
            };
        }
        return {
            title: 'External Pending',
            color: colors.VOLCANO,
            itemCount: itemCountExternal,
        };
    }
    return null;
};

export const getLineItemExternalFulfillmentStatus = (lineItem) => {
    if (!lineItem) {
        return {
            title: 'Loading...',
            color: null,
        };
    }
    const {qty, qtyExternalFulfilled = 0} = lineItem;
    if (qty === qtyExternalFulfilled) {
        return {
            title: 'External Ordered',
            color: colors.GREEN,
        };
    }
    return {
        title: 'External Pending',
        color: colors.VOLCANO,
    };
};

export const getWcOrderEditLink = (id = '') => {
    return `https://wc.misspompadour.de/wp-admin/post.php?post=${id}&action=edit`;
};

export const getWcProductEditLink = (id = '') => {
    return `https://wc.misspompadour.de/wp-admin/post.php?post=${id}&action=edit`;
};

export const getWcOrderSearchLink = (id = '') => {
    return `https://wc.misspompadour.de/wp-admin/edit.php?s=${id}&post_status=all&post_type=shop_order&action=-1&m=0&_customer_user&paged=1&action2=-1`;
};

export const getSwLink = (path, id) => {
    return `https://${isProduction ? 'www' : 'staging'}.misspompadour.de/admin#${path}${id}`;
};

export const getFulfillmentLink = (orderId) => {
    return `${routes.ORDER_FULFILLMENT_DETAILS.replace(':orderId', orderId)}`;
};

export const getPlentyOrderLink = (plentyId = '') => {
    return `https://859c52b3a60a09800672559d7dceeb786a7b138c.plentymarkets-cloud-de.com/plenty/terra/order/order-search?order_id=${plentyId}`;
};

export const formatTitle = (title = '') => {
    return title.replace('&amp;', '&');
};

// TODO: Fetch from API!
export const plentyShippingProfiles = [
    {
        label: 'DHL Paket Laaber',
        value: '9',
        warehouseId: 102,
    }, {
        label: 'DHL Warenpost Laaber',
        value: '10',
        warehouseId: 102,
    }, {
        label: 'DHL Warenpost International Laaber',
        value: '17',
        warehouseId: 102,
    }, {
        label: 'DHL International Laaber',
        value: '11',
        warehouseId: 102,
    }, {
        label: 'Swiss Post Laaber',
        value: '12',
        warehouseId: 102,
    }, {
        label: 'DHL - Vonexio Fulfillment',
        value: '14',
        warehouseId: 108,
    }, {
        label: 'DHL DE - AS Creation Tapeten',
        value: '15',
        warehouseId: 109,
    }, {
        label: 'UPS EU - AS Creation Tapeten',
        value: '16',
        warehouseId: 109,
    }, {
        label: 'DHL Paket Sinzing',
        value: '6',
        warehouseId: 1,
    }, {
        label: 'DHL Warenpost Sinzing',
        value: '7',
        warehouseId: 1,
    }, {
        label: 'DHL International Sinzing',
        value: '8',
        warehouseId: 1,
    },
    // {
    //     label: 'Dropp Same-Day Delivery',
    //     value: '13',
    //     warehouseId: 104, // 104 Berlin | 105 Hamburg | 106 Munich
    // },
];

export const getPlentyShippingProfilesForCountry = (countryCode = 'DE', warehouseId ) => {
  return plentyShippingProfiles.map((p) => {
    const international = p.label.includes('International');
    let disabled = false;
    // Only DHL International for other countries
    if (countryCode !== 'DE' && !international) {
      disabled = true;
    } else if (countryCode === 'DE' && international) {
      disabled = true;
    }
    // If warehouseId is defined - only allow shipping profiles for this warehouse
    if (warehouseId && p.warehouseId !== warehouseId) {
      disabled = true;
    }
    return {
      ...p,
      disabled,
    };
  })
};
