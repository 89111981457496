import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

import { Container, ContentWrapper, CardWrapper } from '../../components/Layout/Layout';
import { OrdersFulfillmentTableByFilters } from '../../components/OrdersFulfillmentTableByFilters/OrdersFulfillmentTableByFilters';

export const OrderFulfillmentByWarehouse = (props) => {
  const {
    user,
    match: { params },
    location: { pathname = '' },
  } = props;
  const warehouseSlug = _.last(pathname.split('/'));

  const { filterTags, fulfillmentFilterTags, warehouses } = useMemo(() => {
    const filterTags = {
      hasColorCardsOnly: false,
    };
    // Do not show this order in laaber for fulfillment -> Only in sinzing after it was transferred
    if (warehouseSlug === 'laaber') {
      filterTags.needsSinzingTransfer = false;
    }
    return {
      warehouses: [`warehouse-${warehouseSlug}`],
      filterTags,
      fulfillmentFilterTags: {
        hasExternalFulfilled: true,
        hasProductionFulfilled: true,
      },
    };
  }, [warehouseSlug]);

  return (
    <Container>
      <ContentWrapper>
        <CardWrapper>
          <OrdersFulfillmentTableByFilters
            title={`Fulfillment Orders ${warehouseSlug}`}
            user={user}
            filterTags={filterTags}
            fulfillmentFilterTags={fulfillmentFilterTags}
            warehouses={warehouses}
            showOrderStatistics={true}
          />
        </CardWrapper>
      </ContentWrapper>
    </Container>
  );
};
