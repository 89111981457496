import React, { useRef, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import * as Parse from 'parse';

import { DATE_FORMAT } from '../../lib/constants';
import { Button, Col, Row, Tag } from 'antd';
import _ from 'lodash';
import { colors } from '../../lib/theme';
import { getWcOrderEditLink } from '../../lib/utils';
import { routes } from '../../lib/routes';
import { Loader } from '../Loader/Loader';
import { useWindowFocus } from '../../hooks/useWindowFocus';

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 20px;
`;

const CardWrapper = styled.div`
  padding: 15px;
  background-color: ${colors.WHITE};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const ChartContainer = styled.div`
  width: 100%;
  position: relative;
  height: 150px;
  border: 1px solid ${colors.TRUE_BLACK10};
  overflow: hidden;
`;

const ChartBar = styled.div`
  position: relative;
  width: 100%;
  height: ${({ size }) => size * 0.25}px;
  background-color: ${({ color }) => color || colors.PRIMARY};
  z-index: 110;
  bottom: 0;
`;
const ChartBarWrapper = styled.div`
  position: absolute;
  right: ${({ index }) => index * 2.22}%;
  width: 1.9%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 100;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;
const ChartLine = styled.div`
  position: absolute;
  bottom: ${({ size }) => size}px;
  width: 100%;
  height: 1px;
  background-color: ${colors.TRUE_BLACK10};
  z-index: 1;
`;
const ChartOverlay = styled.div`
  position: absolute;
  bottom: 30px;
  top: 30px;
  left: 30px;
  right: 30px;
  background-color: ${colors.WHITE};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 120;
`;
const ChartOverlayInner = styled.div`
  padding: 15px;
  overflow-y: auto;
  max-height: 95px;
  overflow-wrap: break-word;
`;
const OrderIdList = styled.div`
  width: 100%;
  margin-bottom: 15px;
  width: 100%;
`;

const NUMBER_OF_DAYS = 45;
const UPDATE_THRESHOLD = 1000;

export const OrderFulfillmentStatistics = () => {
  const [activeDate, setActiveDate] = useState(undefined);
  const [orders, setOrders] = useState([]);
  const [warehouseMap, setWarehouseMap] = useState({});
  const [loading, setLoading] = useState(false);
  const windowFocussed = useWindowFocus();
  const ordersUpdatedRef = useRef(0);

  const fetchData = async () => {
    // Prevent to double fetch data
    const lastUpdateDiff = Date.now() - ordersUpdatedRef.current;
    if (loading || lastUpdateDiff < UPDATE_THRESHOLD) {
      return;
    }
    ordersUpdatedRef.current = Date.now();
    try {
      setLoading(true);
      const data = await Parse.Cloud.run('adminFetchFulfillmentOrderStatistics', {});
      setOrders(_.get(data, 'orders', []));
      setWarehouseMap(_.keyBy(_.get(data, 'warehouses', []), 'objectId'));
    } catch (error) {
      console.warn('OrderFulfillmentStatistics: fetchData failed', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (windowFocussed) {
      fetchData();
    }
  }, [windowFocussed]);

  useEffect(() => {
    fetchData();
  }, []);

  const { currentOrders, prevOrders, nextOrders, partlyFulfilledOrders } = useMemo(() => {
    const nextOrdersStartDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
    const currentOrdersStartDate = moment().subtract(1, 'days').hours(0).minutes(0).seconds(0).milliseconds(0);
    return {
      currentOrders: orders.filter((order) => {
        const orderDate = moment(order.date);
        return orderDate >= currentOrdersStartDate && orderDate < nextOrdersStartDate;
      }),
      prevOrders: orders.filter((order) => {
        const orderDate = moment(order.date);
        return orderDate < currentOrdersStartDate;
      }),
      nextOrders: orders.filter((order) => {
        const orderDate = moment(order.date);
        return orderDate >= nextOrdersStartDate;
      }),
      partlyFulfilledOrders: orders.filter((order) => {
        return order.packingStarted;
      }),
    };
  }, [orders]);

  const orderGroupsByDate = useMemo(() => {
    return _.groupBy(
      orders.map((order) => {
        return {
          orderId: order.id,
          date: moment(order.date).format(DATE_FORMAT),
          warehouseName: _.get(warehouseMap[order.warehouseId], 'name'),
          warehouseColor: _.get(warehouseMap[order.warehouseId], 'color'),
        };
      }),
      'date',
    );
  }, [orders, warehouseMap]);

  const ordersGroupsByWarehouse = useMemo(() => {
    return _.groupBy(
      orders.map((order) => {
        return {
          orderId: order.id,
          warehouseName: _.get(warehouseMap[order.warehouseId], 'name'),
          warehouseSlug: _.get(warehouseMap[order.warehouseId], 'slug'),
          warehouseColor: _.get(warehouseMap[order.warehouseId], 'color'),
        };
      }),
      'warehouseSlug',
    );
  }, [orders, warehouseMap]);

  const renderChart = useCallback(() => {
    return (
      <ChartContainer>
        {_.times(NUMBER_OF_DAYS, (index) => {
          const date = moment().subtract(index, 'days').format(DATE_FORMAT);
          const orders = orderGroupsByDate[date] || [];
          const ordersByWarehouse = _.groupBy(_.sortBy(orders, ['warehouseName'], ['asc']), 'warehouseName');
          return (
            <ChartBarWrapper
              key={`chart-bar-${index}`}
              index={index}
              onClick={() => setActiveDate({ date, ordersByWarehouse })}
            >
              {_.values(ordersByWarehouse).map((warehouseOrders) => {
                const warehouseColor = _.get(_.first(warehouseOrders), 'warehouseColor');
                const count = _.size(warehouseOrders);
                return <ChartBar key={`chart-bar-${index}-${warehouseColor}`} size={count} color={warehouseColor} />;
              })}
            </ChartBarWrapper>
          );
        })}
        <ChartLine size={25} />
        <ChartLine size={50} />
        <ChartLine size={75} />
        <ChartLine size={100} />
        <ChartLine size={125} />
        <ChartLine size={150} />
      </ChartContainer>
    );
  }, [orderGroupsByDate]);

  return (
    <Container>
      <CardWrapper>
        <Row gutter={[16, 16]}>
          <Col sm={4} xs={8}>
            <h1>{_.size(orders)}</h1>
            <h5>All orders</h5>
          </Col>
          <Col sm={4} xs={8}>
            <h1>{_.size(prevOrders)}</h1>
            <h5>Orders older 2 days</h5>
          </Col>
          <Col sm={4} xs={8}>
            <h1>{_.size(partlyFulfilledOrders)}</h1>
            <h5>Partly fulfilled Orders</h5>
          </Col>
          {_.values(ordersGroupsByWarehouse).map((group) => {
            const name = _.get(_.first(group), 'warehouseName');
            const color = _.get(_.first(group), 'warehouseColor');
            if (!_.size(name)) {
              return null;
            }
            return (
              <Col sm={6} xs={12} key={color}>
                <h1>{Math.round((100 * _.size(group)) / _.size(orders))}%</h1>
                <h5>
                  <Tag color={color}>{name}</Tag>
                </h5>
              </Col>
            );
          })}
        </Row>

        {renderChart()}

        {activeDate ? (
          <ChartOverlay>
            <h3>
              {activeDate.date} ({_.size(_.flatten(_.values(activeDate.ordersByWarehouse)))} orders)
            </h3>
            <ChartOverlayInner>
              {_.values(activeDate.ordersByWarehouse).map((warehouseOrders) => {
                const color = _.get(_.first(warehouseOrders), 'warehouseColor');
                const name = _.get(_.first(warehouseOrders), 'warehouseName');
                const count = _.size(warehouseOrders);
                return (
                  <OrderIdList key={name} key={color}>
                    <Tag color={color}>
                      {name} ({count} orders)
                    </Tag>{' '}
                    {warehouseOrders.map((order) => (
                      <span key={order.orderId}>
                        <a href={routes.ORDER_FULFILLMENT_DETAILS.replace(':orderId', order.orderId)} target='_blank'>
                          {order.orderId}
                        </a>{' '}
                        (
                        <a href={getWcOrderEditLink(order.orderId)} target='_blank'>
                          WC
                        </a>
                        ),
                      </span>
                    ))}
                  </OrderIdList>
                );
              })}
            </ChartOverlayInner>
            <Button onClick={() => setActiveDate(undefined)}>Close</Button>
          </ChartOverlay>
        ) : null}

        {loading ? <Loader /> : null}
      </CardWrapper>
    </Container>
  );
};
