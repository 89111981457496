import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import * as Parse from 'parse';
import _ from 'lodash';
import moment from 'moment';
import { UI, colors } from '../../lib/theme';
import { Link } from 'react-router-dom';
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

export const Container = styled.div`
  height: calc(100vh - ${UI.NAVBAR_HEIGHT}px - ${UI.FOOTER_HEIGHT}px);
  display: block;
  width: 100vw;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 100px;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`;
export const ContentWrapper = styled.div`
  position: relative;
  max-width: ${UI.CONTENT_MAXWIDTH}
  padding: 70px 15px 15px;
  z-index: 50;
    
  @media only screen and (max-width: 992px) {
    padding-top: 15px;
  }
`;
export const CardWrapper = styled.div`
  padding: 0;
  background-color: ${colors.WHITE};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  overflow: hidden;
`;
export const CardHeader = styled.div`
  padding: 15px;
  background-color: ${colors.WHITE};
  border-bottom: 2px solid ${colors.TRUE_BLACK20};
  ${({ block = false }) => {
    return !block
      ? `
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `
      : '';
  }}
  cursor: ${({ onClick }) => _.isFunction(onClick) ? 'pointer' : 'default'}
  
  &:hover {
    opacity: ${({ onClick }) => _.isFunction(onClick) ? '0.7' : '1'};
  }
`;
export const CardOpenIcon = styled(PlusCircleOutlined)`
  font-size: 1.2rem;
  color: ${colors.PRIMARY};
  margin-right: 10px;
`;
export const CardCloseIcon = styled(MinusCircleOutlined)`
  font-size: 1.2rem;
  color: ${colors.PRIMARY};
  margin-right: 10px;
`;
export const CardHeaderIcon = ({ open }) => {
  return open ? <CardCloseIcon/> : <CardOpenIcon/>
};
export const CardContent = styled.div`
  border-bottom: 1px solid ${colors.TRUE_BLACK20};
  padding: 15px;
  word-wrap: break-word;
  position: relative;
`;
export const CardFooter = styled.div`
  position: relative;
  background-color: ${colors.TRUE_BLACK10};
  border-bottom: 1px solid ${colors.TRUE_BLACK20};
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
export const PullRight = styled.div`
  text-align: right;
`;
export const Spacer = styled.span`
  display: ${({ block = false }) => (block ? 'block' : 'inline-block')};
  width: 8px;
  height: 16px;
`;
export const LinkCard = styled.div`
  background-color: ${colors.PRIMARY};
  padding: 20px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`;
export const LinkCardTitle = styled.h2`
  color: ${colors.WHITE};
`;
