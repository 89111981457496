import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import {Alert, Button, InputNumber, notification, Tag, List, Modal, Row, Col, Select, Table} from 'antd';
import * as Parse from 'parse';

const { confirm } = Modal;

const ButtonContainer = styled.div`
`;

export const OrderDeliveryDeleteButton = ({ plentyOrderId, onDelete }) => {

  const [loading, setLoading] = useState(false);

  const handleDeleteOrderDelivery = async () => {
    setLoading(true);
    try {
      const data = await Parse.Cloud.run('adminDeletePlentyOrderDelivery', {
        plentyOrderId,
      });
      setTimeout(() => {
        setLoading(false);
        notification.success({
          message: `Order delivery deleted.`,
        });
        onDelete();
      }, 2000);
    } catch (error) {
      console.warn('handleDeleteOrderDelivery failed', error);
      setLoading(false);
      notification.error({
        message: `Failed to delete order delivery.`,
      });
    }
  };

  const handleDeleteOrderDeliveryConfirm = () => {
    confirm({
      content: 'Do you want to delete this delivery? This can not be reversed!',
      onOk() {
        handleDeleteOrderDelivery()
      },
      onCancel() {
      },
    });
  };

  if (!plentyOrderId) {
    return null;
  }

  return (
    <ButtonContainer>
      <Button type='danger' onClick={handleDeleteOrderDeliveryConfirm} loading={loading}>
        Delete Delivery
      </Button>
    </ButtonContainer>
  );
};
