import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import * as Parse from 'parse';
import _ from 'lodash';
import {
  Container,
  ContentWrapper,
  CardWrapper,
  CardHeader,
  CardContent,
  PullRight,
  CardFooter,
  Spacer,
} from '../../components/Layout/Layout';
import {Input, Row, Col, Upload, message, Button, Tag, Divider, AutoComplete, notification, Modal, Select} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { colors } from '../../lib/theme';
import {routes} from "../../lib/routes";

const { confirm } = Modal;
const PRODUCT_IMAGE_DOT_SIZE = 20;

const UploadedImage = styled.img`
  width: 100%;
`;
const UploadedVideo = styled.video`
  width: 100%;
`;
const PreviewImageWrapper = styled.div`
  width: 100%;
  position: relative;
`;
const PreviewImage = styled.img`
  width: 100%;
  background-color: ${colors.TRUE_BLACK10};
  position: relative;
`;
const ProductImageDot = styled.div`
  position: absolute;
  left: auto;
  right: auto;
  border-radius: 50%;
  border: 2px solid ${({ active }) => (active ? colors.TRUE_BLACK : colors.WHITE)};
  background-color: ${({ colorcode }) => colorcode || colors.RED};
  height: ${PRODUCT_IMAGE_DOT_SIZE}px;
  width: ${PRODUCT_IMAGE_DOT_SIZE}px;
`;

// --------------Upload section----------------
const { Dragger } = Upload;

// --------------Tags for room and color-------------
const { CheckableTag } = Tag;
// const roomTagData = ['Küche', 'Badezimmer', 'Fleisen', 'Schlafzimmer', 'Möbel', 'Outdoor','Kachelofen'];
export const roomTagData = [
  'wall',
  'nursery',
  'kitchen',
  'bathroom',
  'tiles',
  'bedroom',
  'livingroom',
  'furniture',
  'office',
  'chair',
  'stairs',
  'door',
  'hallway',
  'frontdoor',
  'garden',
  'outdoor',
  'oven',
  'radiator',
  'heating',
  'floor',
  'ceiling',
  'bagcam',
  'deals',
  'showcase',
  'app',
  'faq',
  'seasonal',
  'decoration',
];
// const colorTagData = ['Beige', 'Blau', 'Erdtöne', 'Gelb', 'Grau','Grün','Mauve','Orange','Petrol','Pink','Rot','Schwarz','Türkis','Violett','Weiß'];
export const colorTagData = [
  'beige',
  'blue',
  'earthtones',
  'yellow',
  'grey',
  'green',
  'orange',
  'petrol',
  'pink',
  'red',
  'black',
  'violet',
  'white',
];

export const qualityTagData = ['low', 'medium', 'high'];
export const sourceTagData = ['instagram', 'customer', 'product_image', 'ambassador', 'other'];
export const typeTagData = ['reels', 'feed', 'story', 'before_after', 'other'];

const UploadedMedia = ({ image }) => {
  const { url, type } = image;
  if (type === 'video') {
    return (
        <UploadedVideo controls>
          <source src={url} type="video/mp4"/>
        </UploadedVideo>
    );
  }
  return (
      <UploadedImage src={url}/>
  );
}

const HotTags = ({ tags = [], setTags, selectedTags = [], title, single }) => {
  const handleChange = (tag, checked) => {
    if (single) {
      // Only set one tag to active
      setTags([tag]);
    } else {
      // Set multiple tags to active
      const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
      setTags(nextSelectedTags);
    }
  };

  return (
    <>
      <span style={{ marginRight: 8 }}>{title}:</span>
      {tags.map((tag) => (
        <CheckableTag key={tag} checked={selectedTags.includes(tag)} onChange={(checked) => handleChange(tag, checked)}>
          {tag}
        </CheckableTag>
      ))}
    </>
  );
};

const ImageDemo = ({ image, onClick, products = [], onImageSize, activeProductId }) => {
  if (!image) {
    return <PreviewImage />;
  }

  const { url } = image;

  const handleLoadImage = (event) => {
    onImageSize({
      width: event.target.offsetWidth,
      height: event.target.offsetHeight,
    });
  };

  return (
    <PreviewImageWrapper onClick={onClick}>
      <PreviewImage src={url} onLoad={handleLoadImage} />

      {products.map((product) => {
        if (!product) {
          return null;
        }
        const { x, y, colorCode, id } = product;
        const active = activeProductId === id;
        return (
          <ProductImageDot key={`${id}-${x}-${y}`} style={{ left: `${x}%`, top: `${y}%` }} colorcode={colorCode} active={active} />
        );
      })}
    </PreviewImageWrapper>
  );
};

const customColorTagRender = (props) => {
  const { label, value, closable, onClose } = props;
  return (
      <Tag
          color={value}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
  );
};

export const ImageLibraryEdit = (props) => {
  const { user, authenticated, match = {}, history } = props;
  const {
    location: { pathname = '' },
  } = history;
  const imageCollectionIdParam = _.last(pathname.split('/'));

  const isNew = imageCollectionIdParam === 'new';
  const [imageCollectionId, setImageCollectionId] = useState(imageCollectionIdParam);
  const imagesRef = useRef([]);
  const [slug, setSlug] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState(null);
  const [instagramHandle, setInstagramHandle] = useState('');
  const [productIds, setProductIds] = useState('');
  const [likeCount, setLikeCount] = useState(0);
  const [images, setImages] = useState([]);
  const [roomTags, setRoomTags] = useState([]);
  const [active, setActive] = useState(false);
  const [colorTags, setColorTags] = useState([]);
  const [qualityTags, setQualityTags] = useState(['low']);
  const [products, setProducts] = useState([]);
  const [source, setSource] = useState('');
  const [type, setType] = useState('');
  const [caption, setCaption] = useState('');
  const [productSearchOptions, setProductSearchOptions] = useState({});

  const imageSizeRef = useRef(null);

  const fetchProductNames = async (productIds) => {
    try {
      // TODO: Get products by ids
      // const { count, results } = await Parse.Cloud.run('adminWpFetchProducts', {
      //   page: 1,
      //   limit: 100,
      //   ids: productIds,
      // });
      // // Set Product options like they are set when user searches for products
      // // -> Shows the name for the linked product
      // const nextProductSearchOptions = {};
      // _.each(productIds, (productId, index) => {
      //   const product = results.find((result) => `${result.id}` === productId);
      //   nextProductSearchOptions[index] = [
      //     {
      //       value: `${product.id}`,
      //       label: product.postTitle,
      //       colorCode: product.colorCode || '',
      //     },
      //   ];
      // });
      // setProductSearchOptions(nextProductSearchOptions);
    } catch (error) {
      console.warn('fetchProductNames failed', error);
    }
  };

  const fetchImageCollection = async (imageCollectionId) => {
    try {
      const ImageCollection = Parse.Object.extend('ImageCollection');
      const imageCollectionQuery = new Parse.Query(ImageCollection);
      imageCollectionQuery.equalTo('objectId', imageCollectionId);
      imageCollectionQuery.include('user.username');
      const imageCollection = await imageCollectionQuery.first();

      const ImageCollectionImage = Parse.Object.extend('ImageCollectionImage');
      const imageCollectionImagesQuery = new Parse.Query(ImageCollectionImage);
      imageCollectionImagesQuery.equalTo('imageCollection', imageCollection);
      const imageCollectionImages = await imageCollectionImagesQuery.find();

      const ImageCollectionImageProduct = Parse.Object.extend('ImageCollectionImageProduct');
      const imageCollectionImageProductsQuery = new Parse.Query(ImageCollectionImageProduct);
      imageCollectionImageProductsQuery.equalTo('imageCollection', imageCollection);
      const imageCollectionImageProducts = await imageCollectionImageProductsQuery.find();

      setRoomTags(imageCollection.get('roomTags') || []);
      setColorTags(imageCollection.get('colorTags') || []);
      setQualityTags([imageCollection.get('quality') || 'medium']);
      setSlug(imageCollection.get('slug') || '');
      setLikeCount(imageCollection.get('likeCount') || 0);
      setActive(imageCollection.get('active') || false);
      setCustomerName(imageCollection.get('customerName') || '');
      setInstagramHandle(imageCollection.get('instagramHandle') || '');
      setProductIds((imageCollection.get('productIds') || []).join(','));
      setSource((imageCollection.get('source') || ''));
      setCaption((imageCollection.get('caption') || ''));
      setType((imageCollection.get('type') || ''));

      const user = imageCollection.get('user');
      if (user) {
        setCustomerId(user.get('username'));
      }

      setImages(imageCollectionImages.map((image) => image.toJSON()));

      const nextProducts = imageCollectionImageProducts.map((product) => product.toJSON());
      setProducts(nextProducts);
      fetchProductNames(nextProducts.map((p) => p.productId));
    } catch (error) {
      console.warn('fetchImageCollection failed', error);
    }
  };

  useEffect(() => {
    if (imageCollectionIdParam !== 'new') {
      fetchImageCollection(imageCollectionIdParam);
    }
  }, [imageCollectionIdParam]);

  // Handle to change "before" and "after" Image tags
  const handleImageTagChange = (imageId, tag) => {
    let resetImages = images;
    // ----- Resetting active tags if needed
    const prevActiveImage = images.find((image) => (image.tags || []).includes(tag));
    const prevActiveImageId = _.get(prevActiveImage, 'objectId');
    if (prevActiveImageId !== imageId) {
      resetImages = images.map((image) => {
        if (image.objectId === prevActiveImageId) {
          const { tags = [] } = image;
          return {
            ...image,
            tags: tags.filter((filterTag) => {
              return filterTag !== tag;
            }),
          };
        }
        return image;
      });
    }

    // ------- Applying new tags
    const nextImages = resetImages.map((image) => {
      if (image.objectId === imageId) {
        const { tags = [] } = image;
        const isTagActive = tags.includes(tag);
        const nextTags = isTagActive ? tags.filter((t) => t !== tag) : [...tags, tag];
        return {
          ...image,
          tags: nextTags,
        };
      }
      return image;
    });
    setImages(nextImages);
  };

  const handleSave = async () => {
    try {
      const ImageCollection = Parse.Object.extend('ImageCollection');
      const imageCollectionQuery = new Parse.Query(ImageCollection);
      imageCollectionQuery.equalTo('objectId', imageCollectionId);
      let imageCollection = await imageCollectionQuery.first();
      // None found - create a new one
      if (!imageCollection) {
        imageCollection = new ImageCollection();
        imageCollection = await imageCollection.save();
      }

      imageCollection.set('slug', slug || '');
      imageCollection.set('active', active === true);
      imageCollection.set('roomTags', roomTags);
      imageCollection.set('colorTags', colorTags);
      imageCollection.set('quality', qualityTags[0]);
      imageCollection.set('customerName', customerName);
      imageCollection.set('instagramHandle', instagramHandle);
      imageCollection.set('source', source);
      imageCollection.set('type', type);
      imageCollection.set('caption', caption);
      imageCollection.set('productIds', productIds.split(',').map(id => (`${id}`).trim()));

      const ImageCollectionImage = Parse.Object.extend('ImageCollectionImage');
      const imageCollectionImagesQuery = new Parse.Query(ImageCollectionImage);
      imageCollectionImagesQuery.equalTo('imageCollection', imageCollection);
      const imageCollectionImages = await imageCollectionImagesQuery.find();

      // NOTE: Products are not used any more
      // const ImageCollectionImageProduct = Parse.Object.extend('ImageCollectionImageProduct');
      // const imageCollectionImageProductsQuery = new Parse.Query(ImageCollectionImageProduct);
      // imageCollectionImageProductsQuery.equalTo('imageCollection', imageCollection);
      // const imageCollectionImageProducts = await imageCollectionImageProductsQuery.find();
      const imageCollectionImageProducts = [];

      const nextImageCollectionImages = imageCollectionImages.map((imageCollectionImage) => {
        const findImage = images.find((image) => image.objectId === imageCollectionImage.id);
        if (findImage) {
          imageCollectionImage.set('tags', findImage.tags || []);
          const colors = (findImage.colors || []).filter(c => c.length === 7); // all format #f2f2f2
          imageCollectionImage.set('colors', colors);
        }
        return imageCollectionImage;
      });
      await Parse.Object.saveAll(nextImageCollectionImages);

      const imageCollectionImageAfter = nextImageCollectionImages.find((image) =>
        (image.get('tags') || []).includes('after'),
      );
      // if (!imageCollectionImageAfter) {
      //   throw new Error('After tag missing');
      // }

      // Add after image to imageCollection for easy access
      if (imageCollectionImageAfter) {
        imageCollection.set('afterImageUrl', imageCollectionImageAfter.get('url') || '');
      }
      const savedImageCollection = await imageCollection.save();

      notification.success({
        message: 'All saved',
        description: 'The image collection was saved successfully.',
      });


      if (savedImageCollection.id !== imageCollectionId) {
        history.push(routes.ADMIN_IMAGE_LIBRARY_EDIT.replace(':id', savedImageCollection.id));
        window.location.reload();
      }
    } catch (error) {
      console.warn('handleSave failed', error);
      notification.error({
        message: 'Error :(',
        description: error.toString(),
      });
    }
  };

  const { serverURL } = Parse;

  const uploadProps = {
    name: 'imageLibrary',
    action: `${serverURL}/admin/image-library/upload`,
    headers: {
      'X-User-Id': user.objectId,
      Authorization: user.sessionToken,
    },
    data: {
      imageCollectionId,
    },
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        const {
          file: { response },
        } = info;
        imagesRef.current = [...imagesRef.current, ...response.images];
        setImages(imagesRef.current);
        setImageCollectionId(response.imageCollectionId);
        fetchImageCollection(response.imageCollectionId);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        console.warn('Upload failed', status, info);
      }
    },
  };

  const afterImage = images.find((image) => (image.tags || []).includes('after'));

  // --------------Handle Image Click--------------
  const handleImageClick = (event) => {
    const { width, height } = imageSizeRef.current || {};
    if (!width || !height) {
      // TODO: Show error message
      return;
    }

    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;

    setProducts([
      ...products,
      {
        objectId: `${x}-${y}`,
        x: Math.round((100 * (x - PRODUCT_IMAGE_DOT_SIZE / 2)) / width),
        y: Math.round((100 * (y - PRODUCT_IMAGE_DOT_SIZE / 2)) / height),
        productId: '',
      },
    ]);
  };

  const handleImageSizeChange = (imageSize) => {
    imageSizeRef.current = imageSize;
  };

  //--------------Search Section----------------
  const [productSearchParams, setProductSearchParams] = useState('');
  const [activeProductId, setActiveProductId] = useState(null);
  const productSearchDebounceTimeoutRef = useRef(null);

  useEffect(() => {
    (async () => {
      const { index, searchTerm } = productSearchParams;
      if (!_.isNumber(index) || _.size(searchTerm) < 3) {
        return;
      }

      try {
        // TODO: Fetch from plentyProducts
        const { count, results } = await Parse.Cloud.run('adminWpFetchProducts', {
          page: 1,
          limit: 5,
          searchString: searchTerm,
          includeVariations: false,
        });

        const nextProductSearchOptions = {
          ...productSearchOptions,
          [index]: results.map((product) => {
            return {
              value: `${product.id}`,
              label: product.postTitle,
              colorCode: product.colorCode || '',
            };
          }),
        };
        setProductSearchOptions(nextProductSearchOptions);
      } catch (error) {
        console.warn('adminWpFetchProducts failed', error);
        productSearchOptions[index] = [];
        setProductSearchOptions(productSearchOptions);
      }
    })();
  }, [productSearchParams]);

  const onProductSelect = (productId, index) => {
    const findProductFromSearchResults = (productSearchOptions[index] || []).find(
      (option) => option.value === productId,
    );
    const nextProducts = products.map((product, productIndex) => {
      if (index === productIndex) {
        return {
          ...product,
          productId,
          colorCode: _.get(findProductFromSearchResults, 'colorCode'),
        };
      }
      return product;
    });
    setProducts(nextProducts);
  };

  const handleRemoveProduct = (index) => {
    const nextProducts = products.map((product, productIndex) => {
      return productIndex !== index ? product : null;
    });
    setProducts(nextProducts);
  };

  //  -----------Search Bar-------------------
  const onProductSearch = (value, index) => {
    if (productSearchDebounceTimeoutRef.current) {
      clearTimeout(productSearchDebounceTimeoutRef.current);
      productSearchDebounceTimeoutRef.current = null;
    }
    productSearchDebounceTimeoutRef.current = setTimeout(() => {
      setProductSearchParams({ searchTerm: value, index });
    }, 500);
  };

  const handleProductRowEnter = (objectId) => {
    setActiveProductId(objectId);
  };

  const handleProductRowLeave = (objectId) => {
    if (activeProductId === objectId) {
      setActiveProductId(null);
    }
  };

  const handleImageDelete = async (image) => {
    confirm({
      content: 'Do you want to delete this image?',
      onOk() {
        (async () => {
          try {
            const ImageCollectionImage = Parse.Object.extend('ImageCollectionImage');
            const imageCollectionImageQuery = new Parse.Query(ImageCollectionImage);
            imageCollectionImageQuery.equalTo('objectId', image.objectId);
            const imageCollectionImage = await imageCollectionImageQuery.first();
            await imageCollectionImage.destroy();
            notification.success({
              message: 'Image deleted',
            });
            setImages(images.filter((i) => i.objectId !== image.objectId));
          } catch (error) {
            console.warn('Delete Image failed', error);
            notification.error({
              message: 'Image delete failed',
            });
          }
        })();
      },
      onCancel() {},
    });
  };

  const handleSlugChange = ({ target: { value }}) => {
    setSlug(value);
  }

  const handleCaptionChange = ({ target: { value }}) => {
    setCaption(value);
  }

  const handleImageColorChange = (image) => {
    return (nextColors) => {
      const nextImages = images.map((i) => {
        if (i.objectId !== image.objectId) {
          return i;
        }
        console.log('SET IMAGE COLORS', nextColors);
        i.colors = nextColors;
        return i;
      });
      setImages(nextImages);
    }
  };

  return (
    <Container>
      <ContentWrapper>

        <CardWrapper>
          <CardHeader>
            <h1>General Image Collection Info</h1>
          </CardHeader>
          <CardContent>
            <Row>
              <Col span={24}>
                <HotTags tags={roomTagData} selectedTags={roomTags} setTags={setRoomTags} title={'Categories'} />
              </Col>
            </Row>
            <Row>
              <Divider />
              <Col span={24}>
                <HotTags tags={colorTagData} selectedTags={colorTags} setTags={setColorTags} title={'Colors'} />
              </Col>
            </Row>
            <Row>
              <Divider />
              <Col span={8}>
                <HotTags
                    tags={qualityTagData}
                    selectedTags={qualityTags}
                    setTags={setQualityTags}
                    title={'Image Quality'}
                    single
                />
              </Col>
              <Col span={8}>
                <HotTags
                    tags={typeTagData}
                    selectedTags={[type]}
                    setTags={(types) => setType(types[0])}
                    title={'Media type'}
                    single
                />
              </Col>
              <Col span={8}>
                <HotTags
                    tags={sourceTagData}
                    selectedTags={[source]}
                    setTags={(sources) => setSource(sources[0])}
                    title={'Media source'}
                    single
                />
              </Col>
            </Row>
            <Row>
              <Divider />
              <Col span={8}>
                <br/>
                <Button.Group>
                  <Button
                      type={!active ? 'danger' : 'default'}
                      onClick={() => setActive(false)}
                  >
                    Inactive
                  </Button>
                </Button.Group>
                <Button
                    type={active ? 'primary' : 'default'}
                    onClick={() => setActive(true)}
                >
                  Active
                </Button>
              </Col>
              <Col span={7}>
                Slug:{' '}
                <Input value={slug} onChange={handleSlugChange}/>
                <p className='ant-upload-hint'>This is used to group pictures e.g. for competitions etc.</p>
              </Col>
              <Col span={1}/>
              <Col span={7}>
                Product Ids:{' '}
                <Input value={productIds}/>
              </Col>
              <Col span={1}/>
              <Col span={7}>
                Customer Name:{' '}
                <Input value={customerName} disabled/>
                {_.size(customerId) ? (
                    <a href={routes.ADMIN_CUSTOMER_SEARCH_DETAIL.replace(':id', customerId)} target='_blank'>Show Customer Profile</a>
                ) : null}
              </Col>
              <Col span={1}/>
              <Col span={7}>
                Instagram Handle:{' '}
                <Input value={instagramHandle} disabled/>
              </Col>
              <Col span={1}/>
              <Col span={7}>
                Likes:{' '}
                <Input value={likeCount} disabled/>
              </Col>
              <Col span={1}/>
            </Row>
              <Col span={24}>
                Caption:{' '}
                <Input.TextArea value={caption} onChange={handleCaptionChange}/>
              </Col>
            <Row>

            </Row>
          </CardContent>
          <CardFooter>
            <PullRight>
              <Button type='primary' onClick={handleSave}>
                Save
              </Button>
            </PullRight>
          </CardFooter>
        </CardWrapper>

        <Spacer />

        <CardWrapper>
          <CardContent>
            <Row gutter={[16, 16]}>
              {images.map((image) => {
                return (
                  <Col span={6} key={image.objectId}>
                    <UploadedMedia image={image} />
                    <Spacer />
                    <Row>
                      <Col span={12}>
                        <Button.Group>
                          <Button
                            type={(image.tags || []).includes('before') ? 'primary' : 'default'}
                            onClick={() => handleImageTagChange(image.objectId, 'before')}
                          >
                            Before
                          </Button>
                          <Button
                            type={(image.tags || []).includes('after') ? 'primary' : 'default'}
                            onClick={() => handleImageTagChange(image.objectId, 'after')}
                          >
                            After
                          </Button>
                          <Button type='danger' onClick={() => handleImageDelete(image)} icon={<DeleteOutlined />} />
                        </Button.Group>
                       </Col>
                    </Row>
                    <Spacer />
                    <Row>
                      <Col span={24}>
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            onChange={handleImageColorChange(image)}
                            tokenSeparators={[',']}
                            options={(image.colors || []).map(c => { return { value: c, label: c }})}
                            placeholder='Hex colors'
                            tagRender={customColorTagRender}
                            defaultValue={(image.colors || [])}
                        />
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </CardContent>
          {!isNew ? (
              <CardContent>
                <Dragger {...uploadProps}>
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>Click or drag files to upload your pictures</p>
                  <p className='ant-upload-hint'>Upload one or multiples pictures.</p>
                </Dragger>
              </CardContent>
          ) : null}
        </CardWrapper>

        <Spacer />

        <CardWrapper>
          <CardHeader>
            <Row>
              <Col span={24}>
                <h2>Step 4: Place Dots and Select Products</h2>
              </Col>
            </Row>
          </CardHeader>
          <CardContent>
            <Row>
              <Col span={12}>
                <ImageDemo
                  image={afterImage}
                  onClick={handleImageClick}
                  products={products}
                  onImageSize={handleImageSizeChange}
                  activeProductId={activeProductId}
                />
              </Col>
              <Col span={12}>
                {products.map((product, index) => {
                  if (!product) {
                    return null;
                  }
                  const { x, y, productId, objectId } = product;
                  const findProductById = (productSearchOptions[index] || []).find(
                    (option) => option.value === productId,
                  );
                  const productName = _.get(findProductById, 'label');
                  return (
                    <Row
                      gutter={[16, 16]}
                      key={`${x}-${y}-${index}`}
                      onMouseEnter={() => handleProductRowEnter(objectId)}
                      onMouseLeave={() => handleProductRowLeave(objectId)}
                    >
                      <Col span={5}>
                        <strong>{index + 1}.</strong> X:{x}-Y:{y}
                        <br />
                        {_.size(productId) ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                      </Col>
                      <Col span={17}>
                        <strong>{productName}</strong>
                        <AutoComplete
                          defaultValue={productId}
                          options={productSearchOptions[index]}
                          onSelect={(option) => onProductSelect(option, index)}
                          onSearch={(value) => onProductSearch(value, index)}
                          placeholder='Search for product here'
                          style={{ width: '100%' }}
                        />
                      </Col>
                      <Col span={2}>
                        <Button type='danger' onClick={() => handleRemoveProduct(index)} icon={<DeleteOutlined />} />
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </CardContent>
          <CardFooter>
            <PullRight>
              <Button type='primary' onClick={handleSave}>
                Save
              </Button>
            </PullRight>
          </CardFooter>
        </CardWrapper>
      </ContentWrapper>
    </Container>
  );
};
