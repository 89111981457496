import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { colors } from '../../lib/theme';

import { getFulfillmentUsername } from '../../lib/utils';

import { Button, Col, Input, Pagination, Row, Table } from 'antd';
import {
  orderBounceDateTableColumn,
  orderCustomerAndAddressTableColumn,
  orderIdTableColumn,
  orderFulfillmentTagsTableColumn,
  orderItemTagsTableColumn,
  orderStatusTableColumn,
  orderTotalTableColumn,
} from '../../lib/tableUtils';
import { OrderFulfillmentStatistics } from '../OrderFulfillmentStatistics/OrderFulfillmentStatistics';
import { useOrderFulfillmentWithFilters } from '../../hooks/useOrderFulfillmentWithFilters';
import { CardFooter, CardHeader, CardWrapper, Container, ContentWrapper, Spacer } from '../Layout/Layout';
import { Loader } from '../Loader/Loader';

const { Search } = Input;

const orderTableColumnsDef = [
  orderIdTableColumn,
  orderBounceDateTableColumn,
  orderStatusTableColumn,
  orderFulfillmentTagsTableColumn,
  orderCustomerAndAddressTableColumn,
  orderItemTagsTableColumn,
  orderTotalTableColumn,
  {
    // This gets extended in render function
    title: 'Actions',
    dataIndex: 'postDate',
    key: 'actions',
    width: 260,
    render: (postDate, row) => '',
  },
];

const ITEMS_PER_PAGE = 50;

export const OrdersFulfillmentTableByFilters = ({
  user,
  title,
  showOrderStatistics,
  actionsColumn,
  fulfillmentFilterTags = {},
  filterTags = {},
  warehouses = [],
  orderIds = [],
}) => {
  const [mounted, setMounted] = useState(false);
  const prevFilters = useRef({
    limit: ITEMS_PER_PAGE,
    page: 1,
    fulfillmentFilterTags,
    filterTags,
    orderIds,
    warehouses,
  });
  const [filters, setFilters] = useState(prevFilters.current);

  const { orders, hasNextPage, count, totalCount, claimOrder, bounceOrder, loading } = useOrderFulfillmentWithFilters({
    user,
    filters,
    skipQuery: mounted === false,
  });

  const [orderTableColumns, setOrderTableColumns] = useState(orderTableColumnsDef);

  useEffect(() => {
    const nextOrderTableColumns = orderTableColumnsDef.map((column) => {
      if (column.key === 'actions') {
        return (
          actionsColumn || {
            ...column,
            render: (postDate, row) => {
              const fulfillment = _.get(row, 'fulfillment', {});
              const { qty = 0, qtyFulfilled } = fulfillment;
              const fulfillmentUserName = getFulfillmentUsername(row);
              const claimDisabled = qty === qtyFulfilled || _.size(fulfillmentUserName) > 1;
              const bounceDisabled = claimDisabled;
              return (
                <div>
                  <Button type='primary' onClick={() => claimOrder(row)} disabled={claimDisabled}>
                    Claim
                  </Button>
                  <Spacer />
                  {!bounceDisabled ? (
                    <Button type='danger' onClick={() => bounceOrder(row)} disabled={claimDisabled}>
                      Bounce
                    </Button>
                  ) : null}
                </div>
              );
            },
          }
        );
      }
      return column;
    });
    setOrderTableColumns(nextOrderTableColumns);
  }, [setOrderTableColumns]);

  const handleSearch = useCallback(
    async (searchValue) => {
      if (!_.size(searchValue) && prevFilters.current) {
        setFilters({
          ...filters,
          externalOrderId: [],
        });
        return;
      }
      setFilters({
        ...filters,
        orderIds: [searchValue],
      });
    },
    [user, filters, setFilters],
  );

  const handlePlentySearch = useCallback(
    async (searchValue) => {
      if (!_.size(searchValue) && prevFilters.current) {
        setFilters({
          ...filters,
          orderIds: [],
        });
        return;
      }
      setFilters({
        ...filters,
        orderIds: [searchValue],
      });
    },
    [user, filters, setFilters],
  );

  const handlePaginationChange = useCallback((page, pageSize) => {
    setFilters({
      ...filters,
      page,
    });
  });

  useEffect(() => {
    // FIXME: Components seems to mount and unmount 1 time when initial loading the app
    // This leads to a double fetching of data :/
    // Therefore wait 200 ms before component is properly mounted and visible
    const mountTimeout = setTimeout(() => {
      setMounted(true);
    }, 200);
    return () => {
      clearTimeout(mountTimeout);
    };
  }, []);

  const TablePagination = (
    <CardFooter>
      {_.size(orders) ? (
        <Pagination
          onChange={handlePaginationChange}
          current={filters.page}
          pageSize={ITEMS_PER_PAGE}
          defaultPageSize={ITEMS_PER_PAGE}
          total={totalCount}
          showSizeChanger={false}
        />
      ) : null}
    </CardFooter>
  );

  if (!mounted) {
    return <Loader />;
  }

  return (
    <>
      {showOrderStatistics ? <OrderFulfillmentStatistics /> : null}
      <CardWrapper>
        <CardHeader block>
          <Row gutter={[16, 16]}>
            <Col lg={12} sm={24}>
              <h1>
                {title} (Page {filters.page} of {totalCount} Orders)
              </h1>
            </Col>
            <Col lg={12} sm={24}>
              <Search placeholder='Search PM order ID' enterButton='Search PM' onSearch={handlePlentySearch} />
              <Search placeholder='Search WC order ID' enterButton='Search WC' onSearch={handleSearch} />
            </Col>
          </Row>
        </CardHeader>
        {TablePagination}
        <Table
          columns={orderTableColumns}
          dataSource={orders}
          pagination={false}
          loading={loading}
          rowKey='id'
        />
        {TablePagination}
      </CardWrapper>
    </>
  );
};
