import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { UI, colors } from '../../lib/theme';
import { routes } from '../../lib/routes';

import { Card, Col, Row } from 'antd';
import { OrderFulfillmentStatistics } from '../../components/OrderFulfillmentStatistics/OrderFulfillmentStatistics';
import { Container, ContentWrapper, CardWrapper, LinkCard, LinkCardTitle } from '../../components/Layout/Layout';

const Spacer = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  background-color: ${colors.TRUE_BLACK20};
`;

export class Dashboard extends Component {
  componentDidMount() {}

  render() {
    const { user } = this.props;
    const { isAdmin } = user;

    return (
      <Container>
        <ContentWrapper>
          <CardWrapper>
            <OrderFulfillmentStatistics />

            <Card>
              <h1>Orders</h1>

              <Row gutter={[15, 15]}>

                <Col sm={8} xs={24}>
                  <Link to={routes.ORDER_FULFILLMENT}>
                    <LinkCard>
                      <LinkCardTitle>Order Fulfillment</LinkCardTitle>
                    </LinkCard>
                  </Link>
                </Col>

                <Col sm={8} xs={24}>
                  <Link to={routes.ORDER_PRODUCTION}>
                    <LinkCard>
                      <LinkCardTitle>Order Production</LinkCardTitle>
                    </LinkCard>
                  </Link>
                </Col>

                <Col sm={8} xs={24}>
                  <Link to={routes.ORDER_BOUNCED}>
                    <LinkCard>
                      <LinkCardTitle>Orders Bounced</LinkCardTitle>
                    </LinkCard>
                  </Link>
                </Col>

                <Col sm={8} xs={24}>
                  <Link to={routes.ORDER_COLOR_CARDS}>
                    <LinkCard>
                      <LinkCardTitle>Orders Color Cards only</LinkCardTitle>
                    </LinkCard>
                  </Link>
                </Col>

                <Col sm={8} xs={24}>
                  <Link to={routes.ORDER_SEARCH}>
                    <LinkCard>
                      <LinkCardTitle>Order Search</LinkCardTitle>
                    </LinkCard>
                  </Link>
                </Col>
              </Row>

              <Spacer />
            </Card>
          </CardWrapper>
        </ContentWrapper>
      </Container>
    );
  }
}
