import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as Parse from 'parse';
import _ from 'lodash';
import { fetchUser } from '../lib/parseUtils';

export const useOrderFulfillmentHistory = ({ user, orderId }) => {
  const historyRef = useRef();
  const [history, setHistory] = useState([]);
  const orderFulfillmentQueryRef = useRef();
  const orderFulfillmentQuerySubscriptionRef = useRef();
  // Store users by id as user objects dont change in short time frame
  const userStoreRef = useRef({});

  const updateFulfillmentHistory = async (orderFulfillmentHistory) => {
    const historyUserPointer = _.get(orderFulfillmentHistory, 'user', undefined);
    let historyUser = historyUserPointer;
    // Manually fetch this users data - not included in subscription updates
    if (historyUserPointer) {
      // Only fetch if the same user was not already fetched - reduce number of requests
      const historyUserId = historyUserPointer.objectId;
      if (userStoreRef.current[historyUserId]) {
        historyUser = userStoreRef.current[historyUserId];
      } else {
        const userResult = await fetchUser(historyUserId);
        if (userResult) {
          historyUser = userResult.toJSON();
          userStoreRef.current[historyUserId] = historyUser;
        }
      }
    }
    // Update ref and state
    historyRef.current = historyRef.current.map((h) => {
      if (h.objectId === orderFulfillmentHistory.objectId) {
        return {
          ...h,
          ...orderFulfillmentHistory,
          user: historyUser,
        };
      }
      return h;
    });
    setHistory(historyRef.current);
  };

  const createFulfillmentHistory = async (orderFulfillmentHistory) => {
    const historyOrderId = _.get(orderFulfillmentHistory, 'orderId', undefined);
    if (orderId !== historyOrderId) {
      return;
    }
    const historyUserPointer = _.get(orderFulfillmentHistory, 'user', undefined);
    let historyUser = historyUserPointer;
    // Manually fetch this users data - not included in subscription updates
    if (historyUserPointer) {
      // Only fetch if the same user was not already fetched - reduce number of requests
      const historyUserId = historyUserPointer.objectId;
      if (userStoreRef.current[historyUserId]) {
        historyUser = userStoreRef.current[historyUserId];
      } else {
        const userResult = await fetchUser(historyUserId);
        if (userResult) {
          historyUser = userResult.toJSON();
          userStoreRef.current[historyUserId] = historyUser;
        }
      }
    }
    // Add item to ref and state
    historyRef.current = _.orderBy(
      [
        ...historyRef.current,
        {
          ...orderFulfillmentHistory,
          user: historyUser,
        },
      ],
      ['createdAt'],
      ['desc'],
    );
    setHistory(historyRef.current);
  };

  const subscribeOrderFulfillmentHistory = async () => {
    if (!orderFulfillmentQueryRef.current) {
      return;
    }
    orderFulfillmentQuerySubscriptionRef.current = await orderFulfillmentQueryRef.current.subscribe();
    orderFulfillmentQuerySubscriptionRef.current.on('create', (object) => {
      createFulfillmentHistory(object.toJSON());
    });
    orderFulfillmentQuerySubscriptionRef.current.on('update', (object) => {
      updateFulfillmentHistory(object.toJSON());
    });
    // orderFulfillmentQuerySubscriptionRef.current.on('enter', (object) => {
    //   console.log('history entered', object);
    // });
    // // Delete and Leave should never happen
    // orderFulfillmentQuerySubscriptionRef.current.on('leave', (object) => {
    //   console.log('history left', object);
    // });
    // orderFulfillmentQuerySubscriptionRef.current.on('delete', (object) => {
    //   console.log('history deleted', object);
    // });
  };

  const unsubscribeOrderFulfillmentHistory = async () => {
    if (!orderFulfillmentQuerySubscriptionRef.current) {
      return;
    }
    orderFulfillmentQuerySubscriptionRef.current.unsubscribe();
    orderFulfillmentQuerySubscriptionRef.current = null;
  };

  useEffect(() => {
    unsubscribeOrderFulfillmentHistory();
  }, []);

  const fetchData = useCallback(async () => {
    const OrderFulfillmentHistory = Parse.Object.extend('OrderFulfillmentHistory');
    orderFulfillmentQueryRef.current = new Parse.Query(OrderFulfillmentHistory);
    orderFulfillmentQueryRef.current.equalTo('orderId', orderId);
    orderFulfillmentQueryRef.current.include('user');
    orderFulfillmentQueryRef.current.limit(1000);
    orderFulfillmentQueryRef.current.descending('createdAt');
    const orderFulfillmentQueryResult = await orderFulfillmentQueryRef.current.find({});
    const nextHistory = orderFulfillmentQueryResult.map((order) => order.toJSON());
    // Keep ref for updates via subscription
    historyRef.current = nextHistory;
    setHistory(nextHistory);

    // Subscribe to query
    subscribeOrderFulfillmentHistory();
    return () => unsubscribeOrderFulfillmentHistory();
  }, [orderId, history, setHistory, unsubscribeOrderFulfillmentHistory, subscribeOrderFulfillmentHistory]);

  useEffect(() => {
    fetchData();
  }, [orderId]);

  return { history };
};
