import React, { } from 'react';
import { routes } from '../../lib/routes';

import { Container, ContentWrapper, Spacer, CardWrapper } from '../../components/Layout/Layout';
import { AdminClassEntryEdit } from '../../components/AdminClassEntryEdit/AdminClassEntryEdit';

const properties = [
  {
    key: 'slug',
    label: 'Slug',
    type: 'radio',
    required: true,
    extra: '',
    options: [
      { value: 'HOME_HEADER', label: 'Home Header' },
      { value: 'SHOP_HEADER', label: 'Shop Header' },
      { value: 'SHOP_HEADER_V2', label: 'Shop Header V2' },
    ],
  },
  {
    key: 'title',
    label: 'Title',
    type: 'string',
    required: true,
    extra: '',
  },
  {
    key: 'label',
    label: 'Label',
    type: 'string',
    required: true,
    extra: '',
  },
  {
    key: 'icon',
    label: 'Icon Name',
    type: 'radio',
    required: false,
    extra: '',
    options: [
      { value: '', label: 'None' },
      { value: '&#61748;', label: 'Arrow Right' },
    ],
    // type: 'string',
    // required: false,
    // extra: '',
  },
  {
    key: 'visible',
    label: 'Visible',
    type: 'checkbox',
    required: false,
    extra: 'Only if visible is true, the tile will show up in the app',
  },
  {
    key: 'sortIndex',
    label: 'sortIndex',
    type: 'number',
    required: false,
    extra:
      'E.g 1 or 2 etc. - Use this field to sort the tiles with numbers. The lower the number, the earlier the tile is sorted in the app.',
  },
  // {
  //   key: 'iconSize',
  //   label: 'Icon Size',
  //   type: 'string',
  //   required: false,
  //   extra: '',
  // },
  {
    key: 'actionType',
    label: 'Action Type',
    type: 'radio',
    required: true,
    extra: '',
    options: [
      { value: 'WEB_VIEW', label: 'Webview (internal)' },
      { value: 'WEB_URL', label: 'Weblink (external)' },
      { value: 'PRODUCT_LINK', label: 'Product Link' },
      { value: 'CATEGORY_LINK', label: 'Category Link' },
      { value: 'TOOL_LINK', label: 'Tool Link' },
    ],
  },
  {
    key: 'actionValue',
    label: 'Action Value',
    type: 'string',
    required: true,
    extra:
      'Examples: Webview or Weblink: "https://www.misspompadour.de", Product Link: 123123 (Product ID), Category Link: 123123 (Category ID), Tool Link: "AugmentedPreviewModal" | "CameraColorPickerModal"',
  },
  {
    key: 'actionPayload',
    label: 'Action Payload',
    type: 'jsonObject',
    required: false,
    extra: 'Use this to transport additional data to the action view (JSON Object)',
  },
  {
    key: 'backgroundColor',
    label: 'Background Color',
    type: 'string',
    required: false,
    extra: 'The color will only be used if you do not set a background-image',
  },
  {
    key: 'imageUrl',
    label: 'Background Image Url',
    type: 'image',
    required: false,
    extra: 'If you set a background-image, the background-color will not be used.',
  },
  {
    key: 'startsAt',
    label: 'Start Date',
    type: 'datetime',
    required: true,
    extra: 'Select start AND end date. Select Time as Local DE Time.',
  },
  {
    key: 'endsAt',
    label: 'End Date',
    type: 'datetime',
    required: true,
    extra: 'Select start AND end date. Select Time as Local DE Time.',
  },
];

export const PromotionCarouselItemEdit = (props) => {
  const { user, authenticated, match = {}, history } = props;

  return (
    <Container>
      <ContentWrapper>
        <AdminClassEntryEdit
          user={user}
          history={history}
          title={'Promotion Carousel Item'}
          className={'PromotionCarouselItem'}
          editRoute={routes.ADMIN_PROMOTION_CAROUSEL_ITEMS_EDIT}
          listRoute={routes.ADMIN_PROMOTION_CAROUSEL_ITEMS}
          properties={properties}
        />
      </ContentWrapper>
    </Container>
  );
};
