import React, { useState, useEffect } from 'react';
import {
  Container,
  ContentWrapper,
  CardWrapper,
  CardContent,
  CardHeader,
  Spacer,
  CardFooter
} from '../../components/Layout/Layout';
import * as Parse from 'parse';
import { Tag, Form, Input, Button, Checkbox, message, notification, Modal, Statistic } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {colors} from "../../lib/theme";
import {useActiveJobLogs} from "../../hooks/useActiveJobLogs";
import {DataForm} from "../../components/AdminClassEntryEdit/AdminClassEntryEdit";

const { confirm } = Modal;

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 10 },
};

const imageUploadFormProperties = [
  {
    key: 'imageUrl',
    label: 'Background Image Url',
    type: 'image',
    required: false,
    extra: 'Please only upload small pictures, as the phone only shows a small thumbnail - 100x100px',
  },
]

export const PushNotifications = ({ user, authenticated, history }) => {
  const [numberOfDevices, setNumberOfDevices] = useState(0);
  const [isSending, setIsSending] = useState(false);

  const [countTitle, setCountTitle] = useState(0);
  const countTitleMax = 40;
  const remainingTitle = countTitleMax - countTitle;

  const [countBody, setCountBody] = useState(0);
  const countBodyMax = 80;
  const remainingBody = countBodyMax - countBody;


  const [imageUploadForm] = Form.useForm();

  const { previousJobLogs = [] } = useActiveJobLogs({
    fetchPrevious: true,
    fetchActive: false,
    type: 'send-push-notification',
  });

  useEffect(() => {
    (async () => {
      const DeviceRegistry = Parse.Object.extend('DeviceRegistry');
      const deviceRegistryQuery = new Parse.Query(DeviceRegistry);
      deviceRegistryQuery.equalTo('pushAllowed', true);
      deviceRegistryQuery.exists('pushToken');
      const deviceCount = await deviceRegistryQuery.count();
      setNumberOfDevices(deviceCount);
    })();

    (async () => {
      const { sending } = await Parse.Cloud.run('adminAppFetchSendPushNotificationStatus', {});
      setIsSending(sending || false);
    })();
  }, []);

  const handleSendPushNotification = async (title, body, token, allDevices, imageUrl) => {
    try {
      const response = await Parse.Cloud.run('adminAppSendMulticastPushNotification', {
        title,
        body,
        token,
        allDevices,
        imageUrl,
      });
      notification.success({
        message: `Notification sent to ${response.successCount} devices, failed for ${response.failureCount}!`,
        description: 'Well done!',
      });
    } catch (error) {
      notification.error({
        message: 'Cannot send notification',
        description: 'Unfortunately, something went wrong, please try again.',
      });
    }
  };

  const onFinish = (values) => {
    const { body, title, token, allDevices, imageUrl } = values;

    if (body.length && title && (token || allDevices)) {
      confirm({
        title: 'Do you want to send this push notification?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          console.log('OK');
          handleSendPushNotification(title, body, token, allDevices, imageUrl);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      notification.error({
        message: 'Cannot send push',
        description: 'Please check your inputs and either provide a token or send it to all devices',
      });
    }
  };

  if (!user.roles.includes('administrator')) {
    return (
      <Container>
        <ContentWrapper>
          <CardWrapper>
            <CardHeader>
              <h1>This section is only available for administrator.</h1>
            </CardHeader>
          </CardWrapper>
        </ContentWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <ContentWrapper>
        <CardWrapper>
          <CardHeader>
            <h1>Notifications Panel</h1>
            {isSending ? <Tag color={colors.GREEN}>SENDING</Tag> : <Tag color={colors.GREY}>NOT SENDING</Tag>}
          </CardHeader>
          <CardContent>
            <Form {...layout} name='nest-messages' onFinish={onFinish}>
              <Form.Item name={['title']} label='Title' rules={[{ required: true, message: 'Title is needed!' }]}>
                <Input maxLength={40} onChange={(e) => setCountTitle(e.target.value.length)} />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 2 }}>
                <Statistic title='Remaining Characters' value={remainingTitle} />
              </Form.Item>
              <Form.Item name={['body']} label='Body' rules={[{ required: true, message: 'Body is needed!' }]}>
                <Input maxLength={80} onChange={(e) => setCountBody(e.target.value.length)} />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 2 }}>
                <Statistic title='Remaining Characters' value={remainingBody} />
              </Form.Item>
              <Form.Item name={['token']} label='Token'>
                <Input />
              </Form.Item>
              <Form.Item name={['imageUrl']} label='Image Url' extra={'Thumbnail only - needs to be public'}>
                <Input />
              </Form.Item>
              <Form.Item
                name={['allDevices']}
                valuePropName='checked'
                initialValue={false}
                wrapperCol={{ ...layout.wrapperCol, offset: 2 }}
              >
                <Checkbox>Send to All</Checkbox>
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 2 }}>
                <Statistic title='Number of Devices' value={numberOfDevices} />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 2 }}>
                <Button name={['button']} type='primary' htmlType='submit'>
                  Send Push Notification
                </Button>
              </Form.Item>
            </Form>
          </CardContent>
        </CardWrapper>

        <Spacer/>

        <CardWrapper>
          <CardHeader>Image Upload</CardHeader>
          <CardContent>
            <DataForm
                data={{}}
                onSubmit={() => {}}
                form={imageUploadForm}
                loading={false}
                properties={imageUploadFormProperties}
                title={''}
                isNew={true}
                user={user}
            />
          </CardContent>
        </CardWrapper>

        <Spacer/>

        {previousJobLogs.map((jobLog) => {
          const payload = jobLog.payload || {};
          return (
              <div key={jobLog.objectId}>
                <CardWrapper>
                  <CardContent>
                    <h3>{payload.title}</h3>
                    <p>{payload.body}</p>
                    </CardContent>
                  <CardFooter>
                    <p>{jobLog.completed ? 'COMPLETED' : 'RUNNING'} | {jobLog.currentProcess} of {jobLog.processCount} completed | {jobLog.createdAt}</p>
                    <p>To {payload.tokenCount} Devices | {payload.successCount} ok</p>
                  </CardFooter>
                </CardWrapper>
                <Spacer/>
              </div>
          );
        })}
      </ContentWrapper>
    </Container>
  );
};
