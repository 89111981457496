import _ from 'lodash';
import React from 'react';
import {TranslationContentTable} from "../../../components/TranslationContentTable/TranslationContentTable";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
]

export const AdminTranslationPages = ({user, authenticated, history}) => {

    return (
        <TranslationContentTable
            type={'cms-page'}
            capitalName={'Pages'}
            functionName={'Pages'}
            columns={columns}
            user={user}
            history={history}
        />
    )

};
