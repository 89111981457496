import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { UI, colors } from '../../lib/theme';
import {Col, Row, Table, Tag, Button, notification, Modal, Alert, Select, Input} from 'antd';
import {
  MinusOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  formatTitle,
  getWcProductEditLink,
  getPlentyShippingProfilesForCountry,
} from '../../lib/utils';

import {
  CardWrapper,
  CardContent,
  CardHeader,
  CardFooter,
  Spacer, CardHeaderIcon, PullRight,
} from '../../components/Layout/Layout';
import {useOrderPackages} from "../../hooks/useOrderPackages";
import {OrderDeliveryModal} from "../OrderDeliveryModal/OrderDeliveryModal";
import {OrderDeliveryDeleteButton} from "../OrderDeliveryDeleteButton/OrderDeliveryDeleteButton";
import {OrderDeliveryNotEUProcessingButton} from "../OrderDeliveryNotEUProcessingButton/OrderDeliveryNotEUProcessingButton";
import {GtinScannerInput} from "../GtinScannerInput/GtinScannerInput";
import {OrderOriginTags} from "../OrderOriginTags/OrderOriginTags";
import {OrderPackageNotesOverlay} from "../OrderPackageNotesOverlay/OrderPackageNotesOverlay";
import {useOrderNotes} from "../../hooks/useOrderNotes";

const TableWrapper = styled.div`
  background-color: ${colors.WHITE};
  margin: -15px -15px;
  position: relative;
`;
const TableOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  z-index: 100;
`;

const LineItemQtyColumn = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
const LineItemQtyLabel = styled.p`
  padding-right: 8px;
  margin: 0;
  display: inline-block;
  font-weight: 600;
`;
const DeliverySeparator = styled.div`
  background-color: ${colors.BLACK50};
  width: 100%;
  height: 5px;
`;
const VoucherOverlay = styled.div`
  background-color: ${colors.WHITE};
  display: ${({ visible }) => visible ? 'block' : 'none'};
  z-index: 1000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.95;
  padding: 48px;
`;
const VoucherOverlayHeader =styled(CardHeader)`
  background-color: ${colors.PRIMARY}33;
  border-bottom: 2px solid ${colors.PRIMARY};
`;
const DeliveryPackageContainer = styled.div`
  position: relative;
`;
const PackageCardHeader = styled(CardHeader)`
  ${({ active }) => active ? `background-color: ${colors.PRIMARY}33; border-bottom: 2px solid ${colors.PRIMARY};` : ''};
`

const lineItemImageColumn = {
  title: 'Image',
  dataIndex: 'image',
  key: 'image',
  render: (itemName, row) => {
    const imageUrl = _.get(row, 'plentyProduct.imageUrl', '');
    return <img src={imageUrl} style={{ width: 60, height: 60, borderRadius: 4, backgroundColor: '#f2f2f2' }}/>;
  }
};
const lineItemNameColumn = {
  title: 'Name',
  dataIndex: 'itemName',
  key: 'itemName',
  render: (itemName, row) => {
    const { qty, plentyProduct = {} } = row;
    const { gtin } = plentyProduct;
    return (
      <>
        {row.isExternalItem ? <Tag color={colors.BLUE}>External Fulfillment</Tag> : null}
        <strong>{qty} x </strong> {formatTitle(itemName)}{' '}
        {_.size(gtin) ? (
            <small><br/>GTIN: {gtin}</small>
        ) : null}
      </>
    );
  },
};
const lineItemSkuColumn = {
  title: 'SKU',
  dataIndex: 'sku',
  key: 'sku',
  render: (sku, row) => (
    <a href={getWcProductEditLink(row.productId)} target='_blank'>
      {sku}
    </a>
  ),
  className: 'package-item-sku-cell',
};
const lineItemQuantityColumn = {
  title: 'Quantity',
  dataIndex: 'qty',
  key: 'qty',
  render: (qty, row) => {
    const { unpackedQty, isMainDelivery } = row;
    return (
        <LineItemQtyColumn>
          <LineItemQtyLabel>
            {row.packedQty} / {row.qty}
          </LineItemQtyLabel>
          {isMainDelivery ? (
              <>
                {unpackedQty === 0 ? <Tag color={colors.GREEN}>Packaged</Tag> : null}
                {unpackedQty === row.qty ? <Tag color={colors.VOLCANO}>Pending</Tag> : null}
                {unpackedQty > 0 && unpackedQty !== row.qty ? <Tag color={colors.GOLD}>{unpackedQty} Missing</Tag> : null}
              </>
          ) :  null}
        </LineItemQtyColumn>
    );
  },
  className: 'package-item-qty-cell',
};
const lineItemActionsColumn = {
  // This gets extended in render function
  title: 'Actions',
  dataIndex: 'totalTaxes',
  key: 'actions',
  width: 110,
  render: (objectId, row) => '',
  className: 'package-item-actions-cell',
};

const packageTableColumnsDef = [
  lineItemImageColumn,
  lineItemNameColumn,
  lineItemSkuColumn,
  lineItemQuantityColumn,
  lineItemActionsColumn,
];

const createActionButtons = (row, onAdd, onRemove, addDisabled, removeDisabled) => {
  return (
    <>
      <Button type='danger' onClick={() => onRemove(row)} disabled={removeDisabled} icon={<MinusOutlined />} />
      <Spacer />
      <Button type='primary' onClick={() => onAdd(row)} disabled={addDisabled} icon={<PlusOutlined />} />
    </>
  );
};

const formatVoucherCode = (code = '') => {
  return code.replace('ß', '-')
      .replace('Y', '#')
      .replace('Z', 'Y')
      .replace('#', 'Z');
}

const DeliveryWithPackagesItem = ({
    mainDeliveryId,
    orderId,
    deliveryCount,
    loading,
    joinedLineItems,
    orderItemCount,
    delivery,
    index,
    packages,
    localPackages,
    localPackagesUpdated,
    unpackedItems,
    unpackedItemsRef,
    packageTableColumns,
    saveShippingProfile,
    refreshOrder,
    registerLabelsForDHL,
    resetLabelsForDHL,
    createPackage,
    deletePackage,
    saveAllPackages,
    notEU,
    packageOpenMap,
    setPackageOpenMap,
    disable,
    onScanItem,
    scanningActivePackageId,
    setScanningActivePackageId,
}) => {

  const shippingProfileId = _.get(delivery, 'plentyShippingProfileId');
  const { plentyId, lineItems: deliveryItemsWithBundles, plentyWarehouseId, shippingCountry, status } = delivery;
  const deliveryItems = useMemo(() => {
    return getFulfillmentLineItems(deliveryItemsWithBundles);
  }, [deliveryItemsWithBundles]);

  const plentyOrderId = `${plentyId}`;
  const isMainDelivery = `${mainDeliveryId}` === plentyOrderId;
  const hasSubDeliveries = deliveryCount > 1;
  const deliveryItemsWithGtin = useMemo(() => {
    return deliveryItems.map(item => {
      const { plentyVariationId } = item;
      const lineItem = joinedLineItems.find(i => i.plentyVariationId === plentyVariationId);
      return {
        ...item,
        plentyProduct: _.get(lineItem, 'warehouseStock.plentyProduct', {}),
      };
    });
  }, [deliveryItems]);

  const localPackagesInDelivery = useMemo(() => {
    return localPackages.filter(p => `${p.orderId}` === plentyOrderId);
  }, [localPackages]);

  const localPackagesRef = useRef(localPackages);
  useEffect(() => {
    localPackagesRef.current = localPackagesInDelivery;
  }, [localPackages]);

  const packagesInDelivery = useMemo(() => {
    return packages.filter(p => `${p.orderId}` === plentyOrderId);
  }, [packages]);

  const availableItemsForDeliveryMap = useMemo(() => {
    return deliveryItemsWithGtin.reduce((map, deliveryItem) => {
      const { plentyVariationId } = deliveryItem;
      return {
        ...map,
        [`${plentyVariationId}`]: deliveryItem,
      }
    }, {})
  }, [deliveryItemsWithGtin]);
  const deliveryItemCount= _.sum(_.values(availableItemsForDeliveryMap).map(item => item.qty));
  const deliveryPackageItemQty = _.sum(localPackagesInDelivery.map(p => _.sumBy(p.items, 'packedQty')));
  const deliveryFullyPacked = deliveryItemCount > 0 && deliveryItemCount === deliveryPackageItemQty;

  const shippingProfiles = useMemo(() => {
    return getPlentyShippingProfilesForCountry(shippingCountry, plentyWarehouseId);
  }, [shippingCountry, isMainDelivery, plentyWarehouseId]);

  const packagesRegistered = useMemo(() => {
    return (packagesInDelivery || []).reduce((registered, orderPackage) => {
      return registered || (_.size(orderPackage.packageNumber) > 0 || _.size(orderPackage.labelBase64) > 0);
    }, false);
  }, [packagesInDelivery]);
  const packagesSaved = useMemo(() => {
    return localPackagesUpdated === 0;
  }, [localPackagesUpdated]);

  const printIframeRef = useRef();
  const handleLabelPrint = useCallback((labelBase64) => {
    var byteCharacters = atob(labelBase64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);

    const openNewWindow = () => {
      const newWindow = window.open(fileURL);
      newWindow.print();
    };

    Modal.success({
      title: 'Print Label',
      width: 600,
      closable: true,
      afterClose: () => {
        printIframeRef.current = null;
      },
      content: (
          <div>
            <iframe src={fileURL} style={{ width: '100%', height: '200px'}} ref={printIframeRef}/>
            <Button onClick={openNewWindow}>Open in new Window</Button>
          </div>
      ),
    });

    setTimeout(() => {
      if (printIframeRef.current) {
        printIframeRef.current.focus();
        printIframeRef.current.contentWindow.print();
      } else {
        setTimeout(() => {
          // Try again after 1 Second
          if (printIframeRef.current) {
            printIframeRef.current.focus();
            printIframeRef.current.contentWindow.print();
          }
        }, 1000);
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (deliveryFullyPacked && !packagesRegistered) {
      saveAllPackages(plentyOrderId, localPackagesInDelivery);
    }
  }, [deliveryFullyPacked]);

  const [activeShippingProfile, setActiveShippingProfile] = useState('6');
  const shippingProfileChangeDisabled = false;
  const handleShippingProfileChange = (shippingProfileId) => {
    setActiveShippingProfile(shippingProfileId);
  };

  const handleSaveShippingProfile = () => {
    saveShippingProfile(plentyOrderId, activeShippingProfile, localPackagesInDelivery);
    refreshOrder();
  };

  useEffect(() => {
    if (shippingProfileId !== activeShippingProfile) {
      setActiveShippingProfile(shippingProfileId);
    }
  }, [shippingProfileId]);

  const handlePackageHeaderClick = (packageId) => {
    const packageOpen = packageOpenMap[packageId];
    const nextPackageOpen = !packageOpen;
    setPackageOpenMap({ ...packageOpenMap, [packageId]: nextPackageOpen });
    if (nextPackageOpen) {
      // Activate package for scanning also
      setScanningActivePackageId(packageId);
    }
  }

  // Move variables to ref, so that these work inside the event function
  const scanningActivePackageIdRef = useRef(scanningActivePackageId);
  const packageOpenMapRef = useRef(packageOpenMap);
  const registerLabelsForDHLRef = useRef((id) => {});
  const printLabelForActivePackageRef = useRef((id) => {});
  const allowRegisterLabelsDHL = !packagesRegistered && packagesSaved && !notEU && (deliveryFullyPacked || (isMainDelivery && hasSubDeliveries));
  useEffect(() => {
    scanningActivePackageIdRef.current = scanningActivePackageId;
    packageOpenMapRef.current = packageOpenMap;
    registerLabelsForDHLRef.current = () => {
      return allowRegisterLabelsDHL ? registerLabelsForDHL(plentyOrderId) : null;
    }
  }, [scanningActivePackageId, packageOpenMap]);

  const scanningActiveInDelivery = useMemo(() => {
    const activePackageInDelivery = packagesInDelivery.find(p => p.id ===  scanningActivePackageId);
    return Object.values(packageOpenMap).includes(true) && _.isObject(activePackageInDelivery);
  }, [packagesInDelivery, scanningActivePackageId]);
  useEffect(() => {
    const watchKeys = (event) => {
      let nextPackage;
      const currentPackageIndex = localPackagesRef.current.findIndex(p => p.id === scanningActivePackageIdRef.current);
      switch (event.keyCode) {
        case 40:
          // Down - next package
          event.preventDefault();
          nextPackage = localPackagesRef.current[_.size(packages) > currentPackageIndex ? currentPackageIndex + 1 : currentPackageIndex];
          break;
        case 38:
          // Up - prev package
          event.preventDefault();
          nextPackage = localPackagesRef.current[currentPackageIndex > 0 ? currentPackageIndex - 1 : currentPackageIndex];
          break;
        case 32:
          // Space - register labels for delivery
          event.preventDefault();
          registerLabelsForDHLRef.current();
          break;
        case 80:
          // P - print label of active package
          const labelBase64 = _.get(localPackagesRef.current[currentPackageIndex], 'labelBase64');
          if (_.size(labelBase64)) {
            event.preventDefault();
            handleLabelPrint(labelBase64);
          }
          break;
      }
      if (nextPackage) {
        setPackageOpenMap({ ...packageOpenMapRef.current, [nextPackage.id]: true })
        setScanningActivePackageId(nextPackage.id);
      }
    }

    if (scanningActiveInDelivery) {
      // Only listen to keys, if current package is in this delivery
      document.addEventListener('keydown', watchKeys);
    }
    return () => {
      document.removeEventListener('keydown', watchKeys);
    }
  }, [scanningActiveInDelivery]);



  const [showVoucherCodeItemScanning, setShowVoucherCodeItemScanning] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const voucherCodeInputRef = useRef();
  const { addOrderNote } = useOrderNotes({ orderId });

  const handleScanItem = (item, scanningType) => {
    onScanItem(item, scanningType);

    const isVoucherCodeItem = _.get(item, 'plentyProduct.isVoucherCode') === true;
    if (isVoucherCodeItem) {
      // Open voucher Code scanning
      setTimeout(() => {
        setShowVoucherCodeItemScanning(true);
        setTimeout(() => {
          voucherCodeInputRef.current.focus();
        }, 100);
      }, 100);
    }
  }

  const addVoucherCodeTimeoutRef = useRef();
  useEffect(() => {
    if (voucherCode.length > 5) {
      if (addVoucherCodeTimeoutRef.current) {
        clearTimeout(addVoucherCodeTimeoutRef.current);
        addVoucherCodeTimeoutRef.current = undefined;
      }
      addVoucherCodeTimeoutRef.current = setTimeout(async () => {
        const cleanVoucherCode = formatVoucherCode(voucherCode);
        await addOrderNote(`Voucher Code: ${cleanVoucherCode}`);
        setShowVoucherCodeItemScanning(false);
        setVoucherCode('');
      }, 300);
    }
  }, [voucherCode, addOrderNote]);

  const handleAddPackage = () => {
    (async() => {
      await saveAllPackages(plentyOrderId, localPackagesInDelivery);
      await createPackage(plentyOrderId, activeShippingProfile);
    })();
  }


  return (
      <div>
        <DeliverySeparator/>
        <CardContent>
          <Row gutter={[16, 16]}>
            <Col xs={12}>
              <h3>
                {isMainDelivery ? 'Main-' : ''}Delivery {index + 1} (PlentyId {plentyOrderId})
                {' '}
                {status && !isMainDelivery ? <Tag color={status.color}>{status.name}</Tag> : ''}
              </h3>
            </Col>
            <Col xs={12}>
              <PullRight>
                {!isMainDelivery && status.plentyStatusId < 7 && !packagesRegistered ? (
                    <OrderDeliveryDeleteButton plentyOrderId={plentyOrderId} onDelete={refreshOrder}/>
                ) : null}
              </PullRight>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={6}>
              <Select
                  showArrow
                  value={activeShippingProfile}
                  style={{ width: '100%' }}
                  options={shippingProfiles}
                  onChange={handleShippingProfileChange}
                  disabled={shippingProfileChangeDisabled}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Button
                  type='primary'
                  block
                  onClick={handleSaveShippingProfile}
                  disabled={shippingProfileChangeDisabled || !packagesSaved || packagesRegistered}
              >
                Save Shipping Method
              </Button>
            </Col>
            <Col xs={24} sm={12}>
              <PullRight>
                {_.size(unpackedItems) && !packagesRegistered ? (
                    <>
                      <Button onClick={handleAddPackage}>Add Package</Button>
                      <Spacer/>
                    </>
                ) : null}
                {packagesRegistered ? (
                    <Button type='danger' onClick={() => resetLabelsForDHL(plentyOrderId, shippingCountry)} loading={loading}>Reset all Labels</Button>
                ) : null}
                {!packagesRegistered && packagesSaved && !notEU ? (
                    <Button type='primary' disabled={!allowRegisterLabelsDHL} onClick={() => registerLabelsForDHL(plentyOrderId, shippingCountry)} loading={loading}>Register all Labels</Button>
                ) : null}
                {!packagesRegistered && packagesSaved && notEU ? (
                    <OrderDeliveryNotEUProcessingButton plentyOrderId={plentyOrderId} onSuccess={refreshOrder} disabled={!deliveryFullyPacked}/>
                ) : null}
                {!packagesRegistered && !packagesSaved ? (
                    <Button type='primary' onClick={() => saveAllPackages(plentyOrderId, localPackagesInDelivery)} loading={loading}>Save all Packages</Button>
                ) : null}
              </PullRight>
            </Col>
          </Row>
        </CardContent>
        <CardContent>
          <Alert
              message={isMainDelivery ? (
                <>
                  <strong>Main Delivery:</strong>
                  {deliveryFullyPacked ? (<strong> This delivery is packed completely!</strong>) : <> Only if all items can be shipped at once!</>}
                </>
              ) : (
                <>
                  <strong>Order Delivery:</strong>
                  {deliveryFullyPacked ? (<strong> This delivery is packed completely!</strong>) : <> This delivery must include:</>}
                  <ul>
                    {deliveryItems.map(i => <li key={i.plentyVariationId}>{i.qty} x {i.itemName}</li>)}
                  </ul>
                </>
              )}
              type={deliveryFullyPacked ? 'success' : 'warning'}
              showIcon
          />
        </CardContent>

        {localPackagesInDelivery.map((orderPackage) => {
          const { id: packageId, packageId: packageTypeId, packageNumber, labelBase64 } = orderPackage;
          const disableDelete = _.size(labelBase64) > 0;
          const disablePrintLabel = !_.size(labelBase64);
          const packageOpen = packageOpenMap[packageId];
          const scanningActive = scanningActivePackageId === packageId;
          const name = [4].includes(packageTypeId) ? 'DHL Warenpost' : 'DHL Paket';
          const filteredOrderItems = orderPackage.items.map((item) => {
            const unpackedItem = unpackedItemsRef.current.find(i => i.itemId === item.itemId);
            const unpackedQty = _.get(unpackedItem, 'unpackedQty', 0);
            // If order delivery (= not main delivery), then it might be that only a specific number of items can be in this delivery
            const deliveryItem = availableItemsForDeliveryMap[`${item.plentyVariationId}`] || {};
            const qtyForDelivery = deliveryItem.qty || 0;
            const unpackedQtyForDelivery = qtyForDelivery - item.packedQty;
            return {
              ...item,
              isMainDelivery,
              qty: isMainDelivery ? item.qty : qtyForDelivery,
              unpackedQty: isMainDelivery ? unpackedQty : unpackedQtyForDelivery,
              plentyProduct: deliveryItem.plentyProduct || {},
            };
          }).filter((item) => {
            // Filter here for items that have unpacked quantity still
            return item.unpackedQty > 0 || item.packedQty > 0;
          });
          return (
              <CardContent key={`package-${orderPackage.id}`}>
                <CardWrapper>
                  <PackageCardHeader onClick={() => handlePackageHeaderClick(packageId)} active={packageOpen && scanningActive}>
                    <h3><CardHeaderIcon open={packageOpen}/> {name} #{packageId} ({_.sumBy(orderPackage.items, 'packedQty')} of {deliveryItemCount} Items) {_.size(packageNumber) ? <Tag>DHL Tracking: {packageNumber}</Tag> : ''}</h3>
                    <Button type='primary' onClick={() => handleLabelPrint(labelBase64)} disabled={disablePrintLabel}>Print Label {scanningActive ? ' (P)' : ''}</Button>
                  </PackageCardHeader>
                  {packageOpen ? (
                    <>
                      {scanningActive && !showVoucherCodeItemScanning ? (
                          <GtinScannerInput
                              onAdd={handleScanItem}
                              lineItems={filteredOrderItems}
                              gtinKey='plentyProduct.gtin'
                              baseGtinKey='plentyProduct.baseGtin'
                              qtyCheckEnabled={true}
                              qtyCheckItemKey='packedQty'
                              voucherCodeScanningEnabled={true}
                          />
                      ) : null}
                      <CardContent>
                        <TableWrapper>
                          {disable ? <TableOverlay /> : ''}
                          <Table
                              columns={packageTableColumns}
                              dataSource={filteredOrderItems}
                              rowKey={'itemId'}
                              pagination={{
                                pageSize: 1000,
                                hideOnSinglePage: true,
                              }}
                          />
                        </TableWrapper>
                      </CardContent>
                    </>
                  ) : null}
                  <CardFooter>
                    <p><strong>{_.sumBy(orderPackage.items, 'packedQty')} Items</strong></p>
                    <Button type='danger' disabled={disableDelete} onClick={() => deletePackage(plentyOrderId, packageId)}>Delete Package</Button>
                  </CardFooter>
                </CardWrapper>
              </CardContent>
          );
        })}

        <VoucherOverlay visible={showVoucherCodeItemScanning}>
          <CardWrapper>
            <VoucherOverlayHeader>
              <h2>Scan the voucher</h2>
            </VoucherOverlayHeader>
            <CardContent>
              <Input type='text' ref={voucherCodeInputRef} value={voucherCode} onChange={({ target: { value }}) => setVoucherCode(value)} placeholder='Scan voucher code here'/>
            </CardContent>
            <CardFooter>
              <span></span>
              <Button type='danger' onClick={() => setShowVoucherCodeItemScanning(false)}>Cancel</Button>
            </CardFooter>
          </CardWrapper>
        </VoucherOverlay>
      </div>
  )
}

const getFulfillmentLineItems = (lineItems) => {
  return _.values((lineItems || []).reduce((map, lineItem) => {
    const { plentyVariationId } = lineItem;
    if (!map[plentyVariationId]) {
      map[plentyVariationId] = {
        ...lineItem,
        qty: 0,
      }
    }
    map[plentyVariationId].qty += lineItem.qty;
    return map;
  }, {}));
}

export const OrderPackageTable = ({ user, order = {}, refreshOrder = () => {}, joinedLineItems = [] }) => {
  // Sum up all line items by plentyVariationId
  const { plentyId, lineItems: lineItemsWithBundles, id: orderId } = order;
  const lineItems = useMemo(() => {
    return getFulfillmentLineItems(lineItemsWithBundles);
  }, [lineItemsWithBundles]);

  const [open, setOpen] = useState(false);
  const [packageOpenMap, setPackageOpenMap] = useState({});
  const [packageTableColumns, setPackageTableColumns] = useState(packageTableColumnsDef);
  const [scanningActivePackageId, setScanningActivePackageId] = useState('');

  const orderFulfillment = _.get(order, 'fulfillment');
  const fulfillmentUserId = _.get(order, 'fulfillment.fulfillmentUser.objectId');
  const fulfillmentUsername = _.get(order, 'fulfillment.fulfillmentUser.username');
  const notEU = _.get(order, 'notEU');
  const orderItemCount = _.get(order, 'fulfillment.itemCount', 0);
  const currentUserFulfills = user.objectId === fulfillmentUserId;
  const deliveries = _.get(order, 'deliveries', []);
  const orderAndDeliveries = [order, ...deliveries];

  const [localPackages, setLocalPackages] = useState([]);
  const [localPackagesUpdated, setLocalPackagesUpdated] = useState(0);
  const localPackagesRef = useRef([]);
  const unpackedItemsRef = useRef([]);

  const [deliveryModalVisible, setDeliveryModalVisible] = useState(false);

  const plentyOrderIds = useMemo(() => {
    const { plentyId, deliveries = [] } = order || {};
    const deliveryIds = deliveries.map(delivery => `${delivery.plentyId}`);
    if (!plentyId) {
      return [];
    }
    return [`${plentyId}`, ...deliveryIds];
  }, [order]);

  const {
    loading,
    packages,
    createPackage,
    deletePackage,
    saveAllPackages,
    registerLabelsForDHL,
    resetLabelsForDHL,
    saveShippingProfile,
  } = useOrderPackages({ user, plentyOrderIds, orderId });

  useEffect(() => {
    localPackagesRef.current = packages.map((orderPackage) => {
      return {
        ...orderPackage,
        items: _.sortBy((lineItems || []).map((item) => {
          const itemInPackage = orderPackage.items.find(i => `${i.plentyOrderItemId}` === `${item.itemId}`);
          const packedQty = _.get(itemInPackage, 'packedQty', 0);
          return {
            ...item,
            packedQty,
            packageId: orderPackage.id,
          };
        }), 'itemName'),
      };
    });
    setLocalPackages(localPackagesRef.current);
    setLocalPackagesUpdated(0);
  }, [packages, lineItems]);

  const unpackedItems = useMemo(() => {
    const allItemsFromPackages = _.flatten(localPackages.map(orderPackage => orderPackage.items || []));
    // Sum all packed quantities for all items from all packages
    const packedItemQuantitiesByIdMap = (allItemsFromPackages).reduce((itemMap, item) => {
      const itemId = `${item.itemId}`;
      return {
        ...itemMap,
        [itemId]: _.isNumber(itemMap[itemId]) ? itemMap[itemId] + item.packedQty : item.packedQty,
      }
    }, {});
    // Go through all line items and compare the quantities
    unpackedItemsRef.current = (lineItems || []).map((item) => {
      const packedQuantity = packedItemQuantitiesByIdMap[`${item.itemId}`] || 0;
      const allQuantityPackaged = item.qty === packedQuantity;
      if (allQuantityPackaged) {
        return null;
      }
      return {
        ...item,
        unpackedQty: (item.qty || 0) - packedQuantity,
      };
    }).filter(item => _.isObject(item));
    return unpackedItemsRef.current;
  }, [localPackages, lineItems]);

  // Items that are not packed yet
  const unpackedItemsQty = useMemo(() => {
    return _.sumBy(unpackedItems, 'unpackedQty');
  }, [unpackedItems]);

  // Items that are not allocated to any delivery yet
  const unallocatedItems = useMemo(() => {
    if (!_.size(deliveries)) {
      return unpackedItems.map((item) => {
        return {
          ...item,
          unallocatedQty: item.qty,
        }
      });
    }
    const allocatedItemMap = _.flatten(deliveries.map(d => d.lineItems)).reduce((map, item) => {
      const { plentyVariationId, qty } = item;
      return {
        [`${plentyVariationId}`]: qty + (map[[`${plentyVariationId}`]] || 0)
      }
    }, {});
    return unpackedItems.map((item) => {
      const allocatedItemQty = _.get(allocatedItemMap, `${item.plentyVariationId}`) || 0;
      // Are all allocated already?
      if (allocatedItemQty && allocatedItemQty === item.qty) {
        return null;
      }
      return {
        ...item,
        qty: item.qty - allocatedItemQty,
        unallocatedQty: item.qty - allocatedItemQty
      };
    }).filter(item => _.isObject(item));
  }, [lineItems, unpackedItems, deliveries]);

  const setPackedItemQtyInPackage = (packageId, itemId, qtyChange, packingType = 'manual') => {
    const { current = [] } = localPackagesRef;
    const unpackedItem = unpackedItemsRef.current.find(item => item.itemId === itemId);
    const unpackedQty = _.get(unpackedItem, 'unpackedQty', 0);
    // Can only pack an item that is still unpacked
    if (qtyChange === 1 && unpackedQty === 0) {
      notification.error({
        message: `Item already completely packed.`,
      });
      return;
    }
    localPackagesRef.current = current.map((localPackage) => {
      return `${packageId}` === `${localPackage.id}` ? {
        ...localPackage,
        items: localPackage.items.map(item => {
          const nextPackedQty = item.packedQty + qtyChange;
          const qtyChangeAllowed = nextPackedQty >= 0 && nextPackedQty <= item.qty;
          return `${item.itemId}` === `${itemId}` && qtyChangeAllowed ? {
            ...item,
            packedQty: nextPackedQty,
            packingType,
          } : item;
        }),
      } : localPackage;
    });
    setLocalPackages(localPackagesRef.current);
    setLocalPackagesUpdated(Date.now());
  };

  useEffect(() => {
    const nextColumns = packageTableColumnsDef.map((column) => {
      if (column.key === 'actions') {
        return {
          ...column,
          render: (objectId, row) => {
            const { itemId, packageId, qty, packedQty, unpackedQty } = row;
            const addDisabled = qty === packedQty || !unpackedQty || !currentUserFulfills;
            const removeDisabled = packedQty === 0 || !currentUserFulfills;
            return createActionButtons(row, () => setPackedItemQtyInPackage(packageId, itemId, 1, 'manual'), () => setPackedItemQtyInPackage(packageId, itemId, -1, 'manual'), addDisabled, removeDisabled);
          },
        };
      }
      return column;
    });
    setPackageTableColumns(nextColumns);
  }, [setPackageTableColumns, currentUserFulfills]);

  const handleCreateDelivery = () => {
    refreshOrder();
    setDeliveryModalVisible(false);
  };

  const handleScanItem = (row) => {
    const { itemId, packageId, qty, packedQty, unpackedQty } = row;
    const addDisabled = qty === packedQty || !unpackedQty || !currentUserFulfills;
    if (!addDisabled) {
      setPackedItemQtyInPackage(packageId, itemId, 1, 'scan');
    }
  };

  return (
    <Col span={24}>
      <CardWrapper>
        <CardHeader onClick={() => setOpen(!open)}>
          <span>
            <h1>
              <CardHeaderIcon open={open}/> Shipping
                <OrderOriginTags order={order}/>
            </h1>
          </span>
          <PullRight>
            {unpackedItemsQty > 0 ? (
                <Tag color={colors.VOLCANO}>{unpackedItemsQty} Items pending</Tag>
            ) : (
                <Tag color={colors.GREEN}>All Items packaged</Tag>
            )}
          </PullRight>
        </CardHeader>
        {open ? (
            <DeliveryPackageContainer>
              {!currentUserFulfills && _.size(fulfillmentUsername) ? <TableOverlay /> : ''}

              <OrderPackageNotesOverlay order={order}/>

              {orderAndDeliveries.map((delivery, index) => {
                return (
                    <DeliveryWithPackagesItem
                        key={delivery.plentyId}
                        mainDeliveryId={plentyId}
                        orderId={orderId}
                        deliveryCount={_.size(deliveries)}
                        delivery={delivery}
                        joinedLineItems={joinedLineItems}
                        orderItemCount={orderItemCount}
                        notEU={notEU}
                        index={index}
                        localPackages={localPackages}
                        packages={packages}
                        unpackedItems={unpackedItems}
                        unpackedItemsRef={unpackedItemsRef}
                        localPackagesUpdated={localPackagesUpdated}
                        packageTableColumns={packageTableColumns}
                        onScanItem={handleScanItem}
                        scanningActivePackageId={scanningActivePackageId}
                        setScanningActivePackageId={setScanningActivePackageId}
                        packageOpenMap={packageOpenMap}
                        setPackageOpenMap={setPackageOpenMap}
                        createPackage={createPackage}
                        deletePackage={deletePackage}
                        saveAllPackages={saveAllPackages}
                        registerLabelsForDHL={registerLabelsForDHL}
                        resetLabelsForDHL={resetLabelsForDHL}
                        saveShippingProfile={saveShippingProfile}
                        refreshOrder={refreshOrder}
                        disabled={!currentUserFulfills && _.size(fulfillmentUsername)}
                        loading={loading}
                    />
                )
              })}

              {_.size(unpackedItems) > 0 ? (
                  <>
                    <DeliverySeparator/>
                    <CardFooter>
                      <div>
                        <h3>Unpacked items</h3>
                        {unpackedItems.map((item) => {
                          return <p key={item.itemId}>{item.unpackedQty} x {item.itemName} ({item.plentyId} {item.itemId})</p>
                        })}
                        <Button type='primary' onClick={() => setDeliveryModalVisible(true)}>Create new Order Delivery</Button>
                      </div>
                    </CardFooter>
                  </>
              ) : ''}
            </DeliveryPackageContainer>
        ) : null}
      </CardWrapper>

      <OrderDeliveryModal visible={deliveryModalVisible} onClose={() => setDeliveryModalVisible(false)} onSave={handleCreateDelivery} unallocatedItems={unallocatedItems} order={order}/>

    </Col>
  );
};
