import React, { useEffect, useState } from 'react';
import _ from "lodash";

import { Redirect } from 'react-router-dom';
import * as Parse from "parse";
import {notification} from "antd";

import { routes } from '../../lib/routes';
import { Container, ContentWrapper } from '../../components/Layout/Layout';
import {Loader} from "../../components/Loader/Loader";

export const OrderFulfillmentByPlentyId = ({ user, match, location }) => {

  const { pathname } = location;
  const plentyOrderId = _.last(pathname.split('/'));

  const [orderId, setOrderId] = useState();

  useEffect(() => {
    if (_.size(plentyOrderId)) {
      (async () => {
        try {
          const OrderFulfillment = Parse.Object.extend('OrderFulfillment');
          const orderFulfillmentQuery = new Parse.Query(OrderFulfillment);
          orderFulfillmentQuery.equalTo('plentyId', parseInt(plentyOrderId));
          const result = await orderFulfillmentQuery.first();
          const nextOrderId = result.get('orderId');
          setOrderId(nextOrderId);
        } catch (error) {
          console.warn('adminFetchOrderIdForPlentyId failed', error);
          notification.error({
            message: `Cannot find plenty order for ${plentyOrderId}.`,
          });
        }
      })()
    }
  }, [plentyOrderId]);

  if (orderId) {
    return <Redirect to={routes.ORDER_FULFILLMENT_DETAILS.replace(':orderId', orderId)} />
  }

  return (
    <Container>
      <ContentWrapper>
        <Loader/>
      </ContentWrapper>
    </Container>
  );
}
