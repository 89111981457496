import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import _ from 'lodash';
import * as Parse from 'parse';

import {Button, notification, Input, Row, Col, Checkbox, Avatar, Tag, Table} from 'antd';

import {
    CardCloseIcon,
    CardContent,
    CardFooter,
    CardHeader, CardOpenIcon,
    CardWrapper,
    Spacer
} from "../../components/Layout/Layout";
import {formatPrice, getSwLink} from "../../lib/utils";
import {routes} from "../../lib/routes";
import {AdminClassEntryList} from "../../components/AdminClassEntryList/AdminClassEntryList";
import {AdminClassEntryEdit} from "../../components/AdminClassEntryEdit/AdminClassEntryEdit";

export const CardHeaderIcon = ({ open }) => {
    return open ? <CardCloseIcon/> : <CardOpenIcon/>
};

const affiliateCodeTableConfig = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
    },
    {
        title: 'Discount Type',
        dataIndex: 'discountType',
        key: 'discountType',
    },
    {
        title: 'Discount Value',
        dataIndex: 'discountValue',
        key: 'discountValue',
    },
    {
        title: 'Provision Type',
        dataIndex: 'provisionType',
        key: 'provisionType',
    },
    {
        title: 'Provision Value',
        dataIndex: 'provisionValue',
        key: 'provisionValue',
    },
    {
        title: 'SW Promotion Id',
        dataIndex: 'promotionId',
        key: 'promotionId',
        render: (promotionId) => {
            return (
                <a href={getSwLink('/sw/promotion/v2/detail/', promotionId)} target='_blank'>{promotionId}</a>
            );
        }
    },
];

const affiliateProperties = [
    {
        key: 'userId',
        label: 'User Id',
        type: 'string',
        required: true,
        disabled: true,
        extra: 'Shopware Customer Id',
    },
    {
        key: 'name',
        label: 'Name',
        type: 'string',
        required: true,
        extra: '',
    },
    {
        key: 'description',
        label: 'Description',
        type: 'text',
        required: false,
        extra: '',
    },
    {
        key: 'imageUrl',
        label: 'Image',
        type: 'image',
        required: false,
        extra: '',
    },
    {
        key: 'logoUrl',
        label: 'Logo',
        type: 'image',
        required: false,
        extra: '',
    },
    {
        key: 'active',
        label: 'Is Active?',
        type: 'checkbox',
        required: false,
        extra: '',
    }
];

const affiliateCodeProperties = [
    {
        key: 'code',
        label: 'Code',
        type: 'string',
        required: true,
        extra: '',
    },
    {
        key: 'discountType',
        label: 'Discount Type',
        type: 'radio',
        required: true,
        extra: '',
        options: [
            { value: 'percentage', label: 'Percentage' },
            { value: 'absolute', label: 'Absolute' },
            { value: 'none', label: 'None / Link' },
        ],
    },
    {
        key: 'discountValue',
        label: 'Discount Value',
        type: 'number',
    },
    {
        key: 'provisionType',
        label: 'Provision Type',
        type: 'radio',
        required: true,
        extra: '',
        options: [
            { value: 'percentage', label: 'Percentage' },
            { value: 'absolute', label: 'Absolute' },
        ],
    },
    {
        key: 'provisionValue',
        label: 'Provision Value',
        type: 'number',
    },
    {
        key: 'affiliate',
        label: 'Affiliate',
        type: 'pointer',
        pointerClass: 'Affiliate',
        required: false,
        disabled: true,
        extra: '',
    },
    {
        key: 'active',
        label: 'Is Active?',
        type: 'checkbox',
        required: false,
        extra: '',
    },
];

const affiliateLinkColumnConfig = [
    {
        title: 'Id',
        dataIndex: 'objectId',
        key: 'objectId',
    },
    {
        title: 'Url',
        dataIndex: 'url',
        key: 'url',
        render: (url) => (
            <a href={url} target='_blank'>{url}</a>
        )
    },
    {
        title: 'Target Url',
        dataIndex: 'targetUrl',
        key: 'targetUrl',
        render: (targetUrl) => (
            <a href={targetUrl} target='_blank'>{targetUrl}</a>
        )
    },
    {
        title: 'Visit Count',
        dataIndex: 'visitCount',
        key: 'visitCount',
    },
];

const affiliateLinkFormProperties = [
    {
        key: 'targetUrl',
        label: 'Target Url',
        type: 'string',
        required: true,
        extra: '',
    },
];

export const CustomerAffiliate = ({user, authenticated, history, activeCustomer }) => {
    const [loading, setLoading] = useState(false);
    const [showCodeForm, setShowCodeForm] = useState(false);
    const [showLinkForm, setShowLinkForm] = useState(false);

    const { location: { pathname = '' } } = history;
    const customerId = _.get(activeCustomer, 'id', '');

    const [affiliate, setAffiliate] = useState(null);
    useEffect(() => {
        if (!_.size(customerId)) {
            return;
        }
        (async () => {
            setLoading(true);
            try {
                const ClassName = Parse.Object.extend('Affiliate');
                const query = new Parse.Query(ClassName);
                query.equalTo('userId', activeCustomer.id);
                const result = await query.first();
                if (!result) {
                    setAffiliate({});
                } else {
                    setAffiliate(result.toJSON());
                }
            } catch (error) {
                console.warn('FetchAffiliate', error);
            }
            setLoading(false);
        })();
    }, [customerId]);

    const [affiliateCodes, setAffiliateCodes] = useState([]);
    const [affiliateLinks, setAffiliateLinks] = useState([]);
    useEffect(() => {
        if (!_.size(affiliateCodes)) {
            return;
        }
        (async () => {
            setLoading(true);
            try {
                const ShortLink = Parse.Object.extend('ShortLink');
                const shortLinkQueries = affiliateCodes.map((affiliateCode) => {
                    const { code } = affiliateCode;
                    const shortLinkQuery = new Parse.Query(ShortLink);
                    shortLinkQuery.contains('targetUrl', code);
                    return shortLinkQuery;
                });
                const shortLinks = await Parse.Query.or.apply(this, shortLinkQueries).find();
                const shortLinksWithUrl = shortLinks.map(s => s.toJSON()).map(s => {
                    const url = `https://mpmpdr.com/farbe/${s.objectId}`;
                    return {...s, url};
                })
                setAffiliateLinks(shortLinksWithUrl);
            } catch (error) {
                console.warn('FetchAffiliateLinks', error);
            }
            setLoading(false);
        })();
    }, [affiliateCodes]);

    const handleAffiliateCodeData = useCallback((data) => {
        setAffiliateCodes(data);
    }, []);

    if (!activeCustomer) {
        return null;
    }

    return (
        <>
            <Spacer/>
          <CardWrapper>
              <CardHeader><h2>Affiliate</h2></CardHeader>
              <CardContent>
                  <Row gutter={[16, 16]}>
                      <Col md={8} sm={24}>
                          <h1>{formatPrice(0)}</h1>
                          <p>Provision earned</p>
                      </Col>
                      <Col md={8} sm={24}>
                          <h1>{formatPrice(0)}</h1>
                          <p>Discount given</p>
                      </Col>
                      <Col md={8} sm={24}>
                          <h1>{formatPrice(0)}</h1>
                          <p>Orders referred</p>
                      </Col>
                  </Row>
              </CardContent>
          </CardWrapper>
          <Spacer/>
          {affiliate ? (
              <AdminClassEntryEdit
                  user={user}
                  history={history}
                  title={'Affiliate'}
                  className={'Affiliate'}
                  editRoute={routes.ADMIN_CUSTOMER_SEARCH_DETAIL.replace(':id', customerId)}
                  listRoute={routes.ADMIN_CUSTOMER_SEARCH_DETAIL}
                  properties={affiliateProperties}
                  data={{...affiliate, userId: _.get(activeCustomer, 'id')}}
                  disableNavigation={true}
                  autoLoad={false}
              />
          ) : null}
          {affiliate && affiliate.objectId ? (
              <>
                  <CardWrapper>
                      <CardHeader onClick={() => setShowCodeForm(!showCodeForm)}>
                          <h3><CardHeaderIcon open={showCodeForm}/> Manage Affiliate Codes</h3>
                      </CardHeader>
                      {showCodeForm ? (
                          <AdminClassEntryEdit
                              user={user}
                              history={history}
                              title={'Affiliate Code'}
                              className={'AffiliateCode'}
                              editRoute={routes.ADMIN_CUSTOMER_SEARCH_DETAIL.replace(':id', customerId)}
                              listRoute={routes.ADMIN_CUSTOMER_SEARCH_DETAIL}
                              properties={affiliateCodeProperties}
                              data={{ affiliate: { id: affiliate.objectId } }}
                              disableNavigation={true}
                              autoLoad={false}
                          />
                      ) : null}
                      <AdminClassEntryList
                          editable={false}
                          className={'AffiliateCode'}
                          filters={[{ field: 'affiliate', value: affiliate.objectId, type: 'Affiliate'}]}
                          editRoute={'/'}
                          tableConfig={affiliateCodeTableConfig}
                          onData={handleAffiliateCodeData}
                      />
                  </CardWrapper>
              </>
          ) : null}
          <Spacer/>
          {affiliate && affiliateLinks ? (
              <>
                  <CardWrapper>
                      <CardHeader>
                          <h3>Affiliate Links</h3>
                      </CardHeader>
                      <CardHeader onClick={() => setShowLinkForm(!showLinkForm)}>
                          <h3><CardHeaderIcon open={showLinkForm}/> Manage Affiliate Links</h3>
                      </CardHeader>
                      {showLinkForm ? (
                          <AdminClassEntryEdit
                              user={user}
                              history={history}
                              title={'Affiliate Link'}
                              className={'ShortLink'}
                              editRoute={routes.ADMIN_CUSTOMER_SEARCH_DETAIL.replace(':id', customerId)}
                              listRoute={routes.ADMIN_CUSTOMER_SEARCH_DETAIL}
                              properties={affiliateLinkFormProperties}
                              data={{ targetUrl: 'https://www.misspompadour.de/?affiliateCode=ENTER_THE_CODE_HERE' }}
                              disableNavigation={true}
                              autoLoad={false}
                          />
                      ) : null}
                      <Table
                          columns={affiliateLinkColumnConfig}
                          dataSource={affiliateLinks}
                          rowKey='objectId'
                          pagination={{
                              pageSize: 100,
                          }}
                      />
                  </CardWrapper>
              </>
          ) : null}
          <Spacer/>
          <CardWrapper>
              <CardHeader><h3>By Discount</h3></CardHeader>
              <CardContent>
                  <Row gutter={[16, 16]}>
                      <Col md={8} sm={24}>
                          <h1>{formatPrice(0)}</h1>
                          <p>Discount given</p>
                      </Col>
                      <Col md={8} sm={24}>
                          <h1>{formatPrice(0)}</h1>
                          <p>Orders referred</p>
                      </Col>
                  </Row>
              </CardContent>
              <CardFooter/>
              <CardHeader><h3>By Link</h3></CardHeader>
              <CardContent>
                  <Row gutter={[16, 16]}>
                      <Col md={6} sm={24}>
                          <h1>{formatPrice(0)}</h1>
                          <p>Provision earned</p>
                      </Col>
                      <Col md={6} sm={24}>
                          <h1>{formatPrice(0)}</h1>
                          <p>Total Visits</p>
                      </Col>
                      <Col md={6} sm={24}>
                          <h1>{formatPrice(0)}</h1>
                          <p>Orders referred</p>
                      </Col>
                      <Col md={6} sm={24}>
                          <h1>{2}</h1>
                          <p>Orders per Visit</p>
                      </Col>
                  </Row>
              </CardContent>
          </CardWrapper>
        </>
    );
};
