import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { UI, colors } from '../../lib/theme';
import { routes } from '../../lib/routes';
import { getOrderFulfillmentStatus } from '../../lib/utils';
import _ from 'lodash';

import { useActiveOrderFulfillment } from '../../hooks/useActiveOrderFulfillment';

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  padding: 0 5px 5px;
  z-index: 1000;
`;
const OrderContainer = styled.div`
  padding: 0;
  border-left: 8px solid ${({ border }) => border || colors.WHITE};
  background-color: ${({ background }) => background || colors.WHITE};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  display: inline-block;
  width: 78px;
  margin: 5px 5px 0 0;
  border-radius: 4px;
  padding: 8px;
  transform: scale(0.9);
`;
const OrderIdLabel = styled.h5`
  color: ${({ color }) => color || colors.TRUE_BLACK};
  font-weight: 700;
  margin-bottom: 0;
`;

export const ActiveOrderFulfillments = (props) => {
  const { user, history } = props;
  const { orders = [], activeOrder } = useActiveOrderFulfillment({ user });

  const { pathname } = window.location;

  const getDetailsPage = useCallback(
    (orderId) => {
      // This makes sure that the back button gets you to the correct overview
      if (pathname.includes(routes.ORDER_OVERVIEW)) {
        return routes.ORDER_OVERVIEW_DETAILS.replace(':orderId', orderId);
      } else if (pathname.includes(routes.ORDER_EXTERNAL)) {
        return routes.ORDER_EXTERNAL_DETAILS.replace(':orderId', orderId);
      } else if (pathname.includes(routes.ORDER_PRODUCTION)) {
        return routes.ORDER_PRODUCTION_DETAILS.replace(':orderId', orderId);
      } else if (pathname.includes(routes.ORDER_COLOR_CARDS)) {
        return routes.ORDER_COLOR_CARDS_DETAILS.replace(':orderId', orderId);
      } else if (pathname.includes(routes.ORDER_BOUNCED)) {
        return routes.ORDER_BOUNCED_DETAILS.replace(':orderId', orderId);
      }else if (pathname.includes(routes.ORDER_MARKETPLACE)) {
        return routes.ORDER_MARKETPLACE_DETAILS.replace(':orderId', orderId);
      }
      return routes.ORDER_FULFILLMENT_DETAILS.replace(':orderId', orderId);
    },
    [pathname],
  );

  const isFulfillment = pathname.includes('fulfillment');

  const getBatchPage = () => {
    return routes.ORDER_FULFILLMENT_BATCH_DETAILS;
  };

  const sortedOrders = _.orderBy(
    _.uniqBy(orders, function (e) {
      return e.orderId;
    }),
    ['orderId'],
    ['asc'],
  );

  return (
    <Container>
      {sortedOrders.map((fulfillment) => {
        const orderFulfillmentStatus = getOrderFulfillmentStatus(fulfillment);
        return (
          <Link key={fulfillment.objectId} to={getDetailsPage(fulfillment.orderId)}>
            <OrderContainer border={orderFulfillmentStatus ? orderFulfillmentStatus.color : undefined}>
              <OrderIdLabel>{fulfillment.orderId}</OrderIdLabel>
            </OrderContainer>
          </Link>
        );
      })}
      {_.size(sortedOrders) > 0 && isFulfillment ? (
        <Link to={getBatchPage()}>
          <OrderContainer border={colors.PRIMARY} background={colors.PRIMARY}>
            <OrderIdLabel color={colors.WHITE}>{_.size(sortedOrders)} BATCH</OrderIdLabel>
          </OrderContainer>
        </Link>
      ) : null}
    </Container>
  );
};
