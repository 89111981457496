import React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../lib/theme';
import _ from 'lodash';

import { Progress, Button } from 'antd';
import { useActiveJobLogs } from '../../hooks/useActiveJobLogs';

const Container = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  padding: 16px;
  z-index: 1000;
  max-height: 100vh;
  overflow-y: auto;
  width: auto;
`;
const JobContainer = styled.div`
  padding: 0;
  background-color: ${({ background }) => background || colors.WHITE};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  margin: 5px 5px 0 0;
  border-radius: 4px;
  padding: 8px;
`;
const ActionContainer = styled.div`
  margin-left: 15px;
`;
const TitleLabel = styled.h4`
  color: ${colors.TRUE_BLACK};
  font-weight: 600;
  margin-bottom: 15px;
`;

export const ActiveJobLogs = (props) => {
  const { user, history } = props;
  const { jobLogs, cancelById: cancelJobLog } = useActiveJobLogs({ user });
  const activeJobs = useMemo(() => {
    return _.uniqBy(jobLogs, (e) => {
      return e.objectId;
    });
  }, [jobLogs]);

  if (!_.size(activeJobs)) {
    return null;
  }

  return (
    <Container>
      {activeJobs.map((jobLog) => {
        const progress = Math.floor((100 * jobLog.currentProcess) / jobLog.processCount);
        return (
          <JobContainer key={jobLog.objectId}>
            <Progress type='circle' percent={progress} width={40} />
            <ActionContainer>
              <TitleLabel>{jobLog.description}</TitleLabel>
              <Button type='danger' onClick={() => cancelJobLog(jobLog.objectId)}>Cancel</Button>
            </ActionContainer>
          </JobContainer>
        );
      })}
    </Container>
  );
};
