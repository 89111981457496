import React, { useMemo } from 'react';
import styled from 'styled-components';
import { UI, colors as themeColors } from '../../lib/theme';

import _ from 'lodash';
import { Col, Row, Tag, Button } from 'antd';
import { CheckCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { PullRight, Spacer } from '../Layout/Layout';
import { formatPrice, getLineItemFulfillmentStatus } from '../../lib/utils';
import { WarehouseStockChip } from '../WarehouseStockChip/WarehouseStockChip';
import {GtinScannerInput} from "../GtinScannerInput/GtinScannerInput";

const Container = styled.div`
  position: relative;
`;
const ItemRow = styled(Row)`
  position: relative;
  padding: 15px;
  border-top: 15px solid ${themeColors.TRUE_BLACK10};
`;
const ItemOrderRow = styled(Row)`
  position: relative;
  border-left: 5px solid ${({ color }) => color || themeColors.TRUE_BLACK20};
  border-top: 1px solid ${themeColors.TRUE_BLACK10};

  & > div {
    padding: 15px;
  }
`;

const FulfilledOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
`;
const FulfilledIcon = styled(CheckCircleOutlined).attrs({})`
  color: ${themeColors.GREEN};
  font-size: 2.5rem;
`;
const ItemImage = styled.img.attrs({})`
  width: 100px;
  height: auto;
  max-width: 100%;
  border: 1px solid rgba(0,0,0,0.05);
  background-color: ${themeColors.VERY_LIGHT_GREY};
  border-radius: 3px;
  aspect-ratio: 1;
`;
const BundleItemRow = styled(Row)`
  position: relative;
  border-top: 1px solid ${themeColors.TRUE_BLACK10};
  padding: 15px;
`;
const ItemNameTitle = styled.h3`
    margin: 0 0 10px;
    padding: 0;
    font-size: 24px;
    font-weight: 600;
`;
const BoxNumberLabel = styled.h3`
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 600;
`;
const BoxOrderIdTag = styled(Tag)`
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
`;
const QuantityLabel = styled.h3`
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 600;
`;

export const BatchOrderFulfillmentTable = ({
  orders,
  colors,
  lineItems,
  onAddItem,
  onRemoveItem,
}) => {
  const sortedLineItemsWithMeta = useMemo(() => {
      const lineItemsWithOrderIndex = (lineItems || []).map((lineItem) => {
          const orderIndex = (orders || []).findIndex((order) => order && `${order.id}` === `${lineItem.orderId}`);
          const { attributedItemName, location, isExternalItem } = lineItem;
          // External Items should appear at the top
          return {
              ...lineItem,
              orderIndex,
              sortString: `${isExternalItem ? 'AAAAA' : ''}${location} - ${attributedItemName}`,
          };
      });
      return _.orderBy(lineItemsWithOrderIndex, ['sortString'], ['asc'])
  }, [lineItems]);
  // Sort items by warehouse location & name
  const groupedLineItems = Object.values(_.groupBy(sortedLineItemsWithMeta, 'attributedItemName'));
  const sortedLineItemsByName = _.orderBy(sortedLineItemsWithMeta, ['itemName', 'orderIndex'], ['asc']);
  return (
    <Container>
      <GtinScannerInput
          onAdd={onAddItem}
          lineItems={sortedLineItemsByName}
          gtinKey='warehouseStock.plentyProduct.gtin'
          baseGtinKey='warehouseStock.plentyProduct.baseGtin'
          qtyCheckEnabled={true}
          qtyCheckItemKey='qtyFulfilled'
      />
      {groupedLineItems.map((lineItemGroup) => {
        const {
          attributedItemName,
          itemName,
          itemId,
          sku,
          lineTotal,
          lineTax,
          qty,
          paFarbvarianten,
          paProdukt,
          paFarbmenge,
          location,
          onStockClick,
          warehouseStock,
          plentyVariationId,
          bundleItems,
          isExternalItem,
        } = _.first(lineItemGroup);
        const imageUrl = _.get(warehouseStock, 'plentyProduct.imageUrl');
        const gtin = _.get(warehouseStock, 'plentyProduct.gtin', '');
        const baseGtin = _.get(warehouseStock, 'plentyProduct.baseGtin', '');
        const itemPrice = (parseFloat(lineTotal) + parseFloat(lineTax)) / qty;
        const itemSum = lineItemGroup.reduce((sum, item) => sum + (item.qty || 0), 0);
        const itemFulfilledSum = lineItemGroup.reduce((sum, item) => sum + (item.qtyFulfilled || 0), 0);
        const attributes = [paFarbvarianten, paProdukt, paFarbmenge].filter((a) => _.size(a) > 0);
        const backgroundColor = isExternalItem ? themeColors.PRIMARY_LIGHT : themeColors.WHITE;
        return (
          <div key={attributedItemName} style={{ backgroundColor }}>
            <ItemRow>
              <Col span={4}>
                <ItemImage src={imageUrl}/>
              </Col>
              <Col span={14}>
                <ItemNameTitle>
                  {itemName} (PID:{plentyVariationId})
                  <small>{_.size(attributes) > 0 ? ` (${attributes.join(',')})` : ''}</small>
                </ItemNameTitle>{' '}
                - {formatPrice(itemPrice)} [{sku}]
                <br />
                Warehouse: {_.get(warehouseStock, 'warehouse.name', '')} - {location || 'No Location'}
                <br />
                GTIN: {gtin.length ? gtin : '-'} {_.size(baseGtin) ? `(Base: ${baseGtin})` : ''}
              </Col>
              <Col span={6}>
                <PullRight>
                  <strong>
                    {itemFulfilledSum} / {itemSum}
                  </strong>
                  <br />
                  <WarehouseStockChip warehouseStock={warehouseStock} onClick={onStockClick} />
                </PullRight>
              </Col>
            </ItemRow>
            {_.size(bundleItems) ? (
                <BundleItemRow>
                  <strong>Includes</strong>:{' '}{bundleItems.map(item => <Tag key={item.itemId}>{item.itemName}</Tag>)}
                </BundleItemRow>
            ) : null}
            {_.orderBy(lineItemGroup, ['orderIndex'], ['asc']).map((lineItem) => {
              const { itemId, qty, qtyFulfilled, orderIndex } = lineItem;
              const removeDisabled = qty === 0;
              const addDisabled = qty === qtyFulfilled;
              const { color, title } = getLineItemFulfillmentStatus(lineItem);
              if (orderIndex < 0) {
                return (
                  <ItemOrderRow key={itemId}>
                    <Col md={24} xs={24}>
                      This item is not part of the current 8 order batch. Unclaim order #{lineItem.orderId} or wait to
                      pack this item with the next batch.
                    </Col>
                  </ItemOrderRow>
                );
              }
              return (
                <ItemOrderRow color={colors[orderIndex]} key={itemId}>
                  <Col md={16} xs={24}>
                    <BoxOrderIdTag color={colors[orderIndex]}>Order #{lineItem.orderId}</BoxOrderIdTag>{' '}
                    <BoxOrderIdTag color={color}>{title}</BoxOrderIdTag>
                    <BoxNumberLabel>Box {orderIndex + 1}</BoxNumberLabel>
                  </Col>
                  <Col span={4} xs={12}>
                    <QuantityLabel>
                      {qtyFulfilled} / {qty}
                    </QuantityLabel>
                  </Col>
                  <Col span={4} xs={12}>
                    <PullRight>
                      <Button
                        type='danger'
                        onClick={() => onRemoveItem(lineItem, 'manual')}
                        disabled={removeDisabled}
                        icon={<MinusOutlined />}
                      />
                      <Spacer />
                      <Button
                        type='primary'
                        onClick={() => onAddItem(lineItem, 'manual')}
                        disabled={addDisabled}
                        icon={<PlusOutlined />}
                      />
                    </PullRight>
                  </Col>
                  {addDisabled ? (
                    <FulfilledOverlay>
                      <FulfilledIcon />
                    </FulfilledOverlay>
                  ) : null}
                </ItemOrderRow>
              );
            })}
          </div>
        );
      })}
    </Container>
  );
};
