import { useState, useEffect, useCallback } from 'react';

export const usePersistedState = (key, value) => {
  const [state, setState] = useState(value);
  // Do not allow any writing until initial state is read successfully
  const [preventWrite, setPreventWrite] = useState(true);

  const { localStorage } = window;

  // Get initial value
  useEffect(() => {
    (async () => {
      try {
        const stringValue = localStorage.getItem(key);
        if (!stringValue) {
          setPreventWrite(false);
          return;
        }
        const storedValue = JSON.parse(stringValue);
        if (storedValue) {
          setState(storedValue);
        }
        // Unblock writing after local state is restored
        setPreventWrite(false);
      } catch (error) {
        // Unblock writing
        setPreventWrite(false);
        // eslint-disable-next-line no-console
        console.warn('usePersistedState get failed', error);
      }
    })();
  }, []);

  const writeState = useCallback(
    async (stateToWrite) => {
      if (preventWrite) {
        // Dont allow writing while initial state is not available yet
        return;
      }
      try {
        localStorage.setItem(key, JSON.stringify(stateToWrite));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('usePersistedState set failed', error);
      }
    },
    [key, preventWrite],
  );

  useEffect(() => {
    if (!preventWrite) {
      // Write prevention just cleared - write whatever is in the local state
      // writeState(state);
    }
  }, [preventWrite]);

  // Set value when updated
  useEffect(() => {
    writeState(state);
  }, [key, state]);

  return [state, setState, !preventWrite];
};
