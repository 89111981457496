import React, { useState } from 'react';
import _ from 'lodash';
import { routes } from '../../lib/routes';
import { Button, Col, Input, notification, Row } from 'antd';

import { Container, ContentWrapper } from '../../components/Layout/Layout';
import { AdminClassEntryEdit } from '../../components/AdminClassEntryEdit/AdminClassEntryEdit';

const properties = [
  {
    key: 'title',
    label: 'Title',
    type: 'string',
    required: true,
  },
  {
    key: 'videoId',
    label: 'Video Id',
    type: 'string',
    required: true,
  },
  {
    key: 'url',
    label: 'Video Url',
    type: 'string',
    required: true,
  },
  {
    key: 'description',
    label: 'Video Description',
    type: 'text',
    required: false
  },
  {
    key: 'lengthInSeconds',
    label: 'Video Length in Seconds',
    type: 'number',
    required: false
  },
  {
    key: 'popularityIndex',
    label: 'Popularity Index',
    type: 'number',
    required: false
  },
  {
    key: 'publishedAt',
    label: 'Published At',
    type: 'date',
    required: false
  },
  {
    key: 'previewImageUrl',
    label: 'Custom Preview Image',
    type: 'image',
    required: false,
  },
  {
    key: 'image',
    label: 'Youtube Image Url',
    type: 'string',
    required: false,
  },
];

export const YoutubeVideosEdit = (props) => {
  const { user, authenticated, match = {}, history } = props;
  const {
    location: { pathname = '' },
  } = history;
  const templateId = _.last(pathname.split('/'));

  return (
    <Container>
      <ContentWrapper>
        <AdminClassEntryEdit
          user={user}
          history={history}
          title={'Youtube Video'}
          className={'YoutubeVideo'}
          editRoute={routes.ADMIN_YOUTUBE_VIDEOS_EDIT}
          listRoute={routes.ADMIN_YOUTUBE_VIDEOS}
          properties={properties}
          FooterComponent={null}
        />
      </ContentWrapper>
    </Container>
  );
};
