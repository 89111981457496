import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import * as Parse from 'parse';
import { Link } from 'react-router-dom';
import moment from "moment/moment";

import { Spacer, CardWrapper, CardContent } from '../../components/Layout/Layout';
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  notification,
  Checkbox,
  Radio,
  InputNumber,
  message,
  Upload,
  Modal, DatePicker,
} from 'antd';
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import { Loader } from '../../components/Loader/Loader';
import {colors} from "../../lib/theme";

const { confirm } = Modal;

const Container = styled.div`
  position: relative;
`;
const PreviewImage = styled.img`
  position: relative;
  width: 200px;
  height: auto;
  margin: 0 20px 20px 0;
  object-fit: cover;
  display: block;
  background-color: ${colors.VERY_LIGHT_GREY};
  border-radius: 4px;
  overflow: hidden;
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 20 },
  },
  colon: false,
};

export const DataForm = ({ user, form, data = {}, onSubmit, loading, properties = [], title, isNew }) => {
  const [forceRender, setForceRender] = useState(0);
  const uploadedImageUrlsRef = useRef({});

  const initialValues = useMemo(() => {
    const nextData = { ...data };
    _.each(properties, (property) => {
      const { type, key } = property;
      try {
        if (['jsonObject', 'jsonArray'].includes(type) && data[key]) {
          nextData[key] = JSON.stringify(data[key], null, 2);
        } else if (['textJson'].includes(type) && data[key]) {
          nextData[key] = JSON.stringify(JSON.parse(data[key]), null, 2);
        } else if (type === 'image') {
          uploadedImageUrlsRef.current[key] = data[key] || '';
        } else if (type === 'date') {
          if (_.isObject(data[key])) {
            nextData[key] = _.size(data[key].iso) ? moment(data[key].iso) : undefined;
          }
        } else if (type === 'datetime') {
          if (_.isObject(data[key])) {
            nextData[key] = _.size(data[key].iso) ? moment(data[key].iso) : undefined;
          }
        } else if (type === 'pointer') {
          if (_.isObject(data[key])) {
            nextData[key] = data[key].id;
          }
        }
      } catch (error) {
        uploadedImageUrlsRef.current[key] = data[key] || '';
      }
    });
    return nextData;
  }, [data]);

  const handleOptionChange = () => setForceRender(Date.now());

  const handleSubmit = async (event) => {
    try {
      const values = await form.validateFields();
      // Check for json fields
      _.each(properties, (property) => {
        const { type, key } = property;
        if (type === 'jsonObject') {
          if (_.size(values[key])) {
            const json = JSON.parse(values[key]);
            if (values[key] && !_.isObject(json)) {
              throw new Error('Wrong JSON input, no JSON object.');
            }
            values[key] = json;
          } else {
            values[key] = {};
          }
        } else if (type === 'jsonArray') {
          if (_.size(values[key])) {
            const json = JSON.parse(values[key]);
            if (!_.isArray(json)) {
              throw new Error('Wrong JSON input, no JSON array.');
            }
            values[key] = json;
          } else {
            values[key] = [];
          }
        } else if (type === 'textJson') {
          if (_.size(values[key])) {
            // Will throw error if not a proper json
            const jsonString = JSON.stringify(JSON.parse(values[key]));
            values[key] = jsonString;
          } else {
            values[key] = [];
          }
        } else if (type === 'image') {
          values[key] = uploadedImageUrlsRef.current[key] || '-';
        } else if (type === 'date') {
          if (_.size(values[key])) {
            values[key] = new Date(values[key]);
          }
        } else if (type === 'datetime') {
          if (_.size(values[key])) {
            values[key] = new Date(values[key]);
          }
        }
      });
      onSubmit(values);
    } catch (error) {
      console.warn('AdminClassEntryEdit: Form failed', error);
      notification.error({
        message: 'Cannot save entry',
        description: 'Please review your inputs.',
      });
    }
  };

  const { serverURL } = Parse;

  const uploadProps = useMemo(() => {
    return {
      name: 'imageUpload',
      action: `${serverURL}/admin/image-cdn/upload`,
      headers: {
        'X-User-Id': user.objectId,
        Authorization: user.sessionToken,
      },
      data: {},
      multiple: true,
    };
  }, [user]);

  const onUploadChange = useCallback((property) => {
    return (info) => {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        const {
          file: { response },
        } = info;
        console.log('info', info);
        uploadedImageUrlsRef.current[property] = response.imageUrl;
        setForceRender(forceRender + 1);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    };
  }, []);

  const handleImageDelete = async (property) => {
    confirm({
      content: 'Do you want to delete this image?',
      onOk() {
        (async () => {
          try {
            const imageUrl = uploadedImageUrlsRef.current[property] || data[property];
            await Parse.Cloud.run('adminDeleteCdnImages', { paths: [imageUrl] });
            notification.success({
              message: 'Image deleted',
            });
            uploadedImageUrlsRef.current[property] = '';
            setForceRender(forceRender + 1);
            handleSubmit();
          } catch (error) {
            console.warn('Delete Image failed', error);
            notification.error({
              message: 'Image delete failed',
            });
          }
        })();
      },
      onCancel() {},
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Form {...formItemLayout} form={form} onFinish={handleSubmit} initialValues={initialValues}>
      <Row gutter={16}>
        <Col span={24}>
          {!isNew ? (
            <>
              <Form.Item name='objectId' label='ObjectId'>
                <Input disabled />
              </Form.Item>
              <Spacer />
              <Form.Item name='createdAt' label='Created'>
                <Input disabled />
              </Form.Item>
              <Form.Item name='updatedAt' label='Updated'>
                <Input disabled />
              </Form.Item>
              <Spacer />
            </>
          ) : null}
          {properties.map(({ key, label, extra, type, required, options = [], disabled, placeholder }) => {
            switch (type) {
              case 'checkbox':
                return (
                  <Form.Item
                    key={key}
                    name={key}
                    label={label}
                    rules={[{ required, message: `'Please give it a ${label}!'` }]}
                    extra={_.size(extra) ? <small>{extra}</small> : ''}
                    valuePropName='checked'
                  >
                    <Checkbox style={{ lineHeight: '32px' }} disabled={disabled} />
                  </Form.Item>
                );
              case 'radio':
                return (
                  <Form.Item
                    key={key}
                    name={key}
                    label={label}
                    rules={[{ required, message: `'Please give it a ${label}!'` }]}
                    extra={_.size(extra) ? <small>{extra}</small> : ''}
                  >
                    <Radio.Group onChange={handleOptionChange} disabled={disabled}>
                      {options.map(({ value: optionValue, label: optionLabel }) => {
                        return (
                          <Radio.Button key={optionValue} value={optionValue}>
                            {optionLabel}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </Form.Item>
                );
              case 'text':
                return (
                    <Form.Item
                        key={key}
                        name={key}
                        label={label}
                        rules={[{ required, message: `'Please give it a ${label}!'` }]}
                        extra={_.size(extra) ? <small>{extra}</small> : ''}
                    >
                      <Input.TextArea rows={10} disabled={disabled} placeholder={placeholder} />
                    </Form.Item>
                );
              case 'textJson':
                return (
                    <Form.Item
                        key={key}
                        name={key}
                        label={label}
                        rules={[{ required, message: `'Please give it a ${label}!'` }]}
                        extra={_.size(extra) ? <small>{extra}</small> : ''}
                    >
                      <Input.TextArea rows={30} disabled={disabled} placeholder={placeholder} />
                    </Form.Item>
                );
              case 'number':
                return (
                  <Form.Item
                    key={key}
                    name={key}
                    label={label}
                    rules={[{ required, message: `'Please give it a ${label}!'` }]}
                    extra={_.size(extra) ? <small>{extra}</small> : ''}
                  >
                    <InputNumber disabled={disabled} placeholder={placeholder} />
                  </Form.Item>
                );
              case 'image':
                return (
                  <Form.Item
                    key={key}
                    name={key}
                    label={label}
                    rules={[{ required, message: `'Please upload a ${label}!'` }]}
                    extra={_.size(extra) ? <small>{extra}</small> : ''}
                  >
                    <PreviewImage src={uploadedImageUrlsRef.current[key]} />
                    <Input value={uploadedImageUrlsRef.current[key]} disabled />

                    {_.size(uploadedImageUrlsRef.current[key]) ? (
                      <Button type='danger' onClick={() => handleImageDelete(key)}>
                        Delete image
                      </Button>
                    ) : (
                      ''
                    )}

                    <Upload {...uploadProps} onChange={onUploadChange(key)}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </Form.Item>
                );
              case 'jsonObject':
                return (
                  <Form.Item
                    key={key}
                    name={key}
                    label={label}
                    rules={[{ required, message: `'Please give it a ${label}!'` }]}
                    extra={_.size(extra) ? <small>{extra}</small> : ''}
                  >
                    <Input.TextArea rows={10} disabled={disabled} />
                  </Form.Item>
                );
              case 'jsonArray':
                return (
                    <Form.Item
                        key={key}
                        name={key}
                        label={label}
                        rules={[{ required, message: `'Please give it a ${label}!'` }]}
                        extra={_.size(extra) ? <small>{extra}</small> : ''}
                    >
                      <Input.TextArea rows={10} disabled={disabled} />
                    </Form.Item>
                );
              case 'pointer':
                return (
                    <Form.Item
                        key={key}
                        name={key}
                        label={label}
                        rules={[{ required, message: `'Please give it a ${label}!'` }]}
                        extra={_.size(extra) ? <small>{extra}</small> : ''}
                    >
                      <Input disabled={disabled} placeholder={placeholder} />
                    </Form.Item>
                );
              case 'date':
                return (
                    <Form.Item
                        key={key}
                        name={key}
                        label={label}
                        rules={[{ required, message: `'Please give it a ${label}!'` }]}
                        extra={_.size(extra) ? <small>{extra}</small> : ''}
                    >
                      <Input disabled={disabled} placeholder={placeholder} />
                    </Form.Item>
                );
              case 'datetime':
                return (
                    <Form.Item
                        key={key}
                        name={key}
                        label={label}
                        rules={[{ required, message: `'Please give it a ${label}!'` }]}
                        extra={_.size(extra) ? <small>{extra}</small> : ''}
                    >
                      <DatePicker showTime disabled={disabled} />
                    </Form.Item>
                );
              default:
                return (
                  <Form.Item
                    key={key}
                    name={key}
                    label={label}
                    rules={[{ required, message: `'Please give it a ${label}!'` }]}
                    extra={_.size(extra) ? <small>{extra}</small> : ''}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                );
            }
          })}
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type='primary' htmlType='submit' loading={loading}>
            Save {title}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const AdminClassEntryEdit = (props) => {
  const {
    user,
    className,
    properties,
    listRoute,
    editRoute,
    history,
    title,
    FooterComponent,
    data: dataProps = {},
    entryId: entryIdProps,
    disableNavigation,
    autoLoad = true
  } = props;
  const {
    location: { pathname = '' },
  } = history;
  const entryId = entryIdProps || _.last(pathname.split('/'));

  const [form] = Form.useForm();

  const [data, setData] = useState(dataProps);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!_.size(entryId) || entryId === 'new' || !autoLoad) {
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const ClassName = Parse.Object.extend(className);
        const query = new Parse.Query(ClassName);
        query.equalTo('objectId', entryId);
        const result = await query.first();
        setData(result.toJSON());
      } catch (error) {
        notification.error({
          message: 'Cannot get data',
          description: 'Unfortunately, we cannot fetch this data for you.',
        });
      }
      setLoading(false);
    })();
  }, [entryId, autoLoad]);

  const handleSubmit = async (values) => {
    const { objectId } = values;
    setLoading(true);
    try {
      const ClassName = Parse.Object.extend(className);
      const newEntry = new ClassName();
      if (_.size(objectId)) {
        newEntry.id = objectId;
      }
      _.each(properties, (prop) => {
        const { type, key, pointerClass } = prop;
        if (_.size(key) && (values[key] || values[key] === false)) {
          if (type === 'pointer') {
            // Pointer needs to be set with pointer class reference
            const PointerClassName = Parse.Object.extend(pointerClass);
            const pointerObject = new PointerClassName();
            pointerObject.id = values[key];
            newEntry.set(key, pointerObject);
          } else {
            newEntry.set(key, values[key]);
          }
        }
      });
      const saveEntry = await newEntry.save();
      notification.success({
        message: 'All saved',
        description: 'The new entry was saved successfully.',
      });
      history.push(editRoute.replace(':id', saveEntry.id));
    } catch (error) {
      notification.error({
        message: 'Cannot save entry',
        description: 'Unfortunately, we cannot save the new entry. Please try again.',
      });
    }
    setLoading(false);
  };

  return (
    <Container>
      {!disableNavigation ? (
          <>
            <Link to={listRoute}>
              <Button type='primary' icon={<ArrowLeftOutlined />}>
                Back to overview
              </Button>
            </Link>
            <Spacer block />
          </>
      ) : false}
      <CardWrapper>
        <CardContent>
          {_.size(_.get(data, ['objectId'])) ? (
            <h1>
              Edit {title}: {_.get(data, ['name'])}
            </h1>
          ) : (
            <h1>Create new {title}</h1>
          )}
          <DataForm
            data={data}
            onSubmit={handleSubmit}
            form={form}
            loading={loading}
            properties={properties}
            title={title}
            isNew={entryId === 'new'}
            user={user}
          />
        </CardContent>
      </CardWrapper>

      <Spacer />

      {FooterComponent ? (
        <CardWrapper>
          <CardContent>{FooterComponent}</CardContent>
        </CardWrapper>
      ) : undefined}
    </Container>
  );
};
