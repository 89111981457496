import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';
import { routes } from '../../lib/routes';
import * as Parse from 'parse';

import { Form, Input, Select, Checkbox, Button, Col, Row, notification } from 'antd';

const Container = styled.div`
  height: calc(100vh - ${UI.NAVBAR_HEIGHT}px - ${UI.FOOTER_HEIGHT}px);
  display: block;
  width: 100vw;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
`;
const ContentWrapper = styled.div`
    max-width: ${UI.CONTENT_MAXWIDTH}
    padding: 15px;
`;
const CardWrapper = styled.div`
  padding: 30px;
  background-color: ${colors.WHITE};
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 20 },
  },
  colon: false,
};

const UserForm = ({ form, activeUser, onSubmit, loading }) => {
  const { getFieldDecorator } = form;

  const handleSubmit = (event) => {
    event.preventDefault();
    form.validateFieldsAndScroll((error, values) => {
      if (error) {
        notification.error({
          message: 'Cannot save user',
          description: 'Please review your inputs.',
        });
        return;
      }
      onSubmit(values);
    });
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='ObjectId'>
            {getFieldDecorator('objectId', {
              initialValue: activeUser.objectId,
              rules: [],
            })(<Input disabled />)}
          </Form.Item>
          <Form.Item label='Username'>
            {getFieldDecorator('username', {
              initialValue: activeUser.username,
              rules: [
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label='Password'>
            {getFieldDecorator('password', {
              rules: [],
            })(<Input type='password' />)}
          </Form.Item>
          <Form.Item label='Created'>
            {getFieldDecorator('createdAt', {
              initialValue: activeUser.createdAt,
              rules: [],
            })(<Input disabled />)}
          </Form.Item>
          <Form.Item label='Updated'>
            {getFieldDecorator('updatedAt', {
              initialValue: activeUser.updatedAt,
              rules: [],
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Roles'>
            {getFieldDecorator('roles', {
              initialValue: activeUser.roles || [],
              rules: [
                {
                  required: true,
                  message: 'Please select a user role!',
                },
              ],
            })(
              <Select mode='multiple'>
                <Select.Option key='user'>User</Select.Option>
                <Select.Option key='administrator'>Administrator</Select.Option>
              </Select>,
            )}
          </Form.Item>
          <Form.Item label=' '>
            {getFieldDecorator('active', {
              initialValue: activeUser.active || false,
              valuePropName: 'checked',
              rules: [],
            })(<Checkbox>Account is active</Checkbox>)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type='primary' htmlType='submit' loading={loading} disabled={!_.size(activeUser.objectId)}>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const AdminUsersEdit = (props) => {
  const { user, authenticated, match = {}, history } = props;
  const {
    location: { pathname = '' },
  } = history;
  const userId = _.last(pathname.split('/'));

  // const MemoizedForm = useMemo(() => Form.create({ name: 'register' })(UserForm), [UserForm]);

  const [activeUser, setActiveUser] = useState({});
  useEffect(() => {
    (async () => {
      try {
        const Users = Parse.Object.extend('_User');
        const query = new Parse.Query(Users);
        query.equalTo('objectId', userId);
        query.limit(1);
        const results = await query.find();
        if (_.size(results) === 1) {
          setActiveUser(results[0].toJSON());
        }
      } catch (error) {
        notification.error({
          message: 'Cannot get users',
          description: 'Unfortunately, we cannot fetch any users for you.',
        });
      }
    })();
  }, [userId]);

  const handleSubmit = async (values) => {
    const { objectId, password, username, roles = [], active = false } = values;
    // For now we don't allow any new user creation here
    if (!_.size(objectId)) {
      return;
    }
    try {
      const User = Parse.Object.extend('_User');
      const newUser = new User();
      if (_.size(objectId)) {
        newUser.id = objectId;
      }
      if (_.size(password) > 0) {
        newUser.set('password', password);
      }
      newUser.set('username', username);
      newUser.set('roles', roles);
      newUser.set('active', active);
      const savedUser = await newUser.save();
      notification.success({
        message: 'All saved',
        description: 'User was saved successfully.',
      });
      history.push(routes.ADMIN_USERS_EDIT.replace(':userId', savedUser.id));
    } catch (error) {
      notification.error({
        message: 'Cannot save user',
        description: 'Unfortunately, we cannot save the user. Please try again.',
      });
    }
  };

  return (
    <Container>
      <ContentWrapper>
        {_.size(_.get(activeUser, ['objectId'])) ? (
          <h1>Edit user: {_.get(activeUser, ['username'])}</h1>
        ) : (
          <h1>Create new user</h1>
        )}
        <CardWrapper></CardWrapper>
      </ContentWrapper>
    </Container>
  );
};
