import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { UI, colors } from '../../lib/theme';
import { Link } from 'react-router-dom';

import { Tag, Col, Row, Alert } from 'antd';
import { CardWrapper, CardContent, CardHeader, PullRight, CardFooter } from '../Layout/Layout';
import { formatPrice, getOrderFulfillmentStatus, orderStatuses } from '../../lib/utils';
import { routes } from '../../lib/routes';

const CardContainer = styled.div`
  position: relative;
`;
const CardWrapperWithColor = styled(CardWrapper)`
  border-left: 10px solid ${({ color }) => color || colors.PRIMARY};
`;

export const BatchOrderCard = ({ order, color, index }) => {
  if (!order) {
    return (
      <CardContainer>
        <CardWrapperWithColor color={color}>
          <CardHeader>
            <strong>Box {index + 1}</strong>
          </CardHeader>
        </CardWrapperWithColor>
      </CardContainer>
    );
  }

  const orderStatus = _.get(order, 'postStatus');
  const orderStatusConfig = _.get(orderStatuses, orderStatus);

  const orderFulfillment = _.get(order, 'fulfillment');
  const warehouse = _.get(orderFulfillment, 'warehouse');
  const orderFulfillmentStatus = getOrderFulfillmentStatus(orderFulfillment);

  return (
    <CardContainer>
      <CardWrapperWithColor color={color}>
        <CardHeader>
          <strong>
            Box {index + 1}
            {' - '}
            <Link to={routes.ORDER_FULFILLMENT_DETAILS.replace(':orderId', order.id)}>#{order.id}</Link>
            {' - '}
            {formatPrice(order.orderTotal)}
          </strong>
          <div>
            {orderStatusConfig ? <Tag color={orderStatusConfig.color}>{orderStatusConfig.title}</Tag> : ''}
            {orderFulfillmentStatus ? (
              <Tag color={orderFulfillmentStatus.color}>{orderFulfillmentStatus.title}</Tag>
            ) : null}
          </div>
        </CardHeader>
        <CardContent>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {_.size(order.shippingCompany) ? (
                <strong>
                  {order.shippingCompany}
                  <br />
                </strong>
              ) : (
                ''
              )}
              <strong>
                {order.shippingFirstName} {order.shippingLastName}
              </strong>
              <br />
              {order.shippingAddress1}
              <br />
              {order.shippingAddress2 ? (
                <>
                  {order.shippingAddress2}
                  <br />
                </>
              ) : null}
              {order.shippingCountry} - {order.shippingPostcode} {order.shippingCity}
              <br />
              <br />
              {orderFulfillment ? (
                <strong>
                  {orderFulfillment.itemCountFulfilled} / {orderFulfillment.itemCount} items fulfilled
                </strong>
              ) : null}
            </Col>
            <Col span={12}>
              {_.size(order.customerNote) || _.size(order.postExcerpt) ? (
                <Alert
                  message={
                    <>
                      <strong>Customer Note:</strong> {order.customerNote || order.postExcerpt}
                    </>
                  }
                  type='warning'
                  showIcon
                />
              ) : (
                <Alert message='No customer note' type='info' showIcon />
              )}
            </Col>
          </Row>
        </CardContent>
        <CardFooter>{warehouse ? <Tag color={warehouse.color}>{warehouse.name}</Tag> : null}</CardFooter>
      </CardWrapperWithColor>
    </CardContainer>
  );
};
