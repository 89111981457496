import React from 'react';
import { routes } from '../../lib/routes';
import { AdminClassEntryList } from '../../components/AdminClassEntryList/AdminClassEntryList';

const tableConfig = [
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: (image) => {
      return <img src={image} style={{ width: '100px' }}/>
    }
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Url',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: 'Published At',
    dataIndex: 'publishedAt',
    key: 'publishedAt',
  },
];

export const YoutubeVideos = ({ user, authenticated, history }) => {
  return (
    <AdminClassEntryList
      history={history}
      title={'Youtube Video'}
      className={'YoutubeVideo'}
      tableConfig={tableConfig}
      editRoute={routes.ADMIN_YOUTUBE_VIDEOS_EDIT}
    />
  );
};
