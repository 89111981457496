import React, { Component } from 'react';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';

import { OrdersFulfillmentTableByFilters } from '../../components/OrdersFulfillmentTableByFilters/OrdersFulfillmentTableByFilters';

const Container = styled.div`
  height: calc(100vh - ${UI.NAVBAR_HEIGHT}px - ${UI.FOOTER_HEIGHT}px);
  display: block;
  width: 100vw;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
`;
const ContentWrapper = styled.div`
    max-width: ${UI.CONTENT_MAXWIDTH}
    padding: 15px;
`;
const CardWrapper = styled.div`
  padding: 30px 0;
`;

const filterTags = {};
const fulfillmentFilterTags = {
  hasBeenBounced: true,
};
const warehouses = [];

export class OrderBounced extends Component {
  componentDidMount() {}

  render() {
    const { user } = this.props;
    return (
      <Container>
        <ContentWrapper>
          <CardWrapper>
            <OrdersFulfillmentTableByFilters
              title={`Bounced Orders`}
              user={user}
              filterTags={filterTags}
              fulfillmentFilterTags={fulfillmentFilterTags}
              warehouses={warehouses}
              showOrderStatistics={false}
            />
          </CardWrapper>
        </ContentWrapper>
      </Container>
    );
  }
}
