import React, { useState, useEffect } from 'react';
import { Popover, Form, Input, Button, Space } from 'antd';
import _ from 'lodash';
import { Spacer } from '../Layout/Layout';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export const PackerSelectionForm = ({ parseUser }) => {
  const parsePackerId = parseUser.get('packerId');
  const hasPackerId = _.size(parsePackerId) > 0;
  const [packerId, setPackerId] = useState(parsePackerId);

  const [visible, setVisible] = useState(hasPackerId === false);

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  useEffect(() => {
    // In case the packer ID was updated on the server or different device -> Sync it
    setPackerId(parsePackerId);
  }, [parsePackerId]);

  const formRef = React.createRef();

  const onReset = async () => {
    formRef.current.resetFields();
    if (parseUser) {
      try {
        parseUser.set('packerId', '');
        await parseUser.save();
        setPackerId(undefined);
      } catch (error) {
        console.warn('Packer selection form onReset failed!');
      }
    }
  };

  const onFinish = async (values) => {
    if (parseUser && _.size(values.PackerID) >= 2) {
      try {
        parseUser.set('packerId', values.PackerID);
        await parseUser.save();
        setPackerId(values.PackerID);
        setVisible(false);
      } catch (error) {
        console.warn('Packer selection form onFinish failed!');
      }
    }
  };

  return (
    <>
      <Space>
        <Popover
          content={
            <Form {...layout} ref={formRef} name='control-ref' onFinish={onFinish}>
              <Form.Item name='PackerID' label='Packer ID' rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
                <Spacer />
                <Button htmlType='button' onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Form>
          }
          trigger='click'
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <Button type={_.size(packerId) ? 'primary' : 'danger'}>
            Packer ID{_.size(packerId) ? `: ${packerId || ''}` : ''}
          </Button>
        </Popover>
      </Space>
    </>
  );
};
