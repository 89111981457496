import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import { routes } from '../../lib/routes';
import { Container, CardWrapper, ContentWrapper, LinkCard, LinkCardTitle } from '../../components/Layout/Layout';

import { OrdersFulfillmentTableByFilters } from '../../components/OrdersFulfillmentTableByFilters/OrdersFulfillmentTableByFilters';

const warehouses = [];
const filterTags = {
  hasProductionItems: true,
};
const fulfillmentFilterTags = {
  hasProductionFulfilled: false,
};

export const OrderProduction = (props) => {
  const {
    user,
    match: { params },
    location: { pathname = '' },
  } = props;

  return (
      <Container>

        <ContentWrapper>

          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Link to={routes.ORDER_PRODUCTION_BY_BRAND.replace(':brandId', 'misspompadour')}>
                <LinkCard>
                  <LinkCardTitle>MissPompadour Production</LinkCardTitle>
                </LinkCard>
              </Link>
            </Col>
            <Col span={6}>
              <Link to={routes.ORDER_PRODUCTION_BY_BRAND.replace(':brandId', 'cosycolours')}>
                <LinkCard>
                  <LinkCardTitle>CosyColours Production</LinkCardTitle>
                </LinkCard>
              </Link>
            </Col>
            <Col span={6}>
              <Link to={routes.ORDER_PRODUCTION_BY_BRAND.replace(':brandId', 'littlepomp')}>
                <LinkCard>
                  <LinkCardTitle>LittlePomp Production</LinkCardTitle>
                </LinkCard>
              </Link>
            </Col>
            <Col span={6}>
              <Link to={routes.ORDER_PRODUCTION_BY_BRAND.replace(':brandId', 'pearls')}>
                <LinkCard>
                  <LinkCardTitle>Pearls Production</LinkCardTitle>
                </LinkCard>
              </Link>
            </Col>
          </Row>

          <CardWrapper>
            <OrdersFulfillmentTableByFilters
                title={`Production Orders`}
                user={user}
                filterTags={filterTags}
                fulfillmentFilterTags={fulfillmentFilterTags}
                warehouses={warehouses}
                showOrderStatistics={false}
            />
          </CardWrapper>
        </ContentWrapper>
      </Container>
  );
};
