import React from 'react';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';

import { Spin } from 'antd';

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${colors.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Loader = () => {
  return (
    <Container>
      <Spin />
    </Container>
  );
};
