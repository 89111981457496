export const UI = {
  NAVBAR_HEIGHT: 64,
  FOOTER_HEIGHT: 0,
  SIDEBAR_WIDTH: 250,
  ANNOTATOR_TOPBAR_HEIGHT: 45,
  CONTENT_MAXWIDTH: 1200,
};

export const colors = {
  TRUE_BLACK: '#000',
  TRUE_BLACK10: '#f0f0f0',
  TRUE_BLACK20: '#d6d6d6',
  BLACK: '#000d1c',
  BLACK90: '#001529',
  BLACK80: '#001c38',
  BLACK70: '#15243b',
  BLACK60: '#162941',
  BLACK50: '#18324c',
  PRIMARY: '#1890ff',
  PRIMARY_LIGHT: '#6bc1ff',
  PRIMARY10: '#e8f5ff',
  SECONDARY: '#6589ac',
  SECONDARY_LIGHT: '#86b6e4',
  WHITE: '#ffffff',
  SUCCESS: '#1e9b4e',
  DANGER: '#c5394f',
  DANGER10: '#ffdad8',
  WARNING: '#c5a436',
  WARNING10: '#fffce5',

  AMAZON_ORANGE: '#FF9900',

  MAGENTA: '#dd408d',
  ROSE: '#dd90be',
  RED: '#d93936',
  VOLCANO: '#ff4a0d',
  ORANGE: '#ff7337',
  GOLD: '#e2c446',
  YELLOW: '#e2d708',
  LIME: '#93e233',
  GREEN: '#2dd072',
  CYAN: '#51cdc0',
  LIGHTBLUE: '#83d4d9',
  BLUE: '#238aff',
  GEEKBLUE: '#355ccc',
  PURPLE: '#6a1fb8',
  GREY: '#767676',

  STOCK: '#86b6e4',
  STOCK_DISABLED: '#9ea6ac',
  STOCK_ALLOCATED: '#e2d132',
  STOCK_AVAILABLE: '#6bd08f',
  STOCK_OUT: '#eb7579',

  MAGENTA_NEW: '#DB4373',
  GREEN_NEW: '#006669',
  ABALONE: '#E8E1E0',
  PALE_SILVER: '#c7c0b7',
  CHARCOAL: '#555049',
  BLACK_NEW: '#1D1F1F',
  DARK_GREY: '#414545',
  MID_GREY: '#A5A5A5',
  LIGHT_GREY: '#D2D2D2',
  VERY_LIGHT_GREY: '#EFF0F0',
};
