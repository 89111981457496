import React, {useCallback, useEffect, useState} from 'react';
import {Button, message} from "antd";
import {Loader} from "../Loader/Loader";
import {Spacer} from "../Layout/Layout";

const Parse = require('parse');

export const FacebookAuth = () => {
    const [ready, setReady] = useState(false);
    const [params, setParams] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        script.async = false;
        document.body.append(script);

        setTimeout(async () => {
            const nextParams = await Parse.Cloud.run('adminFetchFacebookClientLoginParams');
            setParams(nextParams);

            window.FB.init({
                appId: nextParams.appId,
                autoLogAppEvents: false,
                xfbml: false,
                version: 'v15.0'
            });
            setReady(true);
        }, 5000);
    }, []);

    const handleFacebookAuth = useCallback(() => {
        window.FB.login((response) => {
            (async () => {
                try {
                    // Send token to server
                    await Parse.Cloud.run('adminSaveFacebookClientAccessToken', {
                        accessToken: response.authResponse.accessToken,
                    });
                    message.success('Access Token successfully created!');
                } catch (error) {
                    message.error('Access Token failed!');
                    console.warn(error);
                }
            })();
        }, {
            scope: params.scopes
        });
    }, [params]);

    const handleFetchLatestInstagramMedia = useCallback(async () => {
        try {
            // Send token to server
            await Parse.Cloud.run('adminMediaFetchInstagramMedia', {
                continuePages: false,
            });
            message.success('Importing latest Instagram Feed!');
        } catch (error) {
            message.error('Importing latest Instagram Feed failed!');
            console.warn(error);
        }
    }, []);

    const handleFetchLatestInstagramStories = useCallback(async () => {
        try {
            // Send token to server
            await Parse.Cloud.run('adminMediaFetchInstagramStories', {
                continuePages: false,
            });
            message.success('Importing latest Instagram Stories!');
        } catch (error) {
            message.error('Importing latest Instagram Stories failed!');
            console.warn(error);
        }
    }, []);

    return (
        <div className="facebook-auth-container">
            <p><strong>This can only be done by Admins with access to the MissPompadour Instagram Account!</strong></p>
            {ready ? (
                <>
                    <Button onClick={handleFacebookAuth}>Login With Facebook</Button>
                    <Spacer/>
                    <Button onClick={handleFetchLatestInstagramMedia}>Import latest Instagram Media</Button>
                    <Spacer/>
                    <Button onClick={handleFetchLatestInstagramStories}>Import latest Instagram Stories</Button>
                </>
            ) : <Loader/> }
        </div>
    )
};
