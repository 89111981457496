import React from 'react';
import { routes } from '../../lib/routes';
import { AdminClassEntryList } from '../../components/AdminClassEntryList/AdminClassEntryList';

const tableConfig = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Product Count',
    dataIndex: 'productCount',
    key: 'productCount',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
  },
];

export const ProductFeeds = ({ user, authenticated, history }) => {
  return (
    <AdminClassEntryList
      history={history}
      title={'Product Feed'}
      className={'ProductFeed'}
      tableConfig={tableConfig}
      editRoute={routes.ADMIN_PRODUCT_FEEDS_EDIT}
    />
  );
};
