import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { UI, colors } from '../../lib/theme';
import { Link } from 'react-router-dom';

import { useLineItemFulfillment } from '../../hooks/useLineItemFulfillment';
import { useOrderFulfillment } from '../../hooks/useOrderFulfillment';

import { Col, Row, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useActiveOrderFulfillment } from '../../hooks/useActiveOrderFulfillment';

import { Container, ContentWrapper, CardWrapper, PullRight, CardHeader } from '../../components/Layout/Layout';
import { BatchOrderCard } from '../../components/BatchOrderCard/BatchOrderCard';
import { BatchOrderFulfillmentTable } from '../../components/BatchOrderFulfillmentTable/BatchOrderFulfillmentTable';
import { routes } from '../../lib/routes';
import { usePersistedState } from '../../hooks/usePersistedState';
import {useWarehouseStock} from "../../hooks/useWarehouseStock";

export const orderColors = [
  colors.RED,
  colors.BLACK,
  colors.GREEN_NEW,
  colors.CYAN,
  colors.GEEKBLUE,
  colors.YELLOW,
  colors.PURPLE,
  colors.ORANGE,

  colors.ROSE,
  colors.GREY,
  colors.LIGHTBLUE,
  colors.YELLOW,
  colors.LIME,
  colors.MAGENTA,
  colors.VOLCANO,
  colors.MAGENTA,
];

const ContentHeader = styled.div`
  position: fixed;
  top: ${UI.NAVBAR_HEIGHT};
  left: 0;
  right: 0;
  height: 55px;
  padding: 10px 15px;
  background-color: ${colors.WHITE};
  border-bottom: 1px solid ${colors.TRUE_BLACK20};
  z-index: 100;
  display: block;

  @media only screen and (max-width: 992px) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    padding-right: 15px;
    height: auto;
  }
`;

export const OrderFulfillmentBatchDetails = (props) => {
  const {
    user,
    match: { params },
    location: { pathname = '' },
  } = props;

  const { orders: claimedOrders = [], activeOrder } = useActiveOrderFulfillment({ user });
  const [orderQueue, setOrderQueue, orderQueueLoaded] = usePersistedState('orderQueue', {});

  const plentyOrderIdString = claimedOrders.map((order) => {
    return order.plentyId;
  }).join(',');
  const memoizedPlentyOrderIds = useMemo(() => {
    // Only take the first 8 orders
    return plentyOrderIdString.includes(',') ? plentyOrderIdString.split(',') : [];
  }, [plentyOrderIdString]);
  const { orders, claimOrder, unclaimOrder, bounceOrder } = useOrderFulfillment({
    user,
    orderIds: memoizedPlentyOrderIds,
    skipQuery: !_.size(memoizedPlentyOrderIds),
    usePlentyId: true,
    orderType: '1,5',
  });

  const warehouseId = _.get(_.first(orders), 'fulfillment.warehouse.objectId');

  const orderIdString = claimedOrders.map((order) => {
    return order.orderId;
  }).join(',');
  const memoizedOrderIds = useMemo(() => {
    // Only take the first 8 orders
    return orderIdString.includes(',') ? orderIdString.split(',') : [];
  }, [orderIdString]);
  const { lineItems, addItem, removeItem } = useLineItemFulfillment({
    user,
    orderIds: memoizedOrderIds,
    skipQuery: !_.size(memoizedOrderIds),
    warehouseId,
  });

  const sortedOrders = useMemo(() => _.orderBy(orders, ['id'], ['asc']), [orders]);
  const [forceUpdateOrderQueue, setForceUpdateOrderQueue] = useState(0);

  useEffect(() => {
    if (!orderQueueLoaded || !_.size(sortedOrders)) {
      return;
    }
    const nextOrderQueue = { ...orderQueue };
    const sortedOrderIds = sortedOrders.map((order) => order.id);
    // Cleanup old indizes, that are not part of the currently claimed orders any more
    _.each(nextOrderQueue, (orderId, index) => {
      if (!sortedOrderIds.includes(orderId)) {
        delete nextOrderQueue[index];
      }
    });

    // Assign new and free index - but only for box 1 to 8, > 8 stays without index until a free spot occurs
    _.each(sortedOrders, (order) => {
      // Skip if already assigned to a slot
      if (_.values(nextOrderQueue).includes(order.id)) {
        return;
      }
      let foundQueueNumber = false;
      _.times(8, (index) => {
        if (!foundQueueNumber && nextOrderQueue[index] === undefined) {
          nextOrderQueue[index] = order.id;
          foundQueueNumber = true;
        }
      });
    });

    setOrderQueue(nextOrderQueue);
  }, [sortedOrders, orderQueueLoaded, forceUpdateOrderQueue]);

  const queuedOrders = useMemo(() => {
    if (!orderQueue) {
      return [];
    }
    return _.times(8, (index) => {
      return sortedOrders.find((order) => order.id === orderQueue[index]);
    });
  }, [orderQueue]);

  useEffect(() => {
    const lastUpdated = (Date.now() - forceUpdateOrderQueue) // Check for 30 Seconds
    const orderWithBoxAllocated = queuedOrders.filter(o => _.isObject(o));
    if (_.size(orderWithBoxAllocated) === 8 && lastUpdated > 1000 * 30) {
      setOrderQueue({});
      setForceUpdateOrderQueue(Date.now());
    }
  }, [queuedOrders]);

  const orderLineItems = useMemo(() => {
    return _.flatten(orders.map((order) => order.lineItems));
  }, [orders]);

  const productionBaseProductIds = useMemo(() => {
    return _.flatten(orderLineItems.map(item => item.bundleItems)).filter(item => _.isObject(item)).map(item => item.variationId);
  }, [orderLineItems]);

  const { warehouseStocks: productionBaseWarehouseStock } = useWarehouseStock({
    live: false,
    productIds: productionBaseProductIds,
    warehouseId,
    skipQuery: !_.size(warehouseId) || !_.size(productionBaseProductIds),
  });

  const joinedLineItems = useMemo(() => {
    return lineItems
      .map((item) => {
        const orderLineItem = orderLineItems.find((i) => `${i.itemId}` === item.itemId);
        if (!orderLineItem) {
          return undefined;
        }
        const { itemName, paFarbvarianten, paProdukt, paFarbmenge, bundleItems } = orderLineItem;
        const attributes = [paFarbvarianten, paProdukt, paFarbmenge].filter((a) => _.size(a) > 0);

        // Add the base gtin to the product if it is a production item
        if (_.size(bundleItems)) {
          const bundleItemWarehouseStock = productionBaseWarehouseStock.find(stock => stock.plentyVariationId === _.first(bundleItems).plentyVariationId);
          // Set product Gtin to Base Gtin
          const baseGtin = _.get(bundleItemWarehouseStock, 'plentyProduct.gtin');
          if (_.isObject(_.get(item, 'warehouseStock.plentyProduct'))) {
            item.warehouseStock.plentyProduct.baseGtin = baseGtin;
          }
        }

        return {
          ...item,
          ...orderLineItem,
          attributedItemName: `${itemName} - ${attributes.join(',')}`,
          qty: item.qty,
        };
      })
      .filter((item) => _.isObject(item));
  }, [lineItems, orderLineItems, productionBaseWarehouseStock]);

  const queuedOrderCount = Math.min(_.size(sortedOrders), _.size(queuedOrders));
  return (
    <Container>
      <ContentHeader>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Link to={routes.ORDER_FULFILLMENT}>
              <Button type='primary' icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
          </Col>
          <Col lg={12}>
            <PullRight>
              <h1>{_.size(queuedOrders)} Order Batch</h1>
            </PullRight>
          </Col>
        </Row>
      </ContentHeader>

      <ContentWrapper>
        <Row gutter={[16, 16]}>
          {queuedOrders.map((order, index) => {
            return (
              <Col sm={24} md={12} key={`order-card-${index}`}>
                <BatchOrderCard order={order} color={orderColors[index]} index={index} />
              </Col>
            );
          })}
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <CardWrapper>
              <CardHeader>
                <h3>
                  Batch item fulfillment
                  <br />
                  <small>
                    {_.size(sortedOrders) - queuedOrderCount} additional orders claimed, {queuedOrderCount} of{' '}
                    {_.size(sortedOrders)} orders displayed
                  </small>
                </h3>
              </CardHeader>
              <BatchOrderFulfillmentTable
                orders={queuedOrders}
                colors={orderColors}
                lineItems={joinedLineItems}
                onAddItem={addItem}
                onRemoveItem={removeItem}
              />
            </CardWrapper>
          </Col>
        </Row>
      </ContentWrapper>

    </Container>
  );
};
