import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import * as Parse from 'parse';
import _ from 'lodash';
import {
  Container,
  ContentWrapper,
  CardWrapper,
  CardHeader,
  CardContent,
  PullRight,
  CardFooter,
  Spacer,
} from '../../components/Layout/Layout';
import {Row, Col, Upload, message, Button, Tag, Divider, AutoComplete, notification, Modal, Input, Alert} from 'antd';
import {
  MinusCircleOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import { colors } from '../../lib/theme';
import {routes} from "../../lib/routes";

const { confirm } = Modal;

const ProductImage = styled.img`
  width: 100%;
  min-height: 100px;
  background-color: #f2f2f2;
  border: 2px solid ${colors.TRUE_BLACK10};
  border-radius: 6px;
  display: block;
`;
const ProductQtyLabel = styled.span`
  width: 50px;
  display: inline-block;
  font-weight: bold;
  text-align: center;
`;

export const CustomerSupportCartLink = (props) => {
  const { user, authenticated, match = {}, history } = props;
  const {
    location: { pathname = '' },
  } = history;
  const cartLinkIdParam = _.last(pathname.split('/'));

  const [cartLinkId, setCartLinkId] = useState(cartLinkIdParam);
  const [dirty, setDirty] = useState(false);

  const [products, setProducts] = useState([]);
  const [productSearchOptions, setProductSearchOptions] = useState({});

  useEffect(() => {
    (async () => {
      if (cartLinkId !== 'new') {
        const CartLink = Parse.Object.extend('CartLink');
        const cartLinkQuery = new Parse.Query(CartLink);
        cartLinkQuery.equalTo('objectId', cartLinkId);
        const nextCartLink = await cartLinkQuery.first();
        const payload = nextCartLink.get('payload');
        const productIds = nextCartLink.get('productIds');
        if (payload && _.size(payload.products)) {
          setProducts(payload.products);
        }

        const PlentyProduct = Parse.Object.extend('PlentyProduct');
        const plentyProductQuery = new Parse.Query(PlentyProduct);
        plentyProductQuery.containedIn('externalId', productIds);
        const queryResults = await plentyProductQuery.find();
        const flatResults = queryResults.map(q => q.toJSON());
        updateSearchOptions(flatResults);
      }
    })();
  }, [cartLinkIdParam]);

  const handleSave = async (linkUrl = '') => {
    try {
      const CartLink = Parse.Object.extend('CartLink');
      let nextCartLink;
      if (cartLinkId !== 'new') {
        const cartLinkQuery = new Parse.Query(CartLink);
        cartLinkQuery.equalTo('objectId', cartLinkId);
        nextCartLink = await cartLinkQuery.first();
      }

      if (!nextCartLink) {
        nextCartLink = new CartLink();
      }

      const productIds = products.map(p => p.productId);
      const quantities = products.map(p => p.qty);
      nextCartLink.set('productIds', productIds);
      nextCartLink.set('quantities', quantities);
      nextCartLink.set('linkUrl', linkUrl);
      nextCartLink.set('payload', {
        products,
      });

      const savedCartLink = await nextCartLink.save();
      setCartLinkId(savedCartLink.id);
      setDirty(false);

      notification.success({
        message: 'All saved',
        description: 'The cart link was saved successfully.',
      });

      if (cartLinkIdParam !== savedCartLink.id) {
        history.push(routes.ADMIN_CUSTOMER_SUPPORT_CART_LINK.replace(':id', savedCartLink.id));
      }

      // Show Success Message
    } catch (error) {
      console.warn('handleSave failed', error);
      notification.success({
        message: 'Error :(',
        description: error.toString(),
      });
    }
  };

  const handleAddProduct = () => {
    setProducts([
      ...products,
      {
        qty: 1,
        productId: '',
      },
    ]);
  };

  const [productSearchParams, setProductSearchParams] = useState('');
  const [activeProductId, setActiveProductId] = useState(null);
  const productSearchDebounceTimeoutRef = useRef(null);

  const productSearchOptionsRef = useRef({});
  const updateSearchOptions = (products = []) => {
    products.forEach((product) => {
      productSearchOptionsRef.current = {
        ...productSearchOptionsRef.current,
        [product.externalId]: {
          value: `${product.externalId}`,
          label: product.name,
          colorCode: product.colorCode || '',
          inStock: true,
          imageUrl: product.imageUrl || '',
        }
      };
    });
    setProductSearchOptions(productSearchOptionsRef.current);
  }

  const [searchSuggestions, setSearchSuggestions] = useState([]);
  useEffect(() => {
    (async () => {
      const { index, searchTerm } = productSearchParams;
      if (!_.isNumber(index) || _.size(searchTerm) < 3) {
        return;
      }

      try {
        const PlentyProduct = Parse.Object.extend('PlentyProduct');
        const plentyProductQuery = new Parse.Query(PlentyProduct);
        plentyProductQuery.contains('name', searchTerm);
        plentyProductQuery.limit(20);
        const queryResults = await plentyProductQuery.find();
        const flatResults = queryResults.map(q => q.toJSON()).filter(p => {
          return p.isActive === true && _.size(p.externalId) > 0;
        });
        updateSearchOptions(flatResults);
        setSearchSuggestions(flatResults.map(p => {
          return {
            value: `${p.externalId}`,
            label: p.name,
          }
        }));
      } catch (error) {
        console.warn('Fetch Products failed', error);
        productSearchOptions[index] = [];
        setProductSearchOptions(productSearchOptions);
      }
    })();
  }, [productSearchParams]);

  const onProductSelect = (productId, index) => {
    const findProductFromSearchResults = (productSearchOptions[index] || []).find(
      (option) => option.value === productId,
    );
    const nextProducts = products.map((product, productIndex) => {
      if (index === productIndex) {
        return {
          ...product,
          productId,
          colorCode: _.get(findProductFromSearchResults, 'colorCode'),
          imageUrl: _.get(findProductFromSearchResults, 'imageUrl'),
          inStock: _.get(findProductFromSearchResults, 'inStock'),
        };
      }
      return product;
    });
    setProducts(nextProducts);
  };

  const handleIncrementProductQty = (index) => {
    const nextProducts = products.map((product, productIndex) => {
      return productIndex !== index ? product : {
        ...product,
        qty: product.qty + 1,
      };
    });
    setProducts(nextProducts);
  };
  const handleDecrementProductQty = (index) => {
    const nextProducts = products.map((product, productIndex) => {
      return productIndex !== index ? product : {
        ...product,
        qty: product.qty - 1,
      };
    }).filter(p => p.qty > 0);
    setProducts(nextProducts);
  };

  const onProductSearch = (value, index) => {
    if (productSearchDebounceTimeoutRef.current) {
      clearTimeout(productSearchDebounceTimeoutRef.current);
      productSearchDebounceTimeoutRef.current = null;
    }
    productSearchDebounceTimeoutRef.current = setTimeout(() => {
      setProductSearchParams({ searchTerm: value, index });
    }, 500);
  };

  const handleProductRowEnter = (objectId) => {
    setActiveProductId(objectId);
  };

  const handleProductRowLeave = (objectId) => {
    if (activeProductId === objectId) {
      setActiveProductId(null);
    }
  };

  const saveLinkDebounceTimeoutRef = useRef(null);

  const cartLink = useMemo(() => {
    const productIds = products.map(p => p.productId);
    const quantities = products.map(p => p.qty);
    const cartLinkParams = {
      cartId: cartLinkId,
      // productIds,
      // quantities,
    };
    const cartLinkParamsString = btoa(JSON.stringify(cartLinkParams));
    return `https://www.misspompadour.de/l/warenkorb-vorbereiten-n/?cart=${cartLinkParamsString}`;
  }, [products]);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(cartLink);
      notification.success({
        message: 'Copied to clipboard!',
      });
    } catch (error) {
      notification.error({
        message: 'Cannot copy to clipboard :(',
      });
    }
  };

  useEffect(() => {
    if (!dirty) {
      setDirty(true);
    }
  }, [products]);

  useEffect(() => {
    if (!dirty) {
      return;
    }
    const validProducts = products.filter(p => _.size(p.productId) && p.qty > 0);
    const allProductsValid = _.size(products) > 0 && _.size(validProducts) === _.size(products);
    if (!allProductsValid) {
      return;
    }
    if (saveLinkDebounceTimeoutRef.current) {
      clearTimeout(saveLinkDebounceTimeoutRef.current);
      saveLinkDebounceTimeoutRef.current = null;
    }
    saveLinkDebounceTimeoutRef.current = setTimeout(() => {
      handleSave(cartLink);
    }, 1000);
  }, [dirty, products]);

  return (
    <Container>
      <ContentWrapper>

        <CardWrapper>
          <CardHeader>
              <h2>Search Products and create Link</h2>
              {dirty ? <Tag color={colors.DANGER}>NOT SAVED</Tag> : <Tag color={colors.SUCCESS}>SAVED</Tag>}
          </CardHeader>
          <CardContent>
            <Row>
              <Col span={18}>
                <Input value={cartLink} disabled/>
              </Col>
              <Col span={6}>
                <Button type='primary' onClick={handleCopyLink}>Copy Link</Button>
              </Col>
            </Row>
          </CardContent>
          <CardContent>
            <Alert showIcon message={'The search is case sensitive! (Groß & Kleinschreibung beachten)'}/>
            <br/>
            <Alert showIcon message={'The stock check does not work right now :('}/>
          </CardContent>
          <CardContent>
              {products.map((product, index) => {
                if (!product) {
                  return null;
                }
                const { productId, objectId, qty } = product;
                const findProductById = Object.values(productSearchOptions || {}).find(
                  (option) => option.value === productId,
                );
                const productName = _.get(findProductById, 'label');
                const inStock = _.get(findProductById, 'inStock');
                const imageUrl = _.get(findProductById, 'imageUrl', '');
                return (
                  <Row
                    gutter={[16, 16]}
                    key={`${productId}-${index}`}
                    onMouseEnter={() => handleProductRowEnter(objectId)}
                    onMouseLeave={() => handleProductRowLeave(objectId)}
                  >
                    <Col span={4}>
                      <ProductImage src={imageUrl}/>
                    </Col>
                    <Col span={16}>
                      <AutoComplete
                        defaultValue={productId}
                        options={findProductById ? [findProductById]: searchSuggestions}
                        onSelect={(option) => onProductSelect(option, index)}
                        onSearch={(value) => onProductSearch(value, index)}
                        placeholder='Search for "Blau mit Grau - Stark"'
                        style={{ width: '100%' }}
                      />
                      <strong>{qty} x {productName} [{inStock ? 'In Stock' : 'Out of Stock'}]</strong>
                    </Col>
                    <Col span={4}>
                      <Button type='danger' onClick={() => handleDecrementProductQty(index)} icon={<MinusCircleOutlined />} />
                      <ProductQtyLabel> {qty} </ProductQtyLabel>
                      <Button type='primary' onClick={() => handleIncrementProductQty(index)} icon={<PlusCircleOutlined />} />
                    </Col>
                  </Row>
                );
              })}
          </CardContent>
          <CardFooter>
            <span>{_.size(products)} Products</span>
            <PullRight>
              <Button onClick={handleAddProduct}>Add new Product</Button>
            </PullRight>
          </CardFooter>
        </CardWrapper>
      </ContentWrapper>
    </Container>
  );
};
