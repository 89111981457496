import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import {Alert, Button, InputNumber, notification, Tag, List, Modal, Row, Col, Select, Table} from 'antd';
import * as Parse from 'parse';

const { confirm } = Modal;

const ButtonContainer = styled.div`
`;

export const OrderDeliveryNotEUProcessingButton = ({ plentyOrderId, onSuccess, disabled }) => {

  const [loading, setLoading] = useState(false);

  const handleUpdateOrderToNotEUProcessing = async () => {
    setLoading(true);
    try {
      const data = await Parse.Cloud.run('adminSavePlentyOrderStatus', {
        plentyOrderId,
        plentyOrderStatus: 6.5
      });
      setTimeout(() => {
        setLoading(false);
        notification.success({
          message: `Delivery moved to Not-EU Processing.`,
        });
        onSuccess();
      }, 2000);
    } catch (error) {
      console.warn('handleUpdateOrderToNotEUProcessing failed', error);
      setLoading(false);
      notification.error({
        message: `Failed to move delivery to Not-EU Processing.`,
      });
    }
  };

  const handleNotEUProcessing = () => {
    confirm({
      content: 'Do you want to delete this delivery? This can not be reversed!',
      onOk() {
        handleNotEUProcessing()
      },
      onCancel() {
      },
    });
  };

  if (!plentyOrderId) {
    return null;
  }

  return (
    <ButtonContainer>
      <Button type='primary' onClick={() => handleUpdateOrderToNotEUProcessing()} loading={loading} disabled={disabled}>To Not-EU Processing</Button>
    </ButtonContainer>
  );
};
