import React from 'react';
import styled from 'styled-components';
import { UI, colors } from '../../lib/theme';
import _ from 'lodash';

import {Avatar, Menu, Dropdown, Button} from 'antd';
import {LogoutOutlined, ProfileOutlined, UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {routes} from "../../lib/routes";


const MenuContainer = styled.div`
  float: right;
`;
const DropdownContainer = styled.div`
`;
const UserAvatarContainer = styled(Avatar)`
  background-color: ${colors.PRIMARY};
  
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const UserAvatar = styled(UserOutlined)`
  color: ${colors.white};
  font-size: 18px;
`;
const UserName = styled.p`
  color: ${colors.WHITE};
  font-size: 15px;
  margin-right: 10px;
  display: inline-block;
`;
const StyledMenuMobile = styled(Menu).attrs({
    theme: 'dark',
    mode: 'vertical',
})`
  line-height: ${UI.NAVBAR_HEIGHT}px;
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;
const MobileVisible = styled.div`
  display: none;
  
  @media only screen and (max-width: 768px) {
    border-top: 1px solid ${colors.BLACK70};
    display: block;
    width: 100vw;
    padding: 20px;
  }
`;
const DesktopVisible = styled.div`
  display: block;
  
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const UserMenu = ({ onLogout, user, showMobile = true, showDesktop = true }) => {

    const menu = (
        <StyledMenuMobile>
            <Menu.Item icon={<UserOutlined/>}>
                <Link to={routes.USER_PROFILE}>Profile</Link>
            </Menu.Item>
            <Menu.Item onClick={onLogout} icon={<LogoutOutlined/>}>
                Logout
            </Menu.Item>
        </StyledMenuMobile>
    );

  return (
      <MenuContainer>
          {showMobile ? (
              <MobileVisible>
                  <UserName>{_.get(user, 'username', '')}</UserName>
                  <UserAvatarContainer>
                      <UserAvatar/>
                  </UserAvatarContainer>
                  {menu}
              </MobileVisible>
          ) : null}
          {showDesktop ? (
              <DesktopVisible>
                  <Dropdown overlay={menu} placement="bottomRight" trigger="click" arrow>
                      <DropdownContainer>
                          <UserName>{_.get(user, 'username', '')}</UserName>
                          <UserAvatarContainer>
                              <UserAvatar/>
                          </UserAvatarContainer>
                      </DropdownContainer>
                  </Dropdown>
              </DesktopVisible>
          ) : null}
      </MenuContainer>
  );
};
