import _ from 'lodash';
import React from 'react';
import { routes } from '../../lib/routes';
import { AdminClassEntryList } from '../../components/AdminClassEntryList/AdminClassEntryList';
import { Tag } from 'antd';
import {colors} from "../../lib/theme";
import {colorTagData, qualityTagData, roomTagData, sourceTagData, typeTagData} from "./ImageLibraryEdit";
import moment from "moment";

const tableConfig = [
  {
    title: 'After Image',
    dataIndex: 'previewImageUrl',
    key: 'previewImageUrl',
    render: (imageUrl = '', row) => {
      const previewImageUrl = _.get(row, 'afterImageUrl', imageUrl);
      const imageUrlSm = previewImageUrl.replace('.jpg', '-xs.jpg').replace('.png', '-xs.png').replace('.jpeg', '-xs.jpeg');
      return (imageUrlSm ? <img src={imageUrlSm} style={{ width: 100 }} /> : '')
    },
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (active) => active ? <Tag color={colors.BLUE}>TRUE</Tag> : <Tag color={colors.RED}>FALSE</Tag>,
  },
  {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
  },
  {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
  },
  {
    title: 'Likes',
    dataIndex: 'likeCount',
    key: 'likeCount',
    render: (likeCount) => <span>{likeCount} Likes</span>,
  },
  {
    title: 'Room Tags',
    dataIndex: 'roomTags',
    key: 'roomTags',
    render: (roomTags = []) =>
      roomTags.map((roomTag) => {
        return <Tag key={roomTag}>{roomTag}</Tag>;
      }),
  },
  {
    title: 'Quality',
    dataIndex: 'quality',
    key: 'quality',
    render: (quality) => <Tag>{quality}</Tag>,
  },
  {
    title: 'Published',
    dataIndex: 'publishedAt',
    key: 'publishedAt',
    render: (publishedAt, row) => moment(publishedAt || row.createdAt).format('YYYY-MM-DD'),
  },
];

const createOptions = (data) => {
  return data.map((tag) => {
    return {
      value: tag,
      label: tag,
    }
  })
}

const filterOptions = [
  {
    field: 'active',
    multi: false,
    options: [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' },
    ]
  },
  {
    field: 'quality',
    multi: false,
    options: createOptions(qualityTagData)
  },
  {
    field: 'roomTags',
    multi: false,
    fieldType: 'array',
    options: createOptions(roomTagData)
  },
  {
    field: 'colorTags',
    multi: false,
    fieldType: 'array',
    options: createOptions(colorTagData)
  },
  {
    field: 'source',
    multi: false,
    options: createOptions(sourceTagData)
  },
  {
    field: 'type',
    multi: false,
    options: createOptions(typeTagData)
  }
]

const sortOptions = [
  {
    value: 'createdAt|DESC',
    label: 'Date DESC'
  },
  {
    value: 'createdAt|ASC',
    label: 'Date ASC'
  },
  {
    value: 'likeCount|DESC',
    label: 'Likes DESC'
  },
  {
    value: 'likeCount|ASC',
    label: 'Likes ASC'
  }
]

export const ImageLibrary = ({ user, authenticated, history }) => {
  return (
        <AdminClassEntryList
            history={history}
            title={'Image Collection'}
            className={'ImageCollection'}
            tableConfig={tableConfig}
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            editRoute={routes.ADMIN_IMAGE_LIBRARY_EDIT}
        />
  );
};
