import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

import { Container, ContentWrapper, CardWrapper } from '../../components/Layout/Layout';
import { OrdersFulfillmentTableByFilters } from '../../components/OrdersFulfillmentTableByFilters/OrdersFulfillmentTableByFilters';

export const OrderProductionByBrand = (props) => {
  const {
    user,
    match: { params },
    location: { pathname = '' },
  } = props;
  const brandSlug = _.last(pathname.split('/'));

  const { filterTags, fulfillmentFilterTags, warehouses } = useMemo(() => {
    const filterTags = {};
    // Do not show this order in laaber for fulfillment -> Only in sinzing after it was transferred
    if (brandSlug === 'misspompadour') {
      filterTags.hasMissPompadourManufacture = true;
    } else if (brandSlug === 'cosycolours') {
      filterTags.hasCosyColoursProduction = true;
    } else if (brandSlug === 'littlepomp') {
      filterTags.hasLittlePompProduction = true;
    } else if (brandSlug === 'pearls') {
      filterTags.hasPearlsProductionItems = true;
    }
    return {
      warehouses: [],
      filterTags,
      fulfillmentFilterTags: {
        hasProductionFulfilled: false,
      },
    };
  }, [brandSlug]);

  return (
    <Container>
      <ContentWrapper>
        <CardWrapper>
          <OrdersFulfillmentTableByFilters
            title={`Production Orders ${brandSlug}`}
            user={user}
            filterTags={filterTags}
            fulfillmentFilterTags={fulfillmentFilterTags}
            warehouses={warehouses}
            showOrderStatistics={false}
          />
        </CardWrapper>
      </ContentWrapper>
    </Container>
  );
};
