import React, {useState} from 'react';
import styled from 'styled-components';

import {Button} from 'antd';

const Container = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(255,255,255,0.9);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    flex-direction: column;
`;
const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 20px 50px;
`;
const LogoImage = styled.img`
    height: 80px;
    width: 200px;
    object-fit: contain;
`;
const ConfirmButton = styled(Button)`
    width: 100%;
`;

const standardNotes = [
    'No Extra Material!',
    'No Flyers!',
    // 'Include Delivery Slip'
]
const configs = {
    b2b: {
        name: "B2B Mylands",
        image: '/images/mylands-logo.jpg',
        notes: standardNotes,
    },
    avocado: {
        name: "Avocado",
        image: '/images/avocado-logo.jpg',
        notes: standardNotes,
    },
    amazon: {
        name: "Amazon",
        image: '/images/amazon-logo.jpg',
        notes: standardNotes,
    },
    otto: {
        name: "Otto",
        image: '/images/otto-logo.jpg',
        notes: standardNotes,
    },
}

const getConfig = (type) => {
    if (configs[type]) {
        return configs[type];
    }
    return {
        name: type.toUpperCase(),
        image: `/images/${type.split(' ').join('-')}-logo.png`,
        notes: standardNotes,
    };
}

const Content = ({ type }) => {
    const config = getConfig(type);
    return (
        <>
            <LogoImage src={config.image}/>
            <h2>{config.name} Order!</h2>
            <ul>
                {config.notes.map((note, index) => (
                    <li key={index}>{note}</li>
                ))}
            </ul>
        </>
    )
};

export const OrderPackageNotesOverlay = ({ order }) => {
    const [show, setShow] = useState(true);

    if (!order || !show) {
        return null;
    }
    const { isB2BOrder, originId, originName = '', paymentMethod, shippingCountry } = order;
    const isAvocado = paymentMethod === 'avocadostore'; // Legacy
    const isAmazon = originName.toLowerCase().includes('amazon');
    const isOtto = originName.toLowerCase().includes('otto');
    const isMarketplace = originName.toLowerCase().includes('marketplace');
    if (!isB2BOrder && !isAvocado && !isAmazon && !isOtto && !isMarketplace) {
        return null;
    }
    return (
        <Container>
            <ContentContainer>
                {isB2BOrder ? <Content type={'b2b'}/> : null}
                {isAvocado ? <Content type={'avocado'}/> : null}
                {isOtto ? <Content type={'otto'}/> : null}
                {isAmazon ? <Content type={'amazon'}/> : null}
                {isMarketplace ? <Content type={originName.toLowerCase()}/> : null}
                <ConfirmButton type='primary' onClick={() => setShow(false)}>Alright!</ConfirmButton>
            </ContentContainer>
        </Container>
    )
};
