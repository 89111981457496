import React from 'react';
import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { Root } from './containers/Root/Root';
import { colors } from './lib/theme';

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.BLACK};
  display: block;
`;

export const App = () => {
  return (
    <BrowserRouter>
      <AppContainer>
        <Root />
      </AppContainer>
    </BrowserRouter>
  );
};
