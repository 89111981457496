import React, { useState, useEffect, useMemo, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { UI, colors } from '../../lib/theme';

import { Loader } from '../../components/Loader/Loader';
import { DATE_TIME_FORMAT } from '../../lib/constants';

import { Col, Row, Tag, Alert } from 'antd';
import {
  formatPrice,
  getDuration,
} from '../../lib/utils';

import {
  CardWrapper,
  CardContent,
  CardHeader,
} from '../../components/Layout/Layout';
import {OrderOriginTags} from "../OrderOriginTags/OrderOriginTags";


export const OrderFulfillmentAddress = ({ order }) => {

  return (
    <>
      <Col sm={12} xs={24}>
        <CardWrapper>
          <CardHeader>
            <h1>Order Details</h1>
            {order ? (
                <p>
                  {moment(order.postDate).format(DATE_TIME_FORMAT)} ({getDuration(order.postDate)})
                </p>
            ) : (
                ''
            )}
          </CardHeader>
          <CardContent>
            {order ? (
                <>
                  <OrderOriginTags order={order}/>
                  <br /><br />
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <strong>Shipping:</strong>
                      <br />
                      {_.size(order.shippingCompany) ? (
                          <strong>
                            {order.shippingCompany}
                            <br />
                          </strong>
                      ) : null}
                      <strong>
                        {order.shippingFirstName} {order.shippingLastName}
                      </strong>
                      <br />
                      {order.shippingAddress1}
                      <br />
                      {order.shippingAddress2 ? (
                          <>
                            {order.shippingAddress2}
                            <br />
                          </>
                      ) : null}
                      {order.shippingCountry} - {order.shippingPostcode} {order.shippingCity}
                      <br />
                    </Col>
                  </Row>
                  <br />
                  {_.size(order.customerNote) || _.size(order.postExcerpt) ? (
                      <Alert
                          message={
                            <>
                              <strong>Customer Note:</strong> {order.customerNote || order.postExcerpt}
                            </>
                          }
                          type='warning'
                          showIcon
                      />
                  ) : (
                      <Alert message='No customer note' type='info' showIcon />
                  )}
                  <br />
                  <small>{order.customerUserAgent}</small>
                  {order.notEU ? (
                      <Alert
                          message={
                            <>
                              <strong>Not-EU Delivery:</strong> This order needs to be shipped to a third country, that is not part of the EU.
                            </>
                          }
                          type='warning'
                          showIcon
                      />
                  ) : null}
                </>
            ) : (
                <Loader />
            )}
          </CardContent>
        </CardWrapper>
      </Col>

      <Col sm={12} xs={24}>
        <CardWrapper>
          <CardHeader>
            <h1>Payment</h1>
            <p>{order ? <strong>{order.paymentMethodTitle}</strong> : ''}</p>
          </CardHeader>
          <CardContent>
            {order ? (
                <p>
                  <strong>Total</strong>: {formatPrice(order.orderTotal)}
                  <br />
                  <strong>- Taxes</strong>: {formatPrice(order.orderTax)}
                  <br />
                  <strong>- Shipping</strong>:{' '}
                  {formatPrice(parseFloat(order.orderShipping) + parseFloat(order.orderShippingTax))}
                  <br />
                </p>
            ) : (
                <Loader />
            )}
          </CardContent>
        </CardWrapper>
      </Col>
    </>
  );
};
